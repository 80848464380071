<template>
  <div class="modal">
    <div class="modal-content">
      <!-- Cabeçalho -->
      <div class="modal-header">
        <img
          v-if="icon"
          :src="icon"
          alt="Ícone"
          class="modal-icon"
          :style="{ width: '30px', height: '30px' }"
        />
        <h3 class="modal-title">{{ title }}</h3>
        <div class="modal-actions">
          <i class="fas fa-times close-icon" @click="$emit('close')"></i>
        </div>
      </div>

      <!-- Corpo -->
      <div class="modal-body">
        <div class="input-group input-a-SubsetorModal">
          <input
            id="subsetor"
            type="text"
            placeholder="Pesquisar Subsetor"
            class="input-field"
            ref="pesquisarInput"
            @input="pesquisarSubsetor($event.target.value)"
          />
        </div>
        <div class="input-group input-a-SubsetorModal">
          <input
            id="cliente"
            type="text"
            placeholder="Pesquisar Subsetor"
            class="input-field"
            @input="pesquisarSubsetorB($event.target.value)"
          />
        </div>
      </div>
      <v-main class="custom-tabela-subsetor">
        <div class="tabela-header">
          <table class="tabela">
            <thead>
              <tr>
                <th class="A1subsetor-header">Setor</th>
                <th class="B1subsetor-header"></th>
                <th class="C1subsetor-header"></th>
                <th class="D1subsetor-header"></th>
                <th :class="['statussubsetor-header']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      class="status-checkbox-header"
                      @change="pesquisarFechada($event.target.checked)"
                      title="Marcado significa Subsetor fechada "
                    />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tabela-wrapper">
          <table class="tabela">
            <tbody>
              <tr
                v-for="task in tasks"
                :key="task.subsetor_id"
                class="tr-subsetor"
                @dblclick="SubsetorSelecionado(task)"
                style="cursor: pointer"
              >
                <td class="A1subsetor-cell">
                  <input
                    v-model="task.subsetor"
                    @keydown.enter.prevent="EnterSubsetorEditar(task)"
                    class="A2subsetor-cell"
                    style="cursor: pointer"
                    :readonly="
                      !editingTaskId || editingTaskId !== task.subsetor_id
                    "
                    :style="{
                      backgroundColor:
                        editingTaskId === task.subsetor_id ? '#f7f702' : '',
                      cursor: editingTaskId === task.subsetor_id ? '' : 'pointer',
                      boxShadow:
                        editingTaskId === task.subsetor_id
                          ? 'inset 2px 2px 5px rgba(0,0,0,0.2)'
                          : '',
                      border:
                        editingTaskId === task.subsetor_id
                          ? '1px solid #dcdcdc'
                          : '',
                      transform:
                        editingTaskId === task.subsetor_id ? 'translateZ(0)' : '',
                    }"
                  />
                </td>
                <td class="B1subsetor-cell">
                  <i
                    class="fas fa-plus-circle"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Adicionar"
                    @click="enableAdiciona(task)"
                  ></i>
                </td>
                <td class="C1subsetor-cell">
                  <i
                    class="fas fa-edit"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Editar"
                    @click="enableEdit(task)"
                  ></i>
                </td>
                <td class="D1subsetor-cell">
                  <i
                    class="fas fa-times-circle"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Excluir"
                    @click="enableExclui(task)"
                  ></i>
                </td>
                <td :class="['statussubsetor-cell']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      :checked="task.statussubsetor === true"
                      @change="
                        (event) => {
                          task.statussubsetor = event.target.checked ? true : false;
                          editarStatusSubsetor(task);
                        }
                      "
                      class="status-checkbox"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-main>
      <!-- Rodapé (opcional) -->
      <div class="modal-footer" v-if="showFooter">
        <slot name="footer">
          <button class="btn btn-secondary" @click="$emit('close')">
            Cancelar
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>
    
      <script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "../subsetor/subsetor.css";
import { data, Subsetor, LarguraDropdown } from "../subsetor/subsetor.js";

export default {
  name: "SubsetorModal",
  data() {
    return {
      ...data(),
    };
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "Subsetor",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "/123/icones/icone_preto.png", // Caminho do ícone ou null
    },
    task: {
      type: [Object, Number],
      required: true,
    },
  },

  watch: {
    tasks: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.adjustHeaderWidth();
        });
      },
    },
  },
  mounted() {
    this.initializeTasks().then(() => {
      LarguraDropdown();
    });
    this.$refs.pesquisarInput.focus();
    this.adjustHeaderWidth();
    window.addEventListener("resize", this.adjustHeaderWidth);
  },
  methods: {
    ...Subsetor,
  },
};
</script>