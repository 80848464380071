<template>
  <div>
    <div class="modal-overlay">
      <div class="modal-fullscreen">
        <!-- Cabeçalho -->
        <div class="modal-header">
          <img
            v-if="icon"
            :src="icon"
            alt="Ícone"
            class="modal-icon"
            :style="{ width: '30px', height: '30px' }"
          />
          <h3 class="modal-title">{{ title }}</h3>
          <div class="modal-actions">
            <i class="fas fa-times close-icon" @click="$emit('close')"></i>
          </div>
        </div>

        <!-- Corpo -->
        <div class="modal-body">
          <div class="input-group input-a-TarefaModal">
            <input
              id="tarefa"
              type="text"
              placeholder="Pesquisar Serviço"
              class="input-field"
              ref="pesquisarInput"
              @keydown="pesquisarServico"
            />
          </div>
          <div class="input-group input-a-TarefaModal">
            <input
              id="tarefa"
              type="text"
              placeholder="Pesquisar Serviço"
              class="input-field"
              @keydown="pesquisarObs"
            />
          </div>
        </div>
        <div class="parent-container">
          <!-- Tabela Dinâmica -->
          <div class="custom-main-lista">
            <div
              class="lista-content-wrapper"
              ref="contentWrapper"
              @scroll="syncScroll"
            >
              <table class="lista-table">
                <thead>
                  <tr>
                    <th
                      v-for="(coluna, index) in colunas"
                      :key="index"
                      :colspan="coluna.colSpan"
                      class="th-lista"
                      :class="coluna.className"
                      :style="[coluna.style, getThStyles()]"
                    >
                      <template v-if="coluna.isButton">
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                          "
                        >
                          <i
                            class="fas fa-plus-circle handle-Servico-modal-adicionar"
                            style="
                              color: white;
                              font-size: 12px;
                              cursor: pointer;
                            "
                            title="Adicionar Servico"
                            @click="handleServicoAdiconar"
                          ></i>
                        </div>
                      </template>

                      <template v-else-if="coluna.isHtml">
                        <!-- Renderiza o HTML da coluna -->
                        <div v-html="coluna.label"></div>
                      </template>

                      <template v-else>
                        <!-- Renderiza o texto da coluna -->
                        {{ coluna.label }}
                      </template>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="task in tasks"
                    :key="task.id"
                    class="tr-lista"
                    :class="getRowClasses(task)"
                  >
                    <td
                      v-for="(coluna, index) in filteredColunas(task)"
                      :key="`col-${index}`"
                      class="td-servico_obs"
                      :colspan="getColSpan(task, coluna)"
                      :style="getTdStyles(task, coluna)"
                      @click="
                        coluna.field === 'a' ? handleTaskClick(task) : null
                      "
                      @dblclick="
                        coluna.field === 'a' && task.tipo === 'Servico'
                          ? handleDoubleClick(task)
                          : null
                      "
                      :title="
                        coluna.field === 'c' && task.tipo === 'Item'
                          ? 'Unidade 01'
                          : coluna.field === 'e' && task.tipo === 'Item'
                          ? 'Unidade 02'
                          : coluna.field === 'g' && task.tipo === 'Item'
                          ? 'Unidade 03'
                          : coluna.field === 'i' && task.tipo === 'Item'
                          ? 'Unidade 04'
                          : coluna.field === 'e' && task.tipo === 'Servico'
                          ? 'Unidade 05'
                          : ''
                      "
                    >
                      <!-- Verificação condicional para U01 -->
                      <template
                        v-if="task.tipo === 'Item' && coluna.field === 'c'"
                      >
                        <div
                          v-if="editingTaskIdU01 === task.trabalho_registro"
                          class="dropdown-wrapper-unidade"
                          :data-dropdown-id-U01="task.trabalho_registro"
                        >
                          <input
                            v-model="editableValueU01"
                            class="dropdown-input-unidade"
                            placeholder="Digite para filtrar"
                          />
                          <ul
                            v-if="
                              filteredOptionsU01 && filteredOptionsU01.length
                            "
                            class="dropdown-options-unidade"
                          >
                            <li
                              v-for="(option, index) in filteredOptionsU01"
                              :key="index"
                              :class="['dropdown-option-unidade']"
                              @click="handleU01ItemClick(option)"
                            >
                              {{ option.label }}
                            </li>
                          </ul>
                        </div>
                        <span v-else @click="enableU01Edit(task)">
                          {{ task.u01 || "-" }}
                        </span>
                      </template>

                      <!-- Verificação condicional para U02 -->
                      <template
                        v-else-if="task.tipo === 'Item' && coluna.field === 'e'"
                      >
                        <div
                          v-if="editingTaskIdU02 === task.trabalho_registro"
                          class="dropdown-wrapper-unidade"
                          :data-dropdown-id-U02="task.trabalho_registro"
                        >
                          <input
                            v-model="editableValueU02"
                            class="dropdown-input-unidade"
                            placeholder="Digite para filtrar"
                          />
                          <ul
                            v-if="
                              filteredOptionsU02 && filteredOptionsU02.length
                            "
                            class="dropdown-options-unidade"
                          >
                            <li
                              v-for="(option, index) in filteredOptionsU02"
                              :key="index"
                              :class="['dropdown-option-unidade']"
                              @click="handleU02ItemClick(option)"
                            >
                              {{ option.label }}
                            </li>
                          </ul>
                        </div>
                        <span v-else @click="enableU02Edit(task)">
                          {{ task.u02 || "-" }}
                        </span>
                      </template>

                      <!-- Verificação condicional para U03 -->
                      <template
                        v-else-if="task.tipo === 'Item' && coluna.field === 'g'"
                      >
                        <div
                          v-if="editingTaskIdU03 === task.trabalho_registro"
                          class="dropdown-wrapper-unidade"
                          :data-dropdown-id-U03="task.trabalho_registro"
                        >
                          <input
                            v-model="editableValueU03"
                            class="dropdown-input-unidade"
                            placeholder="Digite para filtrar"
                          />
                          <ul
                            v-if="
                              filteredOptionsU03 && filteredOptionsU03.length
                            "
                            class="dropdown-options-unidade"
                          >
                            <li
                              v-for="(option, index) in filteredOptionsU03"
                              :key="index"
                              :class="['dropdown-option-unidade']"
                              @click="handleU03ItemClick(option)"
                            >
                              {{ option.label }}
                            </li>
                          </ul>
                        </div>
                        <span v-else @click="enableU03Edit(task)">
                          {{ task.u03 || "-" }}
                        </span>
                      </template>

                      <!-- Verificação condicional para U04 -->
                      <template
                        v-else-if="task.tipo === 'Item' && coluna.field === 'i'"
                      >
                        <div
                          v-if="editingTaskIdU04 === task.trabalho_registro"
                          class="dropdown-wrapper-unidade"
                          :data-dropdown-id-U04="task.trabalho_registro"
                        >
                          <input
                            v-model="editableValueU04"
                            class="dropdown-input-unidade"
                            placeholder="Digite para filtrar"
                          />
                          <ul
                            v-if="
                              filteredOptionsU04 && filteredOptionsU04.length
                            "
                            class="dropdown-options-unidade"
                          >
                            <li
                              v-for="(option, index) in filteredOptionsU04"
                              :key="index"
                              :class="['dropdown-option-unidade']"
                              @click="handleU04ItemClick(option)"
                            >
                              {{ option.label }}
                            </li>
                          </ul>
                        </div>
                        <span v-else @click="enableU04Edit(task)">
                          {{ task.u04 || "-" }}
                        </span>
                      </template>

                      <!-- Verificação condicional para U05 -->
                      <template
                        v-else-if="
                          task.tipo === 'Servico' && coluna.field === 'e'
                        "
                      >
                        <div
                          v-if="editingTaskIdU05 === task.trabalho_registro"
                          class="dropdown-wrapper-unidade"
                          :data-dropdown-id-U05="task.trabalho_registro"
                        >
                          <input
                            v-model="editableValueU05"
                            class="dropdown-input-unidade"
                            placeholder="Digite para filtrar"
                          />
                          <ul
                            v-if="
                              filteredOptionsU05 && filteredOptionsU05.length
                            "
                            class="dropdown-options-unidade"
                          >
                            <li
                              v-for="(option, index) in filteredOptionsU05"
                              :key="index"
                              :class="['dropdown-option-unidade']"
                              @click="handleU05ItemClick(option)"
                            >
                              {{ option.label }}
                            </li>
                          </ul>
                        </div>
                        <span v-else @click="enableU05Edit(task)">
                          {{ task.u05 || "-" }}
                        </span>
                      </template>

                      <!-- Renderização padrão -->
                      <template v-else>
                        <span v-html="getCellValue(task, coluna.field)"></span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Rodapé (opcional) -->
        <div class="modal-footer" v-if="showFooter">
          <slot name="footer">
            <button class="btn btn-secondary" @click="$emit('close')">
              Cancelar
            </button>
          </slot>
        </div>
        <div>
          <!-- Modal Setor -->
          <ServicoModal
            v-if="isModalServicoVisible"
            :task="selectedTask"
            :context="pageContext"
            @close="fecharServicoModal"
            @fecharModalPlace="fecharServicoModal"
          />
        </div>
        <div>
          <!-- Modal Setor -->
          <ItemModal
            v-if="isModalItemVisible"
            :task="selectedTask"
            :context="pageContext"
            @close="fecharItemModal"
            @fecharModalPlace="fecharItemModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>
    
      <script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "../lista/lista.css";
import ServicoModal from "../servico/ServicoModal.vue";
import ItemModal from "../item/ItemModal.vue";
import {
  data,
  Tarefa,
  tabela,
  TaskVisibilityMethods,
  methods,
  LarguraDropdown,
  u01,
  u02,
  u03,
  u04,
  u05,
} from "./lista.js";

export default {
  name: "ListaModal",
  data() {
    return {
      ...data(),
    };
  },
  components: {
    ServicoModal,
    ItemModal,
  },
  created() {
    window.app = this;
  },
  props: {
    title: {
      type: String,
      default: "Servico",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "/123/icones/icone_preto.png", // Caminho do ícone ou null
    },
    task: {
      type: [Object, Number],
      required: true,
    },
    context: {
      type: Object,
      required: true, // Torna obrigatório para garantir que seja recebido
    },
  },
  computed: {
    filteredOptionsU01() {
      const searchValueU01 = this.editableValueU01.toLowerCase().trim();
      const rawOptionsU01 = this.u01Options; // Não precisa de toRaw se os dados são reativos

      if (!searchValueU01) {
        return rawOptionsU01; // Retorna todas as opções se não houver filtro
      }

      return rawOptionsU01.filter((option) =>
        option.label.toLowerCase().includes(searchValueU01)
      );
    },

    filteredOptionsU02() {
      const searchValueU02 = this.editableValueU02.toLowerCase().trim();
      const rawOptionsU02 = this.u02Options; // Não precisa de toRaw se os dados são reativos

      if (!searchValueU02) {
        return rawOptionsU02; // Retorna todas as opções se não houver filtro
      }

      return rawOptionsU02.filter((option) =>
        option.label.toLowerCase().includes(searchValueU02)
      );
    },

    filteredOptionsU03() {
      const searchValueU03 = this.editableValueU03.toLowerCase().trim();
      const rawOptionsU03 = this.u03Options; // Não precisa de toRaw se os dados são reativos

      if (!searchValueU03) {
        return rawOptionsU03; // Retorna todas as opções se não houver filtro
      }

      return rawOptionsU03.filter((option) =>
        option.label.toLowerCase().includes(searchValueU03)
      );
    },

    filteredOptionsU04() {
      const searchValueU04 = this.editableValueU04.toLowerCase().trim();
      const rawOptionsU04 = this.u04Options; // Não precisa de toRaw se os dados são reativos

      if (!searchValueU04) {
        return rawOptionsU04; // Retorna todas as opções se não houver filtro
      }

      return rawOptionsU04.filter((option) =>
        option.label.toLowerCase().includes(searchValueU04)
      );
    },

    filteredOptionsU05() {
      const searchValueU05 = this.editableValueU05.toLowerCase().trim();
      const rawOptionsU05 = this.u04Options; // Não precisa de toRaw se os dados são reativos

      if (!searchValueU05) {
        return rawOptionsU05; // Retorna todas as opções se não houver filtro
      }

      return rawOptionsU05.filter((option) =>
        option.label.toLowerCase().includes(searchValueU05)
      );
    },

    filteredOptions() {
      if (!this.editableValue || this.editableValue.trim() === "") {
        return this.u01Options; // Mostra todas as opções
      }

      // Filtra com base no texto inserido, usando `label`
      return this.u01Options.filter(
        (option) =>
          option.label &&
          option.label.toLowerCase().includes(this.editableValue.toLowerCase())
      );
    },
  },

  watch: {
    editableValueU01: {
      handler() {
        this.updateFilteredOptionsU01();
      },
      immediate: true,
    },
    editableValueU02: {
      handler() {
        this.updateFilteredOptionsU02();
      },
      immediate: true,
    },
    editableValueU03: {
      handler() {
        this.updateFilteredOptionsU03();
      },
      immediate: true,
    },
    editableValueU04: {
      handler() {
        this.updateFilteredOptionsU04();
      },
      immediate: true,
    },
    editableValueU05: {
      handler() {
        this.updateFilteredOptionsU05();
      },
      immediate: true,
    },

    u01Options: {
      handler() {
        this.updateFilteredOptionsU01();
      },
      immediate: true,
    },
    u02Options: {
      handler() {
        this.updateFilteredOptionsU02();
      },
      immediate: true,
    },
    u03Options: {
      handler() {
        this.updateFilteredOptionsU03();
      },
      immediate: true,
    },
    u04Options: {
      handler() {
        this.updateFilteredOptionsU04();
      },
      immediate: true,
    },
    u05Options: {
      handler() {
        this.updateFilteredOptionsU05();
      },
      immediate: true,
    },

    tasks: {
      handler(newTasks, oldTasks) {
        if (newTasks.length !== oldTasks.length) {
          this.$nextTick(() => {
            this.adjustHeaderWidth();
          });
        }
      },
      deep: true,
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeIconContainer);
    const table = this.$el.querySelector("table");
    table.removeEventListener("click", this.handleGlobalClick);
  },
  mounted() {
    this.fetchU01Options();
    this.fetchU02Options();
    this.fetchU03Options();
    this.fetchU04Options();
    this.fetchU05Options();
    this.initializeTasks()
      .then(() => {
        LarguraDropdown();
        console.log("Tarefas inicializadas com sucesso.");
      })
      .catch((error) => {
        console.error("Erro ao inicializar tarefas:", error.message);
      });

    this.$refs.pesquisarInput?.focus();
    this.adjustHeaderWidth();
    window.addEventListener("resize", this.adjustHeaderWidth);
    const table = this.$el.querySelector("table");
    table?.addEventListener("click", this.handleGlobalClick);
  },
  methods: {
    ...Tarefa,
    ...tabela,
    ...TaskVisibilityMethods,
    ...methods,
    ...u01,
    ...u02,
    ...u03,
    ...u04,
    ...u05,
  },
};
</script>