import axios from "axios";
import { state } from "../../store.js";
//import { atualizarCampos } from "../cronograma/editartarefamodal/editartarefa.js";
const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://efran.onrender.com/api"
        : "http://localhost:10000/api",
  });

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    editingTaskId: null,
});

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Subetapa = {
    async initializeTasks() {
        let subetapa_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            subetapa_id = this.task;
        } else if (this.task && this.task.subetapa_id) {
            subetapa_id = this.task.subetapa_id;
        } else {
            console.warn("subetapa_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchSubetapa(subetapa_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statussubetapa-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarSubetapa(valor) {
        clearTimeout(this.debounceTimeoutSubetapa);

        this.debounceTimeoutSubetapa = setTimeout(async () => {
            this.querySubetapa = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchSubetapa(state.pesquisarSubetapa.subetapa_id, this.querySubetapa, state.pesquisarSubetapa.pesquisar2, state.pesquisarSubetapa.pesquisar3);
            this.tasks = tasks;
        }, 300);
    },

    async pesquisarSubetapaB(valor) {
        clearTimeout(this.debounceTimeoutSubetapaB);

        this.debounceTimeoutSubetapaB = setTimeout(async () => {
            this.querySubetapaB = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchSubetapa(state.pesquisarSubetapa.subetapa_id, state.pesquisarSubetapa.pesquisar1, this.querySubetapaB, state.pesquisarSubetapa.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchSubetapa(state.pesquisarSubetapa.projeto_id, state.pesquisarSubetapa.pesquisar1, state.pesquisarSubetapa.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    SubetapaSelecionado(task) {
        //console.warn("Tarefa selecionada:", task);
    
        if (task) {
            // Atualiza o estado global
            state.EditarTarefaCronograma.subetapa = task.subetapa;
            state.EditarTarefaCronograma.subetapa_id = task.subetapa_id;
            //state.EditarTarefaCronograma.subetapa_ordem = 0;
    
            // Chama a função para atualizar os campos
            //atualizarCampos(this);
    
            // Emite o evento e fecha o modal
            this.$emit("fecharModalSubetapa", {});
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.subetapa) {
            this.adicionarSubetapa(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da tarefa que está sendo editada
        this.editingTaskId = task.subetapa_id;
    },

    enableExclui(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.subetapa) {
            this.excluirSubetapa(task,);
        }
    },

    EnterSubetapaEditar(task) {
        this.editingTaskId = null;
        // Ação ao pressionar Enter
        this.SubetapaEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    SubetapaEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.subetapa) {
            this.salvarSubetapa(task,);
        }
    },

    editarStatusSubetapa(task) {
        if (this.editableValue !== task.statussubetapa) {
            this.salvarSubetapa(task);
        }
    },

    salvarSubetapa(task) {
        try {
            SalvarSubetapa(
                { subetapa_id: task.subetapa_id, subetapa: task.subetapa, st: task.statussubetapa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar subetapa:", error);
        }
    },

    adicionarSubetapa(task) {
        try {
            AdicionarSubetapa(
                { subetapa_id: task.subetapa_id, subetapa: task.subetapa, st: task.statussubetapa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar subetapa:", error);
        }
    },

    excluirSubetapa(task) {
        try {
            ExcluirSubetapa(
                { subetapa_id: task.subetapa_id, subetapa: task.subetapa, st: task.statussubetapa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar subetapa:", error);
        }
    },
}

export async function fetchSubetapa(subetapa_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (subetapa_id !== undefined) {
            state.pesquisarSubetapa.subetapa_id = subetapa_id;
        } else {
            state.pesquisarSubetapa.subetapa_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarSubetapa.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarSubetapa.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarSubetapa.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarSubetapa.pesquisar2 = null;
        }

        if (pesquisar3 !== null) {
            state.pesquisarSubetapa.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarSubetapa.pesquisar3 = 1;
        }
        //console.log("pesquisar3", pesquisar3);

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            subetapa_id: state.pesquisarSubetapa.subetapa_id,
            pesquisar1: state.pesquisarSubetapa.pesquisar1,
            pesquisar2: state.pesquisarSubetapa.pesquisar2,
            pesquisar3: state.pesquisarSubetapa.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);

        // Faça a requisição para a API
        const response = await api.get("/subetapa", { params });

        const tasks = response.data.map((task) => ({
            subetapa_id: task.registro,
            subetapa: task.descricao || "",
            statussubetapa: task.st || "",
        }));
        //console.log("bb");
        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}

export async function SalvarSubetapa(task, context) {
    console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.subetapa) {
        exibirMensagem("Esta tarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await api.post("/subetapa/editar", task);
        console.log("Subetapa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSubetapa no contexto recebido
        context.tasks = await fetchSubetapa(state.pesquisarSubetapa.subetapa_id, state.pesquisarSubetapa.pesquisar1, state.pesquisarSubetapa.pesquisar2, state.pesquisarSubetapa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar subetapa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarSubetapa(task, context) {

    try {
        const response = await api.post("/subetapa/adicionar", task);
        //console.log("Subetapa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSubetapa no contexto recebido
        context.tasks = await fetchSubetapa(state.pesquisarSubetapa.subetapa_id, state.pesquisarSubetapa.pesquisar1, state.pesquisarSubetapa.pesquisar2, state.pesquisarSubetapa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar subetapa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirSubetapa(task, context) {
    //console.log("task", task);
    try {
        const response = await api.post("/subetapa/excluir", task);
        //console.log("Subetapa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSubetapa no contexto recebido
        context.tasks = await fetchSubetapa(state.pesquisarSubetapa.subetapa_id, state.pesquisarSubetapa.pesquisar1, state.pesquisarSubetapa.pesquisar2, state.pesquisarSubetapa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar subetapa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}















