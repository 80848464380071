import axios from "axios";
import { state } from "../../store.js";
//import { atualizarCampos } from "../../components/cronograma/editartarefamodal/editartarefa.js";
const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://efran.onrender.com/api"
        : "http://localhost:10000/api",
  });

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    editingTaskId: null,
});

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Place = {
    async initializeTasks() {
        let place_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            place_id = this.task;
        } else if (this.task && this.task.place_id) {
            place_id = this.task.place_id;
        } else {
            console.warn("place_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchPlace(place_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statusplace-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarPlace(valor) {
        clearTimeout(this.debounceTimeoutSetor);

        this.debounceTimeoutSetor = setTimeout(async () => {
            this.queryPlace = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchPlace(state.pesquisarPlace.place_id, this.queryPlace, state.pesquisarPlace.pesquisar2, state.pesquisarPlace.pesquisar3);
            this.tasks = tasks;
        }, 300);
    },

    async pesquisarPlaceB(valor) {
        clearTimeout(this.debounceTimeoutSetorB);

        this.debounceTimeoutPlaceB = setTimeout(async () => {
            this.queryPlaceB = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchPlace(state.pesquisarPlace.place_id, state.pesquisarPlace.pesquisar1, this.queryPlaceB, state.pesquisarPlace.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchPlace(state.pesquisarPlace.projeto_id, state.pesquisarPlace.pesquisar1, state.pesquisarPlace.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    PlaceSelecionado(task) {
        //console.warn("Tarefa selecionada:", task);
    
        if (task) {
            // Atualiza o estado global
            state.EditarTarefaCronograma.place = task.place;
            state.EditarTarefaCronograma.place_id = task.place_id;
            //state.EditarTarefaCronograma.place_ordem = 0;
    
            // Chama a função para atualizar os campos
            //atualizarCampos(this);
    
            // Emite o evento e fecha o modal
            this.$emit("fecharModalPlace", {});
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.place) {
            this.adicionarPlace(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da tarefa que está sendo editada
        this.editingTaskId = task.place_id;
    },

    enableExclui(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.place) {
            this.excluirPlace(task,);
        }
    },

    EnterPlaceEditar(task) {
        this.editingTaskId = null;
        // Ação ao pressionar Enter
        this.PlaceEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    PlaceEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.place) {
            this.salvarPlace(task,);
        }
    },

    editarStatusPlace(task) {
        if (this.editableValue !== task.statusplace) {
            this.salvarPlace(task);
        }
    },

    salvarPlace(task) {
        try {
            SalvarPlace(
                { place_id: task.place_id, place: task.place, st: task.statusplace },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar place:", error);
        }
    },

    adicionarPlace(task) {
        try {
            AdicionarPlace(
                { place_id: task.place_id, place: task.place, st: task.statusplace },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar place:", error);
        }
    },

    excluirPlace(task) {
        try {
            ExcluirPlace(
                { place_id: task.place_id, place: task.place, st: task.statusplace },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar place:", error);
        }
    },
}

export async function fetchPlace(place_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (place_id !== undefined) {
            state.pesquisarPlace.place_id = place_id;
        } else {
            state.pesquisarPlace.place_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarPlace.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarPlace.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarPlace.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarPlace.pesquisar2 = null;
        }

        if (pesquisar3 !== null) {
            state.pesquisarPlace.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarPlace.pesquisar3 = 1;
        }
        //console.log("pesquisar3", pesquisar3);

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            place_id: state.pesquisarPlace.place_id,
            pesquisar1: state.pesquisarPlace.pesquisar1,
            pesquisar2: state.pesquisarPlace.pesquisar2,
            pesquisar3: state.pesquisarPlace.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);

        // Faça a requisição para a API
        const response = await api.get("/place", { params });

        const tasks = response.data.map((task) => ({
            place_id: task.registro,
            place: task.descricao || "",
            statusplace: task.st || "",
        }));
        //console.log("bb");
        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}

export async function SalvarPlace(task, context) {
    //console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.place) {
        exibirMensagem("Esta tarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await api.post("/place/editar", task);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido
        context.tasks = await fetchPlace(state.pesquisarPlace.projeto_id, state.pesquisarPlace.pesquisar1, state.pesquisarPlace.pesquisar2, state.pesquisarPlace.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar place:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarPlace(task, context) {

    try {
        const response = await api.post("/place/adicionar", task);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido
        context.tasks = await fetchPlace(state.pesquisarPlace.projeto_id, state.pesquisarPlace.pesquisar1, state.pesquisarPlace.pesquisar2, state.pesquisarPlace.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar place:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirPlace(task, context) {
    //console.log("task", task);
    try {
        const response = await api.post("/place/excluir", task);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido
        context.tasks = await fetchPlace(state.pesquisarPlace.projeto_id, state.pesquisarPlace.pesquisar1, state.pesquisarPlace.pesquisar2, state.pesquisarPlace.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar place:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}















