// cronograma.js
import axios from 'axios';
import { nextTick } from 'vue';
const anoAtual = new Date().getFullYear();
const datas = gerarDatas().map((date) => ({ label: date, value: date }));
const startDate = `01-01-${anoAtual}`;
const endDate = `01-12-${anoAtual}`;
const task = { isProcessing: false };
import { toRaw } from "vue";
import { state } from "../../store.js";

const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "production"
            ? "https://efran.onrender.com/api"
            : "http://localhost:10000/api",
});

export const data = () => ({
    totalBarWidth: 100,
    zoomLevel: 100,
    isSubtarefaVisible: true,
    isAdicionarVisible: true,
    isEditarVisible: true,
    isExcluirVisible: true,
    isQdeVisible: true,
    isUdeVisible: true,
    isQde1Visible: true,
    isPorcentagemVisible: true,
    isstinicioVisible: true,
    isduracao00Visible: true,
    isinicio00Visible: true,
    istermino00Visible: true,
    isintervalo00Visible: true,
    isstterminoVisible: true,
    clickTimeout: null,
    isLoading: false,
    isTyping: null,
    drawer: true,
    mini: false,
    dataOpcoes: datas,
    startDate: startDate,
    endDate: endDate,
    selectedStartDateOption: { label: startDate, value: startDate }, // Objeto correspondente à opção
    selectedEndDateOption: { label: endDate, value: endDate }, // Objeto correspondente à opção
    dataCarregarObra: [],
    carregarObra: null,
    dataCarregarSetor: [],
    carregarSetor: "",
    dataCarregarSubsetor: [],
    carregarSubsetor: "",
    dataCarregarPlace: [],
    carregarPlace: "",
    dataCarregarEtapa: [],
    carregarEtapa: "",
    dataCarregarSubetapa: [],
    carregarSubetapa: "",
    dataCarregarTarefa: [],
    carregarTarefa: "",
    dataCarregarEmpresa: [],
    carregarEmpresa: "",
    iconContainerVisible: false,
    selectedTaskId: null,
    iconContainerPosition: { top: 0, left: 0 },
    taskId: null,
    options: [],
    isModalEditarUnidadeVisible: false, // Controle de visibilidade do modal
    selectedTask: null, // Dados da tarefa a ser editada
    taskContext: null, // Armazena os dados da tarefa selecionada
    editingQdeTaskId: null,
    activeInputs: {}, // Objeto para rastrear quais inputs estão ativos
    isModalEditarTarefaVisible: false, // Controle de visibilidade do modal
    editingTaskId: null, // ID da tarefa sendo editada
    editableValue: '', // Valor editável do input
    udeOptions: [], // Inicializa como uma lista vazia
    filteredOptions: [], // Opções filtradas
    tasks: [],
    taskState: {},
});

function parseBrazilianDate(dateString) {
    if (dateString instanceof Date && !isNaN(dateString)) {
        return dateString;
    }

    if (typeof dateString === 'string' && !isNaN(Date.parse(dateString))) {
        return new Date(dateString);
    }

    if (typeof dateString === 'string' && /^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`);
    }

    return new Date('2000-01-01');
}

function formatDateToBrazilian(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

export const ReceberDatas = {
    enviarDataInicio(context, valor) {
        //console.log("context", context);
        if (typeof context !== 'object' || !Object.prototype.hasOwnProperty.call(context, 'startDate')) {
            console.error('Contexto inválido passado para enviarDataInicio:', context);
            return;
        }

        // Verifica se valor é uma string e, se não for, converte para string
        if (valor && typeof valor !== 'string') {
            valor = String(valor);
        }

        if (valor && valor.match(/^\d{2}-\d{4}$/)) {
            valor = `01-${valor}`;
        } else if (valor && valor.match(/^\d{4}$/)) {
            valor = `01-01-${valor}`;
        }

        //console.log("valorF", valor);
        context.startDate = valor;
    },

    enviarDataTermino(context, valor) {
        if (typeof context !== 'object' || !Object.prototype.hasOwnProperty.call(context, 'endDate')) {
            console.error('Contexto inválido passado para enviarDataTermino:', context);
            return;
        }

        // Verifica se valor é uma string e, se não for, converte para string
        if (valor && typeof valor !== 'string') {
            valor = String(valor);
        }

        if (valor && valor.match(/^\d{2}-\d{4}$/)) {
            valor = `01-${valor}`;
        } else if (valor && valor.match(/^\d{4}$/)) {
            valor = `01-01-${valor}`;
        }

        context.endDate = valor;

    },
};

export const computed = {
    dateRange() {
        // Extrai o dia, mês e ano das strings `startDate` e `endDate`
        const [startDay, startMonth, startYear] = this.startDate.split('-').map(Number);
        const [endDay, endMonth, endYear] = this.endDate.split('-').map(Number);

        // Define o início e o final com as datas convertidas
        const start = new Date(startYear, startMonth - 1, startDay);
        const end = new Date(endYear, endMonth - 1, endDay);
        const range = [];
        //console.log("start", start);
        // Itera de mês a mês, incluindo até o mês de `end`
        let currentDate = new Date(start.getFullYear(), start.getMonth(), 1);

        while (currentDate <= end) {
            range.push(new Date(currentDate));
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return range;
    },

    uniqueYears() {
        return [...new Set(this.dateRange.map(date => date.getFullYear()))];
    }
};

export const methods = {
    showSpinner() {
        const spinner = document.getElementById("global-spinner");
        //console.log("spinner", spinner);
        if (spinner) {
            spinner.classList.remove("hidden");
        }
    },

    hideSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.add("hidden");
        }
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statusetapa-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    calculateNumericDuration(task) {
        const start = new Date(task.start);
        const end = new Date(task.end);

        // Calcula a diferença em milissegundos e converte para dias
        const differenceInTime = end.getTime() - start.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

        return differenceInDays > 0 ? differenceInDays : 0; // Retorna 0 se a duração for negativa
    },

    handleEditarTarefa(taskId) {

        const task = this.tasks.find((t) => t.id === taskId); // Busca a tarefa pelo ID

        if (task) {
            state.EditarTarefaCronograma.projeto_id = task.projeto_registro,
                state.EditarTarefaCronograma.projeto_ida = task.projeto_registro,
                state.EditarTarefaCronograma.setor_id = task.setor_id,
                state.EditarTarefaCronograma.setor_ida = task.setor_id,
                state.EditarTarefaCronograma.setor_ordem = task.ordem_setor,
                state.EditarTarefaCronograma.subsetor_id = task.subsetor_id,
                state.EditarTarefaCronograma.subsetor_ida = task.subsetor_id,
                state.EditarTarefaCronograma.subsetor_ordem = task.ordem_subsetor,
                state.EditarTarefaCronograma.place_id = task.place_id,
                state.EditarTarefaCronograma.place_ida = task.place_id,
                state.EditarTarefaCronograma.place_ordem = task.ordem_place,
                state.EditarTarefaCronograma.etapa_id = task.etapa_id,
                state.EditarTarefaCronograma.etapa_ida = task.etapa_id,
                state.EditarTarefaCronograma.etapa_ordem = task.ordem_etapa,
                state.EditarTarefaCronograma.subetapa_id = task.subetapa_id,
                state.EditarTarefaCronograma.subetapa_ida = task.subetapa_id,
                state.EditarTarefaCronograma.subetapa_ordem = task.ordem_subetapa,
                state.EditarTarefaCronograma.tarefa_id = task.tarefa_id,
                state.EditarTarefaCronograma.tarefa_ida = task.tarefa_id,
                state.EditarTarefaCronograma.tarefa_ordem = task.ordem_tarefa,
                state.EditarTarefaCronograma.subtarefa_id = task.subtarefa_id,
                state.EditarTarefaCronograma.oempresa_id = task.oempresa_id,
                state.EditarTarefaCronograma.cronograma_id = task.cronograma_id,
                state.EditarTarefaCronograma.cronograma_ida = task.cronograma_id,

                this.selectedTask = task; // Define a tarefa selecionada
            this.isModalEditarTarefaVisible = true; // Exibe o modal
            //console.log("this.selectedTask", this.selectedTask);
            this.pageContext = { // Inclui o contexto da página
                tasks: this.tasks,
                fetchTasks: this.fetchTasks,
            };

            //console.log("task", this.selectedTask);
        } else {
            console.warn("Tarefa não encontrada para o ID:", taskId);
        }
    },

    closeModalEditarTarefa() {
        this.isModalEditarTarefaVisible = false; // Fecha o modal
        this.selectedTask = null; // Limpa os dados da tarefa
    },

    fecharlEditarTarefaModal() {
        console.log("Modal de editar tarefa foi fechado.");
        this.isModalEditarTarefaVisible = false;

        // Chama fetchTasks para atualizar os dados
        fetchTasks(this) // Passa o contexto atual do componente
            .then(() => {
                console.log("Tasks atualizadas com sucesso após fechar o modal.");
            })
            .catch((error) => {
                console.error("Erro ao atualizar tasks após fechar o modal:", error);
            });
    },

    activateInput(id) {
        this.activeInputs[id] = true; // Marca o input como ativo
    },

    deactivateInput(id) {
        delete this.activeInputs[id]; // Remove o estado ativo
    },

    handleQdeOptionClick(task) {
        if (task.tipo === 'Tarefa') {
            this.updateTarefaQde(task);
        } else if (task.tipo === 'Subetapa') {
            this.updateSubetapaQde(task);
        } else if (task.tipo === 'Etapa') {
            this.updateEtapaQde(task);
        } else if (task.tipo === 'Place') {
            this.updatePlaceQde(task);
        } else if (task.tipo === 'Subsetor') {
            this.updateSubsetorQde(task);
        } else if (task.tipo === 'Setor') {
            this.updateSetorQde(task);
        } else if (task.tipo === 'Projeto') {
            this.updateProjetoQde(task);
        }
        // Remove o foco do input
        const input = this.$refs[`qde-${task.id}`];
        if (input && input.blur) {
            input.blur(); // Sai do input
        }

        // Move o foco para o próximo elemento
        const nextElement = document.getElementById('outside-focus-element'); // Substitua com seu próximo elemento
        if (nextElement) {
            nextElement.focus();
        }
        // Adicione outros tipos, se necessário
        this.editingQdeTaskId = null; // Sai do modo de edição
    },

    async updateTarefaQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            id_tarefa: task.id,
            qde_tarefa: task.qde,
        };

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updateSubetapaQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            se: task.id,
            SE_QDE: task.qde,
            projeto_id: task.projeto_id,
        };
        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updateEtapaQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            e: task.id,
            E_QDE: task.qde,
            projeto_id: task.projeto_id,
        };
        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }

    },

    async updatePlaceQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            p: task.id,
            P_QDE: task.qde,
            projeto_id: task.projeto_id,
        };
        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updateSubsetorQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            sb: task.id,
            SB_QDE: task.qde,
            projeto_id: task.projeto_id,
        };
        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }

    },

    async updateSetorQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            s: task.id,
            S_QDE: task.qde,
            projeto_id: task.projeto_id,
        };
        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }

    },

    async updateProjetoQde(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            o: task.id,
            O_QDE: task.qde,
            projeto_id: task.projeto_id,
        };
        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde(context);

            // Aguarda o DOM atualizar e então restaura o scroll
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }

    },

    handleQde1OptionClick(task) {
        if (task.tipo === 'Subetapa') {
            this.updateSubetapaQde1(task);
        } else if (task.tipo === 'Etapa') {
            this.updateEtapaQde1(task);
        } else if (task.tipo === 'Place') {
            this.updatePlaceQde1(task);
        } else if (task.tipo === 'Subsetor') {
            this.updateSubsetorQde1(task);
        } else if (task.tipo === 'Setor') {
            this.updateSetorQde1(task);
        }

        // Remove o foco do input
        const input = this.$refs[`qde1-${task.id}`];
        if (input && input.blur) {
            input.blur(); // Sai do input
        }

        // Move o foco para o próximo elemento
        const nextElement = document.getElementById('outside-focus-element'); // Substitua com seu próximo elemento
        if (nextElement) {
            nextElement.focus();
        }
        // Adicione outros tipos, se necessário
        this.editingQdeTaskId = null; // Sai do modo de edição
    },

    async updateSubetapaQde1(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            se: task.id,
            SE1_QDE: task.qde1,
            projeto_id: task.projeto_id,
        };

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde1(context);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updateEtapaQde1(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            e: task.id,
            E1_QDE: task.qde1,
            projeto_id: task.projeto_id,
        };

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde1(context);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updatePlaceQde1(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            p: task.id,
            P1_QDE: task.qde1,
            projeto_id: task.projeto_id,
        };

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde1(context);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updateSubsetorQde1(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            sb: task.id,
            SB1_QDE: task.qde1,
            projeto_id: task.projeto_id,
        };

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde1(context);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    async updateSetorQde1(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            s: task.id,
            S1_QDE: task.qde1,
            projeto_id: task.projeto_id,
        };

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarQde1(context);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao atualizar a tarefa:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    updateFilteredOptions() {
        const searchValue = this.editableValue.toLowerCase().trim();
        this.filteredOptions = this.udeOptions.filter((option) =>
            option.label.toLowerCase().trim().includes(searchValue)
        );
    },

    handleUdeOptionClick(option, tipo) {
        if (tipo === "Tarefa") {
            this.selectUdeTarefaOption(option);
        } else if (tipo === "Subetapa") {
            this.selectUdeSubetapaOption(option);
        } else if (tipo === "Etapa") {
            this.selectUdeEtapaOption(option);
        } else if (tipo === "Place") {
            this.selectUdePlaceOption(option);
        } else if (tipo === "Subsetor") {
            this.selectUdeSubsetorOption(option);
        } else if (tipo === "Setor") {
            this.selectUdeSetorOption(option);
        } else if (tipo === "Projeto") {
            this.selectUdeObraOption(option);
        } else {
            console.error("Tipo desconhecido:", tipo);
        }

        this.editingTaskId = null; // Fecha o dropdown após a seleção
    },

    enableUdeEdit(task) {
        this.editingTaskId = task.id; // Ativa a edição
        this.editableValue = task.ude || ""; // Preenche o valor atual
        this.filterUdeOptions(); // Chama a função de filtro
    },

    filterUdeOptions() {
        // Remove Proxies para trabalhar com dados brutos
        const rawOptions = this.udeOptions.map(option => toRaw(option));

        //console.log("Opções antes do filtro (raw):", rawOptions);

        if (!this.editableValue || this.editableValue.trim() === "") {
            // Mostra todas as opções
            this.filteredOptions = rawOptions.map(option => ({
                label: option.label || "",
                value: option.value || null,
            }));
        } else {
            // Filtra com base no texto inserido
            this.filteredOptions = rawOptions
                .filter(
                    option =>
                        typeof option.label === "string" &&
                        option.label.toLowerCase().includes(this.editableValue.toLowerCase())
                )
                .map(option => ({
                    label: option.label || "",
                    value: option.value || null,
                }));
        }

        //console.log("Opções filtradas:", this.filteredOptions);
    },

    disableUdeEdit() {
        this.editingTaskId = null; // Desativa a edição
    },

    handleKeyDown(event) {
        if (event.key === "Escape") {
            this.disableUdeEdit(); // Sai do modo de edição ao pressionar Esc
        }
    },

    async selectUdeTarefaOption(option) {
        await this.atualizarUnidade(option, {
            id: true,
            udeKey: 'T_UDE',
            contextKeys: { id: 'id', tude_id: 'T_UDE' },
        });
    },

    async selectUdeSubetapaOption(option) {
        await this.atualizarUnidade(option, {
            id: 'se',
            udeKey: 'SE_UDE',
            contextKeys: { se: 'id', seude_id: 'SE_UDE' },
        });
    },

    async selectUdeEtapaOption(option) {
        await this.atualizarUnidade(option, {
            id: 'e',
            udeKey: 'E_UDE',
            contextKeys: { e: 'id', eude_id: 'E_UDE' },
        });
    },

    async selectUdePlaceOption(option) {
        await this.atualizarUnidade(option, {
            id: 'p',
            udeKey: 'P_UDE',
            contextKeys: { p: 'id', pude_id: 'P_UDE' },
        });
    },

    async selectUdeSubsetorOption(option) {
        await this.atualizarUnidade(option, {
            id: 'sb',
            udeKey: 'SB_UDE',
            contextKeys: { sb: 'id', sbude_id: 'SB_UDE' },
        });
    },

    async selectUdeSetorOption(option) {
        await this.atualizarUnidade(option, {
            id: 's',
            udeKey: 'S_UDE',
            contextKeys: { s: 'id', sude_id: 'S_UDE' },
        });
    },

    async selectUdeObraOption(option) {
        await this.atualizarUnidade(option, {
            id: 'o',
            udeKey: 'O_UDE',
            contextKeys: { o: 'id', oude_id: 'O_UDE' },
        });
    },

    async atualizarUnidade(option, config) {
        this.editableValue = option.label;

        const task = this.tasks.find((t) => t.id === this.editingTaskId);
        if (!task) {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskId);
            return;
        }

        task.ude = option.label;
        task[config.udeKey] = option.value;
        this.editingTaskId = null;

        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        const context = {
            projeto_id: task.projeto_id,
            ude: task.ude,
        };

        for (const [key, sourceKey] of Object.entries(config.contextKeys)) {
            context[key] = (sourceKey === 'id') ? task.id : task[sourceKey];
        }

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));
            await EditarUnidade(context);
            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao atualizar a unidade:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    handleEditarUnidade(taskId) {
        const task = this.tasks.find((t) => t.id === taskId); // Busca a tarefa pelo ID

        if (task) {
            this.selectedTask = task; // Define a tarefa selecionada
            this.isModalEditarUnidadeVisible = true; // Exibe o modal
            //console.log("task", this.selectedTask);

        } else {
            console.warn("Tarefa não encontrada para o ID:", taskId);
        }
    },

    closeModal() {
        this.isModalEditarUnidadeVisible = false; // Fecha o modal
        this.taskToEdit = null; // Limpa os dados da tarefa
    },

    async handleAtualizarPagina() {
        try {
            const scrollContainer = this.$refs.contentWrapper;
            const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

            this.tasks = [];
            await this.fetchTasks(this);

            this.saveTasksState(this.tasks);
            this.loadTasksState(this.tasks);

            this.$nextTick(() => {
                this.clearSVGLines();
                this.connectBars();
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollTopPosition;
                }
            });
        } catch (error) {
            console.error("Erro ao atualizar tarefas:", error);
        }
    },

    async initializeTasks() {
        this.tasks = [];
        await this.fetchTasks(this);

        // Restaura o estado das tarefas, incluindo posições e visibilidade
        this.loadTasksState(this.tasks);

        // Atualiza o estado visual das barras
        this.$nextTick(() => {
            this.clearSVGLines();
            this.connectBars();
            if (this.scrollContainer) {
                this.scrollContainer.scrollTop = this.scrollTopPosition;
            }
        });
    },

    parseBrazilianDate(dateString) {
        if (dateString instanceof Date && !isNaN(dateString)) {
            return dateString;
        }
        if (typeof dateString === 'string' && !isNaN(Date.parse(dateString))) {
            return new Date(dateString);
        }
        if (typeof dateString === 'string' && /^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
            const [day, month, year] = dateString.split('-');
            return new Date(`${year}-${month}-${day}`);
        }
        //console.warn(`Data inválida: ${dateString}. Usando data padrão.`);
        return new Date('2000-01-01');
    },

    getMonthsInYear(year) {
        return this.dateRange.filter(date => date.getFullYear() === year).length;
    },

    formatMonth(date) {
        return date.toLocaleString('default', { month: 'short' });
    },

    isWithinRange(date, start, end) {
        const parsedStart = this.parseBrazilianDate(start);
        const parsedEnd = this.parseBrazilianDate(end);
        return (
            date >= new Date(parsedStart.getFullYear(), parsedStart.getMonth(), 1) &&
            date <= new Date(parsedEnd.getFullYear(), parsedEnd.getMonth() + 1, 0)
        );
    },

    isFirstMonth(start, currentDate) {
        //console.log("isFirstMonth chamado com:", start, currentDate);
        return (
            start.getFullYear() === currentDate.getFullYear() &&
            start.getMonth() === currentDate.getMonth()
        );
    },

    getMonthFill(date, start, end) {
        const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
        const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        // Verifica se o intervalo está fora do mês atual
        if (start > monthEnd || end < monthStart) {
            return { startProportion: 0, fillProportion: 0 };
        }

        // Ajusta os limites para o intervalo dentro do mês
        const effectiveStart = start < monthStart ? monthStart : start;
        const effectiveEnd = end > monthEnd ? monthEnd : end;

        const daysInMonth = (monthEnd - monthStart) / (1000 * 60 * 60 * 24) + 1;
        const daysFromStart = (effectiveStart - monthStart) / (1000 * 60 * 60 * 24);
        let fillDays = (effectiveEnd - effectiveStart) / (1000 * 60 * 60 * 24) + 1;

        // Ajuste específico para janeiro: Adiciona 3px ao final
        const isJanuary = date.getMonth() === 0;
        const totalWidthPx = 100; // Ajuste conforme necessário
        const pxAdjustment = 3; // Adição de 3px
        const adjustmentProportion = pxAdjustment / totalWidthPx;

        if (isJanuary && effectiveEnd === monthEnd) {
            fillDays += adjustmentProportion * daysInMonth;
        }

        const startProportion = Math.max(0, daysFromStart / daysInMonth);
        const fillProportion = Math.min(fillDays / daysInMonth, 1 - startProportion);

        return {
            startProportion: parseFloat(startProportion.toFixed(6)),
            fillProportion: parseFloat(fillProportion.toFixed(6)),
        };
    },

    calculateSecondaryBarStyle(date, task) {
        // Calcula a quantidade total de dias da barra principal
        const totalDays = Math.ceil(
            (new Date(task.end) - new Date(task.start)) / (1000 * 60 * 60 * 24) + 1
        );
        //console.log("Total days:", totalDays);

        // Calcula os dias correspondentes à porcentagem
        const percentageDays = parseFloat((totalDays * (task.porcentagema / 100)).toFixed(10));

        // Define os novos valores de start e end
        const newStart = new Date(task.start);
        const newEnd = new Date(newStart);

        newEnd.setDate(newStart.getDate() + percentageDays - 1);

        // Garante que o novo fim não ultrapasse o fim da barra principal
        if (newEnd > new Date(task.end)) {
            newEnd.setTime(new Date(task.end).getTime());
        }

        // Obtém as proporções da célula com os novos valores
        const monthFill = this.getMonthFill(date, newStart, newEnd);

        // Ajuste para eliminar diferenças pequenas no preenchimento
        const adjustedFillProportion = Math.min(monthFill.fillProportion, 1); // Garantir que não ultrapasse a célula

        return {
            left: `${monthFill.startProportion * 100}%`, // Posição inicial da barra
            width: `${adjustedFillProportion * 100}%`, // Largura ajustada
            backgroundColor: 'rgba(255, 0, 0, 0.5)', // Cor diferenciada
            position: 'absolute',
            top: '50%', // Ajuste vertical
            height: '10px', // Altura menor
        };
    },

    calculateUsedDays(date, task) {
        const start = new Date(task.start);
        const currentMonthStart = new Date(date.getFullYear(), date.getMonth(), 1);
        const currentMonthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        if (currentMonthEnd < start) {
            return 0; // Se o mês atual está antes do início da barra
        }

        const usedDays = Math.ceil(
            (currentMonthStart - start) / (1000 * 60 * 60 * 24)
        );

        return Math.max(0, usedDays); // Garante que não retorne valores negativos
    },

    syncHeaderScroll() {
        this.$refs.headerWrapper.scrollLeft = this.$refs.contentWrapper.scrollLeft;
    },

    setupScrollListener() {
        const contentWrapper = this.$refs.contentWrapper;
        contentWrapper.addEventListener('scroll', this.updateLinesOnScroll);
    },

    handleScroll(event) {
        this.$refs.headerWrapper.scrollLeft = event.target.scrollLeft;
        this.clearSVGLines();
        this.connectBars();
        const svgElement = document.getElementById('seu-elemento-svg'); // Substitua pelo ID real
        if (svgElement && svgElement.firstChild) {
            while (svgElement.firstChild) {
                svgElement.removeChild(svgElement.firstChild);
            }
        } else {
            console.warn('Elemento SVG não encontrado ou não possui filhos.');
        }
    },

    clearSVGLines() {
        const svg = this.$refs.connectionLines;
        while (svg.firstChild) {
            svg.removeChild(svg.firstChild);
        }
    },

    updateLinesOnScroll() {
        const svg = this.$refs.connectionLines;
        while (svg.firstChild) {
            svg.removeChild(svg.firstChild);
        }
        this.connectBars();
    },

    beforeDestroy() {
        const contentWrapper = this.$refs.contentWrapper;
        contentWrapper.removeEventListener('scroll', this.updateLinesOnScroll);
    },
};

export const lifecycleHooks = {
    beforeUnmount() {
        this.$refs.contentWrapper.removeEventListener("scroll", this.syncHeaderScroll);
    },
};

export const MetodosBotoes = {

    toggleSubtarefaVisibility() {
        this.isSubtarefaVisible = !this.isSubtarefaVisible;
        localStorage.setItem('isSubtarefaVisible', this.isSubtarefaVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleQdeVisibility() {
        this.isQdeVisible = !this.isQdeVisible;
        localStorage.setItem('isQdeVisible', this.isQdeVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleUdeVisibility() {
        this.isUdeVisible = !this.isUdeVisible;
        localStorage.setItem('isUdeVisible', this.isUdeVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleqde1Visibility() {
        this.isQde1Visible = !this.isQde1Visible;
        localStorage.setItem('isQde1Visible', this.isQde1Visible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleporcentagemVisibility() {
        this.isPorcentagemVisible = !this.isPorcentagemVisible;
        localStorage.setItem('isPorcentagemVisible', this.isPorcentagemVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleduracao00Visibility() {
        this.isduracao00Visible = !this.isduracao00Visible;
        localStorage.setItem('isduracao00Visible', this.isduracao00Visible);
        this.isstinicioVisible = !this.isstinicioVisible;
        localStorage.setItem('isstinicioVisible', this.isstinicioVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleinicio00Visibility() {
        this.isinicio00Visible = !this.isinicio00Visible;
        localStorage.setItem('isinicio00Visible', this.isinicio00Visible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggletermino00Visibility() {
        this.istermino00Visible = !this.istermino00Visible;
        localStorage.setItem('istermino00Visible', this.istermino00Visible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    toggleintervalo00Visibility() {
        this.isintervalo00Visible = !this.isintervalo00Visible;
        localStorage.setItem('isintervalo00Visible', this.isintervalo00Visible);
        this.isstterminoVisible = !this.isstterminoVisible;
        localStorage.setItem('isstterminoVisible', this.isstterminoVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    handleClickSubtarefa() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandQde();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleSubtarefaVisibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickQde() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandUde();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleQdeVisibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickUde() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandQde1();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleUdeVisibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickQde1() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandporcentagem();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleqde1Visibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickPorcentagem() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandduracao00();
            this.expandstinicio();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleporcentagemVisibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickDuracao00() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandinicio00();
            this.expandstinicio();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleduracao00Visibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickinicio00() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandtermino00();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleinicio00Visibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClicktermino00() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandintervalo00();
            this.expandsttermino();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggletermino00Visibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    handleClickIntervalo00() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.expandsttermino();
        } else {
            this.clickTimeout = setTimeout(() => {
                this.toggleintervalo00Visibility();
                this.clickTimeout = null;
            }, 300);
        }
    },

    expandAllColumns() {
        this.isSubtarefaVisible = true;
        localStorage.setItem('isSubtarefaVisible', this.isSubtarefaVisible);
        this.isAdicionarVisible = true;
        localStorage.setItem('isAdicionarVisible', this.isAdicionarVisible);
        this.isEditarVisible = true;
        localStorage.setItem('isEditarVisible', this.isEditarVisible);
        this.isExcluirVisible = true;
        localStorage.setItem('isExcluirVisible', this.isExcluirVisible);
        this.isQdeVisible = true;
        localStorage.setItem('isQdeVisible', this.isQdeVisible);
        this.isUdeVisible = true;
        localStorage.setItem('isUdeVisible', this.isUdeVisible);
        this.isQde1Visible = true;
        localStorage.setItem('isQde1Visible', this.isQde1Visible);
        this.isPorcentagemVisible = true;
        localStorage.setItem('isPorcentagemVisible', this.isPorcentagemVisible);
        this.isduracao00Visible = true;
        localStorage.setItem('isduracao00Visible', this.isduracao00Visible);
        this.isinicio00Visible = true;
        localStorage.setItem('isinicio00Visible', this.isinicio00Visible);
        this.isstinicioVisible = true;
        localStorage.setItem('isstinicioVisible', this.isstinicioVisible);
        this.istermino00Visible = true;
        localStorage.setItem('istermino00Visible', this.istermino00Visible);
        this.isintervalo00Visible = true;
        localStorage.setItem('isintervalo00Visible', this.isintervalo00Visible);
        this.isstterminoVisible = true;
        localStorage.setItem('isstterminoVisible', this.isstterminoVisible);
        this.$nextTick(() => {
            this.connectBars();
        });
    },

    expandQde() {
        this.isQdeVisible = true;
        localStorage.setItem('isQdeVisible', this.isQdeVisible);
    },

    expandUde() {
        this.isUdeVisible = true;
        localStorage.setItem('isUdeVisible', this.isUdeVisible);
    },

    expandQde1() {
        this.isQde1Visible = true;
        localStorage.setItem('isQde1Visible', this.isQde1Visible);
    },

    expandporcentagem() {
        this.isPorcentagemVisible = true;
        localStorage.setItem('isPorcentagemVisible', this.isPorcentagemVisible);
    },

    expandduracao00() {
        this.isduracao00Visible = true;
        localStorage.setItem('isduracao00Visible', this.isduracao00Visible);
    },

    expandinicio00() {
        this.isinicio00Visible = true;
        localStorage.setItem('isinicio00Visible', this.isinicio00Visible);
    },

    expandstinicio() {
        this.isstinicioVisible = true;
        localStorage.setItem('isstinicioVisible', this.isstinicioVisible);
    },

    expandtermino00() {
        this.istermino00Visible = true;
        localStorage.setItem('istermino00Visible', this.istermino00Visible);
    },

    expandintervalo00() {
        this.isintervalo00Visible = true;
        localStorage.setItem('isintervalo00Visible', this.isintervalo00Visible);
    },

    expandsttermino() {
        this.isstterminoVisible = true;
        localStorage.setItem('isstterminoVisible', this.isstterminoVisible);
    },

    openDrawer(content) {
        this.drawerContent = content; // Define o conteúdo baseado no item de menu clicado
        this.drawer2 = true;          // Abre o segundo sidebar
    },

    navegaCronograma() {
        this.$router.push({ name: 'CronogramaFisico' });
    },
};

export const TaskVisibilityMethods = {

    handleDoubleClick() {
        this.expandirTudo();
    },

    expandirTudo() {
        this.tasks.forEach(task => {
            task.visible = true; // Define a visibilidade de todas as tarefas como `true`
        });
        this.saveTasksState(this.tasks); // Salva o estado atualizado
        this.$nextTick(() => {
            this.connectBars(); // Atualiza as linhas após a modificação
        });
    },

    handleTaskClick(task, tasks) {
        if (task.tipo === "Projeto") {
            if (this.clickTimer) {
                // Limpa o temporizador e chama a função de duplo clique
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
                this.handleDoubleClick(); // Expande tudo no duplo clique
            } else {
                // Define um temporizador para o clique simples
                this.clickTimer = setTimeout(() => {
                    this.clickTimer = null; // Reseta o temporizador
                    this.toggleSetorVisibility(task, tasks); // Executa o clique simples
                    this.$nextTick(() => {
                        this.connectBars(); // Atualiza as linhas
                    });
                }, 300); // Tempo em milissegundos (ajuste conforme necessário)
            }
        }
        if (task.tipo === "Setor") {
            this.toggleSubsetorVisibility(task, tasks);
        } else if (task.tipo === "Subsetor") {
            this.togglePlaceVisibility(task, tasks);
        } else if (task.tipo === "Place") {
            this.toggleEtapaVisibility(task, tasks);
        } else if (task.tipo === "Etapa") {
            this.toggleSubetapaVisibility(task, tasks);
        } else if (task.tipo === "Subetapa") {
            this.toggleTarefaVisibility(task, tasks);
        }

        nextTick(() => {
            this.connectBars();
        });
    },

    toggleSetorVisibility(projetoTask, tasks) {

        if (projetoTask.tipo === "Projeto") {
            tasks.forEach((setor) => {
                // Alterna a visibilidade de Setor
                if (setor.tipo === "Setor" && setor.parent === projetoTask.registro) {
                    setor.visible = !setor.visible;

                    // Se o Setor foi recolhido, também recolhe Subsetor, Place, Etapa, Subetapa e Tarefa associadas
                    if (!setor.visible) {
                        tasks.forEach((subsetor) => {
                            console.log("setor", setor);
                            console.log("subsetor.tipo", subsetor.tipo);
                            console.log("subsetor.parent", subsetor.parent);
                            console.log("setor.id", setor.id);
                            if (subsetor.tipo === "Subsetor" && subsetor.parent === setor.registro) {
                                subsetor.visible = false;

                                // Recolhe Places associados ao Subsetor
                                tasks.forEach((place) => {
                                    if (place.tipo === "Place" && place.parent === subsetor.registro) {
                                        place.visible = false;

                                        // Recolhe Etapas associadas ao Place
                                        tasks.forEach((etapa) => {
                                            if (etapa.tipo === "Etapa" && etapa.parent === place.registro) {
                                                etapa.visible = false;

                                                // Recolhe Subetapas associadas à Etapa
                                                tasks.forEach((subetapa) => {
                                                    if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.registro) {
                                                        subetapa.visible = false;

                                                        // Recolhe Tarefas associadas à Subetapa
                                                        tasks.forEach((tarefa) => {
                                                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.registro) {
                                                                tarefa.visible = false;
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.saveTasksState(tasks); // Salva o estado atualizado
        }
    },

    toggleSubsetorVisibility(setorTask, tasks) {
        //console.log("setorTask", setorTask);
        //console.log("tasks", tasks);

        if (setorTask.tipo === "Setor") {
            tasks.forEach((task) => {
                // Alterna a visibilidade de Subsetor
                if (task.tipo === "Subsetor" && task.parent === setorTask.registro) {
                    task.visible = !task.visible;

                    // Se o Subsetor foi recolhido, também recolhe Place, Etapa, Subetapa e Tarefa associadas
                    if (!task.visible) {
                        tasks.forEach((place) => {
                            if (place.tipo === "Place" && place.parent === task.registro) {
                                place.visible = false;

                                // Recolhe Etapas associadas ao Place
                                tasks.forEach((etapa) => {
                                    if (etapa.tipo === "Etapa" && etapa.parent === place.registro) {
                                        etapa.visible = false;

                                        // Recolhe Subetapas associadas à Etapa
                                        tasks.forEach((subetapa) => {
                                            if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.registro) {
                                                subetapa.visible = false;

                                                // Recolhe Tarefas associadas à Subetapa
                                                tasks.forEach((tarefa) => {
                                                    if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.registro) {
                                                        tarefa.visible = false;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.saveTasksState(tasks); // Salva o estado atualizado
        }
    },

    togglePlaceVisibility(subsetorTask, tasks) {
        if (subsetorTask.tipo === "Subsetor") {
            tasks.forEach((task) => {
                // Alterna a visibilidade de Place
                if (task.tipo === "Place" && task.parent === subsetorTask.registro) {
                    task.visible = !task.visible;

                    // Se o Place foi recolhido, também recolhe Etapa, Subetapa e Tarefa associadas
                    if (!task.visible) {
                        tasks.forEach((etapa) => {
                            if (etapa.tipo === "Etapa" && etapa.parent === task.registro) {
                                etapa.visible = false;

                                // Recolhe Subetapas associadas à Etapa
                                tasks.forEach((subetapa) => {
                                    if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.registro) {
                                        subetapa.visible = false;

                                        // Recolhe Tarefas associadas à Subetapa
                                        tasks.forEach((tarefa) => {
                                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.registro) {
                                                tarefa.visible = false;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.saveTasksState(tasks); // Salva o estado atualizado
        }
    },

    toggleEtapaVisibility(placeTask, tasks) {
        if (placeTask.tipo === "Place") {
            tasks.forEach((task) => {
                // Alterna a visibilidade de etapas
                if (task.tipo === "Etapa" && task.parent === placeTask.registro) {
                    task.visible = !task.visible;

                    // Se a etapa foi recolhida, também recolhe subetapas e tarefas associadas
                    if (!task.visible) {
                        tasks.forEach((subetapa) => {
                            if (subetapa.tipo === "Subetapa" && subetapa.parent === task.registro) {
                                subetapa.visible = false;

                                // Recolhe as tarefas associadas à subetapa
                                tasks.forEach((tarefa) => {
                                    if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.registro) {
                                        tarefa.visible = false;
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.saveTasksState(tasks); // Salva o estado atualizado
        }
    },

    toggleSubetapaVisibility(etapaTask, tasks) {
        if (etapaTask.tipo === "Etapa") {
            tasks.forEach((task) => {
                // Alterna a visibilidade de subetapas
                if (task.tipo === "Subetapa" && task.parent === etapaTask.registro) {
                    task.visible = !task.visible;

                    // Se a subetapa foi recolhida, também recolhe as tarefas associadas
                    if (!task.visible) {
                        tasks.forEach((tarefa) => {
                            if (tarefa.tipo === "Tarefa" && tarefa.parent === task.registro) {
                                tarefa.visible = false;
                            }
                        });
                    }
                }
            });
            this.saveTasksState(tasks);
        }
    },

    toggleTarefaVisibility(subetapaTask, tasks) {
        if (subetapaTask.tipo === "Subetapa") {
            tasks.forEach((task) => {
                if (task.tipo === "Tarefa" && task.parent === subetapaTask.registro) {
                    task.visible = !task.visible;
                }
            });
            this.saveTasksState(tasks);
        }
    },

    saveTasksState(tasks) {
        const tasksState = tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado expandido/recolhido
        }));
        localStorage.setItem("tasksStateCronograma", JSON.stringify(tasksState));
    },

    loadTasksState(tasks) {
        const savedState = JSON.parse(localStorage.getItem("tasksStateCronograma"));
        if (savedState) {
            tasks.forEach(task => {
                const savedTask = savedState.find(t => t.id === task.id);
                if (savedTask) {
                    task.visible = savedTask.visible; // Restaura o estado expandido/recolhido
                }
            });
        }
    },
};

export const MetodosLinhas = {
    clearSVGLines() {
        const svg = this.$refs.connectionLines;
        while (svg.firstChild) {
            svg.removeChild(svg.firstChild);
        }
    },

    parseBrazilianDate,
    formatDateToBrazilian,
    fetchTasks,
    isVisible(element) {
        // Verificar se o elemento é válido
        if (!element || !(element instanceof Element)) return false;

        const style = window.getComputedStyle(element);
        return (
            style.display !== "none" &&
            style.visibility !== "hidden" &&
            style.opacity !== "0" &&
            element.offsetParent !== null // Verifica se o elemento está no layout
        );
    },

    connectBars() {
        this.clearSVGLines();
        if (!this.tasks || this.tasks.length === 0) return;

        this.tasks.forEach((task) => {
            const startBar = this.$refs[`bar-${task.id}`] ? this.$refs[`bar-${task.id}`][0] : null;
            const endBar = this.$refs[`bar-${task.para}`] ? this.$refs[`bar-${task.para}`][0] : null;


            // Verificar se os elementos são válidos e visíveis
            if (!this.isVisible(startBar) || !this.isVisible(endBar)) {
                return; // Ignorar esta tarefa
            }

            // Se ambos os elementos são visíveis, desenhar as linhas
            //console.log(`Drawing line for Task ${task.id}. StartBar: ${startBar}, EndBar: ${endBar}`);
            if (task.ctipo === "inicioinicio") {
                this.drawInicioInicioLine(startBar, endBar);
            } else if (task.ctipo === "terminoinicio") {
                const taskStartOrigin = task.start;
                const taskEndOrigin = task.end;
                const destinationTask = this.tasks.find((t) => t.id === task.para);
                console.log("destinationTask", destinationTask);

                if (!destinationTask) {
                    //console.warn(`Destination task not found for ID: ${task.para}`);
                    return; // Ignorar se o destino não for encontrado
                }

                const taskStartDestination = destinationTask.start;
                const taskEndDestination = destinationTask.end;

                if (
                    taskStartOrigin &&
                    taskEndOrigin &&
                    taskStartDestination &&
                    taskEndDestination
                ) {
                    this.drawTerminoInicioLine(
                        endBar,
                        startBar,
                        taskStartDestination,
                        taskEndDestination,
                        taskStartOrigin,
                        taskEndOrigin
                    );
                } else {
                    console.warn(`Invalid date values for Task ID: ${task.id}`);
                }
            }
        });
    },

    drawInicioInicioLine(startElement, endElement) {
        const svg = this.$refs.connectionLines;
        const svgRect = svg.getBoundingClientRect();
        const startRect = startElement.getBoundingClientRect();
        const endRect = endElement.getBoundingClientRect();

        const startX = startRect.left - svgRect.left;
        const startY = startRect.top - svgRect.top + startRect.height / 2;
        const endX = endRect.left - svgRect.left;
        const endY = endRect.top - svgRect.top + endRect.height / 2;

        const preEndX = endX - 30;

        const d = `M ${endX},${endY} 
                   H ${preEndX} 
                   V ${startY} 
                   H ${startX}`;

        this.ensureShadowFilter(svg, "blueShadowFilter", "rgba(0, 0, 0, 0.3)", 4, 4, 2, false);
        this.createPathWithShadow(svg, d, "blue", "blueShadowFilter", startX, startY, endX, endY);
        this.addArrow(svg, startX, startY, startX - 5, startY, "blue");
    },

    drawTerminoInicioLine(startElement, endElement, startTaskStart, startTaskEnd) {
        //console.log(`Attempting to draw TerminoInicio line: StartElement=${startElement}, EndElement=${endElement}`);
        if (!startElement || !endElement) {
            console.warn("Invalid elements for TerminoInicio line.");
            return; // Evita desenho se os elementos forem inválidos
        }
        const svg = this.$refs.connectionLines;
        const svgRect = svg.getBoundingClientRect();
        const startRect = startElement.getBoundingClientRect();
        const endRect = endElement.getBoundingClientRect();

        const dayWidth = startElement.parentElement.offsetWidth / 30;
        const daysBetweenOrigin = Math.floor((startTaskEnd - startTaskStart) / (1000 * 60 * 60 * 24));
        const barLengthOrigin = daysBetweenOrigin * dayWidth;

        const finalX = startRect.left + barLengthOrigin - svgRect.left + dayWidth;
        const startY = startRect.top - svgRect.top + startRect.height / 2;
        const endX = endRect.left - svgRect.left;
        const endY = endRect.top - svgRect.top + endRect.height / 2;

        const horizontalDifference = Math.abs(endX - finalX);

        let d;

        if (horizontalDifference > 60) {
            const extendedStartX = finalX + 30;
            d = `M ${finalX},${startY} 
                 H ${extendedStartX} 
                 V ${endY} 
                 H ${endX}`;
        } else {
            const extendedStartX = finalX + 30;
            const intermediateY = startY < endY ? startY + 11.5 : startY - 11.5;
            const preEndX = endX - 30;

            d = `M ${finalX},${startY} 
                 H ${extendedStartX} 
                 V ${intermediateY} 
                 H ${preEndX} 
                 V ${endY} 
                 H ${endX}`;
        }

        const upward = startY > endY;
        this.ensureShadowFilter(svg, "redShadowFilter", "rgba(243, 240, 236, 0.1)", 4, 4, 2, upward);
        this.createPathWithShadow(svg, d, "black", "redShadowFilter", finalX, startY, endX, endY);
        this.addArrow(svg, endX, endY, endX - 5, endY, "red");
    },

    ensureShadowFilter(svg, filterId, shadowColor, dx, dy, blur, upward) {
        let defs = svg.querySelector("defs");
        if (!defs) {
            defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
            svg.appendChild(defs);
        }

        if (svg.querySelector(`#${filterId}`)) return;

        const filter = document.createElementNS("http://www.w3.org/2000/svg", "filter");
        filter.setAttribute("id", filterId);

        if (upward) {
            filter.setAttribute("x", "0%");
            filter.setAttribute("y", "-10%");
            filter.setAttribute("width", "150%");
            filter.setAttribute("height", "120%");
        } else {
            filter.setAttribute("x", "0%");
            filter.setAttribute("y", "0%");
            filter.setAttribute("width", "150%");
            filter.setAttribute("height", "120%");
        }

        defs.appendChild(filter);

        const feOffset = document.createElementNS("http://www.w3.org/2000/svg", "feOffset");
        feOffset.setAttribute("dx", dx);
        feOffset.setAttribute("dy", dy);
        feOffset.setAttribute("result", "offsetBlur");
        filter.appendChild(feOffset);

        const feGaussianBlur = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur");
        feGaussianBlur.setAttribute("stdDeviation", blur); // Reduzido para sombra definida
        feGaussianBlur.setAttribute("in", "offsetBlur");
        feGaussianBlur.setAttribute("result", "blurred");
        filter.appendChild(feGaussianBlur);

        const feMerge = document.createElementNS("http://www.w3.org/2000/svg", "feMerge");
        const feMergeNode1 = document.createElementNS("http://www.w3.org/2000/svg", "feMergeNode");
        const feMergeNode2 = document.createElementNS("http://www.w3.org/2000/svg", "feMergeNode");
        feMergeNode1.setAttribute("in", "blurred");
        feMergeNode2.setAttribute("in", "SourceGraphic");

        feMerge.appendChild(feMergeNode1);
        feMerge.appendChild(feMergeNode2);
        filter.appendChild(feMerge);
    },

    createPathWithShadow(svg, d, color, filterId) {
        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path.setAttribute("d", d.trim());
        path.setAttribute("stroke", color);
        path.setAttribute("stroke-width", "0.3");
        path.setAttribute("fill", "none");
        path.setAttribute("filter", `url(#${filterId})`);
        svg.appendChild(path);
    },

    addArrow(svg, endX, endY, startX, startY, color) {
        const arrow = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
        const arrowSize = 8;
        const angle = Math.atan2(endY - startY, endX - startX);

        const arrowPoints = [
            `${endX},${endY}`,
            `${endX - arrowSize * Math.cos(angle - Math.PI / 6)},${endY - arrowSize * Math.sin(angle - Math.PI / 6)}`,
            `${endX - arrowSize * Math.cos(angle + Math.PI / 6)},${endY - arrowSize * Math.sin(angle + Math.PI / 6)}`
        ].join(" ");

        arrow.setAttribute("points", arrowPoints);
        arrow.setAttribute("fill", color);
        svg.appendChild(arrow);
    },
};

export function LarguraDropdown() {
    document.querySelectorAll(
        '.select-carregar-obra, .select-carregar-setor, .select-carregar-subsetor, ' +
        '.select-carregar-place, .select-carregar-subetapa, .select-carregar-etapa, ' +
        '.select-carregar-tarefa, .select-carregar-empresa, .select-carregar-data-inicio, ' +
        '.select-carregar-data-termino'
    ).forEach((select) => {
        const dropdown = select.querySelector('.multiselect__content-wrapper');
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export function gerarDatas() {
    const datas = [];
    const anoAtual = new Date().getFullYear() - 1;
    for (let ano = anoAtual; ano <= anoAtual + 5; ano++) {
        for (let mes = 1; mes <= 12; mes++) {
            const mesFormatado = mes.toString().padStart(2, '0');
            datas.push(`${mesFormatado}-${ano}`);
        }
    }
    return datas;
}

export const EditarCronogramaTarefa = {

    onInputChange(task) {
        this.isTyping = task.id; // Set the task ID when typing starts
    },

    editarStatusInicio(task) {
        if (this.editableValue !== task.status_inicio) {
            this.SalvarTarefaCronograma(task);
        }
    },

    editarStatusTermino(task) {
        if (this.editableValue !== task.status_termino) {
            this.SalvarTarefaCronograma(task);
        }
    },

    async SalvarTarefaCronograma(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));

            SalvarTarefaCronograma(
                {
                    id: task.id,
                    status_inicio: task.status_inicio,
                    status_termino: task.status_termino,
                    status: task.status,
                    duracao01: task.duracao01,
                    inicio01: task.inicio01,
                    termino01: task.termino01,
                    intervalo01: task.intervalo01,
                    qde: task.qde,
                    porcentagem: task.porcentagem,
                },
                this
            );

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao salvar status_inicio:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    formatDateToISO(date) {
        if (!date) return "";
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`; // Formato ISO para o calendário
    },

    formatDateToBrazilian(date) {
        if (!date) return "";
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();
        return `${day}-${month}-${year}`; // Para exibição em formato brasileiro
    },

    editarInicio00(event, task) {
        // Captura o valor selecionado no evento
        const selectedDate = event.target ? event.target.value : event;

        // Verifica se a data selecionada é diferente da data existente
        if (selectedDate !== task.start) {
            // Atualiza a tarefa com a data selecionada
            task.start = selectedDate;
            // Salva a alteração
            this.salvarInicio00(task);
        }

    },

    async salvarInicio00(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));

            salvarInicio00({ id: task.id, inicio: task.start, para: task.para }, this);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao salvar início:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    onEnterintervalo00(task) {
        // Ação ao pressionar Enter
        this.editar_intervalo(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    editar_intervalo(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.intervalo00) {
            this.salvarIntervalo(task);
        }
    },

    async salvarIntervalo(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        try {
            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));

            SalvarIntervalo({ id: task.id, intervalo: task.intervalo00, para: task.para }, this);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao salvar intervalo:", error);
        } finally {
            methods.hideSpinner();
        }
    },

    onEnterduracao00(task) {
        // Ação ao pressionar Enter
        this.editar_duracao(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `duracao-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    editar_duracao(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.duracao00) {
            this.SalvarDuracao(task);
        }
    },

    async SalvarDuracao(task) {
        const scrollContainer = document.querySelector('.cronograma-content-wrapper');
        const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

        try {

            methods.showSpinner();
            await new Promise(resolve => setTimeout(resolve, 100));

            SalvarDuracao({ id: task.id, duracao: task.duracao00 }, this);

            setTimeout(() => {
                if (scrollContainer) {
                    scrollContainer.scrollTop = scrollPosition;
                }
            }, 100);

        } catch (error) {
            console.error("Erro ao salvar duracao:", error);
        } finally {
            methods.hideSpinner();
        }

    },
};

export const Arrastar = {
    handleOpenIconContainer(event, taskId) {

        // Exibe o container do ícone e define sua posição
        this.iconContainerVisible = true;
        this.iconContainerPosition = {
            top: event.clientY,
            left: event.clientX,
        };
        this.taskId = taskId;

        openIconContainer(event, taskId, this);

        // Aguarda a renderização antes de realizar qualquer cálculo
        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleArrastarInicioInicio() {
        if (!this.taskId) {
            console.error("Nenhuma tarefa associada para arrastar.");
            return;
        }

        const taskId = this.taskId;

        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        // Obtém os elementos da barra relacionados à tarefa
        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a tarefa ${taskId}`);
            return;
        }

        // Calcula os estilos computados das barras
        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        arrastarInicioInicio(
            barElements,
            computedStyles,
            this.tasks,
            this,
            this.getMonthFill
        );

        // Atualiza visualizações após o arraste
        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleArrastarTerminoInicio(taskId) {

        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a tarefa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        arrastarTerminoInicio(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleOrdenarTarefa(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a tarefa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        OrdenarTarefa(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleOrdenarSubetapa(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a tarefa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        OrdenarSubetapa(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleOrdenarEtapa(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a Etapa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        OrdenarEtapa(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleOrdenarPlace(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a Etapa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        OrdenarPlace(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleOrdenarSubsetor(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a Etapa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        OrdenarSubsetor(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleOrdenarSetor(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        if (barElements.length === 0) {
            console.warn(`Nenhuma barra encontrada para a Etapa ${taskId}`);
            return;
        }

        const computedStyles = barElements.map((bar) => ({
            element: bar,
            left: parseFloat(window.getComputedStyle(bar).left),
            width: parseFloat(window.getComputedStyle(bar).width),
            top: parseFloat(window.getComputedStyle(bar).top),
        }));

        OrdenarSetor(barElements, computedStyles, this.tasks, this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleExcluirRelacionamento(taskId) {
        // Aguarda a renderização antes de manipular os elementos
        await this.$nextTick();

        await ExcluirRelacionamento(this.tasks.find(task => task.id === taskId), this);

        this.$nextTick(() => {
            this.ensureBarConsistency(taskId);
        });
    },

    async handleAdicionarTarefa(taskId) {
        await AdicionarTarefa(this.tasks.find(task => task.id === taskId), this);
    },

    async handleExcluirTarefa(taskId) {
        //console.log("taskId", taskId);
        await ExcluirTarefa(this.tasks.find(task => task.id === taskId), this);
    },

    handleCloseIconContainer() {
        this.iconContainerVisible = false;
        closeIconContainer(this);

        this.$nextTick(() => {
            if (this.taskId) {
                this.ensureBarConsistency(this.taskId);
            }
        });
    },

    ensureBarConsistency(taskId) {
        // Garante que as barras estejam renderizadas corretamente
        const barElements = Array.from(
            document.querySelectorAll(`.task-bar[data-task-id="${taskId}"]`)
        );

        barElements.forEach((bar) => {
            const computedStyle = window.getComputedStyle(bar);
            bar.style.left = `${computedStyle.left}`;
            bar.style.width = `${computedStyle.width}`;
            bar.style.top = `${computedStyle.top}`;
        });
    },

    enviarDataInicio(valor) {
        if (!valor || isNaN(new Date(valor).getTime())) {
            console.error("Contexto inválido passado para enviarDataInicio:", valor);
            return;
        }
        ReceberDatas.enviarDataInicio(this, valor);
    },

    enviarDataTermino(valor) {
        ReceberDatas.enviarDataTermino(this, valor);
        this.$nextTick(() => {
            MetodosLinhas.connectBars.call(this);
        });
    },

    toggleDrawer() {
        this.mini = !this.mini;
    },

    handleTaskClick(task) {
        TaskVisibilityMethods.handleTaskClick.call(this, task, this.tasks);
    },
};

export const MultiselectCronograma = {

    async Selecionar_obra(registrosSelecionados) {
        await Selecionar_obra(this, registrosSelecionados);
        this.fetchSetor();
        this.fetchSubsetor();
        this.fetchPlace();
        this.fetchEtapa();
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchObras() {
        await fetchObras(this);
    },

    async Selecionar_setor(registrosSelecionados) {
        await Selecionar_setor(this, registrosSelecionados);
        this.fetchSubsetor();
        this.fetchPlace();
        this.fetchEtapa();
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchSetor() {
        await fetchSetor(this);
    },

    async Selecionar_subsetor(registrosSelecionados) {
        await Selecionar_subsetor(this, registrosSelecionados);
        this.fetchPlace();
        this.fetchEtapa();
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchSubsetor() {
        await fetchSubsetor(this);
    },

    async Selecionar_place(registrosSelecionados) {
        await Selecionar_place(this, registrosSelecionados);
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEtapa();
        this.fetchEmpresa();
    },

    async fetchPlace() {
        await fetchPlace(this);
    },

    async Selecionar_etapa(registrosSelecionados) {
        await Selecionar_etapa(this, registrosSelecionados);
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchEtapa() {
        await fetchEtapa(this);
    },

    async Selecionar_subetapa(registrosSelecionados) {
        await Selecionar_subetapa(this, registrosSelecionados);
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchSubetapa() {
        await fetchSubetapa(this);
    },

    async Selecionar_tarefa(registrosSelecionados) {
        await Selecionar_tarefa(this, registrosSelecionados);
        this.fetchEmpresa();
    },

    async fetchTarefa() {
        await fetchTarefa(this);
    },

    async Selecionar_empresa(registrosSelecionados) {
        await Selecionar_empresa(this, registrosSelecionados);
    },

    async fetchEmpresa() {
        await fetchEmpresa(this);
    },

    async fetchUdeOptions() {
        await fetchUdeOptions(this);
    },
};

export const Limpar = {
    async limpar(context = null) {
        console.log("Limpando dados do orçamento...");
        sessionStorage.setItem("CronogramaObra_obra_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_setor_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_subsetor_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_place_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_etapa_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_subetapa_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_tarefa_registro", JSON.stringify([]));
        sessionStorage.setItem("CronogramaObra_empresa_registro", JSON.stringify([]));
        localStorage.setItem("tasksStateCronograma", JSON.stringify([]));

        console.log("context recebido na função limpar:", JSON.parse(localStorage.getItem("tasksStateCronograma")));

        if (context && Array.isArray(context.tasks)) {

            const updatedTasks = await fetchTasks(context);

            context.tasks.splice(0, context.tasks.length, ...updatedTasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Context inválido ou context.tasks não definido corretamente");
        }
    },
};

export async function fetchTasks(context = null) {
    // Salva a posição atual de rolagem
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Função auxiliar para obter os dados do sessionStorage
    const getSessionData = (key) => {
        try {
            const data = JSON.parse(sessionStorage.getItem(key)) || [];
            return data;
        } catch (error) {
            console.error(`Erro ao carregar dados do sessionStorage para a chave ${key}:`, error.message);
            return [];
        }
    };

    try {
        // Obter os registros do sessionStorage
        const obra_registros = getSessionData("CronogramaObra_obra_registro");
        const setor_registros = getSessionData("CronogramaObra_setor_registro");
        const subsetor_registros = getSessionData("CronogramaObra_subsetor_registro");
        const place_registros = getSessionData("CronogramaObra_place_registro");
        const etapa_registros = getSessionData("CronogramaObra_etapa_registro");
        const subetapa_registros = getSessionData("CronogramaObra_subetapa_registro");
        const tarefa_registros = getSessionData("CronogramaObra_tarefa_registro");
        const empresa_registros = getSessionData("CronogramaObra_empresa_registro");

        // Realiza a requisição à API no endpoint '/orcamento'
        const response = await api.get(`/cronograma/cronograma_tarefa`, {
            params: {
                obra_registros: obra_registros.join(","),
                setor_registros: setor_registros.join(","),
                subsetor_registros: subsetor_registros.join(","),
                place_registros: place_registros.join(","),
                etapa_registros: etapa_registros.join(","),
                subetapa_registros: subetapa_registros.join(","),
                tarefa_registros: tarefa_registros.join(","),
                empresa_registros: empresa_registros.join(","),
                pesquisar_item: state.EditarOrcamento.pesquisar1,
                pesquisar_subitem: state.EditarOrcamento.pesquisar2,
                pesquisar_tipo: state.EditarOrcamento.pesquisar3,
            },
        });
        //console.log("Resposta recebida da API:", response.data);

        // Cria a estrutura da árvore a partir dos dados retornados
        const estruturaArvore = criarEstruturaArvore(response.data);

        // Mapeia e processa os dados utilizando a função mapDados
        const tarefasProcessadas = await mapDados(estruturaArvore, context);
        //console.log("tarefasProcessadas:", tarefasProcessadas);
        // Atualiza o array reativo do contexto, se disponível
        if (context && Array.isArray(context.tasks)) {
            context.tasks.splice(0, context.tasks.length, ...tarefasProcessadas);

            // Restaura a posição de rolagem
            window.scrollTo(0, scrollPosition);

            // Verifica se existe a função 'connectBars' e a executa
            if (typeof context.connectBars === "function") {
                try {
                    context.connectBars();
                } catch (error) {
                    console.error("Erro ao conectar barras:", error.message);
                }
            }
        } else {
            console.warn("Contexto inválido ou não contém 'tasks'. Atualizando tarefas diretamente.");
            // Caso não exista o contexto, tenta atualizar 'this.tasks', se disponível
            if (this && Array.isArray(this.tasks)) {
                this.tasks.splice(0, this.tasks.length, ...tarefasProcessadas);
                window.scrollTo(0, scrollPosition);
            }
        }

        return tarefasProcessadas; // Retorna as tarefas processadas e calculadas
    } catch (error) {
        console.error("Erro ao buscar os dados do orçamento:", error.message);
        return [];
    }
}

function criarEstruturaArvore(dados) {
    const estrutura = new Map(); // Usando Map para melhor desempenho

    dados.forEach((item) => {
        const {
            registro,
            projeto_registro, obra, o_ude, ude_o, o_qde, projeto_inicio, projeto_termino,
            setor_registro, setor, s_ude, ude_s, s_qde, s1_qde, setor_inicio, setor_termino, setor_ordem,
            subsetor_registro, subsetor, sb_ude, ude_sb, sb_qde, sb1_qde, subsetor_inicio, subsetor_termino, subsetor_ordem,
            place_registro, place, p_ude, ude_p, p_qde, p1_qde, place_inicio, place_termino, place_ordem,
            etapa_registro, etapa, e_ude, ude_e, e_qde, e1_qde, etapa_inicio, etapa_termino, etapa_ordem,
            subetapa_registro, subetapa, se_ude, ude_se, se_qde, se1_qde, subetapa_inicio, subetapa_termino, subetapa_ordem,
            tarefa_registro, tarefa, t_ude, ude_t, t_qde, t1_qde, tarefa_inicio, tarefa_termino, porcentagem, ctipo, de, para, ordem,
            duracao, intervalo, duracao01, inicio01, termino01, intervalo01, status_inicio, status_termino,
            subtarefa_registro, subtarefa,
            o_empresa_registro, o_empresa_descricao,

        } = item;
        // Função auxiliar para calcular a diferença em dias entre duas datas
        function calcularDias(inicioStr, terminoStr) {
            const inicio = new Date(inicioStr);
            const termino = new Date(terminoStr);
            const diffMilliseconds = termino - inicio;
            return Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
        }

        // Exemplo para projeto e setor:
        const diasProjeto = calcularDias(projeto_inicio, projeto_termino);
        const diasSetor = calcularDias(setor_inicio, setor_termino);
        const diasSubsetor = calcularDias(subsetor_inicio, subsetor_termino);
        const diasPlace = calcularDias(place_inicio, place_termino);
        const diasEtapa = calcularDias(etapa_inicio, etapa_termino);
        const diasSubetapa = calcularDias(subetapa_inicio, subetapa_termino);
        const diasTarefa = calcularDias(tarefa_inicio, tarefa_termino);

        // Adicionar Projeto
        if (projeto_registro && !estrutura.has(projeto_registro)) {
            estrutura.set(projeto_registro, {
                tipo: "Projeto",
                registro: projeto_registro,
                id: projeto_registro,
                parent_id: null,
                tarefa: obra, O_UDE: o_ude, ude: ude_o, qde: o_qde, soma: null, duracao: diasProjeto, tarefa_inicio: projeto_inicio, tarefa_termino: projeto_termino,
                filhos: [],
            });
        }

        const projeto = estrutura.get(projeto_registro);

        // Adicionar Setor
        if (setor_registro) {
            let seto = projeto.filhos.find((f) => f.setor_registro === setor_registro);
            if (!seto) {
                seto = {
                    tipo: "Setor",
                    registro: parseInt(`${projeto_registro}${setor_registro}`),
                    id: parseInt(`${projeto_registro}${setor_registro}`),
                    parent_id: projeto_registro,
                    tarefa: setor, S_UDE: s_ude, ude: ude_s, qde: s_qde, qde1: s1_qde, soma: null, duracao: diasSetor, tarefa_inicio: setor_inicio,
                    tarefa_termino: setor_termino,
                    ordem_setor: setor_ordem, projeto_registro, setor_registro,
                    filhos: [],
                };
                projeto.filhos.push(seto);
            }

            // Adicionar Subsetor
            if (subsetor_registro) {
                let subseto = seto.filhos.find((f) => f.subsetor_registro === subsetor_registro);
                if (!subseto) {
                    subseto = {
                        tipo: "Subsetor",
                        registro: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}`),
                        id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}`),
                        parent_id: parseInt(`${projeto_registro}${setor_registro}`),
                        tarefa: subsetor, SB_UDE: sb_ude, ude: ude_sb, qde: sb_qde, qde1: sb1_qde, soma: null, duracao: diasSubsetor, tarefa_inicio: subsetor_inicio,
                        tarefa_termino: subsetor_termino,
                        ordem_subsetor: subsetor_ordem, projeto_registro, setor_registro,
                        subsetor_registro,
                        filhos: [],
                    };
                    seto.filhos.push(subseto);
                }

                // Adicionar Place
                if (place_registro) {
                    let plac = subseto.filhos.find((f) => f.place_registro === place_registro);
                    if (!plac) {
                        plac = {
                            tipo: "Place",
                            registro: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}`),
                            id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}`),
                            parent_id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}`),
                            tarefa: place, P_UDE: p_ude, ude: ude_p, qde: p_qde, qde1: p1_qde, soma: null, duracao: diasPlace, tarefa_inicio: place_inicio,
                            tarefa_termino: place_termino,
                            ordem_place: place_ordem, projeto_registro, setor_registro,
                            subsetor_registro, place_registro,
                            filhos: [],
                        };
                        subseto.filhos.push(plac);
                    }

                    // Adicionar Etapa
                    if (etapa_registro) {
                        let etap = plac.filhos.find((f) => f.etapa_registro === etapa_registro);
                        if (!etap) {
                            etap = {
                                tipo: "Etapa",
                                registro: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}${etapa_registro}`),
                                id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}${etapa_registro}`),
                                parent_id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}`),
                                tarefa: etapa, E_UDE: e_ude, ude: ude_e, qde: e_qde, qde1: e1_qde, soma: null, duracao: diasEtapa, tarefa_inicio: etapa_inicio,
                                tarefa_termino: etapa_termino,
                                ordem_etapa: etapa_ordem, projeto_registro, setor_registro,
                                subsetor_registro, place_registro, etapa_registro,
                                filhos: [],
                            };
                            plac.filhos.push(etap);
                        }

                        // Adicionar Subetapa
                        if (subetapa_registro) {
                            let subetap = etap.filhos.find((f) => f.subetapa_registro === subetapa_registro);
                            if (!subetap) {
                                subetap = {
                                    tipo: "Subetapa",
                                    registro: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}${etapa_registro}${subetapa_registro}`),
                                    id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}${etapa_registro}${subetapa_registro}`),
                                    parent_id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}${etapa_registro}`),
                                    tarefa: subetapa, SE_UDE: se_ude, ude: ude_se, qde: se_qde, qde1: se1_qde, soma: null, duracao: diasSubetapa, tarefa_inicio: subetapa_inicio,
                                    tarefa_termino: subetapa_termino,
                                    subetapa_registro, ordem_subetapa: subetapa_ordem, projeto_registro, setor_registro,
                                    subsetor_registro, place_registro, etapa_registro,
                                    filhos: [],
                                };
                                etap.filhos.push(subetap);
                            }

                            // Adicionar Tarefa
                            if (tarefa_registro) {
                                let taref = subetap.filhos.find((f) => f.tarefa_registro === tarefa_registro);
                                if (!taref) {
                                    taref = {
                                        tipo: "Tarefa",
                                        cronograma_id: registro,
                                        registro: registro,
                                        id: registro,
                                        parent_id: parseInt(`${projeto_registro}${setor_registro}${subsetor_registro}${place_registro}${etapa_registro}${subetapa_registro}`),
                                        tarefa_registro, tarefa: tarefa, T_UDE: t_ude, ude: ude_t, qde: t_qde, qde1: t1_qde, soma: null, duracao: diasTarefa, tarefa_inicio: tarefa_inicio,
                                        tarefa_termino: tarefa_termino, porcentagem: porcentagem, ctipo: ctipo, de, para, ordem, duracao00: duracao, intervalo00: intervalo,
                                        duracao01, inicio01, termino01, intervalo01, status_inicio, status_termino,
                                        projeto_registro, obra, O_UDE: o_ude,
                                        setor_registro, setor, S_UDE: s_ude, ordem_setor: setor_ordem,
                                        subsetor_registro, subsetor, SB_UDE: sb_ude, ordem_subsetor: subsetor_ordem,
                                        place_registro, place, P_UDE: p_ude, ordem_place: place_ordem,
                                        etapa_registro, etapa, E_UDE: e_ude, ordem_etapa: etapa_ordem,
                                        subetapa_registro, subetapa, SE_UDE: se_ude, ordem_subetapa: subetapa_ordem,
                                        subtarefa_registro, subtarefa,
                                        o_empresa_registro: o_empresa_registro, empresa: o_empresa_descricao,
                                        filhos: [],
                                    };
                                    subetap.filhos.push(taref);
                                }
                            }
                        }
                    }
                }
            }
        }
    });
    return Array.from(estrutura.values());

}

export async function mapDados(tree, context) {

    const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

    try {
        // Processa os nós da árvore recursivamente
        const tasks = [];

        const processNode = (node) => {
            const processedNode = {
                cronograma_id: node.cronograma_id,
                registro: node.id,
                id: node.registro,
                tarefa: node.tarefa || '',
                tarefa_id: node.tarefa_registro || '',
                subtarefa_id: node.subtarefa_registro || '',
                subtarefa: node.subtarefa || '',
                oempresa_id: node.o_empresa_registro || '',
                oempresa: node.empresa || '',
                start: node.tarefa_inicio ? parseBrazilianDate(node.tarefa_inicio) : new Date('2000-01-01'),
                end: node.tarefa_termino ? parseBrazilianDate(node.tarefa_termino) : new Date('2000-01-01'),
                qde: (node.qde ? parseFloat(node.qde) : 0).toFixed(3),
                qde1: (node.qde1 ? parseFloat(node.qde1) : 0).toFixed(3),
                ude: node.ude || '',
                ctipo: node.ctipo || '',
                de: node.de || '',
                para: node.para || '',
                tipo: node.tipo || '',
                intervalo00: node.intervalo00 || '',
                duracao00: node.duracao00 || '',
                status: node.status || '',
                status_inicio: node.status_inicio || '',
                status_termino: node.status_termino || '',
                ordem_tarefa: node.ordem || '',
                projeto_id: node.projeto_registro || '',
                setor_id: node.setor_registro || '',
                subsetor_id: node.subsetor_registro || '',
                place_id: node.place_registro || '',
                etapa_id: node.etapa_registro || '',
                subetapa_id: node.subetapa_registro || '',
                ordem_id: node.ordem_registro || '',
                ordem_ordem: node.ordem_ordem || '',
                ordem: node.ordem || '',
                parent: node.parent_id || '',
                ordem_setor: node.ordem_setor || '',
                ordem_subsetor: node.ordem_subsetor || '',
                ordem_place: node.ordem_place || '',
                ordem_etapa: node.ordem_etapa || '',
                ordem_subetapa: node.ordem_subetapa || '',
                obra: node.obra || '',
                setor: node.setor || '',
                subsetor: node.subsetor || '',
                place: node.place || '',
                etapa: node.etapa || '',
                subetapa: node.subetapa || '',
                duracao01: node.duracao01 || '',
                inicio01: node.inicio01 ? parseBrazilianDate(node.inicio01) : new Date('2000-01-01'),
                termino01: node.termino01 ? parseBrazilianDate(node.termino01) : new Date('2000-01-01'),
                intervalo01: node.intervalo01 || '',
                duracao02: node.duracao02 || '',
                inicio02: node.inicio02 ? parseBrazilianDate(node.inicio02) : new Date('2000-01-01'),
                termino02: node.termino02 ? parseBrazilianDate(node.termino02) : new Date('2000-01-01'),
                intervalo02: node.intervalo02 || '',
                porcentagem: node.porcentagem !== 0 && node.porcentagem !== null && node.porcentagem !== undefined
                    ? `${Number(node.porcentagem).toFixed(2)}%`
                    : node.soma !== null && node.qde !== null && node.qde !== 0
                        ? `${(node.soma / node.qde * 100).toFixed(2)}%`
                        : '0.00%',
                porcentagema: node.porcentagem !== 0 && node.porcentagem !== null && node.porcentagem !== undefined
                    ? Number(node.porcentagem).toFixed(2)
                    : node.soma !== null && node.qde !== null && node.qde !== 0
                        ? (node.soma / node.qde * 100).toFixed(2)
                        : '0.00',

                s: node.s || '',
                sb: node.sb || '',
                p: node.p || '',
                e: node.e || '',
                se: node.se || '',
                t: node.t || '',
                O_UDE: node.O_UDE || '',
                S_UDE: node.S_UDE || '',
                SB_UDE: node.SB_UDE || '',
                P_UDE: node.P_UDE || '',
                E_UDE: node.E_UDE || '',
                SE_UDE: node.SE_UDE || '',
                T_UDE: node.T_UDE || '',
                O_QDE: node.O_QDE || 0,
                S_QDE: node.S_QDE || 0,
                SB_QDE: node.SB_QDE || 0,
                P_QDE: node.P_QDE || 0,
                E_QDE: node.E_QDE || 0,
                SE_QDE: node.SE_QDE || 0,
                T_QDE: node.T_QDE || 0,
                S1_QDE: node.S1_QDE || 0,
                SB1_QDE: node.SB1_QDE || 0,
                P1_QDE: node.P1_QDE || 0,
                E1_QDE: node.E1_QDE || 0,
                SE1_QDE: node.SE1_QDE || 0,
                sequencia: node.sequencia || '',
                O_EMPRESA: node.O_EMPRESA || '',
                visible: savedTasksState[node.registro] !== undefined
                    ? savedTasksState[node.registro]
                    : true, // Usa o estado salvo ou padrão
            };

            tasks.push(processedNode);

            // Processa os filhos do nó atual
            if (node.filhos && node.filhos.length > 0) {
                node.filhos.forEach((child) => processNode(child, processedNode));
            }
        };

        tree.forEach((rootNode) => processNode(rootNode));


        // Atualiza o contexto se fornecido
        if (context && Array.isArray(context.tasks)) {
            context.tasks.splice(0, context.tasks.length, ...tasks);
        }

        return tasks;

    } catch (error) {
        console.error("Erro ao processar tarefas:", error.message);
        throw error; // Lança o erro para ser tratado no nível superior
    }
}

export async function Selecionar_obra(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Recuperar o estado salvo previamente do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

        // Atualizar registrosAtuais com os registros selecionados
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_obra_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_obra_registro');
            registrosAtuais = [];
        }
        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchObras(context) {
    //console.log("Buscando obras...");   
    try {
        const empresa_registro = sessionStorage.getItem("empresa_registro");

        // Faz a requisição para o servidor
        const response = await api.get(`/cronograma/projeto`, {
            params: { empresa_registro: empresa_registro },
        });

        // Mapeia os dados recebidos para o formato desejado
        context.dataCarregarObra = response.data.map((item) => ({
            value: item.registro,
            label:
                "Obra - " +
                item.descricao +
                " / " +
                "Cliente - " +
                item.cliente +
                " / " +
                "revisão " +
                item.r,
        }));

        //console.log("Dados carregados:", context.dataCarregarObra);
    } catch (error) {
        console.error("Erro ao buscar as obras:", error);
    }
}

export async function Selecionar_setor(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

        // Obter registros atuais do sessionStorage
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_setor_registro")) || [];

        // Atualizar registros com base na seleção
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_setor_registro", JSON.stringify(registrosAtuais));
        } else {
            // Quando nenhum registro é selecionado, limpa os estados associados
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            registrosAtuais = [];
            sessionStorage.removeItem("CronogramaObra_setor_registro");
            sessionStorage.setItem("CronogramaObra_subsetor_registro", JSON.stringify([]));
            sessionStorage.setItem("CronogramaObra_place_registro", JSON.stringify([]));
            sessionStorage.setItem("CronogramaObra_etapa_registro", JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchSetor(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];

    try {
        const response = await api.get('/setor/filter', {
            params: {
                obra_registros: obra_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarSetor = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarSetor = [];
    }
}

export async function Selecionar_subsetor(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_subsetor_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_subsetor_registro');
            registrosAtuais = [];
            sessionStorage.setItem('CronogramaObra_place_registro', JSON.stringify([]));
            sessionStorage.setItem('CronogramaObra_etapa_registro', JSON.stringify([]));
        }
        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchSubsetor(context) {

    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    try {
        const response = await api.get('/subsetor/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarSubsetor = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarSubsetor = [];
    }

}

export async function Selecionar_place(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_place_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_place_registro');
            registrosAtuais = [];
            sessionStorage.setItem('CronogramaObra_etapa_registro', JSON.stringify([]));
        }
        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchPlace(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    try {
        const response = await api.get('/place/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarPlace = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarPlace = [];
    }
}

export async function Selecionar_etapa(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_etapa_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_etapa_registro');
            registrosAtuais = [];
            sessionStorage.setItem('CronogramaObra_subetapa_registro', JSON.stringify([]));
        }
        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchEtapa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    try {
        const response = await api.get('/etapa/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarEtapa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarEtapa = [];
    }
}

export async function Selecionar_subetapa(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_subetapa_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_subetapa_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_subetapa_registro');
            registrosAtuais = [];
            sessionStorage.setItem('CronogramaObra_tarefa_registro', JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchSubetapa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    const etapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];
    try {
        const response = await api.get('/subetapa/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(','),
                etapa_registros: etapa_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarSubetapa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarSubetapa = [];
    }
}

export async function Selecionar_tarefa(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_tarefa_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_tarefa_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_tarefa_registro');
            registrosAtuais = [];
        }
        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchTarefa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    const etapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];
    const subetapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subetapa_registro')) || [];

    try {
        const response = await api.get('/tarefa/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(','),
                etapa_registros: etapa_registro.join(','),
                subetapa_registros: subetapa_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarTarefa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarTarefa = [];
    }
}

export async function Selecionar_empresa(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_empresa_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_empresa_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_empresa_registro');
            registrosAtuais = [];
        }
        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchEmpresa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    const etapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];
    const subetapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subetapa_registro')) || [];
    const tarefa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_tarefa_registro')) || [];

    try {
        const response = await api.get('/cronograma/o_empresa', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(','),
                etapa_registros: etapa_registro.join(','),
                subetapa_registros: subetapa_registro.join(','),
                tarefa_registros: tarefa_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarEmpresa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarEmpresa = [];
    }
}

export function openIconContainer(event, taskId, context) {
    const containerWidth = 150;
    const containerHeight = 100;
    const padding = 10;
    const extraOffset = 15;

    // Posição inicial desejada com base no clique
    let top = event.clientY + extraOffset;
    let left = event.clientX + extraOffset;

    // Obtenha as dimensões da janela uma única vez
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Ajuste apenas se o contêiner ultrapassar as bordas da janela
    if (left + containerWidth + padding > windowWidth) {
        left = windowWidth - containerWidth - padding;
    }
    if (top + containerHeight + padding > windowHeight) {
        top = windowHeight - containerHeight - padding;
    }

    // Atualize a posição e o estado do contêiner
    context.iconContainerVisible = true;
    context.selectedTaskId = taskId;
    context.iconContainerPosition = { top, left };
}

export function closeIconContainer(context) {
    context.iconContainerVisible = false;
    context.selectedTaskId = null;

    context.$nextTick(() => {
        context.connectBars();
    });
}

export function arrastarInicioInicio(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let id_move = null;
    let inicio_move = null;
    let termino_move = null;
    let currentHighlightedRow = null;
    let intervalo_move = null;
    let duracao_move = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.id;
                inicio_move = new Date(task.start);
                termino_move = new Date(task.end);
                intervalo_move = task.intervalo00;
                duracao_move = task.duracao00;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });

            document.body.style.userSelect = "none";
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }
            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };

    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            document.body.style.userSelect = "";

            const finalBarPosition = barElements[0].getBoundingClientRect();

            // Detecção mais robusta de mesCell
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);
            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            const inicioDestino = new Date(targetTask.start);
                            const terminoDestino = new Date(targetTask.end);
                            const tipo = 'inicioinicio';

                            try {
                                context.showSpinner();

                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirDetalhesTarefas(
                                    id_move, inicio_move, termino_move,
                                    targetTask.id, inicioDestino, terminoDestino,
                                    tipo, intervalo_move, duracao_move, context
                                );

                                context.tasks = [];

                                const updatedTasks = await fetchTasks(context);

                                context.$nextTick(() => {
                                    context.tasks = [...updatedTasks];
                                    context.loadTasksState(context.tasks);
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            } finally {
                                // Desativa o carregador global
                                context.hideSpinner();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

export function arrastarTerminoInicio(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let id_move = null;
    let inicio_move = null;
    let termino_move = null;
    let currentHighlightedRow = null;
    let intervalo_move = null;
    let duracao_move = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.id;
                inicio_move = new Date(task.start);
                termino_move = new Date(task.end);
                intervalo_move = task.intervalo00;
                duracao_move = task.duracao00;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });

            document.body.style.userSelect = "none";
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }
            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };

    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            document.body.style.userSelect = "";

            const finalBarPosition = barElements[0].getBoundingClientRect();

            // Detecção mais robusta de mesCell
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);
            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            const inicioDestino = new Date(targetTask.start);
                            const terminoDestino = new Date(targetTask.end);
                            const tipo = 'terminoinicio';

                            try {
                                context.showSpinner();

                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirDetalhesTarefas(
                                    id_move, inicio_move, termino_move,
                                    targetTask.id, inicioDestino, terminoDestino,
                                    tipo, intervalo_move, duracao_move, context
                                );

                                context.tasks = [];

                                const updatedTasks = await fetchTasks(context);

                                context.$nextTick(() => {
                                    context.tasks = [...updatedTasks];
                                    context.loadTasksState(context.tasks);
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            } finally {
                                // Finaliza o estado temporário da tarefa
                                context.hideSpinner();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirDetalhesTarefas(idOrigem, inicioOrigem, terminoOrigem, idDestino, inicioDestino, terminoDestino, tipo, intervalo_move, duracao_move, context) {
    try {
        const diffInDays = (inicioDestino - inicioOrigem) / (1000 * 60 * 60 * 24);
        await fetchTarefasVincular(idOrigem, inicioOrigem, terminoOrigem, idDestino, inicioDestino, terminoDestino, diffInDays, tipo, intervalo_move, duracao_move, context);
    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
    }
}

export async function fetchTarefasVincular(idOrigem, inicioOrigem, terminoOrigem, idDestino, inicioDestino, terminoDestino, diffInDays, tipo, intervaloOrigem, duracaoOrigem) {

    try {
        // Verifica se a tarefa já está em processamento
        if (task.isProcessing) {
            console.warn("Tarefa já está sendo processada. Ignorando a chamada.");
            return;
        }
        task.isProcessing = true;

        const response = await api.post('/cronograma/tarefas_vincular', {
            idOrigem,
            inicioOrigem,
            terminoOrigem,
            idDestino,
            inicioDestino,
            terminoDestino,
            diffInDays,
            tipo,
            intervaloOrigem,
            duracaoOrigem
        });

        return response.data;

    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
        // Retorna null caso ocorra um erro
        return null;
    } finally {
        // Finaliza o estado temporário da tarefa
        task.isProcessing = false;
    }
}

export async function SalvarIntervalo(task, context) {

    if (task.isProcessing) return;
    task.isProcessing = true;

    // Verifica se `task.para` está vazio ou indefinido
    if (!task.para) {
        exibirMensagem("Esta tarefa não tem vínculo."); // Mensagem personalizada
        task.isProcessing = false; // Libera o flag após o alerta
        return; // Sai da função sem fazer nada
    }

    try {
        // Envia a tarefa para o backend via requisição POST
        const response = await api.post('/cronograma/salvar-intervalo', task);

        // Verifica o contexto e executa as atualizações necessárias
        if (context && typeof context.$nextTick === 'function') {

            await context.fetchTasks(context);

            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                console.log("aa");
                context.clearSVGLines();
                context.connectBars();
            });

        } else {
            console.warn("Contexto ou $nextTick não está disponível.");
        }

        return response.data; // Retorna os dados da resposta
    } catch (error) {
        console.error('Erro ao salvar intervalo:', error);
        throw error; // Propaga o erro para o chamador
    } finally {
        // Libera o flag após a execução
        task.isProcessing = false;
    }
}

export async function salvarInicio00(task, context) {
    // Verifica se já está processando para evitar chamadas duplicadas
    if (task.isProcessing) return;
    task.isProcessing = true;

    try {

        // Envia a tarefa para o backend via requisição POST
        const response = await api.post('/cronograma/salvar-inicio', task);

        // Verifica o contexto e executa as atualizações necessárias
        if (context && typeof context.fetchTasks === 'function') {

            await context.fetchTasks(context);

            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        return response.data; // Retorna os dados da resposta
    } catch (error) {
        console.error('Erro ao salvar inicio:', error);
        throw error; // Propaga o erro para o chamador
    } finally {
        // Libera o flag após a execução
        task.isProcessing = false;
    }
}

export async function SalvarDuracao(task, context) {
    // Verifica se já está processando para evitar chamadas duplicadas
    if (task.isProcessing) return;
    task.isProcessing = true;

    try {

        // Envia a tarefa para o backend via requisição POST
        const response = await api.post('/cronograma/salvar-duracao', task);

        await fetchTasks.call(context);
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();

        });
        return response.data; // Retorna os dados da resposta
    } catch (error) {
        console.error('Erro ao salvar duracao:', error);
        throw error; // Propaga o erro para o chamador
    } finally {
        // Libera o flag após a execução
        task.isProcessing = false;
    }
}

export async function SalvarTarefaCronograma(task, context) {

    try {
        const response = await api.post('/cronograma/salvar-tarefa-cronograma', task);

        // Verifica o contexto e executa as atualizações necessárias
        if (context && typeof context.$nextTick === 'function') {
            await context.fetchTasks(); // Busca novamente os dados
        } else {
            console.warn("Contexto ou $nextTick não está disponível.");
        }

        return response.data;
    } catch (error) {
        console.error('Erro ao salvar tarefa cronograma:', error);
        throw error;
    }
}

export function OrdenarTarefa(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentHighlightedRow = null;
    let id_move = null;
    let ordem_move = null;
    let id_recebe = null;
    let ordem_recebe = null;
    let projeto_id = null;
    let setor_id = null;
    let subsetor_id = null;
    let place_id = null;
    let etapa_id = null;
    let subetapa_id = null;
    let tipo_move = null;
    let tipo_recebe = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.id;
                ordem_move = task.ordem_tarefa;
                projeto_id = task.projeto_id;
                setor_id = task.setor_id;
                subsetor_id = task.subsetor_id;
                place_id = task.place_id;
                etapa_id = task.etapa_id;
                subetapa_id = task.subetapa_id;
                tipo_move = task.tipo;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }

            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };

    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            const finalBarPosition = barElements[0].getBoundingClientRect();
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);

            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            id_recebe = targetTask.id;
                            ordem_recebe = targetTask.ordem_tarefa;
                            tipo_recebe = targetTask.tipo;

                            try {
                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirOrdenarTarefa(
                                    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
                                    setor_id, subsetor_id, place_id, etapa_id, subetapa_id,
                                    tipo_move, tipo_recebe, context
                                );

                                context.tasks = [];

                                await context.fetchTasks(context);

                                context.loadTasksState(context.tasks);

                                context.$nextTick(() => {
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirOrdenarTarefa(
    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
    setor_id, subsetor_id, place_id, etapa_id, subetapa_id,
    tipo_move, tipo_recebe, context
) {
    if (task.isProcessing) return;
    task.isProcessing = true;

    // Captura posição da rolagem
    const scrollContainer = document.querySelector('.cronograma-content-wrapper');
    const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

    console.log("tipo_move", tipo_move, "tipo_recebe", tipo_recebe);

    // Verifica se os tipos de tarefa não correspondem
    if (tipo_move !== tipo_recebe) {
        exibirMensagem("Os tipos de tarefas não são compatíveis. Não é possível vincular.");
        task.isProcessing = false;
        return;
    }

    try {
        methods.showSpinner();
        await new Promise(resolve => setTimeout(resolve, 100)); // pequena pausa para UX

        // Faz a requisição para ordenar as tarefas
        const response = await api.post('/cronograma/ordenar_tarefa', {
            id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
            setor_id, subsetor_id, place_id, etapa_id, subetapa_id
        });

        // Atualiza o contexto, se estiver disponível
        if (context && typeof context.fetchTasks === 'function') {
            await context.fetchTasks(context);
            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        // Restaura a posição da rolagem
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollPosition;
            }
        }, 100);

        return response.data;

    } catch (error) {
        console.error("Erro ao ordenar tarefas:", error);
        return null;
    } finally {
        task.isProcessing = false;
        methods.hideSpinner();
    }
}

export function OrdenarSubetapa(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentHighlightedRow = null;
    let id_move = null;
    let ordem_move = null;
    let id_recebe = null;
    let ordem_recebe = null;
    let projeto_id = null;
    let setor_id = null;
    let subsetor_id = null;
    let place_id = null;
    let etapa_id = null;
    let subetapa_id = null;
    let tipo_move = null;
    let tipo_recebe = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.subetapa_id;
                ordem_move = task.ordem_subetapa;
                projeto_id = task.projeto_id;
                setor_id = task.setor_id;
                subsetor_id = task.subsetor_id;
                place_id = task.place_id;
                etapa_id = task.etapa_id;
                subetapa_id = task.subetapa_id;
                tipo_move = task.tipo;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }

            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };

    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            const finalBarPosition = barElements[0].getBoundingClientRect();
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);

            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            id_recebe = targetTask.subetapa_id;
                            ordem_recebe = targetTask.ordem_subetapa;
                            tipo_recebe = targetTask.tipo;

                            try {
                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirOrdenarSubetapa(
                                    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
                                    setor_id, subsetor_id, place_id, etapa_id, subetapa_id,
                                    tipo_move, tipo_recebe, context
                                );

                                context.tasks = [];

                                await context.fetchTasks(context);

                                context.loadTasksState(context.tasks);

                                context.$nextTick(() => {
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirOrdenarSubetapa(
    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
    setor_id, subsetor_id, place_id, etapa_id, subetapa_id,
    tipo_move, tipo_recebe, context
) {
    if (task.isProcessing) return;
    task.isProcessing = true;

    // Captura posição da rolagem
    const scrollContainer = document.querySelector('.cronograma-content-wrapper');
    const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

    // Verifica se os tipos de tarefa não correspondem
    if (tipo_move !== tipo_recebe) {
        exibirMensagem("Os tipos de subetapas não são compatíveis. Não é possível vincular.");
        task.isProcessing = false;
        return;
    }

    try {
        methods.showSpinner();
        await new Promise(resolve => setTimeout(resolve, 100)); // pequena pausa visual

        const response = await api.post('/cronograma/ordenar_subetapa', {
            id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
            setor_id, subsetor_id, place_id, etapa_id, subetapa_id,
            tipo_move, tipo_recebe
        });

        if (context && typeof context.fetchTasks === 'function') {
            await context.fetchTasks(context);

            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        // Restaura a posição da rolagem
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollPosition;
            }
        }, 100);

        return response.data;

    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
        return null;
    } finally {
        task.isProcessing = false;
        methods.hideSpinner();
    }
}

export function OrdenarEtapa(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentHighlightedRow = null;
    let id_move = null;
    let ordem_move = null;
    let id_recebe = null;
    let ordem_recebe = null;
    let projeto_id = null;
    let setor_id = null;
    let subsetor_id = null;
    let place_id = null;
    let tipo_move = null;
    let tipo_recebe = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.etapa_id;
                ordem_move = task.ordem_etapa;
                projeto_id = task.projeto_id;
                setor_id = task.setor_id;
                subsetor_id = task.subsetor_id;
                place_id = task.place_id;
                tipo_move = task.tipo;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }

            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };


    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            const finalBarPosition = barElements[0].getBoundingClientRect();
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);

            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            id_recebe = targetTask.etapa_id;
                            ordem_recebe = targetTask.ordem_etapa;
                            tipo_recebe = targetTask.tipo;

                            try {
                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirOrdenarEtapa(
                                    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
                                    setor_id, subsetor_id, place_id, tipo_move, tipo_recebe, context
                                );

                                context.tasks = [];

                                await context.fetchTasks(context);

                                context.loadTasksState(context.tasks);

                                context.$nextTick(() => {
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirOrdenarEtapa(
    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
    setor_id, subsetor_id, place_id, tipo_move, tipo_recebe, context
) {
    if (task.isProcessing) return;
    task.isProcessing = true;

    // Captura a posição da rolagem
    const scrollContainer = document.querySelector('.cronograma-content-wrapper');
    const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

    console.log("tipo_move", tipo_move, "tipo_recebe", tipo_recebe);

    // Verifica se os tipos de tarefa não correspondem
    if (tipo_move !== tipo_recebe) {
        exibirMensagem("Os tipos de Etapas não são compatíveis. Não é possível vincular.");
        task.isProcessing = false;
        return;
    }

    try {
        methods.showSpinner();
        await new Promise(resolve => setTimeout(resolve, 100)); // pequena pausa visual

        const response = await api.post('/cronograma/ordenar_etapa', {
            id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
            setor_id, subsetor_id, place_id, tipo_move, tipo_recebe
        });

        if (context && typeof context.fetchTasks === 'function') {
            await context.fetchTasks(context);
            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        // Restaura a rolagem após a execução
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollPosition;
            }
        }, 100);

        return response.data;

    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
        return null;
    } finally {
        task.isProcessing = false;
        methods.hideSpinner();
    }
}

export function OrdenarPlace(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentHighlightedRow = null;
    let id_move = null;
    let ordem_move = null;
    let id_recebe = null;
    let ordem_recebe = null;
    let projeto_id = null;
    let setor_id = null;
    let subsetor_id = null;
    let tipo_move = null;
    let tipo_recebe = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.place_id;
                ordem_move = task.ordem_place;
                projeto_id = task.projeto_id;
                setor_id = task.setor_id;
                subsetor_id = task.subsetor_id;
                tipo_move = task.tipo;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }

            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };


    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            const finalBarPosition = barElements[0].getBoundingClientRect();
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);

            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            id_recebe = targetTask.place_id;
                            ordem_recebe = targetTask.ordem_place;
                            tipo_recebe = targetTask.tipo;
                            
                            try {
                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirOrdenarPlace(
                                    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
                                    setor_id, subsetor_id, tipo_move, tipo_recebe, context
                                );

                                context.tasks = [];

                                await context.fetchTasks(context);

                                context.loadTasksState(context.tasks);

                                context.$nextTick(() => {
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirOrdenarPlace(
    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
    setor_id, subsetor_id, tipo_move, tipo_recebe, context
) {
    if (task.isProcessing) return;
    task.isProcessing = true;

    // Captura a posição da rolagem
    const scrollContainer = document.querySelector('.cronograma-content-wrapper');
    const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

    // Verifica se os tipos de tarefa não correspondem
    if (tipo_move !== tipo_recebe) {
        exibirMensagem("Os tipos de Places não são compatíveis. Não é possível vincular.");
        task.isProcessing = false;
        return;
    }

    try {
        methods.showSpinner();
        await new Promise(resolve => setTimeout(resolve, 100)); // pausa visual

        const response = await api.post('/cronograma/ordenar_place', {
            id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
            setor_id, subsetor_id, tipo_move, tipo_recebe
        });

        if (context && typeof context.fetchTasks === 'function') {
            await context.fetchTasks(context);
            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        // Restaura a posição da rolagem
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollPosition;
            }
        }, 100);

        return response.data;

    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
        return null;
    } finally {
        task.isProcessing = false;
        methods.hideSpinner();
    }
}

export function OrdenarSubsetor(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentHighlightedRow = null;
    let id_move = null;
    let ordem_move = null;
    let id_recebe = null;
    let ordem_recebe = null;
    let projeto_id = null;
    let setor_id = null;
    let tipo_move = null;
    let tipo_recebe = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.subsetor_id;
                ordem_move = task.ordem_subsetor;
                projeto_id = task.projeto_id;
                setor_id = task.setor_id;
                tipo_move = task.tipo;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }

            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };

    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            const finalBarPosition = barElements[0].getBoundingClientRect();
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);

            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            id_recebe = targetTask.subsetor_id;
                            ordem_recebe = targetTask.ordem_subsetor;
                            tipo_recebe = targetTask.tipo;

                            try {
                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirOrdenarSubsetor(
                                    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
                                    setor_id, tipo_move, tipo_recebe, context
                                );

                                context.tasks = [];

                                await context.fetchTasks(context);

                                context.loadTasksState(context.tasks);

                                context.$nextTick(() => {
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirOrdenarSubsetor(
    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
    setor_id, tipo_move, tipo_recebe, context
) {
    if (task.isProcessing) return;
    task.isProcessing = true;

    // Captura a posição da rolagem
    const scrollContainer = document.querySelector('.cronograma-content-wrapper');
    const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

    // Verifica se os tipos de tarefa não correspondem
    if (tipo_move !== tipo_recebe) {
        exibirMensagem("Os tipos de Subsetores não são compatíveis. Não é possível vincular.");
        task.isProcessing = false;
        return;
    }

    try {
        methods.showSpinner();
        await new Promise(resolve => setTimeout(resolve, 100)); // pequena pausa para fluidez

        const response = await api.post('/cronograma/ordenar_subsetor', {
            id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
            setor_id, tipo_move, tipo_recebe
        });

        if (context && typeof context.fetchTasks === 'function') {
            await context.fetchTasks(context);
            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        // Restaura a rolagem
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollPosition;
            }
        }, 100);

        return response.data;

    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
        return null;
    } finally {
        task.isProcessing = false;
        methods.hideSpinner();
    }
}

export function OrdenarSetor(barElements, computedStyles, tasks, context) {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentHighlightedRow = null;
    let id_move = null;
    let ordem_move = null;
    let id_recebe = null;
    let ordem_recebe = null;
    let projeto_id = null;
    let tipo_move = null;
    let tipo_recebe = null;

    const highlightRow = (row) => {
        if (currentHighlightedRow !== row) {
            if (currentHighlightedRow) {
                currentHighlightedRow.classList.remove("highlight-row");
            }
            row.classList.add("highlight-row");
            currentHighlightedRow = row;
        }
    };

    const removeHighlight = () => {
        if (currentHighlightedRow) {
            currentHighlightedRow.classList.remove("highlight-row");
            currentHighlightedRow = null;
        }
    };

    const resetToOriginalPosition = () => {
        barElements.forEach(bar => {
            bar.style.transform = "translate(0, 0)";
        });
        context.$nextTick(() => {
            context.clearSVGLines();
            context.connectBars();
        });
    };

    const onMouseMove = (event) => {
        const deltaX = event.clientX - startX;
        const deltaY = event.clientY - startY;

        if (!isDragging && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
            isDragging = true;

            const taskId = barElements[0].getAttribute("data-task-id");
            const task = tasks.find(task => task.id === Number(taskId));

            if (task) {
                id_move = task.setor_id;
                ordem_move = task.ordem_setor;
                projeto_id = task.projeto_id;
                tipo_move = task.tipo;
            }

            barElements.forEach(bar => {
                bar.style.pointerEvents = "none";
                bar.classList.add("task-bar-dragging");
            });
        }

        if (isDragging) {
            barElements.forEach(({ style }) => {
                style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            });

            const rowUnderMouse = document.elementFromPoint(event.clientX, event.clientY)?.closest('.tr-id');
            if (rowUnderMouse) {
                highlightRow(rowUnderMouse);
            } else {
                removeHighlight();
            }

            // Atualiza dinamicamente as linhas SVG
            context.clearSVGLines();
            context.connectBars();
        }
    };


    const onMouseUp = async (event) => {
        if (isDragging) {
            isDragging = false;
            removeHighlight();

            barElements.forEach(bar => {
                bar.style.pointerEvents = "auto";
                bar.classList.remove("task-bar-dragging");
            });

            const finalBarPosition = barElements[0].getBoundingClientRect();
            let mesCell = document.elementFromPoint(event.clientX, event.clientY);

            if (!mesCell || !mesCell.classList.contains("mes-cell")) {
                mesCell = Array.from(document.querySelectorAll(".mes-cell"))
                    .find(cell => {
                        const cellPosition = cell.getBoundingClientRect();
                        return (
                            finalBarPosition.left < cellPosition.right &&
                            finalBarPosition.right > cellPosition.left &&
                            finalBarPosition.top < cellPosition.bottom &&
                            finalBarPosition.bottom > cellPosition.top
                        );
                    });
            }

            if (mesCell) {
                const targetTaskBar = mesCell.querySelector(".task-bar");
                if (targetTaskBar) {
                    const targetTaskId = targetTaskBar.getAttribute("data-task-id");

                    if (targetTaskId && targetTaskId !== String(id_move)) {
                        const targetTask = tasks.find(task => task.id === Number(targetTaskId));

                        if (targetTask) {
                            id_recebe = targetTask.setor_id;
                            ordem_recebe = targetTask.ordem_setor;
                            tipo_recebe = targetTask.tipo;

                            try {
                                const scrollContainer = context.$refs.contentWrapper;
                                const scrollTopPosition = scrollContainer ? scrollContainer.scrollTop : 0;

                                await exibirOrdenarSetor(
                                    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
                                    tipo_move, tipo_recebe, context
                                );

                                context.tasks = [];

                                await context.fetchTasks(context);

                                context.loadTasksState(context.tasks);

                                context.$nextTick(() => {
                                    context.clearSVGLines();
                                    context.connectBars();
                                    if (scrollContainer) {
                                        scrollContainer.scrollTop = scrollTopPosition;
                                    }
                                });

                            } catch (error) {
                                console.error("Erro ao atualizar tarefa no banco de dados:", error);
                                resetToOriginalPosition();
                            }
                        } else {
                            resetToOriginalPosition();
                        }
                    } else {
                        resetToOriginalPosition();
                    }
                } else {
                    resetToOriginalPosition();
                }
            } else {
                resetToOriginalPosition();
            }

            endDrag();
        }
    };

    const endDrag = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const startDrag = (event) => {
        startX = event.clientX;
        startY = event.clientY;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    barElements.forEach((bar) => {
        bar.addEventListener("mousedown", startDrag);
    });
}

async function exibirOrdenarSetor(
    id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
    tipo_move, tipo_recebe, context
) {
    if (task.isProcessing) return;
    task.isProcessing = true;

    // Captura a posição da rolagem
    const scrollContainer = document.querySelector('.cronograma-content-wrapper');
    const scrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;

    // Verifica se os tipos de tarefa não correspondem
    if (tipo_move !== tipo_recebe) {
        exibirMensagem("Os tipos de Setores não são compatíveis. Não é possível vincular.");
        task.isProcessing = false;
        return;
    }

    try {
        methods.showSpinner();
        await new Promise(resolve => setTimeout(resolve, 100)); // pausa visual

        const response = await api.post('/cronograma/ordenar_setor', {
            id_move, ordem_move, id_recebe, ordem_recebe, projeto_id,
            tipo_move, tipo_recebe
        });

        if (context && typeof context.fetchTasks === 'function') {
            await context.fetchTasks(context);
            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        // Restaura a rolagem após atualização
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollPosition;
            }
        }, 100);

        return response.data;

    } catch (error) {
        console.error("Erro ao vincular tarefas:", error);
        return null;
    } finally {
        task.isProcessing = false;
        methods.hideSpinner();
    }
}


async function ExcluirRelacionamento(task, context) {
    // Verifica se já está processando para evitar chamadas duplicadas
    if (task.isProcessing) return;
    task.isProcessing = true;

    try {

        // Envia a tarefa para o backend via requisição POST
        const response = await api.post('/cronograma/excluir-relacionamento', {
            id: task.id // Passa apenas o ID do relacionamento para o backend
        });

        // Verifica o contexto e executa as atualizações necessárias
        if (context && typeof context.fetchTasks === 'function') {

            await context.fetchTasks(context);

            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }

        return response.data; // Retorna os dados da resposta
    } catch (error) {
        console.error('Erro ao salvar inicio:', error);
        throw error; // Propaga o erro para o chamador
    } finally {
        // Libera o flag após a execução
        task.isProcessing = false;
    }
}

async function AdicionarTarefa(task, context) {
    // Verifica se já está processando para evitar chamadas duplicadas
    if (task.isProcessing) return;
    task.isProcessing = true;

    try {

        // Envia a tarefa para o backend via requisição POST
        const response = await api.post('/cronograma/adicionar_tarefa', {
            projeto_id: task.projeto_id || '',
            setor_id: task.setor_id || '',
            subsetor_id: task.subsetor_id || '',
            place_id: task.place_id || '',
            etapa_id: task.etapa_id || '',
            subetapa_id: task.subetapa_id || '',
            setor_ordem: task.ordem_setor || '',
            subsetor_ordem: task.ordem_subsetor || '',
            place_ordem: task.ordem_place || '',
            etapa_ordem: task.ordem_etapa || '',
            subetapa_ordem: task.ordem_subetapa || '',
            O_UDE: task.O_UDE || '',
            S_UDE: task.S_UDE || '',
            SB_UDE: task.SB_UDE || '',
            P_UDE: task.P_UDE || '',
            E_UDE: task.E_UDE || '',
            SE_UDE: task.SE_UDE || '',
            O_QDE: task.O_QDE || 0,
            S_QDE: task.S_QDE || 0,
            SB_QDE: task.SB_QDE || 0,
            P_QDE: task.P_QDE || 0,
            E_QDE: task.E_QDE || 0,
            SE_QDE: task.SE_QDE || 0,
            S1_QDE: task.S1_QDE || 0,
            SB1_QDE: task.SB1_QDE || 0,
            P1_QDE: task.P1_QDE || 0,
            E1_QDE: task.E1_QDE || 0,
            SE1_QDE: task.SE1_QDE || 0,

        });

        // Verifica o contexto e executa as atualizações necessárias
        if (context && typeof context.fetchTasks === 'function') {
            // Garantir que fetchTasks seja chamada com registrosAtuais e que a execução seja aguardada
            await context.fetchTasks(context);
            context.saveTasksState(context.tasks);
            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }
        return response.data; // Retorna os dados da resposta

    } catch (error) {
        console.error('Erro ao salvar inicio:', error);
        throw error; // Propaga o erro para o chamador
    } finally {
        // Libera o flag após a execução
        task.isProcessing = false;
    }
}

async function ExcluirTarefa(task, context) {
    // Verifica se já está processando para evitar chamadas duplicadas
    if (task.isProcessing) return;
    task.isProcessing = true;

    try {

        // Envia a tarefa para o backend via requisição POST
        const response = await api.post('/cronograma/excluir-tarefa', {
            id: task.id // Passa apenas o ID do relacionamento para o backend
        });

        // Verifica o contexto e executa as atualizações necessárias
        if (context && typeof context.fetchTasks === 'function') {
            // Garantir que fetchTasks seja chamada com registrosAtuais e que a execução seja aguardada
            await context.fetchTasks(context);

            context.loadTasksState(context.tasks);

            context.$nextTick(() => {
                context.clearSVGLines();
                context.connectBars();
            });
        } else {
            console.warn("Contexto ou fetchTasks não está disponível.");
        }
        return response.data; // Retorna os dados da resposta
    } catch (error) {
        console.error('Erro ao salvar inicio:', error);
        throw error; // Propaga o erro para o chamador
    } finally {
        // Libera o flag após a execução
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}

export async function fetchUdeOptions(context) {
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.udeOptions = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar
        }));
        //console.log("udeOptions carregadas:", context.udeOptions);
    } catch (error) {
        console.error("Erro ao carregar as opções de UDE:", error);
    }
}

export async function EditarUnidade(context) {
    console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/cronograma/editar-unidade", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarQde(context) {
    try {
        const response = await api.post("/cronograma/editar-qde", context);

        return response.data;

    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    }
}

export async function EditarQde1(context) {
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/cronograma/editar-qde1", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarTarefaCronograma(task, context) {
    //console.log("task:", task);

    if (!task || task.isProcessing) return;
    task.isProcessing = true;

    if (!context || !Array.isArray(context.tasks)) {
        console.error("Contexto ou tasks não definidos.");
        task.isProcessing = false;
        return;
    }

    // Salvar o estado de visibilidade antes da operação
    const savedTasksState = context.tasks.reduce((state, task) => {
        state[task.id] = task.visible;
        return state;
    }, {});

    //console.log("Estado inicial dos IDs no contexto (savedState):", savedTasksState);
    //console.log("setor_id", state.EditarTarefaCronograma.setor_ida);
    //console.log("setor_registro", state.EditarTarefaCronograma.setor_id);
    try {
        // Faz a requisição ao backend para editar a tarefa
        const response = await api.post("/cronograma/editar-tarefa", {
            cronograma_id: state.EditarTarefaCronograma.cronograma_ida,
            projeto_id: task.projeto_id,
            setor_id: state.EditarTarefaCronograma.setor_ida,
            subsetor_id: state.EditarTarefaCronograma.subsetor_ida,
            place_id: state.EditarTarefaCronograma.place_ida,
            etapa_id: state.EditarTarefaCronograma.etapa_ida,
            subetapa_id: state.EditarTarefaCronograma.subetapa_ida,
            tarefa_id: state.EditarTarefaCronograma.tarefa_ida,
            subtarefa_id: state.EditarTarefaCronograma.subtarefa_id,
            cronograma_ida: state.EditarTarefaCronograma.cronograma_id,
            setor_ida: state.EditarTarefaCronograma.setor_id,
            subsetor_ida: state.EditarTarefaCronograma.subsetor_id,
            place_ida: state.EditarTarefaCronograma.place_id,
            etapa_ida: state.EditarTarefaCronograma.etapa_id,
            subetapa_ida: state.EditarTarefaCronograma.subetapa_id,
            tarefa_ida: state.EditarTarefaCronograma.tarefa_id,
            setor_ordem: state.EditarTarefaCronograma.setor_ordem || 1,
            subsetor_ordem: state.EditarTarefaCronograma.subsetor_ordem || 1,
            place_ordem: state.EditarTarefaCronograma.place_ordem || 1,
            etapa_ordem: state.EditarTarefaCronograma.etapa_ordem || 1,
            subetapa_ordem: state.EditarTarefaCronograma.subetapa_ordem || 1,
            ordem: state.EditarTarefaCronograma.ordem || 1,
            oempresa_id: state.EditarTarefaCronograma.oempresa_id
        });

        // Atualiza as tarefas com os dados do servidor
        const updatedTasks = await fetchTasks(context);

        if (!Array.isArray(updatedTasks)) {
            console.error("fetchTasks não retornou um array. Valor recebido:", updatedTasks);
            return;
        }

        //console.log("Tarefas retornadas (updatedTasks):", updatedTasks);

        // Atualizar as tarefas no contexto
        context.tasks.splice(0, context.tasks.length, ...updatedTasks);

        // Restaurar o estado de visibilidade para as tarefas atualizadas
        context.tasks.forEach(task => {
            task.visible = savedTasksState[task.id] ?? true; // Recolhido por padrão se não existir no estado salvo
        });

        //console.log("Tarefas atualizadas com estados restaurados:", context.tasks.map(t => ({ id: t.id, visible: t.visible })));

        return response.data;
    } catch (error) {
        console.error("Erro ao editar a tarefa:", error);
        exibirMensagem("Erro ao editar a tarefa. Tente novamente.");
    } finally {
        task.isProcessing = false;
    }
}
