import axios from "axios";
import { state } from "../../store.js";
//import { atualizarCampos } from "../cronograma/editartarefamodal/editartarefa.js";
const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://efran.onrender.com/api"
        : "http://localhost:10000/api",
  });

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    editingTaskId: null,
});

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Subtarefa = {
    async initializeTasks() {
        let subtarefa_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            subtarefa_id = this.task;
        } else if (this.task && this.task.subtarefa_id) {
            subtarefa_id = this.task.subtarefa_id;
        } else {
            console.warn("subtarefa_id não encontrado no contexto da subtarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchSubtarefa(subtarefa_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statussubtarefa-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarSubtarefa(valor) {
        clearTimeout(this.debounceTimeoutSubtarefa);

        this.debounceTimeoutSubtarefa = setTimeout(async () => {
            this.querySubtarefa = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchSubtarefa(state.pesquisarSubtarefa.subtarefa_id, this.querySubtarefa, state.pesquisarSubtarefa.pesquisar2, state.pesquisarSubtarefa.pesquisar3);
            this.tasks = tasks;
        }, 300);
    },

    async pesquisarSubtarefaB(valor) {
        clearTimeout(this.debounceTimeoutSubtarefaB);

        this.debounceTimeoutSubtarefaB = setTimeout(async () => {
            this.querySubtarefaB = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchSubtarefa(state.pesquisarSubtarefa.subtarefa_id, state.pesquisarSubtarefa.pesquisar1, this.querySubtarefaB, state.pesquisarSubtarefa.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchSubtarefa(state.pesquisarSubtarefa.subtarefa_id, state.pesquisarSubtarefa.pesquisar1, state.pesquisarSubtarefa.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Subtarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar subtarefas com status fechado:", error);
        }
    },

    SubtarefaSelecionado(task) {
        //console.warn("Subtarefa selecionada:", task);
    
        if (task) {
            // Atualiza o estado global
            state.EditarTarefaCronograma.subtarefa = task.subtarefa;
            state.EditarTarefaCronograma.subtarefa_id = task.subtarefa_id;
           
            // Chama a função para atualizar os campos
            //atualizarCampos(this);
    
            // Emite o evento e fecha o modal
            this.$emit("fecharModalSubtarefa", {});
            this.fecharModal();
        } else {
            console.warn("Nenhuma subtarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.subtarefa) {
            this.adicionarSubtarefa(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da subtarefa que está sendo editada
        this.editingTaskId = task.subtarefa_id;
    },

    enableExclui(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.subtarefa) {
            this.excluirSubtarefa(task,);
        }
    },

    EnterSubtarefaEditar(task) {
        this.editingTaskId = null;
        // Ação ao pressionar Enter
        this.SubtarefaEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    SubtarefaEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.subtarefa) {
            this.salvarSubtarefa(task,);
        }
    },

    editarStatusSubtarefa(task) {
        if (this.editableValue !== task.statussubtarefa) {
            this.salvarSubtarefa(task);
        }
    },

    salvarSubtarefa(task) {
        try {
            SalvarSubtarefa(
                { subtarefa_id: task.subtarefa_id, subtarefa: task.subtarefa, st: task.statussubtarefa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar subtarefa:", error);
        }
    },

    adicionarSubtarefa(task) {
        try {
            AdicionarSubtarefa(
                { subtarefa_id: task.subtarefa_id, subtarefa: task.subtarefa, st: task.statussubtarefa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar subtarefa:", error);
        }
    },

    excluirSubtarefa(task) {
        try {
            ExcluirSubtarefa(
                { subtarefa_id: task.subtarefa_id, subtarefa: task.subtarefa, st: task.statussubtarefa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar subtarefa:", error);
        }
    },
}

export async function fetchSubtarefa(subtarefa_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (subtarefa_id !== undefined) {
            state.pesquisarSubtarefa.subtarefa_id = subtarefa_id;
        } else {
            state.pesquisarSubtarefa.subtarefa_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarSubtarefa.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarSubtarefa.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarSubtarefa.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarSubtarefa.pesquisar2 = null;
        }

        if (pesquisar3 !== null) {
            state.pesquisarSubtarefa.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarSubtarefa.pesquisar3 = 1;
        }
        //console.log("pesquisar3", pesquisar3);

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            subtarefa_id: state.pesquisarSubtarefa.subtarefa_id,
            pesquisar1: state.pesquisarSubtarefa.pesquisar1,
            pesquisar2: state.pesquisarSubtarefa.pesquisar2,
            pesquisar3: state.pesquisarSubtarefa.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);

        // Faça a requisição para a API
        const response = await api.get("/subtarefa", { params });

        const tasks = response.data.map((task) => ({
            subtarefa_id: task.registro,
            subtarefa: task.descricao || "",
            statussubtarefa: task.st || "",
        }));
        //console.log("bb");
        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}

export async function SalvarSubtarefa(task, context) {
    console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.subtarefa) {
        exibirMensagem("Esta subtarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await api.post("/subtarefa/editar", task);
        console.log("Subtarefa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSubtarefa no contexto recebido
        context.tasks = await fetchSubtarefa(state.pesquisarSubtarefa.subtarefa_id, state.pesquisarSubtarefa.pesquisar1, state.pesquisarSubtarefa.pesquisar2, state.pesquisarSubtarefa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar subtarefa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarSubtarefa(task, context) {

    try {
        const response = await api.post("/subtarefa/adicionar", task);
        //console.log("Subtarefa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSubtarefa no contexto recebido
        context.tasks = await fetchSubtarefa(state.pesquisarSubtarefa.subtarefa_id, state.pesquisarSubtarefa.pesquisar1, state.pesquisarSubtarefa.pesquisar2, state.pesquisarSubtarefa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar subtarefa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirSubtarefa(task, context) {
    //console.log("task", task);
    try {
        const response = await api.post("/subtarefa/excluir", task);
        //console.log("Subtarefa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSubtarefa no contexto recebido
        context.tasks = await fetchSubtarefa(state.pesquisarSubtarefa.subtarefa_id, state.pesquisarSubtarefa.pesquisar1, state.pesquisarSubtarefa.pesquisar2, state.pesquisarSubtarefa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar subtarefa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}















