import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import LoginEfran from './components/LoginEfran.vue';
import Menu from './components/menu/MenuPage.vue';
import CronogramaFisico from './components/cronograma/CronogramaFisico.vue';
import OrcamentoPage from './components/orcamento/OrcamentoPage.vue';



// Importar Vuetify e estilos
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import './components/cronograma/css.css';
import './components/orcamento/orcamento.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// Importar ícones do Vuetify
import { aliases, mdi } from 'vuetify/iconsets/mdi';

// Adicione isso ao início do seu main.js
window.adjustInputHeight = (input) => {
  input.style.height = "auto"; // Reseta a altura
  input.style.height = `${input.scrollHeight}px`; // Ajusta com base na altura do conteúdo
};

// Criar instância do Vuetify
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

// Função de autenticação usando localStorage
const isAuthenticated = () => !!localStorage.getItem('userLoggedIn');

// Definir rotas
const routes = [
  { path: '/', component: LoginEfran }, // Login será a rota inicial
  { path: '/menu', component: Menu },   // Menu, mas protegido pela autenticação
  { path: '/cronograma', component: CronogramaFisico, name: 'CronogramaFisico' }, 
  { path: '/orcamento', component: OrcamentoPage, name: 'OrcamentoPage' }, 
];

// Criar roteador
const router = createRouter({
  history: createWebHistory('/123'),
  routes,
});

// Verificação de autenticação antes de cada navegação
router.beforeEach((to, from, next) => {
  if (to.path === '/menu' && !isAuthenticated()) {
    next('/');
  } else {
    next();
  }
});

// Configurar o Axios para interceptar respostas
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      return Promise.reject(false); // Retorna false para silenciar o erro 401
    }
    return Promise.reject(error);
  }
);

// Criar e montar a instância do Vue
createApp(App)
  .use(router) // Registrar Vue Router
  .use(vuetify) // Registrar Vuetify
  .mount('#app');
