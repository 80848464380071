import axios from "axios";
import { state } from "../../../store.js";
import { EditarTarefaCronograma } from "../cronograma.js";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://efran.onrender.com/api"
      : "http://localhost:10000/api",
});

export const data = () => ({
  state,
  taskToEdit: null,
  carregarUnidadeObra: null, // Seleção atual no multiselect
  dataCarregarUnidadeObra: [], // Dados carregados para o multiselect
  carregarUnidadeSetor: null, // Seleção atual no multiselect
  dataCarregarUnidadeSetor: [], // Dados carregados para o multiselect
  carregarUnidadeSubsetor: null, // Seleção atual no multiselect
  dataCarregarUnidadeSubsetor: [], // Dados carregados para o multiselect
  carregarUnidadePlace: null, // Seleção atual no multiselect
  dataCarregarUnidadePlace: [], // Dados carregados para o multiselect
  carregarUnidadeEtapa: null, // Seleção atual no multiselect
  dataCarregarUnidadeEtapa: [], // Dados carregados para o multiselect
  carregarUnidadeSubetapa: null, // Seleção atual no multiselect
  dataCarregarUnidadeSubetapa: [], // Dados carregados para o multiselect
  carregarUnidadeTarefa: null, // Seleção atual no multiselect
  dataCarregarUnidadeTarefa: [], // Dados carregados para o multiselect
  isModalObraVisible: false, // Controle de exibição do modal
  isModalSetorVisible: false, // Controle de exibição do modal
  isModalSubsetorVisible: false, // Controle de exibição do modal
  isModalPlaceVisible: false, // Controle de exibição do modal
  isModalEtapaVisible: false, // Controle de exibição do modal
  isModalSubetapaVisible: false, // Controle de exibição do modal
  isModalTarefaVisible: false, // Controle de exibição do modal
  isModalSubtarefaVisible: false, // Controle de exibição do modal
  isModalOempresaVisible: false, // Controle de exibição do modal
  isModalEditarTarefaVisible: true,
  obraSelecionada: "",
  projetoIdSelecionado: null,
  obra: "",
  setor: "",
  subsetor: "",
  place: "",
  etapa: "",
  subetapa: "",
  tarefa: "",
  subtarefa: "",
  oempresa: "",
  Selecionar_unidade_setor: "",
  Selecionar_unidade_subsetor: "",
  Selecionar_unidade_place: "",
  Selecionar_unidade_etapa: "",
  Selecionar_unidade_subetapa: "",
  Selecionar_unidade_tarefa: "",
});

// Ajusta a largura do dropdown de forma responsiva
export function LarguraDropdown() {
  document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
    const dropdown = select.querySelector(".multiselect__content-wrapper");
    if (dropdown) {
      dropdown.style.width = `${select.offsetWidth}px`;
    }
  });
}
export const EditarTarefa = {

  handleAbrirObra(taskId) {
    //console.log("taskId", taskId);
    const task = taskId;

    if (task) {
      this.selectedTask = task; // Define a tarefa selecionada
      this.isModalObraVisible = true; // Exibe o modal
    } else {
      console.warn("Tarefa não encontrada para o ID:", taskId);
    }
  },

  handleAbrirSetor(taskId) {
    //console.log("taskId", taskId);
    const task = taskId;

    if (task) {

      this.selectedTask = task; // Define a tarefa selecionada
      this.isModalSetorVisible = true; // Exibe o modal
    } else {
      console.warn("Tarefa não encontrada para o ID:", taskId);
    }
  },

  handleAbrirSubsetor(taskId) {
    //console.log("taskId", taskId);
    const task = taskId;

    if (task) {

      this.selectedTask = task; // Define a tarefa selecionada
      this.isModalSubsetorVisible = true; // Exibe o modal
    } else {
      console.warn("Tarefa não encontrada para o ID:", taskId);
    }
  },

  handleAbrirPlace(taskId) {
    //console.log("taskId", taskId);
    const task = taskId;

    if (task) {

      this.selectedTask = task; // Define a tarefa selecionada
      this.isModalPlaceVisible = true; // Exibe o modal
    } else {
      console.warn("Tarefa não encontrada para o ID:", taskId);
    }
  },

  handleAbrirEtapa(taskId) {
    //console.log("taskId", taskId);
    const task = taskId;

    if (task) {

      this.selectedTask = task; // Define a tarefa selecionada
      this.isModalEtapaVisible = true; // Exibe o modal
    } else {
      console.warn("Tarefa não encontrada para o ID:", taskId);
    }
  },

  handleAbrirSubetapa(taskId) {
    //console.log("taskId", taskId);
    const task = taskId;

    if (task) {

      this.selectedTask = task; // Define a tarefa selecionada
      this.isModalSubetapaVisible = true; // Exibe o modal
    } else {
      console.warn("Tarefa não encontrada para o ID:", taskId);
    }
  },

  handleAbrirTarefa(taskId) {
    const task = taskId && typeof taskId === "object" ? taskId : 0;
    this.selectedTask = task; // Define a tarefa selecionada
    this.isModalTarefaVisible = true; // Exibe o modal
  },

  handleAbrirSubtarefa(taskId) {
    //console.log("taskId", taskId);
    const task = taskId && typeof taskId === "object" ? taskId : 0;
    this.selectedTask = task; // Define a tarefa selecionada
    this.isModalSubtarefaVisible = true; // Exibe o modal
  },

  handleAbrirOempresa(taskId) {
    // Verifica se taskId é válido, caso contrário define como null
    const task = taskId && typeof taskId === "object" ? taskId : 0;
    this.selectedTask = task; // Define a tarefa selecionada (ou null)
    this.isModalOempresaVisible = true; // Exibe o modal
  },

  onSave() {
    //console.log("Contexto recebido no modal:", this.context);
    try {
      // Chama a função EditarTarefaCronograma com os dados e o contexto atual
      EditarTarefaCronograma(
        {
          projeto_id: state.EditarTarefaCronograma.projeto_id,
          setor_id: state.EditarTarefaCronograma.setor_id,
          setor_ordem: state.EditarTarefaCronograma.setor_ordem,
          subsetor_id: state.EditarTarefaCronograma.subsetor_id,
          subsetor_ordem: state.EditarTarefaCronograma.subsetor_ordem,
          place_id: state.EditarTarefaCronograma.place_id,
          place_ordem: state.EditarTarefaCronograma.place_ordem,
          etapa_id: state.EditarTarefaCronograma.etapa_id,
          etapa_ordem: state.EditarTarefaCronograma.etapa_ordem,
          subetapa_id: state.EditarTarefaCronograma.subetapa_id,
          subetapa_ordem: state.EditarTarefaCronograma.subetapa_ordem,
          tarefa_id: state.EditarTarefaCronograma.tarefa_id,
          subtarefa_id: state.EditarTarefaCronograma.subtarefa_id,
          oempresa_id: state.EditarTarefaCronograma.oempresa_id,
          cronograma_id: state.EditarTarefaCronograma.cronograma_id,
        },
        this.context // Passa o contexto recebido como prop
      );

      // Emite o evento com os dados e o contexto atualizados
      this.$emit("fecharlEditarTarefaModal", {
        context: this.context, // Retorna o contexto atualizado
      });

      // Fecha o modal
      this.fecharlEditarTarefaModal();
    } catch (error) {
      console.error("Erro ao salvar oempresa:", error);
    }
  },

  async fetchEditarTarefa() {
    await fetchEditarTarefa(this);
  },

  fecharlEditarTarefaModal() {
    this.isModalEditarTarefaVisible = false; // Fecha o modal
  },

  fecharObraModal() {
    this.isModalObraVisible = false; // Fecha o modal
  },

  fecharSetorModal() {
    this.isModalSetorVisible = false; // Fecha o modal
  },

  fecharSubsetorModal() {
    this.isModalSubsetorVisible = false; // Fecha o modal
  },

  fecharPlaceModal() {
    this.isModalPlaceVisible = false; // Fecha o modal
  },

  fecharEtapaModal() {
    this.isModalEtapaVisible = false; // Fecha o modal
  },

  fecharSubetapaModal() {
    this.isModalSubetapaVisible = false; // Fecha o modal
  },

  fecharTarefaModal() {
    this.isModalTarefaVisible = false; // Fecha o modal
  },

  fecharSubtarefaModal() {
    this.isModalSubtarefaVisible = false; // Fecha o modal
  },

  fecharOempresaModal() {
    this.isModalOempresaVisible = false; // Fecha o modal
  },

  async fetchObraUnidade() {
    await fetchObraUnidade(this);
  },

  async fetchSetorUnidade() {
    await fetchSetorUnidade(this);
  },

  async fetchSubsetorUnidade() {
    await fetchSubsetorUnidade(this);
  },

  async fetchPlaceUnidade() {
    await fetchPlaceUnidade(this);
  },

  async fetchEtapaUnidade() {
    await fetchEtapaUnidade(this);
  },

  async fetchSubetapaUnidade() {
    await fetchSubetapaUnidade(this);
  },

  async fetchTarefaUnidade() {
    await fetchTarefaUnidade(this);
  },
};

export async function fetchEditarTarefa(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task
    ) {
      //console.warn("Dados insuficientes na tarefa: tarefa, obra ou projeto_id ausentes.");
      return;
    }
    console.log("context.task.ordem_setor", context.task.ordem_setor);
    console.log("context.task.setor_id", context.task.setor_id);
    console.log("context.task.ordem_subsetor", context.task.ordem_subsetor);
    console.log("context.task.ordem_place", context.task.ordem_place);
    console.log("context.task.ordem_etapa", context.task.ordem_etapa);
    console.log("context.task.ordem_subetapa", context.task.ordem_subetapa);
    console.log("context.task.ordem", context.task.ordem);

    // Recupera os valores da tarefa
    state.EditarTarefaCronograma.cronograma_id = context.task.cronograma_id;
    state.EditarTarefaCronograma.obra = context.task.obra;
    state.EditarTarefaCronograma.projeto_id = context.task.projeto_id;
    state.EditarTarefaCronograma.setor = context.task.setor;
    state.EditarTarefaCronograma.setor_id = context.task.setor_id;
    state.EditarTarefaCronograma.setor_ordem = context.task.ordem_setor;
    state.EditarTarefaCronograma.subsetor = context.task.subsetor;
    state.EditarTarefaCronograma.subsetor_id = context.task.subsetor_id;
    state.EditarTarefaCronograma.subsetor_ordem = context.task.ordem_subsetor;
    state.EditarTarefaCronograma.place = context.task.place;
    state.EditarTarefaCronograma.place_id = context.task.place_id;
    state.EditarTarefaCronograma.place_ordem = context.task.ordem_place;
    state.EditarTarefaCronograma.etapa = context.task.etapa;
    state.EditarTarefaCronograma.etapa_id = context.task.etapa_id;
    state.EditarTarefaCronograma.etapa_ordem = context.task.ordem_etapa;
    state.EditarTarefaCronograma.subetapa = context.task.subetapa;
    state.EditarTarefaCronograma.subetapa_id = context.task.subetapa_id;
    state.EditarTarefaCronograma.subetapa_ordem = context.task.ordem_subetapa;
    state.EditarTarefaCronograma.tarefa = context.task.tarefa;
    state.EditarTarefaCronograma.tarefa_id = context.task.tarefa_id;
    state.EditarTarefaCronograma.ordem = context.task.ordem,
    state.EditarTarefaCronograma.subtarefa = context.task.subtarefa;
    state.EditarTarefaCronograma.subtarefa_id = context.task.subtarefa_id;
    state.EditarTarefaCronograma.oempresa = context.task.oempresa;
    state.EditarTarefaCronograma.oempresa_id = context.task.oempresa_id;

    // Atualiza o contexto com os valores
    context.cronograma_id = context.task.cronograma_id;
    context.obra = state.EditarTarefaCronograma.obra;
    context.projeto_id = state.EditarTarefaCronograma.projeto_id;
    context.setor = state.EditarTarefaCronograma.setor;
    context.setor_id = state.EditarTarefaCronograma.setor_id;
    context.setor_ordem = state.EditarTarefaCronograma.setor_ordem;
    context.subsetor = state.EditarTarefaCronograma.subsetor;
    context.subsetor_id = state.EditarTarefaCronograma.subsetor_id;
    context.subsetor_ordem = state.EditarTarefaCronograma.subsetor_ordem;
    context.place = state.EditarTarefaCronograma.place;
    context.place_id = state.EditarTarefaCronograma.place_id;
    context.place_ordem = state.EditarTarefaCronograma.place_ordem;
    context.etapa = state.EditarTarefaCronograma.etapa;
    context.etapa_id = state.EditarTarefaCronograma.etapa_id;
    context.etapa_ordem = state.EditarTarefaCronograma.etapa_ordem;
    context.subetapa = state.EditarTarefaCronograma.subetapa;
    context.subetapa_id = state.EditarTarefaCronograma.subetapa_id;
    context.subetapa_ordem = state.EditarTarefaCronograma.subetapa_ordem;
    context.tarefa = state.EditarTarefaCronograma.tarefa;
    context.tarefa_id = state.EditarTarefaCronograma.tarefa_id;
    context.ordem = state.EditarTarefaCronograma.ordem;
    context.subtarefa = state.EditarTarefaCronograma.subtarefa;
    context.subtarefa_id = state.EditarTarefaCronograma.subtarefa_id;
    context.oempresa = state.EditarTarefaCronograma.oempresa;
    context.oempresa_id = state.EditarTarefaCronograma.oempresa_id;

  } catch (error) {
    console.error("Erro ao processar a tarefa:", error);
  }
}

export async function fetchObraUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.O_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.O_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadeObra = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeObra);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadeObra.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadeObra = unidadeSelecionada; // Atualiza o v-model com o objeto completo
    } else {
      context.carregarUnidadeObra = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeObra);
  } catch (error) {
    console.error("Erro ao buscar unidade da obra:", error);
  }
}

export async function fetchSetorUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.S_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.S_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadeSetor = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeSetor);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadeSetor.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadeSetor = unidadeSelecionada; // Atualiza o v-model com o objeto completo
    } else {
      context.carregarUnidadeSetor = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeSetor);
  } catch (error) {
    console.error("Erro ao buscar unidade da setor:", error);
  }
}

export async function fetchSubsetorUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.SB_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.SB_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadeSubsetor = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeSubsetor);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadeSubsetor.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadeSubsetor = unidadeSelecionada; // Atualiza o v-model com o objeto completo
    } else {
      context.carregarUnidadeSubsetor = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeSubsetor);
  } catch (error) {
    console.error("Erro ao buscar unidade da subsetor:", error);
  }
}

export async function fetchPlaceUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.P_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.P_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadePlace = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeSubsetor);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadePlace.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadePlace = unidadeSelecionada; // Atualiza o v-model com o objeto completo

    } else {
      context.carregarUnidadePlace = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeSubsetor);
  } catch (error) {
    console.error("Erro ao buscar unidade da place:", error);
  }
}

export async function fetchEtapaUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.E_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.E_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadeEtapa = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeSubsetor);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadeEtapa.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadeEtapa = unidadeSelecionada; // Atualiza o v-model com o objeto completo

    } else {
      context.carregarUnidadeEtapa = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeSubsetor);
  } catch (error) {
    console.error("Erro ao buscar unidade da place:", error);
  }
}

export async function fetchSubetapaUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.SE_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.SE_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadeSubetapa = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeSubsetor);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadeSubetapa.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadeSubetapa = unidadeSelecionada; // Atualiza o v-model com o objeto completo

    } else {
      context.carregarUnidadeSubetapa = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeSubsetor);
  } catch (error) {
    console.error("Erro ao buscar unidade da place:", error);
  }
}

export async function fetchTarefaUnidade(context) {
  try {
    // Verifica se existe uma tarefa válida no contexto
    if (!context.task || !context.task.T_UDE) {
      //console.warn("Registro inválido fornecido no contexto ou tarefa não definida.");
      return;
    }

    const registro = context.task.T_UDE; // Usa o ID da tarefa como registro
    //console.log("Carregando dados para o registro:", registro);

    // Faz a requisição para o servidor
    const response = await api.get(`/unidade`, {
      params: { registro },
    });

    // Mapeia os dados recebidos para o formato desejado
    context.dataCarregarUnidadeTarefa = response.data.map((item) => ({
      value: item.registro,
      label: item.unidade,
    }));

    //console.log("Dados carregados no contexto:", context.dataCarregarUnidadeSubsetor);

    // Encontra a unidade correspondente ao registro da tarefa
    const unidadeSelecionada = context.dataCarregarUnidadeTarefa.find(
      (item) => item.value === registro
    );

    // Preenche tanto o value quanto o label
    if (unidadeSelecionada) {
      context.carregarUnidadeTarefa = unidadeSelecionada; // Atualiza o v-model com o objeto completo

    } else {
      context.carregarUnidadeTarefa = null; // Reseta se nenhum valor for encontrado
      //console.warn("Nenhuma unidade correspondente encontrada para o registro:", registro);
    }

    //console.log("Unidade selecionada no multiselect:", context.carregarUnidadeSubsetor);
  } catch (error) {
    console.error("Erro ao buscar unidade da place:", error);
  }
}


