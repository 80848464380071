import SetorModal from "../setor/SetorModal.vue";
import SubsetorModal from "../subsetor/SubsetorModal.vue";
import PlaceModal from "../place/PlaceModal.vue";
import EtaparModal from "../etapa/EtapaModal.vue";
import SubetapaModal from "../subetapa/SubetapaModal.vue";
export default {
  data() {
    return {
      drawer2: false,      // Controle do segundo sidebar
      drawerContent: '',   // Determina o conteúdo exibido no segundo sidebar
      isModalSetorVisible: false,
      isModalSubsetorVisible: false,
      isModalPlaceVisible: false,
      isModalEtapaVisible: false,
      isModalSubetapaVisible: false, 
      selectedTask: null, // Inicialize com null ou com um objeto vazio
    };
  },
  components: {
   SetorModal,SubsetorModal,PlaceModal,EtaparModal,SubetapaModal
  },
  methods: {
    openDrawer(content) {
      this.drawerContent = content; // Define o conteúdo baseado no item de menu clicado
      this.drawer2 = true;          // Abre o segundo sidebar
    },
    navegaCronograma() {
      this.$router.push({ name: 'CronogramaFisico' });
    },
    navegaOrcamento() {
      this.$router.push({ name: 'OrcamentoPage' });
    },
    navegaSetor(task) {
      this.selectedTask = task || {}; // Atualiza a tarefa selecionada
      this.isModalSetorVisible = true; // Exibe o modal
    },
    fecharSetorModal() {
      this.isModalSetorVisible = false;
      this.selectedTask = null; // Limpa a seleção
    },
    navegaSubsetor(task) {
      this.selectedTask = task || {}; // Atualiza a tarefa selecionada
      this.isModalSubsetorVisible = true; // Exibe o modal
    },
    fecharSubsetorModal() {
      this.isModalSubsetorVisible = false;
      this.selectedTask = null; // Limpa a seleção
    },
    navegaPlace(task) {
      this.selectedTask = task || {}; // Atualiza a tarefa selecionada
      this.isModalPlaceVisible = true; // Exibe o modal
    },
    fecharPlaceModal() {
      this.isModalPlaceVisible = false;
      this.selectedTask = null; // Limpa a seleção
    },
    navegaEtapa(task) {
      this.selectedTask = task || {}; // Atualiza a tarefa selecionada
      this.isModalEtapaVisible = true; // Exibe o modal
    },
    fecharEtapaModal() {
      this.isModalEtapaVisible = false;
      this.selectedTask = null; // Limpa a seleção
    },
    navegaSubetapa(task) {
      this.selectedTask = task || {}; // Atualiza a tarefa selecionada
      this.isModalSubetapaVisible = true; // Exibe o modal
    },
    fecharSubetapaModal() {
      this.isModalSubetapaVisible = false;
      this.selectedTask = null; // Limpa a seleção
    }
   },
  name: 'App',
  
};
