<template>
  <v-app>
    <v-navigation-drawer app permanent dark>
      <div class="sidebar-logo">Menu</div>
      <v-divider></v-divider>

      <!-- Menu principal -->
      <v-container class="menu-container">
        <v-row
          align="center"
          class="custom-item"
          justify="start"
          @click="openDrawer('cadastro')"
        >
          <v-col cols="auto">
            <v-icon>mdi-database</v-icon>
          </v-col>
          <v-col>Cadastro</v-col>
        </v-row>
        <v-row
          align="center"
          class="custom-item"
          @click="openDrawer('financeiro')"
        >
          <v-col cols="auto">
            <v-icon>mdi-cash</v-icon>
          </v-col>
          <v-col>Financeiro</v-col>
        </v-row>
        <v-row
          align="center"
          class="custom-item"
          @click="openDrawer('orcamento')"
        >
          <v-col cols="auto">
            <v-icon>mdi-calculator</v-icon>
          </v-col>
          <v-col>Orçamento</v-col>
        </v-row>
        <v-row align="center" class="custom-item" @click="openDrawer('obras')">
          <v-col cols="auto">
            <v-icon>mdi-domain</v-icon>
          </v-col>
          <v-col>Obras</v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <!-- Segundo sidebar que será aberto ao clicar em um item do menu -->
    <v-navigation-drawer app right temporary dark v-model="drawer2">
      <v-container>
        <!-- Submenu para "Cadastro" -->
        <template v-if="drawerContent === 'cadastro'">
          <v-row align="center" class="custom-item">
            <v-col cols="auto">
              <v-icon>mdi-package-variant</v-icon>
            </v-col>
            <v-col>Itens</v-col>
          </v-row>
          <v-row align="center" class="custom-item" @click="navegaSetor(1)">
            <v-col cols="auto">
              <v-icon>mdi-view-grid</v-icon>
            </v-col>
            <v-col>Setores</v-col>
          </v-row>
          <v-row align="center" class="custom-item" @click="navegaSubsetor(1)">
            <v-col cols="auto">
              <v-icon>mdi-subdirectory-arrow-right</v-icon>
            </v-col>
            <v-col>Subsetores</v-col>
          </v-row>
          <v-row align="center" class="custom-item" @click="navegaPlace(1)">
            <v-col cols="auto">
              <v-icon>mdi-folder-outline</v-icon>
            </v-col>
            <v-col>Places</v-col>
          </v-row>
          <v-row align="center" class="custom-item" @click="navegaEtapa(1)">
            <v-col cols="auto">
              <v-icon>mdi-progress-check</v-icon>
            </v-col>
            <v-col>Etapas</v-col>
          </v-row>
          <v-row align="center" class="custom-item" @click="navegaSubetapa(1)">
            <v-col cols="auto">
              <v-icon>mdi-subdirectory-arrow-right</v-icon>
            </v-col>
            <v-col>Subetapas</v-col>
          </v-row>
          <v-row align="center" class="custom-item">
            <v-col cols="auto">
              <v-icon>mdi-format-list-checks</v-icon>
            </v-col>
            <v-col>Tarefas</v-col>
          </v-row>
          <v-row align="center" class="custom-item">
            <v-col cols="auto">
              <v-icon>mdi-subdirectory-arrow-right</v-icon>
            </v-col>
            <v-col>Subtarefas</v-col>
          </v-row>
        </template>

        <!-- Submenu para "Financeiro" -->
        <template v-else-if="drawerContent === 'financeiro'">
          <v-row align="center" class="custom-item">
            <v-col cols="auto">
              <v-icon>mdi-cash-multiple</v-icon>
            </v-col>
            <v-col>Contas a Pagar</v-col>
          </v-row>
          <v-row align="center" class="custom-item">
            <v-col cols="auto">
              <v-icon>mdi-cash-register</v-icon>
            </v-col>
            <v-col>Contas a Receber</v-col>
          </v-row>
        </template>

        <!-- Submenu para "Obras" -->
        <template v-else-if="drawerContent === 'orcamento'">
          <v-row align="center" class="custom-item" @click="navegaOrcamento">
            <v-col cols="auto">
              <v-icon>mdi-file-table</v-icon>
            </v-col>
            <v-col>Planilhas de orçamento</v-col>
          </v-row>
        </template>

        <!-- Submenu para "Obras" -->
        <template v-else-if="drawerContent === 'obras'">
          <v-row align="center" class="custom-item">
            <v-col cols="auto">
              <v-icon>mdi-domain</v-icon>
            </v-col>
            <v-col>Projetos</v-col>
          </v-row>
          <v-row align="center" class="custom-item" @click="navegaCronograma">
            <v-col cols="auto">
              <v-icon>mdi-chart-timeline</v-icon>
            </v-col>
            <v-col>Cronograma</v-col>
          </v-row>
        </template>
      </v-container>
    </v-navigation-drawer>

    <!-- Barra de ferramentas superior -->
    <div class="custom-app-bar-menu">
      <img
        src="/123/icones/icone_preto.png"
        alt="logo"
        width="23"
        height="23"
        style="cursor: pointer; margin-left: -840px"
      />
      <div
        class="titulo"
        style="margin-left: 30px; font-size: 20px; font-family: Cambria, serif"
      >
        <b>Página Inicial</b>
      </div>
    </div>

    <!-- Conteúdo principal -->
    <v-main>
      <v-container>
        <h1>Bem-vindo à Efran Engenharia</h1>
        <p></p>
      </v-container>
    </v-main>
    <div>
      <!-- Modal Setor -->
      <SetorModal
        v-if="isModalSetorVisible"
        :task="selectedTask"
        @close="fecharSetorModal"
      />
    </div>
    <div>
      <!-- Modal Subsetor -->
      <SubsetorModal
        v-if="isModalSubsetorVisible"
        :task="selectedTask"
        @close="fecharSubsetorModal"
      />
    </div>
    <div>
      <!-- Modal Place -->
      <PlaceModal
        v-if="isModalPlaceVisible"
        :task="selectedTask"
        @close="fecharPlaceModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <EtapaModal
        v-if="isModalEtapaVisible"
        :task="selectedTask"
        @close="fecharEtapaModal"
      />
    </div>
    <div>
      <!-- Modal Setor -->
      <SubetapaModal
        v-if="isModalSubetapaVisible"
        :task="selectedTask"
        @close="fecharSubetapaModal"
      />
    </div>
  </v-app>
</template>

 <script>
import script from "./menu.js";
import "../menu/styles/css.css";

export default {
  ...script,
};
</script>
  
  