<template>
  <div class="modal">
    <div class="modal-content">
      <!-- Cabeçalho -->
      <div class="modal-header">
        <img
          v-if="icon"
          :src="icon"
          alt="Ícone"
          class="modal-icon"
          :style="{ width: '30px', height: '30px' }"
        />
        <h3 class="modal-title">{{ title }}</h3>
        <div class="modal-actions">
          <i class="fas fa-times close-icon" @click="$emit('close')"></i>
        </div>
      </div>

      <!-- Corpo -->
      <div class="modal-body">
        <div class="input-group input-a-ObraModal">
          <input
            id="ns"
            type="text"
            placeholder="Pesquisar Obra"
            class="input-field"
            ref="pesquisarInput"
            @input="pesquisarNs($event.target.value)"
          />
        </div>
        <div class="input-group input-a-ObraModal">
          <input
            id="cliente"
            type="text"
            placeholder="Pesquisar Cliente"
            class="input-field"
            @input="pesquisarNsB($event.target.value)"
          />
        </div>
      </div>
      <v-main class="custom-tabela-obra">
        <div class="tabela-header">
          <table class="tabela">
            <thead>
              <tr>
                <th class="A1ns-header">Ns</th>
                <th class="B1ns-header"></th>
                <th class="C1ns-header"></th>
                <th class="D1ns-header"></th>
                <th :class="['statusns-header']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      class="status-checkbox-header"
                      @change="pesquisarFechada($event.target.checked)"
                      title="Marcado significa obra fechada "
                    />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tabela-wrapper">
          <table class="tabela">
            <tbody>
              <tr
                v-for="task in tasks"
                :key="task.ns_registro"
                class="tr-ns"
                @dblclick="NsSelecionado(task)"
                style="cursor: pointer"
              >
                <td class="A1ns-cell">
                  <input
                    v-model="task.ns"
                    @keydown.enter.prevent="EnterNsEditar(task)"
                    class="A2ns-cell"
                    style="cursor: pointer"
                    :readonly="
                      !editingTaskId || editingTaskId !== task.ns_registro
                    "
                    :style="{
                      backgroundColor:
                        editingTaskId === task.ns_registro ? '#f7f702' : '',
                      cursor:
                        editingTaskId === task.ns_registro ? '' : 'pointer',
                      boxShadow:
                        editingTaskId === task.ns_registro
                          ? 'inset 2px 2px 5px rgba(0,0,0,0.2)'
                          : '',
                      border:
                        editingTaskId === task.ns_registro
                          ? '1px solid #dcdcdc'
                          : '',
                      transform:
                        editingTaskId === task.ns_registro
                          ? 'translateZ(0)'
                          : '',
                    }"
                  />
                </td>
                <td class="B1ns-cell">
                  <i
                    class="fas fa-plus-circle"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Adicionar"
                    @click="enableAdiciona(task)"
                  ></i>
                </td>
                <td class="C1ns-cell">
                  <i
                    class="fas fa-edit"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Editar"
                    @click="enableEdit(task)"
                  ></i>
                </td>
                <td class="D1ns-cell">
                  <i
                    class="fas fa-times-circle"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Excluir"
                    @click="enableExclui(task)"
                  ></i>
                </td>
                <td :class="['statusns-cell']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      :checked="task.statusns === true"
                      @change="
                        (event) => {
                          task.statusns = event.target.checked ? true : false;
                          editarStatusNs(task);
                        }
                      "
                      class="status-checkbox"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-main>
      <!-- Rodapé (opcional) -->
      <div class="modal-footer" v-if="showFooter">
        <slot name="footer">
          <button class="btn btn-secondary" @click="$emit('close')">
            Cancelar
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>
    
      <script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./servico.css";

import { data, Ns, LarguraDropdown } from "./servico.js";

export default {
  name: "ServicoModal",
  data() {
    return {
      ...data(),
    };
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "Servico",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "/123/icones/icone_preto.png", // Caminho do ícone ou null
    },
    task: {
      type: [Object, Number],
      required: true,
    },
    context: {
      type: Object,
      required: true, // Torna obrigatório para garantir que o modal receba o contexto
    },
  },

  watch: {
    tasks: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.adjustHeaderWidth();
        });
      },
    },
  },
  mounted() {
    this.initializeTasks().then(() => {
      LarguraDropdown();
    });
    this.$refs.pesquisarInput.focus();
    this.adjustHeaderWidth();
    window.addEventListener("resize", this.adjustHeaderWidth);
  },
  methods: {
    ...Ns,
  },
};
</script>