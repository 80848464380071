import { reactive, watch } from "vue";

// Estado reativo
export const state = reactive({
  empresas: [], // Array para armazenar as empresas
  pesquisarObra: {
    projeto_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarSetor: {
    setor_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarSubsetor: {
    subsetor_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarPlace: {
    place_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarEtapa: {
    etapa_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarSubetapa: {
    subetapa_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarTarefa: {
    tarefa_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarSubtarefa: {
    subtarefa_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarOempresa: {
    oempresa_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarServico: {
    servico_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarNs: {
    ns_registro: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarLista: {
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  EditarLista: {
    servico_id: null,
    item_id: null,
    id: null,
    subservico_fator: { value: null, isProcessing: false },
    subservico_id: { value: null, isProcessing: false },
    servico_unidade_registro: null,

  },
  EditarItem: {
    item_id: null,
    descricao_id: null,
    subdescricao_id: null,
    tipo_id: null,
  },
  EditarOrcamento: {
    cronograma_ida: null,
    setor_ordema: null,
    subsetor_ordema: null,
    place_ordema: null,
    etapa_ordema: null,
    subetapa_ordema: null,
    ordema: null,
    setor_ida: null,
    subsetor_ida: null,
    place_ida: null,
    etapa_ida: null,
    subetapa_ida: null,
    tarefa_ida: null,
    etor_ida: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
    id: null,
  },
  pesquisarItem: {
    item_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },
  pesquisarDescricao: {
    descricao_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },

  pesquisarSubdescricao: {
    subdescricao_id: null,
    pesquisar1: null,
    pesquisar2: null,
    pesquisar3: null,
  },

  EditarTarefaCronograma: {
    obra: null,
    projeto_id: null,
    setor: null,
    setor_id: null,
    setor_ida: null,
    setor_ordem: null,
    subsetor: null,
    subsetor_id: null,
    subsetor_ida: null,
    subsetor_ordem: null,
    place: null,
    place_id: null,
    place_ida: null,
    place_ordem: null,
    etapa: null,
    etapa_id: null,
    etapa_ida: null,
    etapa_ordem: null,
    subetapa: null,
    subetapa_id: null,
    subetapa_ida: null,
    subetapa_ordem: null,
    tarefa: null,
    tarefa_id: null,
    tarefa_ida: null,
    subtarefa: null,
    subtarefa_id: null,
    subtarefa_ida: null,
    oempresa: null,
    oempresa_id: null,
    oempresa_ida: null,
    empresa: null,
    empresa_id: null,
    cronograma_id: null,
    cronograma_ida: null,
  },
});

// Restaura os dados do localStorage ao iniciar
const storedEmpresas = localStorage.getItem("empresas");
if (storedEmpresas) {
  try {
    state.empresas = JSON.parse(storedEmpresas);
    //console.log("Dados restaurados do localStorage:", state.empresas);
  } catch (error) {
    console.error("Erro ao restaurar dados do localStorage:", error);
  }
}

// Salva automaticamente no localStorage sempre que state.empresas mudar
watch(
  () => state.empresas,
  (newValue) => {
    localStorage.setItem("empresas", JSON.stringify(newValue));
    console.log("Dados salvos no localStorage:", newValue);
  },
  { deep: true }
);
