
<template>
  <div>
    <v-app>
      <!-- Sidebar ajustável -->
      <v-navigation-drawer
        app
        v-model="drawer"
        width="40"
        :mini-variant="mini"
        @click="toggleDrawer"
      >
        <div class="sidebar-logo">
          <img
            src="/123/icones/icone_preto.png"
            alt="logo"
            width="23"
            height="23"
            style="cursor: pointer"
          />
        </div>
        <v-divider></v-divider>

        <v-container class="orcamento-container">
          <v-row
            align="center"
            class="custom-item-orcamento"
            @click="openDrawer('menu')"
          >
            <v-col cols="auto">
              <v-icon>mdi-database</v-icon>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="orcamento-container">
          <v-row
            align="center"
            class="custom-item-orcamento"
            @click="limpar"
            style="cursor: pointer"
          >
            <v-col cols="auto">
              <v-icon title="Limpar tudo">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-navigation-drawer>

      <div class="parent-container">
        <!-- Barra de Seleção -->
        <div app class="custom-app-bar-orcamento">
          <div class="selects-container">
            <!-- Multiselects Dinâmicos -->
            <multiselect
              v-model="carregarObra"
              :options="dataCarregarObra || []"
              label="label"
              track-by="value"
              @update:modelValue="Selecionar_obra"
              dense
              :multiple="true"
              placeholder="Selecione uma Obra"
              class="custom-select-orcamento select-carregar-obra"
            ></multiselect>
            <multiselect
              v-model="carregarSetor"
              :options="dataCarregarSetor"
              label="label"
              track-by="value"
              placeholder="Selecione uma Setor"
              searchable
              clearable
              @update:modelValue="Selecionar_setor"
              :multiple="true"
              class="custom-select-orcamento select-carregar-setor"
            ></multiselect>
            <multiselect
              v-model="carregarSubsetor"
              :options="dataCarregarSubsetor"
              label="label"
              track-by="value"
              placeholder="Selecione uma Subsetor"
              searchable
              clearable
              @update:modelValue="Selecionar_subsetor"
              :multiple="true"
              class="custom-select-orcamento select-carregar-subsetor"
            ></multiselect>
            <multiselect
              v-model="carregarPlace"
              :options="dataCarregarPlace"
              label="label"
              track-by="value"
              placeholder="Selecione uma Place"
              searchable
              clearable
              @update:modelValue="Selecionar_place"
              :multiple="true"
              class="custom-select-orcamento select-carregar-place"
            ></multiselect>
            <multiselect
              v-model="carregarEtapa"
              :options="dataCarregarEtapa"
              label="label"
              track-by="value"
              placeholder="Selecione uma Etapa"
              searchable
              clearable
              @update:modelValue="Selecionar_etapa"
              :multiple="true"
              class="custom-select-orcamento select-carregar-etapa"
            ></multiselect>
            <multiselect
              v-model="carregarSubetapa"
              :options="dataCarregarSubetapa"
              label="label"
              track-by="value"
              placeholder="Selecione uma Subetapa"
              searchable
              clearable
              @update:modelValue="Selecionar_subetapa"
              :multiple="true"
              class="custom-select-orcamento select-carregar-subetapa"
            ></multiselect>
            <multiselect
              v-model="carregarTarefa"
              :options="dataCarregarTarefa"
              label="label"
              track-by="value"
              placeholder="Selecione uma Tarefa"
              searchable
              clearable
              @update:modelValue="Selecionar_tarefa"
              :multiple="true"
              class="custom-select-orcamento select-carregar-tarefa"
            ></multiselect>
            <multiselect
              v-model="carregarEmpresa"
              :options="dataCarregarEmpresa"
              label="label"
              track-by="value"
              placeholder="Selecione uma Empresa"
              searchable
              clearable
              @update:modelValue="Selecionar_empresa"
              :multiple="true"
              class="custom-select-orcamento select-carregar-empresa"
            ></multiselect>
            <div class="input-group input-Orcamento-pesquisar-item">
              <input
                id="tarefa"
                type="text"
                placeholder="Pesquisar Item"
                class="input-field"
                ref="pesquisarInput"
                @keydown="pesquisarOrcamentoItem"
              />
            </div>

            <div class="input-group input-Orcamento-pesquisar-item">
              <input
                id="tarefa"
                type="text"
                placeholder="Pesquisar Subitem"
                class="input-field"
                ref="pesquisarInput"
                @keydown="pesquisarOrcamentoSubitem"
              />
            </div>
            <div class="input-group input-Orcamento-pesquisar-tipo">
              <input
                id="tarefa"
                type="text"
                placeholder="Pesquisar Tipo"
                class="input-field"
                ref="pesquisarInput"
                @keydown="pesquisarOrcamentoTipo"
              />
            </div>
          </div>
        </div>

        <!-- Tabela Dinâmica -->
        <div class="custom-main-orcamento">
          <div
            class="orcamento-content-wrapper"
            ref="contentWrapper"
            @scroll="syncScroll"
          >
            <table class="orcamento-table">
              <thead>
                <tr>
                  <th
                    v-for="(coluna, colIndex) in colunas"
                    :key="`header-${colIndex}`"
                    :colspan="coluna.colSpan"
                    class="th-orcamento"
                    :style="getThStyles()"
                  >
                    {{ coluna.label }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="task in tasks"
                  :key="task.id"
                  :class="getRowClasses(task)"
                  :style="getRowStyles(task)"
                >
                  <td
                    v-for="(coluna, colIndex) in filteredColunas(task)"
                    :key="`col-${task.id}-${coluna.field}-${colIndex}`"
                    @click="coluna.field === 'a' ? handleTaskClick(task) : null"
                    @dblclick="
                      task.tipo === 'Projeto' ? handleDoubleClick(task) : null
                    "
                    :colspan="getColSpan(task, coluna)"
                    :style="getTdStyles(task, coluna)"
                    v-html="getCellValue(task, coluna.field)"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <!-- Modal Setor -->
        <ListaModal
          v-if="isModalListaVisible"
          :task="selectedTask"
          :context="pageContext"
          @close="fecharListaModal"
          @fecharModalLista="fecharListaModal"
        />
      </div>
    </v-app>
  </div>
</template>

<script>
import "./orcamento.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import ListaModal from "../lista/ListaModal.vue";
import { mapActions } from "vuex";

import {
  data,
  computed,
  methods,
  Select,
  tabela,
  TaskVisibilityMethods,
  Limpar,
  EditarLista,
  LarguraDropdown,
} from "./orcamento.js";

export default {
  name: "OrcamentoPage",

  components: {
    ListaModal,
    Multiselect,
  },

  data() {
    return {
      ...data(),
    };
  },
  props: {},
  created() {
    window.app = this;
  },
  computed: {
    ...computed,
    filteredTasks() {
      return this.tasks.filter((task) => task.isVisible);
    },
  },

  mounted() {
    LarguraDropdown();
    this.fetchUdeOptions();
    this.fetchObras();
    this.fetchSetor();
    this.fetchSubsetor();
    this.fetchPlace();
    this.fetchEtapa();
    this.fetchSubetapa();
    this.fetchTarefa();
    this.fetchEmpresa();
    // Define o estilo de escala inicial
    document.documentElement.style.setProperty(
      "--zoom-scale",
      this.zoomLevel / 100
    );
    this.tasks.forEach((task) => {
      this.initializeTaskProgress(task);
    });
    document.addEventListener("click", this.handleGlobalClick.bind(this));
    //const table = this.$el.querySelector("table"); // Substitua pelo seletor correto
    //table.addEventListener("click", this.handleGlobalClick);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleGlobalClick.bind(this));
    document.removeEventListener("click", this.closeIconContainer);
    //const table = this.$el.querySelector("table");
    //table.removeEventListener("click", this.handleGlobalClick);
  },

  watch: {
    // Atualiza startDate sempre que selectedStartDateOption mudar
    selectedStartDateOption(newOption) {
      if (newOption) {
        this.startDate = newOption.value; // Atualiza o startDate real
      }
    },

    // Atualiza startDate sempre que selectedStartDateOption mudar
    selectedEndDateOption(newOption) {
      if (newOption) {
        this.endDate = newOption.value; // Atualiza o startDate real
      }
    },
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    ...methods,
    ...Select,
    ...tabela,
    ...TaskVisibilityMethods,
    ...Limpar,
    ...EditarLista,
    ...mapActions(["loadTasks", "updateTaskVisibility"]),
  },
};
</script>


  