<template>
  <div class="modal">
    <div class="modal-content-item">
      <!-- Cabeçalho -->
      <div class="modal-header">
        <img
          v-if="icon"
          :src="icon"
          alt="Ícone"
          class="modal-icon"
          :style="{ width: '30px', height: '30px' }"
        />
        <h3 class="modal-title">{{ title }}</h3>
        <div class="modal-actions">
          <i class="fas fa-times close-icon" @click="$emit('close')"></i>
        </div>
      </div>

      <!-- Corpo -->
      <div class="modal-body">
        <div class="input-group input-a-ItemModal">
          <input
            id="item"
            type="text"
            placeholder="Pesquisar Item"
            class="input-field"
            ref="pesquisarInput"
            @input="pesquisarItem($event.target.value)"
          />
        </div>
        <div class="input-group input-a-ItemModal">
          <input
            id="cliente"
            type="text"
            placeholder="Pesquisar Item"
            class="input-field"
            @input="pesquisarItemB($event.target.value)"
          />
        </div>
      </div>
      <v-main class="custom-tabela-item">
        <div class="tabela-header">
          <table class="tabela-item">
            <thead>
              <tr>
                <th class="A1item-header">Item</th>
                <th class="C1item-header"></th>
                <th class="A2subitem-header">Subitem</th>
                <th class="A2ude-header">Unidade</th>
                <th class="B1item-header"></th>
                <th class="C1item-header"></th>
                <th class="D1item-header"></th>
                <th :class="['statusitem-header']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      class="status-checkbox-header"
                      @change="pesquisarFechada($event.target.checked)"
                      title="Marcado significa obra fechada "
                    />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tabela-wrapper">
          <table class="tabela-item">
            <tbody>
              <tr
                v-for="task in tasks"
                :key="task.setor_id"
                class="tr-item"
                @dblclick="ItemSelecionado(task)"
                style="cursor: pointer"
              >
                <td class="A1item-cell">
                  <div
                    v-if="editingTaskIdDescricao === task.id"
                    class="dropdown-wrapper-descricao"
                    :data-dropdown-id-descricao="task.id"
                  >
                    <input
                      v-model="editableValueDescricao"
                      class="dropdown-input-descricao"
                      placeholder="Digite para filtrar"
                    />
                    <ul
                      v-if="
                        filteredOptionsDescricao &&
                        filteredOptionsDescricao.length
                      "
                      class="dropdown-options-descricao"
                    >
                      <li
                        v-for="(option, index) in filteredOptionsDescricao"
                        :key="index"
                        :class="['dropdown-option-descricao']"
                        @click="handleDescricaoItemClick(option)"
                      >
                        {{ option.label }}
                      </li>
                    </ul>
                  </div>
                  <span v-else @click="enableDescricaoEdit(task)">{{
                    task.descricao
                  }}</span>
                </td>
                <td class="C1item-cell">
                  <i
                    class="fas fa-edit"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Editar Descrição"
                    @click="handleDescricaoModalEditar(task)"
                  ></i>
                </td>
                <td class="A2subitem-cell">
                  <div
                    v-if="editingTaskIdSUB === task.id"
                    class="dropdown-wrapper-subdescricao"
                    :data-dropdown-id-subdescricao="task.id"
                  >
                    <input
                      v-model="editableValueSUB"
                      class="dropdown-input-subdescricao"
                      placeholder="Digite para filtrar"
                    />
                    <ul
                      v-if="filteredOptionsSUB && filteredOptionsSUB.length"
                      class="dropdown-options-subdescricao"
                    >
                      <li
                        v-for="(option, index) in filteredOptionsSUB"
                        :key="index"
                        :class="['dropdown-option-subdescricao']"
                        @click="handleSUBItemClick(option)"
                      >
                        {{ option.label }}
                      </li>
                    </ul>
                  </div>
                  <span v-else @click="enableSUBEdit(task)">{{
                    task.subdescricao
                  }}</span>
                </td>
                <td class="A2ude-cell">
                  <div
                    v-if="editingTaskIdUde === task.id"
                    class="dropdown-wrapper-unidade"
                    :data-dropdown-id-ude="task.id"
                  >
                    <input
                      v-model="editableValueUde"
                      class="dropdown-input-unidade"
                      placeholder="Digite para filtrar"
                    />
                    <ul
                      v-if="filteredOptionsUde && filteredOptionsUde.length"
                      class="dropdown-options-unidade"
                    >
                      <li
                        v-for="(option, index) in filteredOptionsUde"
                        :key="index"
                        :class="['dropdown-option-unidade']"
                        @click="handleUdeItemClick(option)"
                      >
                        {{ option.label }}
                      </li>
                    </ul>
                  </div>
                  <span v-else @click="enableUdeEdit(task)">{{
                    task.ude
                  }}</span>
                </td>
                <td class="C1item-cell">
                  <i
                    class="fas fa-edit"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Editar"
                    @click="handleSubdescricaoModalEditar(task)"
                  ></i>
                </td>
                <td class="B1item-cell">
                  <i
                    class="fas fa-plus-circle"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Adicionar"
                    @click="enableAdiciona(task)"
                  ></i>
                </td>
                <td class="D1item-cell">
                  <i
                    class="fas fa-times-circle"
                    style="color: black; font-size: 12px; cursor: pointer"
                    title="Excluir"
                    @click="enableExclui(task)"
                  ></i>
                </td>
                <td :class="['statusitem-cell']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      :checked="task.statusitem === true"
                      @change="
                        (event) => {
                          task.statusitem = event.target.checked ? true : false;
                          editarStatusItem(task);
                        }
                      "
                      class="status-checkbox"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-main>
      <!-- Rodapé (opcional) -->
      <div class="modal-footer" v-if="showFooter">
        <slot name="footer">
          <button class="btn btn-secondary" @click="$emit('close')">
            Cancelar
          </button>
        </slot>
      </div>
      <div>
        <!-- Modal Setor -->
        <DescricaoModal
          v-if="isModalDescricaoVisible"
          :task="selectedTask"
          :context="pageContext"
          @close="fecharDescricaoModal"
          @fecharModalPlace="fecharDescricaoModal"
        />
      </div>
      <div>
        <!-- Modal Setor -->
        <SubdescricaoModal
          v-if="isModalSubdescricaoVisible"
          :task="selectedTask"
          :context="pageContext"
          @close="fecharSubdescricaoModal"
          @fecharModalPlace="fecharSubdescricaoModal"
        />
      </div>
    </div>
  </div>
</template>
    
      <script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "../item/item.css";
import DescricaoModal from "../descricao/DescricaoModal.vue";
import SubdescricaoModal from "../subdescricao/SubdescricaoModal.vue";
import {
  data,
  Item,
  LarguraDropdown,
  methods,
  unidade,
  descricao,
  subdescricao,
} from "../item/item.js";

export default {
  name: "ItemModal",
  data() {
    return {
      ...data(),
    };
  },
  components: {
    DescricaoModal,
    SubdescricaoModal,
  },
  props: {
    title: {
      type: String,
      default: "Item",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "/123/icones/icone_preto.png", // Caminho do ícone ou null
    },
    task: {
      type: [Object, Number],
      required: true,
    },
    context: {
      type: Object,
      required: true, // Torna obrigatório para garantir que seja recebido
    },
  },
  computed: {
    computed: {
      filteredOptionsUde() {
        const searchValueUde = this.editableValueUde.toLowerCase().trim();
        const rawOptionsUde = this.udeOptions; // Não precisa de toRaw se os dados são reativos

        if (!searchValueUde) {
          return rawOptionsUde; // Retorna todas as opções se não houver filtro
        }

        return rawOptionsUde.filter((option) =>
          option.label.toLowerCase().includes(searchValueUde)
        );
      },

      filteredOptionsDescricao() {
        const searchValueDescricao = this.editableValueDescricao
          .toLowerCase()
          .trim();
        const rawOptionsDescricao = this.descricaoOptions; // Não precisa de toRaw se os dados são reativos

        if (!searchValueDescricao) {
          return rawOptionsDescricao; // Retorna todas as opções se não houver filtro
        }

        return rawOptionsDescricao.filter((option) =>
          option.label.toLowerCase().includes(searchValueDescricao)
        );
      },

      filteredOptionsSUB() {
        const searchValueSUB = this.editableValueSUB.toLowerCase().trim();
        const rawOptionsSUB = this.subOptions; // Não precisa de toRaw se os dados são reativos

        if (!searchValueSUB) {
          return rawOptionsSUB; // Retorna todas as opções se não houver filtro
        }

        return rawOptionsSUB.filter((option) =>
          option.label.toLowerCase().inclsubs(searchValueSUB)
        );
      },
    },
  },
  watch: {
    editableValueUde: {
      handler() {
        this.updateFilteredOptionsUde();
      },
      immediate: true,
    },

    editableValueDescricao: {
      handler() {
        this.updateFilteredOptionsDescricao();
      },
      immediate: true,
    },

    editableValueSUB: {
      handler() {
        this.updateFilteredOptionsSUB();
      },
      immediate: true,
    },

    udeOptions: {
      handler() {
        this.updateFilteredOptionsUde();
      },
      immediate: true,
    },

    descricaoOptions: {
      handler() {
        this.updateFilteredOptionsDescricao();
      },
      immediate: true,
    },

    SUBOptions: {
      handler() {
        this.updateFilteredOptionsSUB();
      },
      immediate: true,
    },

    tasks: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.adjustHeaderWidth();
        });
      },
    },
  },
  mounted() {
    this.fetchUdeOptions();
    this.fetchDescricaoOptions();
    this.fetchSUBOptions();

    this.initializeTasks().then(() => {
      LarguraDropdown();
    });
    this.$refs.pesquisarInput.focus();
    this.adjustHeaderWidth();
    window.addEventListener("resize", this.adjustHeaderWidth);
  },
  methods: {
    ...Item,
    ...methods,
    ...unidade,
    ...descricao,
    ...subdescricao,
  },
};
</script>