// cronograma.js
import axios from 'axios';
//import { nextTick } from 'vue';
const anoAtual = new Date().getFullYear();
//const datas = gerarDatas().map((date) => ({ label: date, value: date }));
const startDate = `01-01-${anoAtual}`;
const endDate = `01-12-${anoAtual}`;
//let isFetching = false;
import { state } from "../../store.js";



const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "production"
            ? "https://efran.onrender.com/api"
            : "http://localhost:10000/api",
});

export const data = () => ({
    zoomLevel: 100,
    tasks: [],
    tasksCarregadas: false,
    isModalListaVisible: false, // Controle de exibição do modal
    clickTimeout: null,
    isLoading: false,
    isTyping: null,
    drawer: true,
    mini: false,
    startDate: startDate,
    endDate: endDate,
    selectedStartDateOption: { label: startDate, value: startDate }, // Objeto correspondente à opção
    selectedEndDateOption: { label: endDate, value: endDate }, // Objeto correspondente à opção
    dataCarregarObra: [],
    carregarObra: null,
    dataCarregarSetor: [],
    carregarSetor: "",
    dataCarregarSubsetor: [],
    carregarSubsetor: "",
    dataCarregarPlace: [],
    carregarPlace: "",
    dataCarregarEtapa: [],
    carregarEtapa: "",
    dataCarregarSubetapa: [],
    carregarSubetapa: "",
    dataCarregarTarefa: [],
    carregarTarefa: "",
    dataCarregarEmpresa: [],
    carregarEmpresa: "",
    iconContainerVisible: false,
    selectedTaskId: null,
    iconContainerPosition: { top: 0, left: 0 },
    taskId: null,
    options: [],
    isModalEditarUnidadeVisible: false, // Controle de visibilidade do modal
    selectedTask: null, // Dados da tarefa a ser editada
    taskContext: null, // Armazena os dados da tarefa selecionada
    udeOptions: [], // Inicializa como uma lista vazia
    editingQdeTaskId: null,
    activeInputs: {}, // Objeto para rastrear quais inputs estão ativos
    isModalEditarTarefaVisible: false, // Controle de visibilidade do modal
    editingTaskId: null, // ID da tarefa sendo editada
    editableValue: '', // Valor editável do input
    filteredOptions: [], // Opções filtradas
    taskState: {},
    colunas: [
        { label: "Descrição", colSpan: 15 },
        { label: "Qde", colSpan: 3 },
        { label: "Unidade", colSpan: 4 },
        { label: "Valor/unidade", colSpan: 4 },
        { label: "Custo MO", colSpan: 4 },
        { label: "Custo MA", colSpan: 4 },
        { label: "Custo LO", colSpan: 4 },
        { label: "Custo Total", colSpan: 4 },
        { label: "Venda MO", colSpan: 4 },
        { label: "Venda MA", colSpan: 4 },
        { label: "Venda LO", colSpan: 4 },
        { label: "Venda Total", colSpan: 5 },
        { label: "", colSpan: 1 },
        { label: "", colSpan: 1 },
    ],
    colunasExtras: [
        { field: "a" },
        { field: "b" },
        { field: "c" },
        { field: "d" },
        { field: "e" },
        { field: "f" },
        { field: "g" },
        { field: "h" },
        { field: "i" },
        { field: "j" },
        { field: "k" },
        { field: "l" },
        { field: "m" },
        { field: "n" },
        { field: "o" },
        { field: "p" },
        { field: "q" },
        { field: "r" },
        { field: "s" },
        { field: "t" },
        { field: "u" },
        { field: "v" },
        { field: "w" },
        { field: "x" },
        { field: "y" },
        { field: "z" },
        { field: "aa" }
    ]

});

export const tabela = {

    getThStyles() {
        return {
            whiteSpace: "nowrap",
            position: "sticky",
            top: "0",
            background: "black",
            color: "white", // Para garantir a legibilidade do texto
            zIndex: "1",
        };
    },

    getRowStyles(task) {
        if (task.tipo === "Projeto") {
            return {
                position: "sticky",
                top: "20px",
                zIndex: "1",
                backgroundColor: "white", // Adicione um fundo para evitar sobreposição visual
            };
        }
        return {}; // Sem estilos adicionais para outros tipos
    },

    filteredColunas(task) {
        const tipoColunas = {
            Projeto: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o"],
            Setor: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o", "p"],
            Subsetor: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o", "p"],
            Place: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o", "p"],
            Etapa: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o", "p"],
            Subetapa: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o", "p"],
            Tarefa: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "l", "m", "n", "o"],
            Servico: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o"],
            Item: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "aa"],
            Subservico: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",],
            Item_subservico: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "aa"],
        };

        return this.colunasExtras.filter((coluna) =>
            tipoColunas[task.tipo]?.includes(coluna.field)
        );
    },

    getCellValue(task, field) {
        const self = this;
        const fieldMappings = {
            Projeto: {
                a: () => task.tarefa || "-",
                b: () => task.qde !== null && task.qde !== undefined
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "0,000",
                c: () => task.o_ude || "-",
                d: () => task.vtvuv_projeto || "-",
                e: () => task.vmo_projeto || "-",
                f: () => task.vma_projeto || "-",
                g: () => task.vlo_projeto || "-",
                h: () => task.vt_projeto || "-",
                i: () => task.vmov_projeto || "-",
                j: () => task.vmav_projeto || "-",
                l: () => task.vlov_projeto || "-",
                m: () => task.vtv_projeto || "-",
            },
            Setor: {
                a: () => task.tarefa || "-",
                b: () => task.qde1 !== null && task.qde1 !== undefined
                    ? task.qde1.toFixed(3).replace(".", ",")
                    : "0,000",
                c: () => task.qde !== null && task.qde !== undefined
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "0,000",
                d: () => task.s_ude || "-",
                e: () => task.vtvuv_setor || "-",
                f: () => task.vmo_setor || "-",
                g: () => task.vma_setor || "-",
                h: () => task.vlo_setor || "-",
                i: () => task.vt_setor || "-",
                j: () => task.vmov_setor || "-",
                l: () => task.vmav_setor || "-",
                m: () => task.vlov_setor || "-",
                n: () => task.vtv_setor || "-",
            },
            Subsetor: {
                a: () => task.tarefa || "-",
                b: () => task.qde1 !== null && task.qde1 !== undefined
                    ? task.qde1.toFixed(3).replace(".", ",")
                    : "0,000",
                c: () => task.qde !== null && task.qde !== undefined
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "0,000",
                d: () => task.sb_ude || "-",
                e: () => task.vtvuv_subsetor || "-",
                f: () => task.vmo_subsetor || "-",
                g: () => task.vma_subsetor || "-",
                h: () => task.vlo_subsetor || "-",
                i: () => task.vt_subsetor || "-",
                j: () => task.vmov_subsetor || "-",
                l: () => task.vmav_subsetor || "-",
                m: () => task.vlov_subsetor || "-",
                n: () => task.vtv_subsetor || "-",
            },
            Place: {
                a: () => task.tarefa || "-",
                b: () => task.qde1 !== null && task.qde1 !== undefined
                    ? task.qde1.toFixed(3).replace(".", ",")
                    : "0,000",
                c: () => task.qde !== null && task.qde !== undefined
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "0,000",
                d: () => task.p_ude || "-",
                e: () => task.vtvuv_place || "-",
                f: () => task.vmo_place || "-",
                g: () => task.vma_place || "-",
                h: () => task.vlo_place || "-",
                i: () => task.vt_place || "-",
                j: () => task.vmov_place || "-",
                l: () => task.vmav_place || "-",
                m: () => task.vlov_place || "-",
                n: () => task.vtv_place || "-",
            },
            Etapa: {
                a: () => task.tarefa || "-",
                b: () => task.qde1 !== null && task.qde1 !== undefined
                    ? task.qde1.toFixed(3).replace(".", ",")
                    : "0,000",
                c: () => task.qde !== null && task.qde !== undefined
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "0,000",
                d: () => task.e_ude || "-",
                e: () => task.vtvuv_etapa || "-",
                f: () => task.vmo_etapa || "-",
                g: () => task.vma_etapa || "-",
                h: () => task.vlo_etapa || "-",
                i: () => task.vt_etapa || "-",
                j: () => task.vmov_etapa || "-",
                l: () => task.vmav_etapa || "-",
                m: () => task.vlov_etapa || "-",
                n: () => task.vtv_etapa || "-",
            },
            Subetapa: {
                a: () => task.tarefa || "-",
                b: () => task.qde1 !== null && task.qde1 !== undefined
                    ? task.qde1.toFixed(3).replace(".", ",")
                    : "0,000",
                c: () => task.qde !== null && task.qde !== undefined
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "0,000",
                d: () => task.se_ude || "-",
                e: () => task.vtvuv_subetapa || "-",
                f: () => task.vmo_subetapa || "-",
                g: () => task.vma_subetapa || "-",
                h: () => task.vlo_subetapa || "-",
                i: () => task.vt_subetapa || "-",
                j: () => task.vmov_subetapa || "-",
                l: () => task.vmav_subetapa || "-",
                m: () => task.vlov_subetapa || "-",
                n: () => task.vtv_subetapa || "-",
            },
            Tarefa: {
                a: () => task.tarefa + ' / ' + task.subtarefa || "-",
                b: () =>
                    task.qde !== null && task.qde !== undefined && task.qde !== ""
                        ? task.qde.toFixed(3).replace(".", ",")
                        : "0,000",
                c: () => task.t_ude || "-",
                d: () => task.vtvuv_tarefa || "-",
                e: () => task.vmo_tarefa || "-",
                f: () => task.vma_tarefa || "-",
                g: () => task.vlo_tarefa || "-",
                h: () => task.vt_tarefa || "-",
                i: () => task.vmov_tarefa || "-",
                j: () => task.vmav_tarefa || "-",
                l: () => task.vlov_tarefa || "-",
                m: () => task.vtv_tarefa || "-",
                n: () => `
                    ${task[""] || ""}
                    <div style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                    ">
                        <i
                            class="fas fa-plus-circle handle-lista-modal"
                            style="color: black; font-size: 12px; cursor: pointer;"
                            title="Adicionar"
                            data-task-id="${task.cronograma_id}"
                        ></i>
                    </div>
                `,

            },
            Servico: {
                a: () => task.tarefa || "-",
                b: () => task.servico_obs || "-",
                c: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = self.formatNumber(task.tr_qde, "0,000");

                    return `
                        <td class="td-servico_obs">
                            <div
                                data-task-id="${task.lista_id}" data-servico-id="${task.servico_id}" data-cronograma-id="${task.cronograma_id}"
                                title="Qde Serviço"
                                class="input-qde-servico editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterServico_qde(${task.lista_id}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                d: () => task.servico_ude || "-",
                e: () => task.vtvuv_servico || "-",
                f: () => task.vtc_vmo_servico || "-",
                g: () => task.vtc_vma_servico || "-",
                h: () => task.vtc_vlo_servico || "-",
                i: () => task.vtc_vt_servico || "-",
                j: () => task.vtv_vmo_servico || "-",
                k: () => task.vtv_vma_servico || "-",
                l: () => task.vtv_vlo_servico || "-",
                m: () => task.vtv_servico || "-",
                n: () => `
                        <div style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                        ">
                        <i
                                class="fas fa-times-circle handle-Servico-excluir"
                                style="color: black; font-size: 12px; cursor: pointer;"
                                title="Excluir Servico"
                                data-task-id="${task.servico_id}-${task.cronograma_id}"
                            ></i>
                        </div>
                    `,
                o: () => task[""] || "-",

            },
            Item: {
                a: () => `${task.itens || "-"} / ${task.subitens || "-"}`,
                b: () => self.formatNumber(task.f1),
                c: () => task.u01 || "-",
                d: () => self.formatNumber(task.f2),
                e: () => task.u02 || "-",
                f: () => self.formatNumber(task.f3),
                g: () => task.u03 || "-",
                h: () => self.formatNumber(task.f4, "0,000"),
                i: () => task.u04 || "-",
                j: () => self.createCheckboxHtml(task.iss_b),
                k: () => self.createCheckboxHtml(task.pis_b),
                l: () => self.createCheckboxHtml(task.cofins_b),
                m: () => self.createCheckboxHtml(task.icms_b),
                n: () => self.createCheckboxHtml(task.p1_b),
                o: () => self.createCheckboxHtml(task.p2_b),
                p: () => self.createCheckboxHtml(task.p3_b),
                q: () => self.createCheckboxHtml(task.p4_b),
                r: () => self.formatNumber(task.qde),
                s: () => task.itens_ude || "-",
                t: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.tr_qde !== null && task.valor !== undefined && task.valor !== ''
                        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(task.valor)
                        : "";

                    return `
                         <td class="td-servico_obs">
                            <div
                                data-task-id="${task.lista_id}"
                                title="Valor"
                                class="input-item_valor editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_valor(${task.lista_id}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                u: () => self.formatCurrency(task.vt),
                v: () => {
                    const valorVenda = task.vt
                        ? task.vt / (1 - (task.ibt || 0) / 100)
                        : null;
                    return self.formatNumber(valorVenda);
                },
                w: () => task.tipo_a || "-",
                x: () => task[""] || "-",
                y: () => task[""] || "-",
                z: () => task.crd || "-",
            },
            Subservico: {
                a: () => task.tarefa || "-",
                b: () => task.subservico_obs || "-",
                c: () => task.subservico_ude || "-",
                d: () => task.vtc_vmosubser || "-",
                e: () => task.vtc_vmasubser || "-",
                f: () => task.vtc_vlosubser || "-",
                g: () => task.vtc_vtsubser || "-",
                h: () => task.vtv_vmosubser || "-",
                i: () => task.vtv_vmasubser || "-",
                j: () => task.vtv_vlosubser || "-",
                k: () => task.vtv_vtsubser || "-",
            },
            Item_subservico: {
                a: () => `${task.itens || "-"} / ${task.subitens || "-"}`,
                b: () => self.formatNumber(task.f1),
                c: () => task.u01 || "-",
                d: () => self.formatNumber(task.f2),
                e: () => task.u02 || "-",
                f: () => self.formatNumber(task.f3),
                g: () => task.u03 || "-",
                h: () => self.formatNumber(task.f4, "0,000"),
                i: () => task.u04 || "-",
                j: () => self.createCheckboxHtml(task.iss_b),
                k: () => self.createCheckboxHtml(task.pis_b),
                l: () => self.createCheckboxHtml(task.cofins_b),
                m: () => self.createCheckboxHtml(task.icms_b),
                n: () => self.createCheckboxHtml(task.p1_b),
                o: () => self.createCheckboxHtml(task.p2_b),
                p: () => self.createCheckboxHtml(task.p3_b),
                q: () => self.createCheckboxHtml(task.p4_b),
                r: () => self.formatNumber(task.qde),
                s: () => task.itens_ude || "-",
                t: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.tr_qde !== null && task.valor !== undefined && task.valor !== ''
                        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(task.valor)
                        : "";

                    return `
                         <td class="td-servico_obs">
                            <div
                                data-task-id="${task.lista_id}"
                                title="Valor"
                                class="input-item_valor editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_valor(${task.lista_id}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                u: () => self.formatCurrency(task.vt),
                v: () => {
                    const valorVenda = task.vt
                        ? task.vt / (1 - (task.ibt || 0) / 100)
                        : null;
                    return self.formatNumber(valorVenda);
                },
                w: () => task.tipo_a || "-",
                x: () => task[""] || "-",
                y: () => task[""] || "-",
                z: () => task.crd || "-",
            },
        };

        const mappings = fieldMappings[task.tipo];
        return mappings && mappings[field] ? mappings[field]() : "-";
    },

    getColSpan(task, coluna) {
        const colSpanMappings = {
            Projeto: {
                o: 1, n: 1, m: 5, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 4, b: 3,
                default: 15, // Valor padrão para "Projeto"
            },
            Setor: {
                p: 1, o: 1, n: 5, m: 4, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 3, b: 2,
                default: 13, // Valor padrão para "Setor"
            },
            Subsetor: {
                p: 1, o: 1, n: 5, m: 4, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 3, b: 2,
                default: 13, // Valor padrão para "Subsetor"
            },
            Place: {
                p: 1, o: 1, n: 5, m: 4, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 3, b: 2,
                default: 13, // Valor padrão para "Place"
            },

            Etapa: {
                p: 1, o: 1, n: 5, m: 4, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 3, b: 2,
                default: 13, // Valor padrão para "Etapa"
            },
            Subetapa: {
                p: 1, o: 1, n: 5, m: 4, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 3, b: 2,
                default: 13, // Valor padrão para "Subetapa"
            },
            Tarefa: {
                o: 1, n: 1, m: 5, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 4, b: 3,
                default: 15, // Valor padrão para "Tarefa"
            },
            Servico: {
                o: 1, n: 1, m: 5, l: 4, k: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 3, d: 3, c: 2, b: 12,
                default: 6, // Valor padrão para "Servico"
            },
            Item: {
                a: 15, b: 2, c: 2, d: 2, e: 2, f: 2, g: 2, h: 2, i: 2,
                j: 1, k: 1, l: 1, m: 1, n: 1, o: 1, p: 1, q: 1,
                r: 2, s: 2, t: 3, u: 3, v: 3, w: 1, x: 1, y: 1, z: 5, aa: 1,
                default: 1, // Valor padrão para "Item"
            },
            Subservico: {
                o: 1, n: 1, m: 1, l: 4, j: 4, i: 4, h: 4, g: 4, f: 4, e: 4, d: 4, c: 4, b: 12,
                default: 6, // Valor padrão para "Subservico"
            },
            Item_subservico: {
                a: 15, b: 2, c: 2, d: 2, e: 2, f: 2, g: 2, h: 2, i: 2,
                j: 1, k: 1, l: 1, m: 1, n: 1, o: 1, p: 1, q: 1,
                r: 2, s: 2, t: 3, u: 3, v: 3, w: 1, x: 1, y: 1, z: 5, aa: 1,
                default: 1, // Valor padrão para "Item_subservico"
            },
        };

        const typeMapping = colSpanMappings[task.tipo];
        if (typeMapping) {
            return typeMapping[coluna.field] || typeMapping.default;
        }
        return 1; // Valor padrão caso o tipo não seja encontrado
    },

    getTdStyles(task, coluna) {

        const styles = {};

        switch (task.tipo) {
            case "Projeto":
                if (coluna.field === "a") {
                    styles.paddingLeft = "5px";
                    styles.cursor = "pointer";
                } else if (["d", "e", "f", "g", "h", "i", "j", "l", "m"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "n", "o"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Setor":
                if (coluna.field === "a") { styles.paddingLeft = "10px"; styles.cursor = "pointer"; }
                else if (["e", "f", "g", "h", "i", "j", "l", "m", "n"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "d", "o", "p"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Subsetor":
                if (coluna.field === "a") { styles.paddingLeft = "15px"; styles.cursor = "pointer"; }
                else if (["e", "f", "g", "h", "i", "j", "l", "m", "n"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "d", "o", "p"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Place":
                if (coluna.field === "a") { styles.paddingLeft = "20px"; styles.cursor = "pointer"; }
                else if (["e", "f", "g", "h", "i", "j", "l", "m", "n"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "d", "o", "p"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Etapa":
                if (coluna.field === "a") { styles.paddingLeft = "25px", styles.cursor = "pointer"; }
                else if (["e", "f", "g", "h", "i", "j", "l", "m", "n"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "d", "o", "p"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Subetapa":
                if (coluna.field === "a") { styles.paddingLeft = "30px", styles.cursor = "pointer"; }
                else if (["e", "f", "g", "h", "i", "j", "l", "m", "n"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "d", "o", "p"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Tarefa":
                if (coluna.field === "a") {
                    styles.paddingLeft = "5px", styles.cursor = "pointer";
                } else if (["d", "e", "f", "g", "h", "i", "j", "l", "m", "n"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["b", "c", "o", "p"].includes(coluna.field)) {
                    styles.textAlign = "center";
                }
                break;
            case "Servico":
                    if (coluna.field === "a") {
                        styles.paddingLeft = "5px";
                        styles.cursor = "pointer";
                    } else if (["k", "f", "g", "h", "i", "j", "l", "m"].includes(coluna.field)) {
                        styles.textAlign = "right";
                        styles.paddingRight = "2px";
                    } else if ([ "n", "o", "d", "e"].includes(coluna.field)) {
                        styles.textAlign = "center";
                    } else if (["b"].includes(coluna.field)) {
                        styles.textAlign = "left";
                        styles.paddingLeft = "5px";
                    } else if (["c"].includes(coluna.field)) {
                        styles.textAlign = "center";
                        styles.backgroundColor = "#dddeac";
                    }
                    break;
    
            case "Subservico":
                if (coluna.field === "a") {
                    styles.paddingLeft = "5px";
                    styles.cursor = "pointer";
                } else if (["k", "f", "g", "h", "i", "j", "l", "m"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if ([ "n", "o", "d", "e"].includes(coluna.field)) {
                    styles.textAlign = "center";
                } else if (["b"].includes(coluna.field)) {
                    styles.textAlign = "left";
                    styles.paddingLeft = "5px";
                } else if (["c"].includes(coluna.field)) {
                    styles.textAlign = "center";
                 }
                break;
            case "Item":
                if (coluna.field === "a") {
                    styles.paddingLeft = "5px";
                    styles.cursor = "pointer";
                } else if (["b", "d", "f", "h", "r", "u", "v",].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["c", "e", "g", "i", "j", "k", "l", "m", "n", "o", "p", "q", "s", "w", "x", "y", "z", "aa",].includes(coluna.field)) {
                    styles.textAlign = "center";
                } else if (["t"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                    styles.backgroundColor = "#dddeac";
                }
                break;
            case "Item_subservico":
                if (coluna.field === "a") {
                    styles.paddingLeft = "5px";
                    styles.cursor = "pointer";
                } else if (["b", "d", "f", "h", "r", "u", "v",].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                } else if (["c", "e", "g", "i", "j", "k", "l", "m", "n", "o", "p", "q", "s", "w", "x", "y", "z", "aa",].includes(coluna.field)) {
                    styles.textAlign = "center";
                } else if (["t"].includes(coluna.field)) {
                    styles.textAlign = "right";
                    styles.paddingRight = "2px";
                    styles.backgroundColor = "#dddeac";
                }
                break;
            default:
                // Caso nenhum tipo seja identificado, pode-se adicionar um estilo genérico ou retornar vazio.
                break;
        }

        return styles;
    },

    getRowClasses(task) {
        const typeClassMapping = {
            Projeto: "descricao-projeto-orcamento",
            Setor: "descricao-setor-orcamento",
            Subsetor: "descricao-subsetor-orcamento",
            Place: "descricao-place-orcamento",
            Etapa: "descricao-etapa-orcamento",
            Subetapa: "descricao-subetapa-orcamento",
            Tarefa: "descricao-tarefa-orcamento",
            Servico: "descricao-servico-orcamento",
            Item: "descricao-item-orcamento",
            Subservico: task.subservico_registro !== null ? "descricao-subservico-orcamento" : "",
            Item_subservico: "descricao-item_subservico-orcamento",
        };

        const classes = [];

        // Adicionar classe para tarefas invisíveis
        if (!task.visible) {
            const visibilityClass = task.tipo ? `${task.tipo.toLowerCase()}-hidden` : "task-hidden";
            //console.log("task.tipo", task.tipo);
            classes.push(visibilityClass);
        }

        // Adicionar classe específica para o tipo de tarefa, se existir no mapeamento
        const typeClass = typeClassMapping[task.tipo];
        if (typeClass) {
            classes.push(typeClass);
        }

        // Retornar todas as classes combinadas
        return classes.join(" ");
    },

    formatNumber(value, fallback = "") {
        return value !== null && value !== undefined && value !== ''
            ? value.toFixed(3).replace(".", ",")
            : fallback;
    },

    formatCurrency(value) {
        return value !== null && value !== undefined && value !== ''
            ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
            : "";
    },

    createCheckboxHtml(checked) {
        return `
        <div class="checkbox-container">
            <input 
                type="checkbox" 
                class="status-checkbox" 
                ${checked ? "checked" : ""} 
                onchange="event.target.checked ? (task.iss_b = 1) : (task.iss_b = 0)">
        </div>
    `;
    },

    handleTaskClick(task) {
        TaskVisibilityMethods.handleTaskClickA.call(this, task, this.tasks);
    },
}

export const EditarLista = {
    showSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.remove("hidden");
        }
    },

    hideSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.add("hidden");
        }
    },

    handleGlobalClick(event) {
        // Impede múltiplas chamadas simultâneas
        if (this.isProcessingGlobalClick) return;
        this.isProcessingGlobalClick = true;

        try {
            // Lógica para identificar o ícone de lista
            const icon = event.target.closest('.handle-lista-modal');
            if (icon) {
                const taskId = icon.getAttribute('data-task-id');
                this.handleListaModal(taskId); // Continua chamando o método relacionado
            }

            // Lógica para identificar o ícone de exclusão de serviço
            const icon_servico_excluir = event.target.closest('.handle-Servico-excluir');
            if (icon_servico_excluir) {
                const dataTaskId = icon_servico_excluir.getAttribute('data-task-id');
                if (dataTaskId) {
                    const [servico_id, cronograma_id] = dataTaskId.split('-'); // Divide os valores

                    // Verifica se os IDs foram corretamente extraídos
                    if (!servico_id || !cronograma_id) {
                        console.error("IDs inválidos extraídos:", { dataTaskId, servico_id, cronograma_id });
                        return;
                    }

                    this.handleServicoExcluir({ servico_id, cronograma_id }); // Passa ambos os valores e o contexto
                }
            }
        } finally {
            // Garante que a flag seja resetada após a execução
            this.isProcessingGlobalClick = false;
        }
    },

    handleListaModal(taskId) {
        this.isLoading = true; // Ativa o pensador (overlay de carregamento)
        try {
            // Salva o estado atual das tarefas antes de qualquer alteração
            this.savetasksStateOrcamento(this.tasks);

            // Localiza a tarefa selecionada
            const task = this.tasks.find((t) => t.cronograma_id === Number(taskId));

            if (task) {
                // Define a tarefa selecionada para o modal
                this.selectedTask = task;
                state.EditarOrcamento.id = task.cronograma_id;

                this.pageContext = {
                    tasks: this.tasks, // Mantém as tarefas no estado atual
                    fetchTasks: this.fetchTasks,
                };

                // Limpa e atualiza o estado relacionado ao modal
                localStorage.setItem('tasksStateLista', JSON.stringify([]));
                state.pesquisarServico.pesquisar2 = '';
                state.pesquisarServico.pesquisar1 = '';
                this.isModalListaVisible = true;

                // Usa um próximo ciclo de renderização para garantir que o modal foi carregado
                this.$nextTick(() => {
                    this.isLoading = false; // Finaliza o pensador após o modal carregar
                });
            } else {
                console.warn("Tarefa não encontrada para o ID:", taskId);
                this.isLoading = false; // Finaliza o pensador se a tarefa não for encontrada
            }
        } catch (error) {
            console.error("Erro ao manipular o modal:", error.message);
            this.isLoading = false; // Finaliza o pensador em caso de erro
        }
    },

    fecharListaModal() {
        this.isModalListaVisible = false; // Fecha o modal
    },

    async onEnterItem_valor(id) {
        const div = document.querySelector(`.input-item_valor[data-task-id="${id}"]`);
        if (!div) {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
            return;
        }

        const valor = div.textContent.trim();

        // Ativa o spinner global
        this.showSpinner();
        //console.log("Spinner ativado: operação de salvar item iniciada.");

        try {
            console.log(`Salvando item com ID ${id} e valor "${valor}"...`);

            // Chama a função para salvar os dados no backend
            await SalvarItemvalor.call(this, { id, valor });

            //console.log(`Item com ID ${id} salvo com sucesso.`);
        } catch (error) {
            console.error("Erro ao salvar o item:", error.message);
        } finally {
            // Finaliza o spinner global
            this.hideSpinner();
            //console.log("Spinner desativado: operação de salvar item finalizada.");
        }
    },

    async handleServicoExcluir(task) {
        if (this.isLoading) {
            console.warn("Outra operação já está em andamento. Ignorando a chamada.");
            return;
        }

        // Ativa o carregador global antes da execução
        this.showSpinner();
        try {
            // Chama a função ServicoExcluir com o contexto criado
            await ServicoExcluir.call(this, task)
        } catch (error) {
            console.error("Erro ao salvar o item:", error.message);
        } finally {
            // Finaliza o spinner global
            this.hideSpinner();
            //console.log("Spinner desativado: operação de salvar item finalizada.");
        }
    },

    async onEnterServico_qde(id) {
        if (this.isLoading) {
            console.warn("Outra operação já está em andamento. Ignorando a chamada.");
            return;
        }
        this.showSpinner();
        //console.log("id", id);
        // Selecionar o elemento baseado no atributo data-task-id
        try {
            const div = document.querySelector(`.input-qde-servico[data-task-id="${id}"]`);
            if (div) {
                // Recuperar os atributos necessários
                const servicoId = div.getAttribute("data-servico-id");
                const cronogramaId = div.getAttribute("data-cronograma-id");
                const valor = div.textContent.trim();

                // Exibir os valores para depuração
                //console.log(`Task ID: ${id}, Serviço ID: ${servicoId}, Valor: ${valor}`);

                // Enviar para o backend ou processar os dados
                await SalvarServico_qde.call(this, { id, servicoId, valor, cronogramaId });
            } else {
                console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
            }
        } catch (error) {
            console.error("Erro ao salvar o item:", error.message);
        } finally {
            // Finaliza o spinner global
            this.hideSpinner();
            //console.log("Spinner desativado: operação de salvar item finalizada.");
        }
    },

    async pesquisarOrcamentoItem(event) {
        if (event.key === 'Enter') {
            if (this.isLoading) {
                console.warn("Outra operação já está em andamento. Ignorando a chamada.");
                return;
            }
            this.showSpinner(); // Exibe o indicador de carregamento

            try {
                this.savetasksStateOrcamento(this.tasks); // Passa a lista de tarefas atual
                this.queryItem = event.target.value;
                state.EditarOrcamento.pesquisar1 = this.queryItem;

                const tasks = await fetchTasks(); // Faz a requisição para buscar tarefas
                this.loadtasksStateOrcamento(tasks);
                this.tasks = tasks;
            } catch (error) {
                console.error("Erro ao pesquisar o item do orçamento:", error.message);
            } finally {
                this.hideSpinner(); // Esconde o indicador de carregamento
            }
        }
    },

    async pesquisarOrcamentoSubitem(event) {
        if (event.key === 'Enter') {
            if (this.isLoading) {
                console.warn("Outra operação já está em andamento. Ignorando a chamada.");
                return;
            }
            this.showSpinner(); // Exibe o indicador de carregamento

            try {
                this.savetasksStateOrcamento(this.tasks); // Passa a lista de tarefas atual
                this.querySubitem = event.target.value;
                state.EditarOrcamento.pesquisar2 = this.querySubitem;

                const tasks = await fetchTasks(); // Faz a requisição para buscar tarefas
                this.loadtasksStateOrcamento(tasks);
                this.tasks = tasks;
            } catch (error) {
                console.error("Erro ao pesquisar o subitem do orçamento:", error.message);
            } finally {
                this.hideSpinner(); // Esconde o indicador de carregamento
            }
        }
    },

    async pesquisarOrcamentoTipo(event) {
        if (event.key === 'Enter') {
            if (this.isLoading) {
                console.warn("Outra operação já está em andamento. Ignorando a chamada.");
                return;
            }
            this.showSpinner(); // Exibe o indicador de carregamento

            try {
                this.savetasksStateOrcamento(this.tasks); // Passa a lista de tarefas atual
                this.queryTipo = event.target.value;
                state.EditarOrcamento.pesquisar3 = this.queryTipo;

                const tasks = await fetchTasks(); // Faz a requisição para buscar tarefas
                this.loadtasksStateOrcamento(tasks);
                this.tasks = tasks;
            } catch (error) {
                console.error("Erro ao pesquisar o tipo do orçamento:", error.message);
            } finally {
                this.hideSpinner(); // Esconde o indicador de carregamento
            }
        }
    },
};

export const TaskVisibilityMethods = {
    handleDoubleClick() {
        this.expandirTudo();
    },

    expandirTudo() {
        this.tasks.forEach(task => {
            task.visible = true; // Define a visibilidade de todas as tarefas como `true`
        });
        this.savetasksStateOrcamento(this.tasks); // Salva o estado atualizado
    },

    handleTaskClickA(task, tasks) {
        if (task.tipo === "Projeto") {
            if (this.clickTimer) {
                // Limpa o temporizador e chama a função de duplo clique
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
                this.handleDoubleClick(); // Expande tudo no duplo clique
            } else {
                // Define um temporizador para o clique simples
                this.clickTimer = setTimeout(() => {
                    this.clickTimer = null; // Reseta o temporizador
                    this.toggleSetorVisibility(task, tasks); // Executa o clique simples

                }, 300); // Tempo em milissegundos (ajuste conforme necessário)
            }
        }
        if (task.tipo === "Setor") {
            this.toggleSubsetorVisibility(task, tasks);
        } else if (task.tipo === "Subsetor") {
            this.togglePlaceVisibility(task, tasks);
        } else if (task.tipo === "Place") {
            this.toggleEtapaVisibility(task, tasks);
        } else if (task.tipo === "Etapa") {
            this.toggleSubetapaVisibility(task, tasks);
        } else if (task.tipo === "Subetapa") {
            this.toggleTarefaVisibility(task, tasks);
        } else if (task.tipo === "Tarefa") {
            this.toggleServicoVisibility(task, tasks);
        } else if (task.tipo === "Servico") {
            this.toggleItemVisibility(task, tasks);
        }

    },

    toggleSetorVisibility(projetoTask, tasks) {
        if (projetoTask.tipo === "Projeto") {
            tasks.forEach((setor) => {
                // Alterna a visibilidade dos Setores
                if (setor.tipo === "Setor" && setor.parent === projetoTask.id) {
                    const wasVisible = setor.visible; // Salva o estado anterior
                    setor.visible = !setor.visible;

                    // Se o Setor foi recolhido, oculta toda a hierarquia associada
                    if (!setor.visible) {
                        tasks.forEach((subsetor) => {
                            if (subsetor.tipo === "Subsetor" && subsetor.parent === setor.id) {
                                subsetor.visible = false;

                                tasks.forEach((place) => {
                                    if (place.tipo === "Place" && place.parent === subsetor.id) {
                                        place.visible = false;

                                        tasks.forEach((etapa) => {
                                            if (etapa.tipo === "Etapa" && etapa.parent === place.id) {
                                                etapa.visible = false;

                                                tasks.forEach((subetapa) => {
                                                    if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                                        subetapa.visible = false;

                                                        tasks.forEach((tarefa) => {
                                                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                                                tarefa.visible = false;

                                                                tasks.forEach((servico) => {
                                                                    if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                                        servico.visible = false;

                                                                        tasks.forEach((item) => {
                                                                            if (
                                                                                (item.tipo === "Item" ||
                                                                                    item.tipo === "Subservico" ||
                                                                                    item.tipo === "Item_subservico") &&
                                                                                item.parent === servico.id
                                                                            ) {
                                                                                item.visible = false;
                                                                            }
                                                                        });
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    } else if (!wasVisible) {
                        // Se o Setor foi expandido, mantém todos os níveis inferiores ocultos
                        tasks.forEach((subsetor) => {
                            if (subsetor.tipo === "Subsetor" && subsetor.parent === setor.id) {
                                subsetor.visible = false;

                                tasks.forEach((place) => {
                                    if (place.tipo === "Place" && place.parent === subsetor.id) {
                                        place.visible = false;

                                        tasks.forEach((etapa) => {
                                            if (etapa.tipo === "Etapa" && etapa.parent === place.id) {
                                                etapa.visible = false;

                                                tasks.forEach((subetapa) => {
                                                    if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                                        subetapa.visible = false;

                                                        tasks.forEach((tarefa) => {
                                                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                                                tarefa.visible = false;

                                                                tasks.forEach((servico) => {
                                                                    if (servico.tipo === "Servico" && servico.parent === tarefa.id || servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                                        servico.visible = false;

                                                                        tasks.forEach((item) => {
                                                                            if (
                                                                                (item.tipo === "Item" ||
                                                                                    item.tipo === "Subservico" ||
                                                                                    item.tipo === "Item_subservico") &&
                                                                                item.parent === servico.id
                                                                            ) {
                                                                                item.visible = false;
                                                                            }
                                                                        });
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.savetasksStateOrcamento(tasks); // Salva o estado atualizado
        }
    },

    toggleSubsetorVisibility(setorTask, tasks) {
        if (setorTask.tipo === "Setor") {
            tasks.forEach((subsetor) => {
                // Alterna a visibilidade de Subsetor
                if (subsetor.tipo === "Subsetor" && subsetor.parent === setorTask.id) {
                    subsetor.visible = !subsetor.visible;

                    // Se o Subsetor foi recolhido, também recolhe Place, Etapa, Subetapa e demais elementos associados
                    if (!subsetor.visible) {
                        tasks.forEach((place) => {
                            if (place.tipo === "Place" && place.parent === subsetor.id) {
                                place.visible = false;

                                // Recolhe Etapas associadas ao Place
                                tasks.forEach((etapa) => {
                                    if (etapa.tipo === "Etapa" && etapa.parent === place.id) {
                                        etapa.visible = false;

                                        // Recolhe Subetapas associadas à Etapa
                                        tasks.forEach((subetapa) => {
                                            if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                                subetapa.visible = false;

                                                // Recolhe Tarefas associadas à Subetapa
                                                tasks.forEach((tarefa) => {
                                                    if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                                        tarefa.visible = false;

                                                        // Recolhe Serviços associados à Tarefa
                                                        tasks.forEach((servico) => {
                                                            if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                                servico.visible = false;

                                                                // Recolhe Itens associados ao Serviço
                                                                tasks.forEach((item) => {
                                                                    if (
                                                                        (item.tipo === "Item" ||
                                                                            item.tipo === "Subservico" ||
                                                                            item.tipo === "Item_subservico") &&
                                                                        item.parent === servico.id
                                                                    ) {
                                                                        item.visible = false;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.savetasksStateOrcamento(tasks); // Salva o estado atualizado
        }
    },

    togglePlaceVisibility(subsetorTask, tasks) {
        if (subsetorTask.tipo === "Subsetor") {
            tasks.forEach((place) => {
                // Alterna a visibilidade de Place
                if (place.tipo === "Place" && place.parent === subsetorTask.id) {
                    const wasVisible = place.visible; // Salva o estado anterior
                    place.visible = !place.visible;

                    // Se o Place foi recolhido, recolhe todas as hierarquias associadas
                    if (!place.visible) {
                        tasks.forEach((etapa) => {
                            if (etapa.tipo === "Etapa" && etapa.parent === place.id) {
                                etapa.visible = false;

                                tasks.forEach((subetapa) => {
                                    if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                        subetapa.visible = false;

                                        tasks.forEach((tarefa) => {
                                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                                tarefa.visible = false;

                                                tasks.forEach((servico) => {
                                                    if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                        servico.visible = false;

                                                        tasks.forEach((item) => {
                                                            if (
                                                                (item.tipo === "Item" ||
                                                                    item.tipo === "Subservico" ||
                                                                    item.tipo === "Item_subservico") &&
                                                                item.parent === servico.id
                                                            ) {
                                                                item.visible = false;
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    } else if (!wasVisible) {
                        // Se o Place foi expandido, mantém níveis inferiores ocultos
                        tasks.forEach((etapa) => {
                            if (etapa.tipo === "Etapa" && etapa.parent === place.id) {
                                etapa.visible = false;

                                tasks.forEach((subetapa) => {
                                    if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                        subetapa.visible = false;

                                        tasks.forEach((tarefa) => {
                                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                                tarefa.visible = false;

                                                tasks.forEach((servico) => {
                                                    if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                        servico.visible = false;

                                                        tasks.forEach((item) => {
                                                            if (
                                                                (item.tipo === "Item" ||
                                                                    item.tipo === "Subservico" ||
                                                                    item.tipo === "Item_subservico") &&
                                                                item.parent === servico.id
                                                            ) {
                                                                item.visible = false;
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.savetasksStateOrcamento(tasks); // Salva o estado atualizado
        }
    },

    toggleEtapaVisibility(placeTask, tasks) {
        if (placeTask.tipo === "Place") {
            tasks.forEach((etapa) => {
                // Alterna a visibilidade das etapas
                if (etapa.tipo === "Etapa" && etapa.parent === placeTask.id) {
                    const wasVisible = etapa.visible; // Salva o estado anterior
                    etapa.visible = !etapa.visible;

                    // Se a etapa foi recolhida, recolhe subetapas, tarefas e elementos associados
                    if (!etapa.visible) {
                        tasks.forEach((subetapa) => {
                            if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                subetapa.visible = false;

                                tasks.forEach((tarefa) => {
                                    if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                        tarefa.visible = false;

                                        // Recolhe serviços e elementos associados
                                        tasks.forEach((servico) => {
                                            if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                servico.visible = false;

                                                // Recolhe itens associados ao serviço
                                                tasks.forEach((item) => {
                                                    if (
                                                        (item.tipo === "Item" ||
                                                            item.tipo === "Subservico" ||
                                                            item.tipo === "Item_subservico") &&
                                                        item.parent === servico.id
                                                    ) {
                                                        item.visible = false;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    } else if (!wasVisible) {
                        // Se a etapa foi expandida, mantém subetapas, tarefas e elementos ocultos
                        tasks.forEach((subetapa) => {
                            if (subetapa.tipo === "Subetapa" && subetapa.parent === etapa.id) {
                                subetapa.visible = false;

                                tasks.forEach((tarefa) => {
                                    if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                        tarefa.visible = false;

                                        // Garante que serviços e itens permanecem ocultos
                                        tasks.forEach((servico) => {
                                            if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                                servico.visible = false;

                                                tasks.forEach((item) => {
                                                    if (
                                                        (item.tipo === "Item" ||
                                                            item.tipo === "Subservico" ||
                                                            item.tipo === "Item_subservico") &&
                                                        item.parent === servico.id
                                                    ) {
                                                        item.visible = false;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.savetasksStateOrcamento(tasks); // Salva o estado atualizado
        }
    },

    toggleSubetapaVisibility(etapaTask, tasks) {
        if (etapaTask.tipo === "Etapa") {
            tasks.forEach((subetapa) => {
                // Alterna a visibilidade das subetapas
                if (subetapa.tipo === "Subetapa" && subetapa.parent === etapaTask.id) {
                    const wasVisible = subetapa.visible; // Salva o estado anterior
                    subetapa.visible = !subetapa.visible;

                    // Se a subetapa foi recolhida, recolhe também as tarefas associadas
                    if (!subetapa.visible) {
                        tasks.forEach((tarefa) => {
                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                tarefa.visible = false;

                                // Recolhe também os serviços associados à tarefa
                                tasks.forEach((servico) => {
                                    if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                        servico.visible = false;

                                        // Recolhe itens, subserviços e itens_subservico associados ao serviço
                                        tasks.forEach((item) => {
                                            if (
                                                (item.tipo === "Item" ||
                                                    item.tipo === "Subservico" ||
                                                    item.tipo === "Item_subservico") &&
                                                item.parent === servico.id
                                            ) {
                                                item.visible = false;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    } else if (!wasVisible) {
                        // Se a subetapa foi expandida, mantém as tarefas e níveis inferiores ocultos
                        tasks.forEach((tarefa) => {
                            if (tarefa.tipo === "Tarefa" && tarefa.parent === subetapa.id) {
                                tarefa.visible = false;

                                // Garante que serviços, itens e subserviços permanecem ocultos
                                tasks.forEach((servico) => {
                                    if (servico.tipo === "Servico" && servico.parent === tarefa.id) {
                                        servico.visible = false;

                                        tasks.forEach((item) => {
                                            if (
                                                (item.tipo === "Item" ||
                                                    item.tipo === "Subservico" ||
                                                    item.tipo === "Item_subservico") &&
                                                item.parent === servico.id
                                            ) {
                                                item.visible = false;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            this.savetasksStateOrcamento(tasks);
        }
    },

    toggleTarefaVisibility(subetapaTask, tasks) {
        if (subetapaTask.tipo === "Subetapa") {
            tasks.forEach((task) => {
                if (task.tipo === "Tarefa" && task.parent === subetapaTask.id) {
                    const wasVisible = task.visible; // Salva o estado anterior
                    task.visible = !task.visible;

                    // Se a tarefa foi recolhida, oculte os serviços, itens e subserviços associados
                    if (!task.visible) {
                        tasks.forEach((servico) => {
                            if (servico.tipo === "Servico" && servico.parent === task.id) {
                                servico.visible = false;

                                // Oculte os itens vinculados ao serviço
                                tasks.forEach((item) => {
                                    if (
                                        (item.tipo === "Item" ||
                                            item.tipo === "Item_subservico" ||
                                            item.tipo === "Subservico") &&
                                        item.parent === servico.id
                                    ) {
                                        item.visible = false;
                                    }
                                });
                            }
                        });
                    } else if (!wasVisible) {
                        // Se a tarefa foi expandida, não altere os serviços, itens e subserviços
                        tasks.forEach((servico) => {
                            if (servico.tipo === "Servico" && servico.parent === task.id) {
                                // Não faz nada para serviços, itens e subserviços ao expandir
                            }
                        });
                    }
                }
            });
            this.savetasksStateOrcamento(tasks);
        }
    },

    toggleServicoVisibility(tarefaTask, tasks) {
        if (tarefaTask.tipo === "Tarefa") {
            tasks.forEach((task) => {
                if (task.tipo === "Servico" && task.parent === tarefaTask.id) {
                    task.visible = !task.visible;

                    // Alternar visibilidade dos itens vinculados ao "Servico"
                    tasks.forEach((subtask) => {
                        if (
                            (subtask.tipo === "Item" ||
                                subtask.tipo === "Item_subservico" ||
                                subtask.tipo === "Subservico") &&
                            subtask.parent === task.id
                        ) {
                            subtask.visible = false;
                            //subtask.visible = task.visible; // Alterna junto com o Servico
                        }
                    });
                }
            });
            this.savetasksStateOrcamento(tasks);
        }
    },

    toggleItemVisibility(servicoTask, tasks) {
        if (servicoTask.tipo === "Servico") {
            tasks.forEach((task) => {
                if (task.tipo === "Item" && task.parent === servicoTask.id) {
                    task.visible = !task.visible;

                }
            });
            tasks.forEach((task) => {
                if (task.tipo === "Subservico" && task.parent === servicoTask.id) {
                    task.visible = !task.visible;

                }


            });
            tasks.forEach((task) => {
                //console.log("tipo",task.tipo, "parent",task.parent, "id",servicoTask.id);
                if (task.tipo === "Item_subservico" && task.parent === servicoTask.id) {
                    //console.log("parent",task.parent, "id",servicoTask.id);
                    task.visible = !task.visible;

                }
            });
            this.savetasksStateOrcamento(tasks);
        }
    },

    savetasksStateOrcamento(tasks) {
        //console.log("aa");
        const tasksStateCronograma = tasks.map(task => ({
            id: task.id,
            visible: task.visible,
        }));
        localStorage.setItem('tasksStateCronograma', JSON.stringify(tasksStateCronograma));
    },

    loadtasksStateOrcamento(tasks) {
        //console.log("bb");
        const tasksStateCronograma = JSON.parse(localStorage.getItem('tasksStateCronograma'));
        if (tasksStateCronograma) {
            tasks.forEach(task => {
                const savedTask = tasksStateCronograma.find(t => t.id === task.id);
                if (savedTask) {
                    task.visible = savedTask.visible;
                }
            });
        }
    }
};

export const methods = {

};

export const Select = {
    async Selecionar_obra(registrosSelecionados) {
        await Selecionar_obra(this, registrosSelecionados);
        this.fetchSetor();
        this.fetchSubsetor();
        this.fetchPlace();
        this.fetchEtapa();
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchObras() {
        await fetchObras(this);
    },

    async Selecionar_setor(registrosSelecionados) {
        await Selecionar_setor(this, registrosSelecionados);
        this.fetchSubsetor();
        this.fetchPlace();
        this.fetchEtapa();
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchSetor() {
        await fetchSetor(this);
    },

    async Selecionar_subsetor(registrosSelecionados) {
        await Selecionar_subsetor(this, registrosSelecionados);
        this.fetchPlace();
        this.fetchEtapa();
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchSubsetor() {
        await fetchSubsetor(this);
    },

    async Selecionar_place(registrosSelecionados) {
        await Selecionar_place(this, registrosSelecionados);
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEtapa();
        this.fetchEmpresa();
    },

    async fetchPlace() {
        await fetchPlace(this);
    },

    async Selecionar_etapa(registrosSelecionados) {
        await Selecionar_etapa(this, registrosSelecionados);
        this.fetchSubetapa();
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchEtapa() {
        await fetchEtapa(this);
    },

    async Selecionar_subetapa(registrosSelecionados) {
        await Selecionar_subetapa(this, registrosSelecionados);
        this.fetchTarefa();
        this.fetchEmpresa();
    },

    async fetchSubetapa() {
        await fetchSubetapa(this);
    },

    async Selecionar_tarefa(registrosSelecionados) {
        await Selecionar_tarefa(this, registrosSelecionados);
        this.fetchEmpresa();
    },

    async fetchTarefa() {
        await fetchTarefa(this);
    },

    async Selecionar_empresa(registrosSelecionados) {
        await Selecionar_empresa(this, registrosSelecionados);
    },

    async fetchEmpresa() {
        await fetchEmpresa(this);
    },

    async fetchUdeOptions() {
        await fetchUdeOptions(this);
    },
}

export const Limpar = {
    async limpar(context = null) {
        try {
            console.log("Iniciando limpeza...");

            // Limpar os registros no sessionStorage
            sessionStorage.setItem(
                "CronogramaObra_obra_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_setor_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_subsetor_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_place_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_etapa_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_subetapa_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_tarefa_registro",
                JSON.stringify([])
            );
            sessionStorage.setItem(
                "CronogramaObra_empresa_registro",
                JSON.stringify([])
            );

            // Limpar o estado de tarefas no localStorage
            localStorage.setItem('tasksStateOrcamento', JSON.stringify([]));
            state.EditarOrcamento.pesquisar1 = '';
            state.EditarOrcamento.pesquisar2 = '';
            state.EditarOrcamento.pesquisar3 = '';
            //console.log("Dados limpos com sucesso. Chamando fetchTasks...");

            // Chamar a função fetchTasks
            await fetchTasks(context);

            console.log("fetchTasks chamada e concluída com sucesso.");
        } catch (error) {
            console.error("Erro durante a limpeza ou ao chamar fetchTasks:", error);
        }
    },
};

export function LarguraDropdown() {
    document.querySelectorAll(
        '.select-carregar-obra, .select-carregar-setor, .select-carregar-subsetor, ' +
        '.select-carregar-place, .select-carregar-subetapa, .select-carregar-etapa, ' +
        '.select-carregar-tarefa, .select-carregar-empresa, .select-carregar-data-inicio, ' +
        '.select-carregar-data-termino'
    ).forEach((select) => {
        const dropdown = select.querySelector('.multiselect__content-wrapper');
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export async function fetchTasks(context = null) {
    //console.log("Início de fetchTasks.");

    try {

        // Obter dados do sessionStorage
        const getSessionData = (key) => {
            try {
                const data = JSON.parse(sessionStorage.getItem(key)) || [];
                //console.log(`Dados carregados para chave ${key}:`, data);
                return data;
            } catch (error) {
                console.error(`Erro ao carregar dados do sessionStorage para chave ${key}:`, error.message);
                return [];
            }
        };

        const obra_registros = getSessionData("CronogramaObra_obra_registro");
        const setor_registros = getSessionData("CronogramaObra_setor_registro");
        const subsetor_registros = getSessionData("CronogramaObra_subsetor_registro");
        const place_registros = getSessionData("CronogramaObra_place_registro");
        const etapa_registros = getSessionData("CronogramaObra_etapa_registro");
        const subetapa_registros = getSessionData("CronogramaObra_subetapa_registro");
        const tarefa_registros = getSessionData("CronogramaObra_tarefa_registro");
        const empresa_registros = getSessionData("CronogramaObra_empresa_registro");

        //console.log("Início da chamada para a API.");

        // Fazer a requisição para a API
        const response = await api.get(`/orcamento`, {
            params: {
                obra_registros: obra_registros.join(","),
                setor_registros: setor_registros.join(","),
                subsetor_registros: subsetor_registros.join(","),
                place_registros: place_registros.join(","),
                etapa_registros: etapa_registros.join(","),
                subetapa_registros: subetapa_registros.join(","),
                tarefa_registros: tarefa_registros.join(","),
                empresa_registros: empresa_registros.join(","),
                pesquisar_item: state.EditarOrcamento.pesquisar1,
                pesquisar_subitem: state.EditarOrcamento.pesquisar2,
                pesquisar_tipo: state.EditarOrcamento.pesquisar3,
            },
        });

        //console.log("Resposta recebida da API:", response.data);

        // Criar a estrutura da árvore
        //console.log("Criando a estrutura da árvore.");
        const estruturaArvore = criarEstruturaArvore(response.data);

        //console.log("Estrutura da árvore criada com sucesso.");

        // Mapear os dados
        //console.log("Início do mapeamento de dados com mapDados.");
        const tarefasProcessadas = await mapDados(estruturaArvore, context);

        //console.log("Mapeamento de dados concluído. Dados processados:", tarefasProcessadas);

        return tarefasProcessadas; // Retorna as tarefas processadas e calculadas
    } catch (error) {
        console.error("Erro ao buscar os dados do orçamento:", error.message);
        return [];
    }
}

function criarEstruturaArvore(dados) {
    const estrutura = new Map(); // Usando Map para melhor desempenho

    dados.forEach((item) => {
        const {
            CRONOGRAMA_EFRAN_REGISTRO,
            PROJETO_REGISTRO, PROJETO, PROJETO_QDE, PROJETO_UDE,
            SETOR_REGISTRO, SETOR, SETOR_QDE, SETOR1_QDE, SETOR_UDE,
            SUBSETOR_REGISTRO, SUBSETOR, SUBSETOR_QDE, SUBSETOR1_QDE, SUBSETOR_UDE,
            PLACE_REGISTRO, PLACE, PLACE_QDE, PLACE1_QDE, PLACE_UDE,
            ETAPA_REGISTRO, ETAPA, ETAPA_QDE, ETAPA1_QDE, ETAPA_UDE,
            SUBETAPA_REGISTRO, SUBETAPA, SUBETAPA_QDE, SUBETAPA1_QDE, SUBETAPA_UDE,
            TAREFA_REGISTRO, TAREFA, TAREFA_QDE, TR1_QDE, SUBTAREFA, TAREFA_UDE,
            SERVICO_REGISTRO, SERVICO, SERVICO_OBS, SERVICO_UNIDADE,
            SUBSERVICO_REGISTRO, SUBSERVICO, SUBSERVICO_OBS, SUBSERVICO_UNIDADE,
            ITEM_REGISTRO, QDE, D_D, SD_D, ITENS_UDE,
            VT, VTMO, VTMA, VTLO, IBT, V, F1, U01, F2, U02, F3, U03, F4, U04, PIS_B, ISS_B, ICMS_B, COFINS_B, P1_B, P2_B, P3_B, P4_B, TIPOA, L_R

        } = item;

        // Adicionar Projeto
        if (PROJETO_REGISTRO && !estrutura.has(PROJETO_REGISTRO)) {
            estrutura.set(PROJETO_REGISTRO, {
                tipo: "Projeto",
                registro: PROJETO_REGISTRO,
                id: PROJETO_REGISTRO,
                parent_id: null,
                projeto_id: PROJETO_REGISTRO,
                descricao: PROJETO,
                projeto_ude: PROJETO_UDE,
                qde: PROJETO_QDE,
                o_qde: PROJETO_QDE,
                qde1: null,
                valor: V,
                filhos: [],
            });
        }

        const projeto = estrutura.get(PROJETO_REGISTRO);

        // Adicionar Setor
        if (SETOR_REGISTRO) {
            let setor = projeto.filhos.find((f) => f.registro === SETOR_REGISTRO);
            if (!setor) {
                setor = {
                    tipo: "Setor",
                    registro: SETOR_REGISTRO,
                    id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}`,
                    parent_id: PROJETO_REGISTRO,
                    projeto_id: PROJETO_REGISTRO,
                    s: `${PROJETO_REGISTRO}${SETOR_REGISTRO}`,
                    descricao: SETOR,
                    s_ude: SETOR_UDE,
                    s_qde: SETOR1_QDE,
                    qde: SETOR1_QDE,
                    qde1: SETOR_QDE,
                    filhos: [],
                };
                projeto.filhos.push(setor);
            }

            // Adicionar Subsetor
            if (SUBSETOR_REGISTRO) {
                let subsetor = setor.filhos.find((f) => f.registro === SUBSETOR_REGISTRO);
                if (!subsetor) {
                    subsetor = {
                        tipo: "Subsetor",
                        registro: SUBSETOR_REGISTRO,
                        id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}`,
                        parent_id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}`,
                        sb: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}`,
                        descricao: SUBSETOR,
                        sb_ude: SUBSETOR_UDE,
                        sb_qde: SUBSETOR1_QDE,
                        qde: SUBSETOR1_QDE,
                        qde1: SUBSETOR_QDE,
                        filhos: [],
                    };
                    setor.filhos.push(subsetor);
                }

                // Adicionar Place
                if (PLACE_REGISTRO) {
                    let place = subsetor.filhos.find((f) => f.registro === PLACE_REGISTRO);
                    if (!place) {
                        place = {
                            tipo: "Place",
                            registro: PLACE_REGISTRO,
                            id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}`,
                            parent_id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}`,
                            p: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}`,
                            descricao: PLACE,
                            p_ude: PLACE_UDE,
                            p_qde: PLACE1_QDE,
                            qde: PLACE1_QDE,
                            qde1: PLACE_QDE,
                            filhos: [],
                        };
                        subsetor.filhos.push(place);
                    }

                    // Adicionar Etapa
                    if (ETAPA_REGISTRO) {
                        let etapa = place.filhos.find((f) => f.registro === ETAPA_REGISTRO);
                        if (!etapa) {
                            etapa = {
                                tipo: "Etapa",
                                registro: ETAPA_REGISTRO,
                                id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}`,
                                parent_id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}`,
                                e: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}`,
                                descricao: ETAPA,
                                e_ude: ETAPA_UDE,
                                e_qde: ETAPA1_QDE,
                                qde: ETAPA1_QDE,
                                qde1: ETAPA_QDE,
                                filhos: [],
                            };
                            place.filhos.push(etapa);
                        }

                        // Adicionar Subetapa
                        if (SUBETAPA_REGISTRO) {
                            let subetapa = etapa.filhos.find((f) => f.registro === SUBETAPA_REGISTRO);
                            if (!subetapa) {
                                subetapa = {
                                    tipo: "Subetapa",
                                    registro: SUBETAPA_REGISTRO,
                                    id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}`,
                                    parent_id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}`,
                                    se: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}`,
                                    descricao: SUBETAPA,
                                    se_ude: SUBETAPA_UDE,
                                    se_qde: SUBETAPA1_QDE,
                                    qde: SUBETAPA1_QDE,
                                    qde1: SUBETAPA_QDE,
                                    filhos: [],
                                };
                                etapa.filhos.push(subetapa);
                            }

                            // Adicionar Tarefa
                            if (TAREFA_REGISTRO) {
                                let tarefa = subetapa.filhos.find((f) => f.registro === TAREFA_REGISTRO);
                                if (!tarefa) {
                                    tarefa = {
                                        tipo: "Tarefa",
                                        registro: CRONOGRAMA_EFRAN_REGISTRO,
                                        id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}${CRONOGRAMA_EFRAN_REGISTRO}`,
                                        parent_id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}`,
                                        t: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}${CRONOGRAMA_EFRAN_REGISTRO}`,
                                        cronograma_id: CRONOGRAMA_EFRAN_REGISTRO,
                                        descricao: TAREFA,
                                        subtarefa: SUBTAREFA,
                                        t_ude: TAREFA_UDE,
                                        t_qde: TAREFA_QDE,
                                        qde: TAREFA_QDE,
                                        qde1: null,
                                        filhos: [],
                                    };
                                    subetapa.filhos.push(tarefa);
                                }

                                // Adicionar Serviço
                                if (SERVICO_REGISTRO) {
                                    let servico = tarefa.filhos.find((f) => f.registro === SERVICO_REGISTRO);
                                    if (!servico) {
                                        servico = {
                                            tipo: "Servico",
                                            registro: SERVICO_REGISTRO,
                                            id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                            parent_id: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}${TAREFA_REGISTRO}`,
                                            ser: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                            cronograma_id: CRONOGRAMA_EFRAN_REGISTRO,
                                            servico_id: SERVICO_REGISTRO,
                                            lista_id: L_R,
                                            descricao: SERVICO,
                                            servico_obs: SERVICO_OBS,
                                            servico_ude: SERVICO_UNIDADE,
                                            qde: TR1_QDE,
                                            tr_qde: TR1_QDE,
                                            qde1: null,
                                            filhos: [],
                                        };
                                        tarefa.filhos.push(servico);
                                    }

                                    // Adicionar Subserviço (se existir)
                                    if (SUBSERVICO_REGISTRO) {
                                        let subservico = servico.filhos.find((f) => f.registro === SUBSERVICO_REGISTRO);
                                        if (!subservico) {
                                            subservico = {
                                                tipo: "Subservico",
                                                registro: SUBSERVICO_REGISTRO,
                                                id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}${SUBSERVICO_REGISTRO}`,
                                                parent_id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                                subser: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}${SUBSERVICO_REGISTRO}`,
                                                descricao: SUBSERVICO,
                                                subservico_obs: SUBSERVICO_OBS,
                                                subservico_unidade: SUBSERVICO_UNIDADE,
                                                qde: QDE,
                                                qde1: null,
                                                filhos: [],
                                            };
                                            servico.filhos.push(subservico);
                                        }

                                        // Adicionar Item ao Subserviço
                                        if (ITEM_REGISTRO) {
                                            let item = subservico.filhos.find((f) => f.registro === ITEM_REGISTRO);
                                            if (!item) {
                                                item = {
                                                    tipo: "Item_subservico",
                                                    id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}${SUBSERVICO_REGISTRO}${ITEM_REGISTRO}`,
                                                    parent_id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                                    projeto_id: PROJETO_REGISTRO,
                                                    s: `${PROJETO_REGISTRO}${SETOR_REGISTRO}`,
                                                    sb: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}`,
                                                    p: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}`,
                                                    e: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}`,
                                                    se: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}`,
                                                    t: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}${TAREFA_REGISTRO}`,
                                                    ser: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                                    subser: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}${SUBSERVICO_REGISTRO}`,
                                                    itens: D_D,
                                                    subitens: SD_D,
                                                    itens_ude: ITENS_UDE,
                                                    qde: QDE,
                                                    qde1: null,
                                                    t_qde: TAREFA_QDE,
                                                    tr_qde: TR1_QDE,
                                                    s_qde: SETOR1_QDE,
                                                    sb_qde: SUBSETOR1_QDE,
                                                    p_qde: PLACE1_QDE,
                                                    e_qde: ETAPA1_QDE,
                                                    se_qde: SUBETAPA1_QDE,
                                                    valor: V,
                                                    vt: VT,
                                                    vtmo: VTMO,
                                                    vtma: VTMA,
                                                    vtlo: VTLO,
                                                    f1: F1,
                                                    u01: U01,
                                                    f2: F2,
                                                    u02: U02,
                                                    f3: F3,
                                                    u03: U03,
                                                    f4: F4,
                                                    u04: U04,
                                                    iss_b: ISS_B,
                                                    pis_b: PIS_B,
                                                    cofins_b: COFINS_B,
                                                    icms_b: ICMS_B,
                                                    p1_b: P1_B,
                                                    p2_b: P2_B,
                                                    p3_b: P3_B,
                                                    p4_b: P4_B,
                                                    tipo_a: TIPOA,
                                                    ibt: IBT,
                                                    lista_id: L_R,
                                                };
                                                subservico.filhos.push(item);
                                            }
                                        }
                                    } else {
                                        // Adicionar Item ao Serviço (se não houver Subserviço)
                                        if (ITEM_REGISTRO) {
                                            let item = servico.filhos.find((f) => f.registro === ITEM_REGISTRO);
                                            if (!item) {
                                                item = {
                                                    tipo: "Item",
                                                    registro: ITEM_REGISTRO,
                                                    id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}${ITEM_REGISTRO}`,
                                                    parent_id: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                                    projeto_id: PROJETO_REGISTRO,
                                                    s: `${PROJETO_REGISTRO}${SETOR_REGISTRO}`,
                                                    sb: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}`,
                                                    p: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}`,
                                                    e: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}`,
                                                    se: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}`,
                                                    t: `${PROJETO_REGISTRO}${SETOR_REGISTRO}${SUBSETOR_REGISTRO}${PLACE_REGISTRO}${ETAPA_REGISTRO}${SUBETAPA_REGISTRO}${TAREFA_REGISTRO}`,
                                                    ser: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}`,
                                                    subser: `${CRONOGRAMA_EFRAN_REGISTRO}${SERVICO_REGISTRO}${SUBSERVICO_REGISTRO}`,
                                                    itens: D_D,
                                                    subitens: SD_D,
                                                    qde: QDE,
                                                    qde1: null,
                                                    t_qde: TAREFA_QDE,
                                                    tr_qde: TR1_QDE,
                                                    s_qde: SETOR1_QDE,
                                                    sb_qde: SUBSETOR1_QDE,
                                                    p_qde: PLACE1_QDE,
                                                    e_qde: ETAPA1_QDE,
                                                    se_qde: SUBETAPA1_QDE,
                                                    valor: V,
                                                    vt: VT,
                                                    vtmo: VTMO,
                                                    vtma: VTMA,
                                                    vtlo: VTLO,
                                                    f1: F1,
                                                    u01: U01,
                                                    f2: F2,
                                                    u02: U02,
                                                    f3: F3,
                                                    u03: U03,
                                                    f4: F4,
                                                    u04: U04,
                                                    iss_b: ISS_B,
                                                    pis_b: PIS_B,
                                                    cofins_b: COFINS_B,
                                                    icms_b: ICMS_B,
                                                    p1_b: P1_B,
                                                    p2_b: P2_B,
                                                    p3_b: P3_B,
                                                    p4_b: P4_B,
                                                    tipo_a: TIPOA,
                                                    ibt: IBT,
                                                    lista_id: L_R,
                                                };
                                                servico.filhos.push(item);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    });
    return Array.from(estrutura.values());

}

export async function mapDados(tree, context) {

    const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

    try {
        // Processa os nós da árvore recursivamente
        const tasks = [];

        const processNode = (node) => {
            const processedNode = {
                cr_id: node.id || null,
                cronograma_id: node.cronograma_id || null,
                lista_id: node.lista_id || null,
                id: node.id || null,
                tipo: node.tipo || '',
                parent: node.parent_id || null,
                projeto_id: node.projeto_id || null,
                setor_id: node.setor_id || null,
                tarefa: node.descricao || '',
                subtarefa: node.subtarefa || '',
                servico_id: node.servico_id || '',
                servico_obs: node.servico_obs || '',
                subservico_obs: node.subservico_obs || '',
                itens: node.itens || '',
                subitens: node.subitens || '',
                qde: node.qde || 0,
                vmo: node.vtmo || '',
                vma: node.vtma || '',
                vlo: node.vtlo || '',
                vt: node.vt || '',
                valor: node.valor || '',
                ibt: node.ibt || '',
                s: node.s || '',
                sb: node.sb || '',
                p: node.p || '',
                e: node.e || '',
                se: node.se || '',
                t: node.t || '',
                ser: node.ser || '',
                subser: node.subser || '',
                t_qde: node.t_qde || '',
                tr_qde: node.tr_qde || '',
                o_qde: node.o_qde || '',
                s_qde: node.s_qde || '',
                sb_qde: node.sb_qde || '',
                p_qde: node.p_qde || '',
                e_qde: node.e_qde || '',
                se_qde: node.se_qde || '',
                o_ude: node.projeto_ude || '',
                s_ude: node.s_ude || '',
                sb_ude: node.sb_ude || '',
                p_ude: node.p_ude || '',
                e_ude: node.e_ude || '',
                se_ude: node.se_ude || '',
                t_ude: node.t_ude || '',
                servico_ude: node.servico_ude || '',
                subservico_ude: node.subservico_ude || '',
                itens_ude: node.itens_ude || '',
                f1: node.f1 || '',
                u01: node.u01 || '',
                f2: node.f2 || '',
                u02: node.u02 || '',
                f3: node.f3 || '',
                u03: node.u03 || '',
                f4: node.f4 || '',
                u04: node.u04 || '',
                iss_b: node.iss_b || '',
                pis_b: node.pis_b || '',
                cofins_b: node.cofins_b || '',
                icms_b: node.icms_b || '',
                p1_b: node.p1_b || '',
                p2_b: node.p2_b || '',
                p3_b: node.p3_b || '',
                p4_b: node.p4_b || '',
                tipo_a: node.tipo_a || '',

                visible: savedTasksState[node.id] !== undefined
                    ? savedTasksState[node.id]
                    : true,
            };

            tasks.push(processedNode);

            // Processa os filhos do nó atual
            if (node.filhos && node.filhos.length > 0) {
                node.filhos.forEach((child) => processNode(child, processedNode));
            }
        };

        tree.forEach((rootNode) => processNode(rootNode));

        // Calcula as tarefas (aguarda a conclusão de calcularTasks)
        await calcularTasks(tasks);

        // Atualiza o contexto se fornecido
        if (context && Array.isArray(context.tasks)) {
            context.tasks.splice(0, context.tasks.length, ...tasks);
        }

        return tasks;
    } catch (error) {
        console.error("Erro ao processar tarefas:", error.message);
        throw error; // Lança o erro para ser tratado no nível superior
    }
}

export async function calcularTasks(tasks) {
    const calcularAcumulado = (tasks, id, idAlvo, atributo, multiplicadores = []) => {
        return tasks.reduce((acumulador, task) => {
            if (task[id] === idAlvo) {
                let valor = parseFloat(task[atributo]) || 0;
                multiplicadores.forEach(multiplicador => {
                    const multiplicadorValor = parseFloat(task[multiplicador]) || 1;
                    valor *= multiplicadorValor;
                });
                return acumulador + valor;
            }
            return acumulador;
        }, 0);
    };

    const calcularComImposto = (tasks, id, idAlvo, atributo, multiplicadores = []) => {
        return tasks.reduce((acumulador, task) => {
            if (task[id] === idAlvo) {
                const ibt = parseFloat(task.ibt) || 0;
                let valor = parseFloat(task[atributo]) || 0;
                if (ibt > 0) valor /= 1 - (ibt / 100);
                multiplicadores.forEach(multiplicador => {
                    const multiplicadorValor = parseFloat(task[multiplicador]) || 1;
                    valor *= multiplicadorValor;
                });
                return acumulador + valor;
            }
            return acumulador;
        }, 0);
    };

    const formatarMoeda = (valor) => {
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    tasks.forEach(task => {
        const ids = {
            projeto: task.projeto_id,
            setor: task.s,
            subsetor: task.sb,
            place: task.p,
            etapa: task.e,
            subetapa: task.se,
            tarefa: task.t,
            servico: task.ser,
        };

        const multiplicadores = {
            projeto: ["t_qde", "s_qde", "sb_qde", "p_qde", "e_qde", "se_qde", "tr_qde"],
            setor: ["t_qde", "s_qde", "sb_qde", "p_qde", "e_qde", "se_qde", "tr_qde"],
            subsetor: ["t_qde", "sb_qde", "p_qde", "e_qde", "se_qde", "tr_qde"],
            place: ["t_qde", "p_qde", "e_qde", "se_qde", "tr_qde"],
            etapa: ["t_qde", "e_qde", "se_qde", "tr_qde"],
            subetapa: ["t_qde", "se_qde", "tr_qde"],
            tarefa: ["t_qde", "tr_qde"],
            servico: ["tr_qde"],
        };

        const atributos = ["vmo", "vma", "vlo", "vt", "vtvu"];
        const resultados = ["vmov", "vmav", "vlov", "vtv", "vtvuv"];

        for (const [nivel, idAlvo] of Object.entries(ids)) {
            if (!idAlvo) continue;

            atributos.forEach((atributo, index) => {
                if (atributo !== "vtvu") {
                    const idCampo = nivel === "projeto" ? "projeto_id" : nivel === "setor" ? "s" : nivel === "subsetor" ? "sb" :
                        nivel === "place" ? "p" : nivel === "etapa" ? "e" : nivel === "subetapa" ? "se" : nivel === "tarefa" ? "t" : "ser";

                    const acumulado = calcularAcumulado(tasks, idCampo, idAlvo, atributo, multiplicadores[nivel] || []);
                    const acumuladoImposto = calcularComImposto(tasks, idCampo, idAlvo, atributo, multiplicadores[nivel] || []);

                    // Salvar os valores não formatados para cálculos posteriores
                    task[`raw_${atributo}_${nivel}`] = acumulado; // Valor bruto
                    task[`raw_${resultados[index]}_${nivel}`] = acumuladoImposto; // Valor bruto com imposto

                    // Formatar os valores
                    task[`${atributo}_${nivel}`] = acumulado ? formatarMoeda(acumulado) : "R$0,00";
                    task[`${resultados[index]}_${nivel}`] = acumuladoImposto ? formatarMoeda(acumuladoImposto) : "R$0,00";
                }
            });

            // Cálculo específico de vtvuv como divisão de vtv pelo multiplicador correspondente
            if (["projeto", "setor", "subsetor", "place", "etapa", "subetapa", "tarefa", "servico"].includes(nivel)) {
                const vtvValor = task[`raw_vtv_${nivel}`] || 0; // Usa o valor bruto salvo
                const qde = parseFloat(
                    task[
                    nivel === "projeto" ? "o_qde" :
                        nivel === "setor" ? "s_qde" :
                            nivel === "subsetor" ? "sb_qde" :
                                nivel === "place" ? "p_qde" :
                                    nivel === "etapa" ? "e_qde" :
                                        nivel === "subetapa" ? "se_qde" :
                                            nivel === "tarefa" ? "t_qde" :
                                                nivel === "servico" ? "tr_qde" :
                                                    `${nivel}_qde`
                    ] || 1
                );
                task[`vtvuv_${nivel}`] = qde > 0 ? formatarMoeda(vtvValor / qde) : "R$0,00";
                //console.log(`task[vtvuv_${nivel}]:`, task[`vtvuv_${nivel}`], task[`${nivel}`], "qde", qde);
            }
        }

        // Cálculos para serviço e subserviço
        const servicoId = task.ser;
        const subservicoId = task.subser;

        atributos.forEach(atributo => {
            // Serviço
            const acumuladoServico = calcularAcumulado(tasks, "ser", servicoId, atributo);
            const acumuladoComImpostoServico = calcularComImposto(tasks, "ser", servicoId, atributo);

            task[`vtc_${atributo}_servico`] = formatarMoeda(acumuladoServico);
            task[`vtv_${atributo}_servico`] = formatarMoeda(acumuladoComImpostoServico);

            // Subserviço
            const acumuladoSubservico = calcularAcumulado(tasks, "subser", subservicoId, atributo);
            const acumuladoComImpostoSubservico = calcularComImposto(tasks, "subser", subservicoId, atributo);

            task[`vtc_${atributo}subser`] = formatarMoeda(acumuladoSubservico);
            task[`vtv_${atributo}subser`] = formatarMoeda(acumuladoComImpostoSubservico);
        });
    });

    //console.log("Tasks calculadas:", tasks);
    return tasks;
}

export async function Selecionar_obra(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.showSpinner();
        //console.log("Carregador ativado: carregando registros selecionados.");

        // Recuperar o estado salvo previamente do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

        // Atualizar registrosAtuais com os registros selecionados
        let registrosAtuais = [];
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_obra_registro", JSON.stringify(registrosAtuais));
        } else {
            //console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem("CronogramaObra_obra_registro");
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_obra:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
    }
}

export async function fetchObras(context) {
    try {
        const empresa_registro = sessionStorage.getItem("empresa_registro");

        // Faz a requisição para o servidor
        const response = await api.get(`/cronograma/projeto`, {
            params: { empresa_registro: empresa_registro },
        });

        // Mapeia os dados recebidos para o formato desejado
        context.dataCarregarObra = response.data.map((item) => ({
            value: item.registro,
            label:
                "Obra - " +
                item.descricao +
                " / " +
                "Cliente - " +
                item.cliente +
                " / " +
                "revisão " +
                item.r,
        }));

        //console.log("Dados carregados:", context.dataCarregarObra);
    } catch (error) {
        console.error("Erro ao buscar as obras:", error);
    }
}

export async function Selecionar_setor(context, registrosSelecionados) {
    try {
        // Ativar o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de setor.");

        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

        // Obter registros atuais do sessionStorage
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_setor_registro")) || [];

        // Atualizar registros com base na seleção
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_setor_registro", JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            registrosAtuais = [];
            sessionStorage.removeItem("CronogramaObra_setor_registro");
            sessionStorage.setItem("CronogramaObra_subsetor_registro", JSON.stringify([]));
            sessionStorage.setItem("CronogramaObra_place_registro", JSON.stringify([]));
            sessionStorage.setItem("CronogramaObra_etapa_registro", JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro ao executar Selecionar_setor:", error.message);
    } finally {
        // Desativar o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de setor concluída.");
    }
}

export async function fetchSetor(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];

    try {
        const response = await api.get('/setor/filter', {
            params: {
                obra_registros: obra_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarSetor = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarSetor = [];
    }
}

export async function Selecionar_subsetor(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de subsetor.");

        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_subsetor_registro")) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_subsetor_registro", JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem("CronogramaObra_subsetor_registro");
            registrosAtuais = [];
            sessionStorage.setItem("CronogramaObra_place_registro", JSON.stringify([]));
            sessionStorage.setItem("CronogramaObra_etapa_registro", JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
                console.log("Estado das tarefas salvo no localStorage com sucesso.");
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro na seleção de subsetor:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de subsetor finalizada.");
    }
}

export async function fetchSubsetor(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    try {
        const response = await api.get('/subsetor/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarSubsetor = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarSubsetor = [];
    }
}

export async function Selecionar_place(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de place.");

        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_place_registro")) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_place_registro", JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem("CronogramaObra_place_registro");
            registrosAtuais = [];
            sessionStorage.setItem("CronogramaObra_etapa_registro", JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
                console.log("Estado das tarefas salvo no localStorage com sucesso.");
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro na seleção de place:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de place finalizada.");
    }
}

export async function fetchPlace(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    try {
        const response = await api.get('/place/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarPlace = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarPlace = [];
    }
}

export async function Selecionar_etapa(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de etapa.");

        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_etapa_registro")) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_etapa_registro", JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem("CronogramaObra_etapa_registro");
            registrosAtuais = [];
            sessionStorage.setItem("CronogramaObra_subetapa_registro", JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
                console.log("Estado das tarefas salvo no localStorage com sucesso.");
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro na seleção de etapa:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de etapa finalizada.");
    }
}

export async function fetchEtapa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    try {
        const response = await api.get('/etapa/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarEtapa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarEtapa = [];
    }
}

export async function Selecionar_subetapa(context, registrosSelecionados) {
    try {
        // Ativa o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de subetapa.");

        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_subetapa_registro")) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_subetapa_registro", JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem("CronogramaObra_subetapa_registro");
            registrosAtuais = [];
            sessionStorage.setItem("CronogramaObra_tarefa_registro", JSON.stringify([]));
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
                console.log("Estado das tarefas salvo no localStorage com sucesso.");
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro na seleção de subetapa:", error.message);
    } finally {
        // Desativa o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de subetapa finalizada.");
    }
}

export async function fetchSubetapa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    const etapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];
    try {
        const response = await api.get('/subetapa/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(','),
                etapa_registros: etapa_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarSubetapa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarSubetapa = [];
    }
}

export async function Selecionar_tarefa(context, registrosSelecionados) {
    try {
        // Ativar o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de tarefa.");

        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem("CronogramaObra_tarefa_registro")) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem("CronogramaObra_tarefa_registro", JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem("CronogramaObra_tarefa_registro");
            registrosAtuais = [];
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
                console.log("Estado das tarefas salvo no localStorage com sucesso.");
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro na seleção de tarefa:", error.message);
    } finally {
        // Desativar o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de tarefa finalizada.");
    }
}

export async function fetchTarefa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    const etapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];
    const subetapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subetapa_registro')) || [];

    try {
        const response = await api.get('/tarefa/filter', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(','),
                etapa_registros: etapa_registro.join(','),
                subetapa_registros: subetapa_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarTarefa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarTarefa = [];
    }
}

export async function Selecionar_empresa(context, registrosSelecionados) {
    try {
        // Ativar o carregador global
        context.currentHighlightedCell();
        console.log("Carregador ativado: iniciando seleção de empresa.");

        // Carregar o estado salvo do localStorage
        const savedTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};
        let registrosAtuais = JSON.parse(sessionStorage.getItem('CronogramaObra_empresa_registro')) || [];

        // Atualizar registrosAtuais com base em registrosSelecionados
        if (Array.isArray(registrosSelecionados) && registrosSelecionados.length > 0) {
            registrosAtuais = registrosSelecionados.map((registro) => registro.value);
            sessionStorage.setItem('CronogramaObra_empresa_registro', JSON.stringify(registrosAtuais));
        } else {
            console.warn("Nenhum registro válido selecionado:", registrosSelecionados);
            sessionStorage.removeItem('CronogramaObra_empresa_registro');
            registrosAtuais = [];
        }

        // Salvar o estado atual de todas as tarefas visíveis antes de atualizar
        context.tasks.forEach((task) => {
            savedTasksState[task.id] = task.visible; // Atualiza o estado salvo para cada tarefa
        });

        // Fazer a requisição para buscar as tarefas com os registros atuais
        const success = await fetchTasks(context);

        if (success) {
            // Restaurar o estado salvo após a atualização das tarefas
            context.tasks.forEach((task) => {
                if (savedTasksState[task.id] !== undefined) {
                    task.visible = savedTasksState[task.id]; // Restaura o estado salvo
                } else {
                    task.visible = true; // Se não houver estado salvo, padrão é visível
                }
            });

            // Adicionar tarefas não carregadas ao estado salvo para garantir persistência
            const allTasksState = { ...savedTasksState };
            context.tasks.forEach((task) => {
                allTasksState[task.id] = task.visible;
            });

            // Salvar o estado atualizado no localStorage
            try {
                localStorage.setItem("tasksStateOrcamento", JSON.stringify(allTasksState));
                console.log("Estado das tarefas salvo no localStorage com sucesso.");
            } catch (error) {
                console.error("Erro ao salvar estado no localStorage:", error.message);
            }
        } else {
            console.error("Erro ao carregar tarefas.");
        }
    } catch (error) {
        console.error("Erro na seleção de empresa:", error.message);
    } finally {
        // Desativar o carregador global
        context.hideSpinner();
        console.log("Carregador desativado: seleção de empresa finalizada.");
    }
}

export async function fetchEmpresa(context) {
    const obra_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_obra_registro')) || [];
    const setor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_setor_registro')) || [];
    const subsetor_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subsetor_registro')) || [];
    const place_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_place_registro')) || [];
    const etapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_etapa_registro')) || [];
    const subetapa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_subetapa_registro')) || [];
    const tarefa_registro = JSON.parse(sessionStorage.getItem('CronogramaObra_tarefa_registro')) || [];

    try {
        const response = await api.get('/cronograma/o_empresa', {
            params: {
                obra_registros: obra_registro.join(','),
                setor_registros: setor_registro.join(','),
                subsetor_registros: subsetor_registro.join(','),
                place_registros: place_registro.join(','),
                etapa_registros: etapa_registro.join(','),
                subetapa_registros: subetapa_registro.join(','),
                tarefa_registros: tarefa_registro.join(',')
            }
        });

        // Verifica se há dados na resposta e mapeia para o formato desejado ou define como array vazio
        context.dataCarregarEmpresa = response.data.length > 0 ? response.data.map((item) => ({
            value: item.registro,
            label: item.descricao,
        })) : [];

    } catch (error) {
        console.error("Erro ao buscar os setores:", error);
        // Define como array vazio caso ocorra um erro
        context.dataCarregarEmpresa = [];
    }
}

export async function fetchUdeOptions(context) {
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.udeOptions = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar
        }));
        //console.log("udeOptions carregadas:", context.udeOptions);
    } catch (error) {
        console.error("Erro ao carregar as opções de UDE:", error);
    }
}

export async function handleServicoOrcamentoAdicionar(task, context) {
    if (!task) {
        console.warn("Nenhuma tarefa foi selecionada.");
        return;
    }

    try {
        // Ativar o carregador global
        context.currentHighlightedCell();

        // Chama a função para adicionar o serviço
        await AdicionarServico(task, context); // Passa o task e o contexto para a função de edição
        //console.log("Tarefa editada com sucesso:", response);
    } catch (error) {
        console.error("Erro ao editar tarefa:", error.message);
    } finally {
        // Desativar o carregador global
        context.hideSpinner();
    }
}

export async function AdicionarServico(task, context) {
    context.isLoading = true;
    //console.log("Contexto recebido:", context);

    try {
        if (!context || !Array.isArray(context.tasks)) {
            console.error("Contexto ou tasks não definidos.");
            return;
        }

        if (task.isProcessing) return;
        task.isProcessing = true;

        // Salvar o estado de visibilidade antes da operação
        const savedTasksState = context.tasks.reduce((state, task) => {
            state[task.id] = task.visible;
            return state;
        }, {});

        //console.log("Estado inicial dos IDs no contexto (savedState):", savedTasksState);

        // Captura a posição de rolagem atual da página
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a chamada para adicionar o serviço
        const response = await api.post('/orcamento/adicionar-servico', {
            id: task.id || '',
            tarefa_id: state.EditarOrcamento.id,
        });

        // Recupera as tarefas atualizadas
        const updatedTasks = await fetchTasks(context);

        if (!Array.isArray(updatedTasks)) {
            console.error("fetchTasks não retornou um array. Valor recebido:", updatedTasks);
            throw new Error("fetchTasks precisa retornar um array.");
        }

        // Atualiza context.tasks preservando a reatividade
        context.tasks.splice(0, context.tasks.length, ...updatedTasks);

        // Restaurar o estado salvo para as tarefas atualizadas
        context.tasks.forEach(task => {
            task.visible = savedTasksState[task.id] ?? true; // Recolhido por padrão se não existir no estado salvo
        });

        //console.log("Tarefas atualizadas com estados restaurados:", context.tasks.map(t => ({ id: t.id, visible: t.visible })));

        // Restaura a posição de rolagem após atualizar as tarefas
        setTimeout(() => {
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition);
            });
        }, 0);

        return response.data;
    } catch (error) {
        console.error("Erro ao adicionar serviço:", error);
        throw error;
    } finally {
        // Reseta os estados temporários
        state.EditarLista.item_id = '';
        task.isProcessing = false;
        context.isLoading = false;

    }
}

export async function ServicoExcluir(task) {
    try {
        // Verifica se a tarefa já está em processamento
        if (task.isProcessing) {
            console.warn("Tarefa já está sendo processada. Ignorando a chamada.");
            return;
        }

        task.isProcessing = true;

        // Salva o estado atual das tarefas antes da atualização
        const savedState = this.tasks.map(t => ({
            id: t.id,
            visible: t.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para excluir o serviço
        const response = await api.post('/orcamento/excluir_servico', {
            servico_id: task.servico_id,
            id: task.cronograma_id,
        });

        //console.log("Serviço excluído com sucesso:", response.data);

        // Atualiza as tarefas e restaura o estado de visibilidade
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(updatedTask => {
            const savedTask = savedState.find(t => t.id === updatedTask.id);
            if (savedTask) {
                updatedTask.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao excluir o serviço:", error);
        throw error;
    } finally {
        // Finaliza o estado temporário da tarefa
        task.isProcessing = false;
    }
}

export async function SalvarItemvalor({ id, valor }) {
    //console.log("id:", id, "valor:", valor);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/orcamento/editar_item-valor', {
            id,
            valor,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function SalvarServico_qde({ id, servicoId, valor, cronogramaId }) {
    //console.log("id:", id, "valor:", valor, "servicoId:", servicoId, "cronogramaId:", cronogramaId);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/orcamento/editar_servico_qde', {
            id,
            valor,
            servicoId,
            cronogramaId,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}
