import axios from "axios";
import { state } from "../../store.js";
//import { atualizarCampos } from "../cronograma/editartarefamodal/editartarefa.js";
const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://efran.onrender.com/api"
        : "http://localhost:10000/api",
  });

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    editingTaskId: null,
});

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Etapa = {
    async initializeTasks() {
        let etapa_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            etapa_id = this.task;
        } else if (this.task && this.task.etapa_id) {
            etapa_id = this.task.etapa_id;
        } else {
            console.warn("etapa_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchEtapa(etapa_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statusetapa-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarEtapa(valor) {
        clearTimeout(this.debounceTimeoutEtapa);

        this.debounceTimeoutEtapa = setTimeout(async () => {
            this.queryEtapa = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchEtapa(state.pesquisarEtapa.etapa_id, this.queryEtapa, state.pesquisarEtapa.pesquisar2, state.pesquisarEtapa.pesquisar3);
            this.tasks = tasks;
        }, 300);
    },

    async pesquisarEtapaB(valor) {
        clearTimeout(this.debounceTimeoutEtapaB);

        this.debounceTimeoutEtapaB = setTimeout(async () => {
            this.queryEtapaB = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchEtapa(state.pesquisarEtapa.etapa_id, state.pesquisarEtapa.pesquisar1, this.queryEtapaB, state.pesquisarEtapa.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchEtapa(state.pesquisarEtapa.projeto_id, state.pesquisarEtapa.pesquisar1, state.pesquisarEtapa.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    EtapaSelecionado(task) {
        //console.warn("Tarefa selecionada:", task);
    
        if (task) {
            // Atualiza o estado global
            state.EditarTarefaCronograma.etapa = task.etapa;
            state.EditarTarefaCronograma.etapa_id = task.etapa_id;
            //state.EditarTarefaCronograma.etapa_ordem = 0;
    
            // Chama a função para atualizar os campos
            //atualizarCampos(this);
    
            // Emite o evento e fecha o modal
            this.$emit("fecharModalEtapa", {});
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.etapa) {
            this.adicionarEtapa(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da tarefa que está sendo editada
        this.editingTaskId = task.etapa_id;
    },

    enableExclui(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.etapa) {
            this.excluirEtapa(task,);
        }
    },

    EnterEtapaEditar(task) {
        this.editingTaskId = null;
        // Ação ao pressionar Enter
        this.EtapaEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    EtapaEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.etapa) {
            this.salvarEtapa(task,);
        }
    },

    editarStatusEtapa(task) {
        if (this.editableValue !== task.statusetapa) {
            this.salvarEtapa(task);
        }
    },

    salvarEtapa(task) {
        try {
            SalvarEtapa(
                { etapa_id: task.etapa_id, etapa: task.etapa, st: task.statusetapa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar etapa:", error);
        }
    },

    adicionarEtapa(task) {
        try {
            AdicionarEtapa(
                { etapa_id: task.etapa_id, etapa: task.etapa, st: task.statusetapa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar etapa:", error);
        }
    },

    excluirEtapa(task) {
        try {
            ExcluirEtapa(
                { etapa_id: task.etapa_id, etapa: task.etapa, st: task.statusetapa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar etapa:", error);
        }
    },
}

export async function fetchEtapa(etapa_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (etapa_id !== undefined) {
            state.pesquisarEtapa.etapa_id = etapa_id;
        } else {
            state.pesquisarEtapa.etapa_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarEtapa.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarEtapa.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarEtapa.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarEtapa.pesquisar2 = null;
        }

        if (pesquisar3 !== null) {
            state.pesquisarEtapa.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarEtapa.pesquisar3 = 1;
        }
        //console.log("pesquisar3", pesquisar3);

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            etapa_id: state.pesquisarEtapa.etapa_id,
            pesquisar1: state.pesquisarEtapa.pesquisar1,
            pesquisar2: state.pesquisarEtapa.pesquisar2,
            pesquisar3: state.pesquisarEtapa.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);
        //console.log("BaseURL usada:", api.defaults.baseURL);
        //console.log("Endpoint chamado: /setor");
        //console.log("Parâmetros enviados:", params);

        // Faça a requisição para a API
        const response = await api.get("/etapa", { params });

        const tasks = response.data.map((task) => ({
            etapa_id: task.registro,
            etapa: task.descricao || "",
            statusetapa: task.st || "",
        }));
        //console.log("bb");
        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}

export async function SalvarEtapa(task, context) {
    //console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.etapa) {
        exibirMensagem("Esta tarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await api.post("/etapa/editar", task);
        //console.log("Etapa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchEtapa no contexto recebido
        context.tasks = await fetchEtapa(state.pesquisarEtapa.projeto_id, state.pesquisarEtapa.pesquisar1, state.pesquisarEtapa.pesquisar2, state.pesquisarEtapa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar etapa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarEtapa(task, context) {

    try {
        const response = await api.post("/etapa/adicionar", task);
        //console.log("Etapa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchEtapa no contexto recebido
        context.tasks = await fetchEtapa(state.pesquisarEtapa.projeto_id, state.pesquisarEtapa.pesquisar1, state.pesquisarEtapa.pesquisar2, state.pesquisarEtapa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar etapa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirEtapa(task, context) {
    //console.log("task", task);
    try {
        const response = await api.post("/etapa/excluir", task);
        //console.log("Etapa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchEtapa no contexto recebido
        context.tasks = await fetchEtapa(state.pesquisarEtapa.projeto_id, state.pesquisarEtapa.pesquisar1, state.pesquisarEtapa.pesquisar2, state.pesquisarEtapa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar etapa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}















