<template>
  <div class="login-container">
    <div class="login-box">
      <!-- Logo -->
      <img src="/123/icones/icone.png" alt="Logo" class="login-logo" />
      <h2>Login</h2>
      <form @submit.prevent="fazerLogin">
        <div class="input-box">
          <input type="text" v-model="usuario" placeholder="Usuário" required />
        </div>
        <div class="input-box">
          <input type="password" v-model="senha" placeholder="Senha" required />
        </div>
        <!-- Select adicionado entre o campo de senha e o botão -->
        <div class="input-box select-container">
          <select
            v-model="empresaSelecionada"
            @change="atualizarEmpresaSelecionada"
            required
          >
            <option disabled value="">Selecione a Empresa</option>
            <option
              v-for="empresa in empresas"
              :key="empresa.id"
              :value="empresa.id"
            >
              {{ empresa.nome }}
            </option>
          </select>
        </div>
        <button type="submit">Entrar</button>
        <p v-if="erro" class="error">{{ erro }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://efran.onrender.com/api"
      : "http://localhost:10000/api",
});

import { state } from "../store.js";
export default {
  name: "LoginEfran",
  data() {
    return {
      usuario: "",
      senha: "",
      empresaSelecionada: "", // Nova variável para o select
      erro: "",
      empresas: [], // Definir 'empresas' como um array vazio no data
    };
  },
  mounted() {
    this.carregarEmpresas();
  },
  methods: {
    async carregarEmpresas() {
      try {
        const response = await api.get("/empresa");
        console.log("Resposta da API:", response.data); // Verifique aqui

        this.empresas = response.data.map((empresa) => ({
          id: empresa.registro,
          nome: empresa.descricao,
        }));

        this.empresas.forEach((empresa) => {
          console.log(`ID: ${empresa.id}, Nome: ${empresa.nome}`);
        });
      } catch (error) {
        console.error("Erro ao carregar empresas:", error);
        this.erro = "Não foi possível carregar as empresas.";
      }
    },
    atualizarEmpresaSelecionada() {
      // Encontra a empresa selecionada pelo ID
      const empresa = this.empresas.find(
        (empresa) => empresa.id === this.empresaSelecionada
      );

      if (empresa) {
        // Atualiza o estado global
        state.empresas = [
          {
            id: empresa.id,
            nome: empresa.nome,
          },
        ];
        localStorage.setItem(
          "empresaSelecionada",
          JSON.stringify(state.empresas)
        );
      }
    },
    async fazerLogin() {
  try {
    // Exibir dados enviados para depuração
    console.log("Tentativa de login com:", { usuario: this.usuario, senha: this.senha });

    // Fazer a requisição ao backend
    const response = await api.post('/acessousuario', {
      usuario: this.usuario,
      senha: this.senha,
    });

    // Exibir a resposta completa da API para depuração
    console.log("Resposta completa da API no login:", response.data);

    // Verificar sucesso na resposta da API
    if (response.data.success) {
      const { registro } = response.data; // Remova 'nome' se não for necessário
  sessionStorage.setItem("empresa_registro", registro);
  localStorage.setItem("userLoggedIn", "true");
  this.$router.push("/menu");
    } else {
      // Tratar credenciais inválidas
      console.warn("Login falhou: Credenciais inválidas");
      this.erro = response.data.message || "Usuário ou senha incorretos";
    }
  } catch (error) {
    // Capturar e exibir erros detalhados
    console.error("Erro ao fazer login:", error);

    if (error.response) {
      console.error("Resposta do servidor:", error.response.data);
      this.erro = error.response.data.message || "Erro ao fazer login. Verifique suas credenciais.";
    } else {
      console.error("Erro sem resposta do servidor:", error.message);
      this.erro = "Erro ao conectar ao servidor. Tente novamente mais tarde.";
    }
  }
}


  },
};
</script>

<style>
@import "@/styles/css.css";
</style>
