import axios from "axios";
import { state } from "../../store.js";
import { handleEditarDescricao } from "../item/item.js";

const api = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://efran.onrender.com/api"
        : "http://localhost:10000/api",
  });

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    editingTaskId: null,
});

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Descricao = {
    async initializeTasks() {
        let descricao_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            descricao_id = this.task;
        } else if (this.task && this.task.descricao_id) {
            descricao_id = this.task.descricao_id;
        } else {
            console.warn("descricao_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchDescricao(descricao_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statusdescricao-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarDescricao(valor) {
        clearTimeout(this.debounceTimeoutDescricao);

        this.debounceTimeoutDescricao = setTimeout(async () => {
            this.queryDescricao = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchDescricao(state.pesquisarDescricao.descricao_id, this.queryDescricao, state.pesquisarDescricao.pesquisar2, state.pesquisarDescricao.pesquisar3);
            this.tasks = tasks;
        }, 300);
    },

    async pesquisarDescricaoB(valor) {
        clearTimeout(this.debounceTimeoutDescricaoB);

        this.debounceTimeoutDescricaoB = setTimeout(async () => {
            this.queryDescricaoB = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchDescricao(state.pesquisarDescricao.descricao_id, state.pesquisarDescricao.pesquisar1, this.queryDescricaoB, state.pesquisarDescricao.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchDescricao(state.pesquisarDescricao.projeto_id, state.pesquisarDescricao.pesquisar1, state.pesquisarDescricao.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    DescricaoSelecionado(task) {
        if (task) {
            console.log("Contexto recebido no modal:", this.context);
    
            // Chama a função intermediária, passando o contexto correto
            handleEditarDescricao(task, this.context); // Passa o task e o contexto explicitamente
            
            this.$emit("fecharModalDescricao", {});
            
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.descricao) {
            this.adicionarDescricao(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da tarefa que está sendo editada
        this.editingTaskId = task.descricao_id;
    },

    enableExclui(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.descricao) {
            this.excluirDescricao(task,);
        }
    },

    EnterDescricaoEditar(task) {
        this.editingTaskId = null;
        // Ação ao pressionar Enter
        this.DescricaoEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    DescricaoEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.descricao) {
            this.salvarDescricao(task,);
        }
    },

    editarStatusDescricao(task) {
        if (this.editableValue !== task.statusdescricao) {
            this.salvarDescricao(task);
        }
    },

    salvarDescricao(task) {
        try {
            SalvarDescricao(
                { descricao_id: task.descricao_id, descricao: task.descricao, st: task.statusdescricao },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar descricao:", error);
        }
    },

    adicionarDescricao(task) {
        try {
            AdicionarDescricao(
                { descricao_id: task.descricao_id, descricao: task.descricao, st: task.statusdescricao },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar descricao:", error);
        }
    },

    excluirDescricao(task) {
        try {
            ExcluirDescricao(
                { descricao_id: task.descricao_id, descricao: task.descricao, st: task.statusdescricao },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar descricao:", error);
        }
    },
}

export async function fetchDescricao(descricao_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (descricao_id !== undefined) {
            state.pesquisarDescricao.descricao_id = descricao_id;
        } else {
            state.pesquisarDescricao.descricao_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarDescricao.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarDescricao.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarDescricao.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarDescricao.pesquisar2 = null;
        }

        if (pesquisar3 !== null) {
            state.pesquisarDescricao.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarDescricao.pesquisar3 = 1;
        }
        //console.log("pesquisar3", pesquisar3);

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            descricao_id: state.pesquisarDescricao.descricao_id,
            pesquisar1: state.pesquisarDescricao.pesquisar1,
            pesquisar2: state.pesquisarDescricao.pesquisar2,
            pesquisar3: state.pesquisarDescricao.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);
        //console.log("BaseURL usada:", api.defaults.baseURL);
        //console.log("Endpoint chamado: /setor");
        //console.log("Parâmetros enviados:", params);

        // Faça a requisição para a API
        const response = await api.get("/descricao/descricao", { params });

        const tasks = response.data.map((task) => ({
            descricao_id: task.registro,
            descricao: task.descricao || "",
            statusdescricao: task.st || "",
        }));
        //console.log("bb");
        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}

export async function SalvarDescricao(task, context) {
    //console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.descricao) {
        exibirMensagem("Esta tarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await api.post("/descricao/editar", task);
        //console.log("Descricao salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchDescricao no contexto recebido
        context.tasks = await fetchDescricao(state.pesquisarDescricao.projeto_id, state.pesquisarDescricao.pesquisar1, state.pesquisarDescricao.pesquisar2, state.pesquisarDescricao.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar descricao:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarDescricao(task, context) {

    try {
        const response = await api.post("/descricao/adicionar", task);
        //console.log("Descricao salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchDescricao no contexto recebido
        context.tasks = await fetchDescricao(state.pesquisarDescricao.projeto_id, state.pesquisarDescricao.pesquisar1, state.pesquisarDescricao.pesquisar2, state.pesquisarDescricao.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar descricao:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirDescricao(task, context) {
    //console.log("task", task);
    try {
        const response = await api.post("/descricao/excluir", task);
        //console.log("Descricao salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchDescricao no contexto recebido
        context.tasks = await fetchDescricao(state.pesquisarDescricao.projeto_id, state.pesquisarDescricao.pesquisar1, state.pesquisarDescricao.pesquisar2, state.pesquisarDescricao.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar descricao:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}















