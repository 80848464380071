<template>
  <div class="modal">
    <div class="modal-content">
      <!-- Cabeçalho -->
      <div class="modal-header">
        <img
          v-if="icon"
          :src="icon"
          alt="Ícone"
          class="modal-icon"
          :style="{ width: '30px', height: '30px' }"
        />
        <h3 class="modal-title">{{ title }}</h3>
        <div class="modal-actions">
          <i class="fas fa-times close-icon" @click="$emit('close')"></i>
        </div>
      </div>

      <!-- Corpo -->
      <div class="modal-body">
        <div class="input-group input-a-ObraModal">
          <input
            id="obra"
            type="text"
            placeholder="Pesquisar Obra"
            class="input-field"
            @input="pesquisarObra($event.target.value)"
          />
        </div>
        <div class="input-group input-a-ObraModal">
          <input
            id="cliente"
            type="text"
            placeholder="Pesquisar Cliente"
            class="input-field"
            @input="pesquisarCliente($event.target.value)"
          />
        </div>
      </div>
      <v-main class="custom-tabela-obra">
        <div class="tabela-header">
          <table class="tabela">
            <thead>
              <tr>
                <th class="A1-header">
                  <i class="fas fa-wallet" title="Código do orçamento"></i>
                </th>
                <th class="B1-header">
                  <i class="fas fa-building" title="Código do projeto"></i>
                </th>
                <th class="C1-header">Projeto</th>
                <th class="D1-header">Cliente</th>
                <th class="E1-header">Rev.</th>
                <th :class="['statusprojeto-header']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      class="status-checkbox-header"
                      @change="pesquisarFechada($event.target.checked)"
                      title="Marcado significa obra fechada "
                    />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="tabela-wrapper">
          <table class="tabela">
            <tbody>
              <tr
                v-for="task in tasks"
                :key="task.projeto_id"
                class="tr-obra"
                @dblclick="ObraSelecionada(task)"
              >
                <td class="A1-cell">{{ task.codigo_orcamento }}</td>
                <td class="B1-cell">{{ task.codigo_projeto }}</td>
                <td class="C1-cell">{{ task.obra }}</td>
                <td class="D1-cell">{{ task.cliente }}</td>
                <td class="E1-cell">{{ task.revisao }}</td>
                <td :class="['statusprojeto-cell']">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      :checked="task.statusprojeto === true"
                      @change="
                        (event) => {
                          task.statusprojeto = event.target.checked ? 1 : 0;
                        }
                      "
                      class="status-checkbox"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-main>
      <!-- Rodapé (opcional) -->
      <div class="modal-footer" v-if="showFooter">
        <slot name="footer">
          <button class="btn btn-secondary" @click="$emit('close')">
            Cancelar
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>
    
      <script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./obra.css";
import { data, Obra, LarguraDropdown } from "./obra.js";

export default {
  name: "ObraModal",
  data() {
    return {
      ...data(),
    };
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "Obra",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "/123/icones/icone_preto.png", // Caminho do ícone ou null
    },
    task: {
      type: Object,
      required: true,
    },
  },
  watch: {
    tasks: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.adjustHeaderWidth();
        });
      },
    },
  },
  mounted() {
    this.initializeTasks().then(() => {
      LarguraDropdown();
    });
    this.adjustHeaderWidth();
    window.addEventListener("resize", this.adjustHeaderWidth); // Ajusta ao redimensionar
    document.addEventListener("DOMContentLoaded", () => {
      // Seleciona todas as linhas (<tr>)
      const rows = document.querySelectorAll(".tr-obra");

      rows.forEach((row) => {
        // Adiciona evento ao passar o mouse
        row.addEventListener("mouseover", () => {
          row.style.backgroundColor = "yellow"; // Cor amarela ao passar o mouse
        });

        // Adiciona evento ao sair do mouse
        row.addEventListener("mouseout", () => {
          row.style.backgroundColor = ""; // Restaura a cor original
        });
      });
    });
  },
  methods: {
    ...Obra,
  },
};
</script>