import axios from "axios";
import { state } from "../../store.js";
import { toRaw } from "vue";
import { handleEditarItem } from "../lista/lista.js";

const task = { isProcessing: false };

const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "production"
            ? "https://efran.onrender.com/api"
            : "http://localhost:10000/api",
});

export const data = () => ({
    taskToEdit: null,
    tasks: [],
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    options: [], // A lista original
    isModalDescricaoVisible: false,
    isModalSubdescricaoVisible: false,
    isLoading: false,
    

    editingTaskIdUde: null,
    editingTaskIdDescricao: null,
    editingTaskIdSUB: null,

    isDropdownOpenUde: false,
    isDropdownOpenDescricao: false,
    isDropdownOpenSUB: false,

    udeOptions: [], // Inicializa como uma lista vazia
    filteredOptionsUde: [], // Opções filtradas
    editableValueUde: "",

    descricaoOptions: [], // Inicializa como uma lista vazia
    filteredOptionsDescricao: [], // Opções filtradas
    editableValueDescricao: "",

    SUBOptions: [], // Inicializa como uma lista vazia
    filteredOptionsSUB: [], // Opções filtradas
    editableValueSUB: "",

});

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const methods = {


}

export const unidade = {
    addClickOutsideListenerUde() {
        if (!this.handleClickOutsideUdeBound) {
            this.handleClickOutsideUdeBound = this.handleClickOutsideUde.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideUdeBound);
        //console.log("Listener de clique fora adicionado para Ude.");
    },

    removeClickOutsideListenerUde() {
        if (this.handleClickOutsideUdeBound) {
            document.removeEventListener("click", this.handleClickOutsideUdeBound);
            //console.log("Listener de clique fora removido para Ude.");
        }
    },

    closeDropdownUde() {
        //console.log("Fechando dropdown para ID Ude:", this.editingTaskIdUde);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdUde = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenUde = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerUde();

        //console.log("Dropdown Ude fechado.");
    },

    handleClickOutsideUde(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdUde) {
            //console.log("Nenhum dropdown Ude está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-ude="${this.editingTaskIdUde}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown Ude não encontrado, fechando.");
            this.closeDropdownUde();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown Ude. Fechando dropdown:", this.editingTaskIdUde);
            this.closeDropdownUde();
        }
    },

    enableUdeEdit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único para Ude.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdUde = task.id; // Define o ID do dropdown sendo editado
            this.editableValueUde = task.ude || ""; // Preenche o valor atual
            this.filterUdeOptions(); // Filtra as opções
            this.addClickOutsideListenerUde(); // Adiciona o listener para fechar ao clicar fora

            //console.log("Dropdown Ude aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchUdeOptions() {
        await fetchUdeOptions(this);
    },

    updateFilteredOptionsUde() {
        if (!this.editableValueUde) {
            // Se editableValueUde for vazio ou nulo, mostre todas as opções
            this.filteredOptionsUde = this.udeOptions.slice(); // Clona o array original
            return;
        }

        const searchValueUde = this.editableValueUde.toLowerCase().trim();

        this.filteredOptionsUde = this.udeOptions.filter((option) => {
            if (!option.label || typeof option.label !== "string") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueUde);
        });
    },

    handleUdeItemClick(option) {
        this.selectUdeOption(option);
        this.editingTaskIdUde = null;
    },

    filterUdeOptions() {

        // Remove Proxies para trabalhar com dados brutos
        const rawOptionsUde = this.udeOptions.map(option => toRaw(option));

        if (!this.editableValueUde || this.editableValueUde.trim() === "") {
            // Mostra todas as opções
            this.filteredOptionsUde = rawOptionsUde.map(option => ({
                label: option.label || "", // Garante que label seja uma string
                value: option.value || null,
            }));
        } else {
            // Filtra com base no texto inserido
            this.filteredOptionsUde = rawOptionsUde
                .filter(option => {
                    // Certifique-se de que option.label existe e é uma string
                    if (typeof option.label !== "string") {
                        //console.warn("Option label is not a string:", option);
                        return false; // Exclui itens inválidos
                    }

                    return option.label.toLowerCase().includes(this.editableValueUde.toLowerCase());
                })
                .map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
        }
    },

    selectUdeOption(option) {
        this.editableValueUde = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.id === this.editingTaskIdUde);

        if (task) {
            task.ude = option.label; // Nome da unidade
            task.ude_id = option.value; // ID da unidade

            //console.log("Tarefa atualizada:", task);

            this.editingTaskIdUde = null; // Desativa a edição

            // Prepara o contexto para enviar ao backend
            const context = {
                id: task.id, // ID da tarefa
                ude: task.ude, // Nome da unidade
                ude_id: task.ude_id, // ID da unidade
            };

            //console.log("Contexto enviado para edição:", context);

            // Chama a função para salvar a tarefa
            EditarUnidade(context)
                .then(() => {
                    //console.log("Tarefa atualizada com sucesso.");
                })
                .catch((error) => {
                    console.error("Erro ao atualizar a tarefa:", error);
                });
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdUde);
        }
    },
}

export const descricao = {
    showSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.remove("hidden");
        }
    },

    hideSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.add("hidden");
        }
    },

    addClickOutsideListenerDescricao() {
        if (!this.handleClickOutsideDescricaoBound) {
            this.handleClickOutsideDescricaoBound = this.handleClickOutsideDescricao.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideDescricaoBound);
        //console.log("Listener de clique fora adicionado.");
    },

    removeClickOutsideListenerDescricao() {
        if (this.handleClickOutsideDescricaoBound) {
            document.removeEventListener("click", this.handleClickOutsideDescricaoBound);
            //console.log("Listener de clique fora removido.");
        }
    },

    closeDropdownDescricao() {
        //console.log("Fechando dropdown para ID:", this.editingTaskIdDescricao);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdDescricao = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenDescricao = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerDescricao();

        //console.log("Dropdown fechado.");
    },

    handleClickOutsideDescricao(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdDescricao) {
            //console.log("Nenhum dropdown está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-descricao="${this.editingTaskIdDescricao}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown não encontrado, fechando.");
            this.closeDropdownDescricao();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown. Fechando dropdown:", this.editingTaskIdDescricao);
            this.closeDropdownDescricao();
        }
    },

    enableDescricaoEdit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdDescricao = task.id; // Define o ID do dropdown sendo editado
            this.editableValueDescricao = task.descricao || ""; // Preenche o valor inicial do campo
            this.filterDescricaoOptions(); // Filtra as opções
            this.addClickOutsideListenerDescricao(); // Adiciona o listener para clique fora

            //console.log("Dropdown aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchDescricaoOptions() {
        await fetchDescricaoOptions(this);
    },

    updateFilteredOptionsDescricao() {
        if (!this.editableValueDescricao) {
            // Se editableValueDescricao for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsDescricao = this.descricaoOptions.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueDescricao = this.editableValueDescricao.toLowerCase().trim();


        this.filteredOptionsDescricao = this.descricaoOptions.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueDescricao);
        });
    },

    handleDescricaoItemClick(option) {
        this.selectDescricaoOption(option);
        this.editingTaskIdDescricao = null;
    },

    filterDescricaoOptions() {
        setTimeout(() => {
            // Remove Proxies para trabalhar com dados brutos
            const rawOptionsDescricao = this.descricaoOptions.map(option => toRaw(option));

            if (!this.editableValueDescricao || this.editableValueDescricao.trim() === "") {
                // Mostra todas as opções
                this.filteredOptionsDescricao = rawOptionsDescricao.map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
            } else {
                // Filtra com base no texto inserido
                this.filteredOptionsDescricao = rawOptionsDescricao
                    .filter(option => {
                        // Certifique-se de que option.label existe e é uma string
                        if (typeof option.label !== "string") {
                            //console.warn("Option label is not a string:", option);
                            return false; // Exclui itens inválidos
                        }

                        return option.label.toLowerCase().includes(this.editableValueDescricao.toLowerCase());
                    })
                    .map(option => ({
                        label: option.label || "", // Garante que label seja uma string
                        value: option.value || null,
                    }));
            }
            //console.log("Opções filtradas:", this.filteredOptionsDescricao);
        }, 500);
    },

    selectDescricaoOption(option) {
        this.editableValueDescricao = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.id === this.editingTaskIdDescricao);

        if (task) {
            task.descricao = option.label; // Nome da unidade
            task.descricao_id = option.value; // ID da unidade

            //console.log("Tarefa atualizada:", task);

            this.editingTaskIdDescricao = null; // Desativa a edição

            // Prepara o contexto para enviar ao backend
            const context = {
                id: task.id, // ID da tarefa
                descricao: task.descricao, // Nome da unidade
                descricao_id: task.descricao_id, // ID da unidade
            };

            //console.log("Contexto enviado para edição:", context);

            // Chama a função para salvar a tarefa
            EditarDescricao(context)
                .then(() => {
                    //console.log("Tarefa atualizada com sucesso.");
                })
                .catch((error) => {
                    console.error("Erro ao atualizar a tarefa:", error);
                });
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdDescricao);
        }
    },
}

export const subdescricao = {
    addClickOutsideListenerSUB() {
        if (!this.handleClickOutsideSUBBound) {
            this.handleClickOutsideSUBBound = this.handleClickOutsideSUB.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideSUBBound);
        //console.log("Listener de clique fora adicionado para SUB.");
    },

    removeClickOutsideListenerSUB() {
        if (this.handleClickOutsideSUBBound) {
            document.removeEventListener("click", this.handleClickOutsideSUBBound);
            //console.log("Listener de clique fora removido para SUB.");
        }
    },

    closeDropdownSUB() {
        //console.log("Fechando dropdown para ID SUB:", this.editingTaskIdSUB);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdSUB = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenSUB = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerSUB();

        //console.log("Dropdown SUB fechado.");
    },

    handleClickOutsideSUB(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdSUB) {
            //console.log("Nenhum dropdown SUB está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-subdescricao="${this.editingTaskIdSUB}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown SUB não encontrado, fechando.");
            this.closeDropdownSUB();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown SUB. Fechando dropdown:", this.editingTaskIdSUB);
            this.closeDropdownSUB();
        }
    },

    enableSUBEdit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único para SUB.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdSUB = task.id; // Define o ID do dropdown sendo editado
            this.editableValueSUB = task.subdescricao || ""; // Preenche o valor inicial do campo
            this.filterSUBOptions(); // Filtra as opções
            this.addClickOutsideListenerSUB(); // Adiciona o listener para clique fora

            //console.log("Dropdown SUB aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchSUBOptions() {
        await fetchSUBOptions(this);
    },

    updateFilteredOptionsSUB() {
        if (!this.editableValueSUB) {
            // Se editableValueSUB for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsSUB = this.SUBOptions.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueSUB = this.editableValueSUB.toLowerCase().trim();


        this.filteredOptionsSUB = this.SUBOptions.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueSUB);
        });
    },

    handleSUBItemClick(option) {
        this.selectSUBOption(option);
        this.editingTaskIdSUB = null;
    },

    filterSUBOptions() {

        // Remove Proxies para trabalhar com dados brutos
        const rawOptionsSUB = this.SUBOptions.map(option => toRaw(option));

        if (!this.editableValueSUB || this.editableValueSUB.trim() === "") {
            // Mostra todas as opções
            this.filteredOptionsSUB = rawOptionsSUB.map(option => ({
                label: option.label || "", // Garante que label seja uma string
                value: option.value || null,
            }));
        } else {
            // Filtra com base no texto inserido
            this.filteredOptionsSUB = rawOptionsSUB
                .filter(option => {
                    // Certifique-se de que option.label existe e é uma string
                    if (typeof option.label !== "string") {
                        //console.warn("Option label is not a string:", option);
                        return false; // Exclui itens inválidos
                    }

                    return option.label.toLowerCase().includes(this.editableValueSUB.toLowerCase());
                })
                .map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
        }
        //console.log("Opções filtradas:", this.filteredOptionsSUB);
    },

    selectSUBOption(option) {
        this.editableValueSUB = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.id === this.editingTaskIdSUB);

        if (task) {
            task.subdescricao = option.label; // Nome da unidade
            task.subdescricao_id = option.value; // ID da unidade

            //console.log("Tarefa atualizada:", task);

            this.editingTaskIdSUB = null; // Desativa a edição

            // Prepara o contexto para enviar ao backend
            const context = {
                id: task.id, // ID da tarefa
                subdescricao: task.subdescricao, // Nome da unidade
                subdescricao_id: task.subdescricao_id, // ID da unidade
            };

            //console.log("Contexto enviado para edição:", context);

            // Chama a função para salvar a tarefa
            EditarSubdescricao(context)
                .then(() => {
                    //console.log("Tarefa atualizada com sucesso.");
                })
                .catch((error) => {
                    console.error("Erro ao atualizar a tarefa:", error);
                });
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdSUB);
        }
    },

}

export const Item = {
    async initializeTasks() {
        let item_id;
        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {
            item_id = this.task;
        } else if (this.task && this.task.itens_registro) {
            item_id = this.task.itens_registro;
        } else {
            console.warn("item_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchItem(item_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statusitem-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "4.55%" : "1%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarItem(valor) {
        clearTimeout(this.debounceTimeoutItem);

        this.debounceTimeoutItem = setTimeout(async () => {
            this.queryItem = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchItem(state.pesquisarItem.item_id, this.queryItem, state.pesquisarItem.pesquisar2, state.pesquisarItem.pesquisar3);
            this.tasks = tasks;
        }, 300);
    },

    async pesquisarItemB(valor) {
        clearTimeout(this.debounceTimeoutItemB);

        this.debounceTimeoutItemB = setTimeout(async () => {
            this.queryItemB = valor;

            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchItem(state.pesquisarItem.item_id, state.pesquisarItem.pesquisar1, this.queryItemB, state.pesquisarItem.pesquisar3);

            this.tasks = tasks;
        }, 300);
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchItem(state.pesquisarItem.projeto_id, state.pesquisarItem.pesquisar1, state.pesquisarItem.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    ItemSelecionado(task) {
        if (task) {
            //console.log("Contexto recebido no modal:", this.context);
    
            // Chama a função intermediária, passando o contexto correto
            handleEditarItem(task, this.context, this.showSpinner, this.hideSpinner); // Passa o task e o contexto explicitamente
            
            this.$emit("fecharModalItem", {});
            
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }        //console.warn("Tarefa selecionada:", task);

    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValueUde !== task.item) {
            this.adicionarItem(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da tarefa que está sendo editada
        this.editingTaskIdUde = task.item_id;
    },

    enableExclui(task) {
        //console.error("task", task);
        // Apenas chama a função se houver alteração
        if (this.editableValueUde !== task.item_id) {
            this.excluirItem(task,);
        }
    },

    EnterItemEditar(task) {
        this.editingTaskIdUde = null;
        // Ação ao pressionar Enter
        this.ItemEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    ItemEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValueUde !== task.item) {
            this.salvarItem(task,);
        }
    },

    editarStatusItem(task) {
        if (this.editableValueUde !== task.statusitem) {
            this.salvarItem(task);
        }
    },

    salvarItem(task) {
        try {
            SalvarItem(
                { item_id: task.item_id, item: task.item, st: task.statusitem },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar item:", error);
        }
    },

    adicionarItem(task) {
        try {
            AdicionarItem(
                { item_id: task.item_id, item: task.item, st: task.statusitem },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar item:", error);
        }
    },

    excluirItem(task) {
        //console.error("task", task);
        try {
            ExcluirItem(
                { item_id: task.id},
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar item:", error);
        }
    },

    fecharDescricaoModal() {
        this.isModalDescricaoVisible = false; // Fecha o modal
    },

    handleDescricaoModalEditar(taskId) {
        this.EditarDescricao(taskId);
    },

    EditarDescricao(taskId) {
        //console.log("taskId recebido (Proxy ou valor):", taskId);

        // Certifique-se de acessar o ID correto (caso seja um objeto Proxy)
        const descricaoId = typeof taskId === 'object' && taskId.descricao_id ? taskId.descricao_id : Number(taskId);

        //console.log("descricaoId extraído para busca:", descricaoId);

        // Encontra a tarefa correspondente
        const task = this.tasks.find((t) => t.descricao_id === Number(descricaoId));

        if (task) {
            //console.log("Tarefa encontrada:", task);

            // Define a tarefa selecionada
            this.selectedTask = task;

            // Atualiza o estado relacionado ao serviço
            state.EditarItem.item_id = task.id;
            //console.log("state.EditarItem.item_id", state.EditarItem.item_id);

            // Define o contexto da página
            this.pageContext = {
                tasks: this.tasks,
            };

            // Exibe o modal de descrição
            this.isModalDescricaoVisible = true;

            //console.log("Modal visível para edição:", this.isModalDescricaoVisible);
        } else {
            // Caso a tarefa não seja encontrada, exibe um aviso
            console.warn("Tarefa não encontrada para o ID:", descricaoId);
        }
    },

    fecharSubdescricaoModal() {
        this.isModalSubdescricaoVisible = false; // Fecha o modal
    },
    
    handleSubdescricaoModalEditar(taskId) {
        this.EditarSubdescricao(taskId);
    },

    EditarSubdescricao(taskId) {
        //console.log("taskId recebido (Proxy ou valor):", taskId);

        // Certifique-se de acessar o ID correto (caso seja um objeto Proxy)
        const subdescricaoId = typeof taskId === 'object' && taskId.subdescricao_id ? taskId.subdescricao_id : Number(taskId);

        //console.log("subdescricaoId extraído para busca:", subdescricaoId);

        // Encontra a tarefa correspondente
        const task = this.tasks.find((t) => t.subdescricao_id === Number(subdescricaoId));

        if (task) {
            //console.log("Tarefa encontrada:", task);

            // Define a tarefa selecionada
            this.selectedTask = task;

            // Atualiza o estado relacionado ao serviço
            state.EditarItem.item_id = task.id;
            //console.log("state.EditarItem.item_id", state.EditarItem.item_id);

            // Define o contexto da página
            this.pageContext = {
                tasks: this.tasks,
            };

            // Exibe o modal de descrição
            this.isModalSubdescricaoVisible = true;

            //console.log("Modal visível para edição:", this.isModalDescricaoVisible);
        } else {
            // Caso a tarefa não seja encontrada, exibe um aviso
            console.warn("Tarefa não encontrada para o ID:", subdescricaoId);
        }
    },
}

export async function fetchItem(item_id = null, pesquisar1 = null, pesquisar2 = null, pesquisar3 = null) {
    //console.log("item", state.EditarLista.item_id);
    try {
        const empresas = state.empresas.map((empresa) => ({
            id: empresa.id,
            nome: empresa.nome,
        }));

        const empresa_id = empresas[0]?.id;

        if (!empresa_id) {
            console.warn("empresa_id não está definido!");
        }

        // Atualiza os parâmetros de pesquisa no estado global
        if (item_id !== undefined) {
            state.pesquisarItem.item_id = item_id;
        } else {
            state.pesquisarItem.item_id = null;
        }

        if (pesquisar1 !== undefined) {
            state.pesquisarItem.pesquisar1 = pesquisar1;
        } else {
            state.pesquisarItem.pesquisar1 = null;
        }

        if (pesquisar2 !== undefined) {
            state.pesquisarItem.pesquisar2 = pesquisar2;
        } else {
            state.pesquisarItem.pesquisar2 = null;
        }

        if (pesquisar3 !== null) {
            state.pesquisarItem.pesquisar3 = pesquisar3;
        } else {
            state.pesquisarItem.pesquisar3 = 1;
        }
        //console.log("pesquisar3", pesquisar3);

        // Construa os parâmetros da requisição a partir do estado centralizado
        const params = {
            empresa_id,
            item_id: state.pesquisarItem.item_id,
            pesquisar1: state.pesquisarItem.pesquisar1,
            pesquisar2: state.pesquisarItem.pesquisar2,
            pesquisar3: state.pesquisarItem.pesquisar3,
        };

        //console.log("Parâmetros enviados para a API:", params);
        //console.log("BaseURL usada:", api.defaults.baseURL);
        //console.log("Endpoint chamado: /setor");
        //console.log("Parâmetros enviados:", params);

        // Faça a requisição para a API
        const response = await api.get("/item", { params });

        const tasks = response.data.map((task) => ({
            id: task.registro,
            ude_id: task.ude_registro,
            ude: task.ude || "",
            descricao_id: task.descricao_registro || "",
            descricao: task.descricao || "",
            subdescricao_id: task.subdescricao_registro || "",
            subdescricao: task.subdescricao || "",
            st: task.st || "",
        }));
        //console.log("bb");
        return tasks;
    } catch (error) {
        console.error("Erro ao processar a requisição:", error);
        return [];
    }
}

export async function SalvarItem(task, context) {
    //console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.item) {
        exibirMensagem("Esta tarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await api.post("/item/editar", task);
        //console.log("Item salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchItem no contexto recebido
        context.tasks = await fetchItem(state.pesquisarItem.projeto_id, state.pesquisarItem.pesquisar1, state.pesquisarItem.pesquisar2, state.pesquisarItem.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar item:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarItem(task, context) {

    try {
        const response = await api.post("/item/adicionar", task);
        //console.log("Item salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchItem no contexto recebido
        context.tasks = await fetchItem(state.pesquisarItem.item_id, state.pesquisarItem.pesquisar1, state.pesquisarItem.pesquisar2, state.pesquisarItem.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar item:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirItem(task, context) {
    //console.log("task", task);
    try {
        const response = await api.post("/item/excluir", task);
        //console.log("Item salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchItem no contexto recebido
        context.tasks = await fetchItem(state.pesquisarItem.item_id, state.pesquisarItem.pesquisar1, state.pesquisarItem.pesquisar2, state.pesquisarItem.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar item:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function fetchUdeOptions(context) {
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.udeOptions = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar
        }));
        //console.log("udeOptions carregadas:", context.udeOptions);
    } catch (error) {
        console.error("Erro ao carregar as opções de UDE:", error);
    }
}

export async function fetchDescricaoOptions(context) {
    //console.log("registro");
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/descricao`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.descricaoOptions = response.data.map(option => ({
            label: option.descricao, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar

        }));
        //console.log("descricaoOptions carregadas:", context.descricaoOptions);
    } catch (error) {
        console.error("Erro ao carregar as opções de Descricao:", error);
    }
}

export async function fetchSUBOptions(context) {
    //console.log("subdescricaoOptions carregadas:", context.SUBOptions);
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/subdescricao`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.SUBOptions = response.data.map(option => ({
            label: option.subdescricao, // Nome da unidade para exibição
            value: option.subdescricao_id // ID da unidade para salvar

        }));
        //console.log("descricaoOptions carregadas:", context.descricaoOptions);
    } catch (error) {
        console.error("Erro ao carregar as opções de Subdescricao:", error);
    }
}

export async function EditarUnidade(context) {
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/item/editar-unidade", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarDescricao(context) {
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/item/editar-descricao", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarSubdescricao(context) {
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/item/editar-subdescricao", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export function handleEditarDescricao(task, context) {
    if (!task) {
        //console.warn("Nenhuma tarefa foi selecionada.");
        return;
    }

    try {
        //console.log("Contexto recebido na edição:", context);
        //console.log("Tarefa recebida:", task);

        EditarDescrica(task, context) // Passa o task e o contexto para a função de edição
            .then((response) => {
                console.log("Tarefa editada com sucesso:", response);
            })
            .catch((error) => {
                console.error("Erro ao editar tarefa:", error);
            });
    } catch (error) {
        console.error("Erro na função handleEditarNomeServico:", error);
    }
}

export async function EditarDescrica(task, context) {
    //console.log("task", task);
    //console.log("context", context);
    //console.log("state.EditarItem.item_id", state.EditarItem.item_id);
    try {
        if (!context.tasks || !Array.isArray(context.tasks)) {
            console.error("Tasks não está definido ou não é um array.");
            return;
        }

        if (task.isProcessing) return;
        task.isProcessing = true;

        // Salva o estado atual antes de atualizar
        const savedState = context.tasks.map((t) => ({
            id: t.id,
            visible: t.visible,
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz o POST para editar o serviço
        const response = await api.post('/item/editar-item-descricao', {
            descricao_id: task.descricao_id || '',
            id: state.EditarItem.item_id || '',
        });

        // Atualiza as tarefas
        const updatedTasks = await fetchItem();

        // Atualiza o estado das tarefas preservando a reatividade
        context.tasks.splice(0, context.tasks.length, ...updatedTasks.map((t) => {
            const savedTask = savedState.find((st) => st.id === t.id);
            return savedTask ? { ...t, visible: savedTask.visible } : t;
        }));

        // Aguarda o DOM ser renderizado e restaura a posição de rolagem
        setTimeout(() => {
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition);
            });
        }, 0);

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao editar serviço:", error);
        throw error;
    } finally {
        state.EditarLista.item_id = '';
        task.isProcessing = false;
    }
}

export function handleEditarSubdescricao(task, context) {
    if (!task) {
        //console.warn("Nenhuma tarefa foi selecionada.");
        return;
    }

    try {
        //console.log("Contexto recebido na edição:", context);
        //console.log("Tarefa recebida:", task);

        EditarSubdescrica(task, context) // Passa o task e o contexto para a função de edição
            .then((response) => {
                console.log("Tarefa editada com sucesso:", response);
            })
            .catch((error) => {
                console.error("Erro ao editar tarefa:", error);
            });
    } catch (error) {
        console.error("Erro na função handleEditarNomeServico:", error);
    }
}

export async function EditarSubdescrica(task, context) {
    //console.log("task", task);
    //console.log("context", context);
    //console.log("state.EditarItem.item_id", state.EditarItem.item_id);
    try {
        if (!context.tasks || !Array.isArray(context.tasks)) {
            //console.error("Tasks não está definido ou não é um array.");
            return;
        }

        if (task.isProcessing) return;
        task.isProcessing = true;

        // Salva o estado atual antes de atualizar
        const savedState = context.tasks.map((t) => ({
            id: t.id,
            visible: t.visible,
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz o POST para editar o serviço
        const response = await api.post('/item/editar-item-subdescricao', {
            subdescricao_id: task.subdescricao_id || '',
            id: state.EditarItem.item_id || '',
        });

        // Atualiza as tarefas
        const updatedTasks = await fetchItem();

        // Atualiza o estado das tarefas preservando a reatividade
        context.tasks.splice(0, context.tasks.length, ...updatedTasks.map((t) => {
            const savedTask = savedState.find((st) => st.id === t.id);
            return savedTask ? { ...t, visible: savedTask.visible } : t;
        }));

        // Aguarda o DOM ser renderizado e restaura a posição de rolagem
        setTimeout(() => {
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition);
            });
        }, 0);

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao editar serviço:", error);
        throw error;
    } finally {
        state.EditarLista.item_id = '';
        task.isProcessing = false;
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}















