import axios from "axios";
import { state } from "../../store.js";
import { atualizarCampos } from "../cronograma/editartarefamodal/editartarefa.js";
import { ref } from "vue"; // Para criar valores reativos
import { toRaw } from "vue";
import { handleServicoOrcamentoAdicionar } from "../orcamento/orcamento.js";

const task = { isProcessing: false };

let isFetching = false;
export const tasks = ref([]);

const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "production"
            ? "https://efran.onrender.com/api"
            : "http://localhost:10000/api",
});

export const data = () => ({
    tasksStateLista: [],
    taskToEdit: null,
    tasks: [],
    isLoading: false,
    pesquisar1: "",
    pesquisar2: "",
    pesquisar3: "",
    debounceTimeout: null,
    isChecked: true,
    editingTaskId: null,
    isModalServicoVisible: false,
    isModalItemVisible: false,
    activeInputs: false,

    editingTaskIdU01: null,
    editingTaskIdU02: null,
    editingTaskIdU03: null,
    editingTaskIdU04: null,
    editingTaskIdU05: null,

    isDropdownOpenU01: false,
    isDropdownOpenU02: false,
    isDropdownOpenU03: false,
    isDropdownOpenU04: false,
    isDropdownOpenU05: false,

    u01Options: [],
    u02Options: [],
    u03Options: [],
    u04Options: [],
    u05Options: [],

    filteredOptionsU01: [],
    filteredOptionsU02: [],
    filteredOptionsU03: [],
    filteredOptionsU04: [],
    filteredOptionsU05: [],

    editableValueU01: "",
    editableValueU02: "",
    editableValueU03: "",
    editableValueU04: "",
    editableValueU05: "",

    colunas: [
        { label: "Descrição", colSpan: 10, style: { textAlign: "left", paddingLeft: "5px" }, },
        { label: "Subdescrição", colSpan: 21, style: { textAlign: "left", paddingLeft: "5px" }, },
        { label: "Rev,", colSpan: 2 },
        { label: "Registro", colSpan: 3 },
        { label: "Unidade", colSpan: 3 },
        { label: "MO", colSpan: 3 },
        { label: "MA", colSpan: 3 },
        { label: "LO", colSpan: 3 },
        { label: "Total", colSpan: 3 },
        { label: "Registro", colSpan: 3 },
        { label: "Fator", colSpan: 2 },
        { label: "", colSpan: 1, isButton: true },
        { label: "", colSpan: 1 },
        { label: "", colSpan: 1 },
        { label: "", colSpan: 1 },
        { label: "", colSpan: 1 },
        { label: "", colSpan: 1 },
    ],
    colunasExtras: [
        { field: "a" },
        { field: "b" },
        { field: "c" },
        { field: "d" },
        { field: "e" },
        { field: "f" },
        { field: "g" },
        { field: "h" },
        { field: "i" },
        { field: "j" },
        { field: "k" },
        { field: "l" },
        { field: "m" },
        { field: "n" },
        { field: "o" },
        { field: "p" },
        { field: "q" },
        { field: "r" },
        { field: "s" },
        { field: "t" },
        { field: "u" },
        { field: "v" },
        { field: "w" },
        { field: "x" },
        { field: "y" },
        { field: "z" },
        { field: "aa" }
    ]
});

export const tabela = {

    getThStyles() {
        return {
            whiteSpace: "nowrap",
            position: "sticky",
            top: "0",
            background: "black",
            color: "white", // Para garantir a legibilidade do texto
            zIndex: "1",
        };
    },

    filteredColunas(task) {
        const tipoColunas = {
            Servico: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q"],
            Item: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r"],
            Subservico: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l"],
            Item_subservico: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r"],
        };

        return this.colunasExtras.filter((coluna) =>
            tipoColunas[task.tipo]?.includes(coluna.field)
        );
    },

    getCellValue(task, field) {
        const fieldMappings = {
            Servico: {
                a: () => task["tarefa"] || "-",
                b: () => `
                    <td class="td-servico_obs">
                        <div
                        data-task-id="${task.servico_registro}"
                        class="input-servico_obs editable-cell ${this.activeInputs?.[task.servico_registro] ? "colorblack" : ""
                    }"
                        contenteditable="true"
                        onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterServicoObs(${task.servico_registro}); }"
                        >
                        ${task["servico_obs"] || ''}
                        </div>
                    </td>
                    `,
                c: () => `
                    <td class="td-servico_rev">
                        <div
                        data-task-id="${task.servico_registro}"
                        class="input-servico_rev editable-cell ${this.activeInputs?.[task.servico_registro] ? "colorblack" : ""
                    }"
                        contenteditable="true"
                        onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterServicoRev(${task.servico_registro}); }"
                        >
                        ${task["servico_rev"] || 0}
                        </div>
                    </td>
                    `,
                d: () => task["servico_registro"] || "-",
                f: () => task["vmo_servico"] || "-",
                g: () => task["vma_servico"] || "-",
                h: () => task["vlo_servico"] || "-",
                i: () => task["vt_servico"] || "-",
                j: () => `
                        <input
                            v-model="task.subservico_registro"
                            data-task-id="${task.servico_registro}"
                            class=" input-subservico_registro editable-cell"
                            :class="[activeInputs['${task.servico_registro}'] ? 'colorblack' : '']"
                        />
                    `,
                k: () => `
                        <input
                            v-model="task.subservico_fator"
                            data-task-id="${task.servico_registro}"
                            class="input-subservico_fator editable-cell"
                            :class="[activeInputs['${task.servico_registro}'] ? 'colorblack' : '']"
                        />
                    `,
                m: () => `
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                ">
                    <i
                        class="fas fa-edit handle-Servico-modal-editar"
                        style="color: black; font-size: 12px; cursor: pointer;"
                        title="Editar Serviço"
                        data-task-id="${task.servico_registro}"
                    ></i>
                </div>
            `,
                n: () => `
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                ">
                    <i
                        class="fas fa-times-circle "
                        style="color: black; font-size: 12px; cursor: pointer;"
                        title="Excluir Serviço"
                        data-task-id="${task.servico_registro}"
                    ></i>
                </div>
            `,
                l: () => `
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            ">
            <i
                    class="fas fa-plus-circle handle-Subservico-adicionar"
                    style="color: black; font-size: 12px; cursor: pointer;"
                    title="Adicionar Subservico"
                    data-task-id="${task.servico_registro}"
                ></i>
            </div>
        `,
                o: () => `
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            ">
            <i
                    class="fas fa-copy handle-Servico-copiar"
                    style="color: black; font-size: 12px; cursor: pointer;"
                    title="Copiar Servico"
                    data-task-id="${task.servico_registro}"
                ></i>
            </div>
        `,
                p: () => `
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            ">
            <i
                    class="fas fa-plus-circle handle-Servico-adicionar-orcamento"
                    style="color: black; font-size: 12px; cursor: pointer;"
                    title="Adicionar Servico ao Orçamento"
                    data-task-id="${task.servico_registro}"
                ></i>
            </div>
        `,
            },

            Item: {
                a: () => `${task["itens"] || ""} / ${task["subitens"] || ""}` || "-",
                b: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.f1 !== null && task.f1 !== undefined && task.f1 !== ''
                        ? task.f1.toFixed(3).replace(".", ",")
                        : "";

                    return `
                        <td class="td-servico_obs">
                            <div
                                data-task-id="${task.trabalho_registro}"
                                title="Fator 1"
                                class="input-item_f1 editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_f1(${task.trabalho_registro}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                d: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.f2 !== null && task.f2 !== undefined && task.f2 !== ''
                        ? task.f2.toFixed(3).replace(".", ",")
                        : "";

                    return `
                        <td class="td-servico_obs" >
                            <div
                                data-task-id="${task.trabalho_registro}"
                                title="Fator 2"
                                class="input-item_f2 editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_f2(${task.trabalho_registro}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                f: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.f3 !== null && task.f3 !== undefined && task.f3 !== ''
                        ? task.f3.toFixed(3).replace(".", ",")
                        : "";

                    return `
                        <td class="td-servico_obs">
                            <div
                                data-task-id="${task.trabalho_registro}"
                                title="Fator 3"
                                class="input-item_f3 editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_f3(${task.trabalho_registro}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                h: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.f4 !== null && task.f4 !== undefined && task.f4 !== ''
                        ? task.f4.toFixed(3).replace(".", ",")
                        : "";

                    return `
                        <td class="td-servico_obs">
                            <div
                                data-task-id="${task.trabalho_registro}"
                                title="Fator 4"
                                class="input-item_f4 editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_f4(${task.trabalho_registro}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                j: () => task.qde !== null && task.qde !== undefined && task.qde !== ''
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "",
                k: () => task["itens_ude"] || "-",
                l: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.valor !== null && task.valor !== undefined && task.valor !== ''
                        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(task.valor)
                        : "";

                    return `
                        <td class="td-servico_obs">
                            <div
                                data-task-id="${task.trabalho_registro}"
                                title="Valor"
                                class="input-item_valor editable-cell ${isEditable}"
                                contenteditable="true"
                                onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_valor(${task.trabalho_registro}); }"
                            >
                                ${content}
                            </div>
                        </td>
                    `;
                },
                m: () => task.vt !== null && task.vt !== undefined && task.vt !== ''
                    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(task.vt)
                    : "",
                n: () => {
                    const isEditable = this.activeInputs?.[task.id] ? "colorblack" : "";
                    const content = task.tipoa !== null && task.tipoa !== undefined && task.tipoa !== ''
                        ? task.tipoa // Trata tipoa como uma string
                        : "";

                    return `
                            <td class="td-servico_obs">
                                <div
                                    data-task-id="${task.trabalho_registro}"
                                    title="Fator 2"
                                    class="input-item_tipo editable-cell ${isEditable}"
                                    contenteditable="true"
                                    onkeydown="if(event.key === 'Enter') { event.preventDefault(); app.onEnterItem_tipo(${task.trabalho_registro}); }"
                                >
                                    ${content}
                                </div>
                            </td>
                        `;
                },

                o: () => `
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            ">
            <i
                    class="fas fa-plus-circle handle-Item-adicionar"
                    style="color: black; font-size: 12px; cursor: pointer;"
                    title="Adicionar Item"
                    data-task-id="${task.servico_registro}"
                ></i>
            </div>
        `,
                p: () => `
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                ">
                    <i
                        class="fas fa-edit handle-Item-modal-editar"
                        style="color: black; font-size: 12px; cursor: pointer;"
                        title="Editar Item"
                        data-task-id="${task.itens_registro}"
                    ></i>
                </div>
            `,
                q: () => `
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            ">
            <i
                    class="fas fa-times-circle handle-Item-excluir"
                    style="color: black; font-size: 12px; cursor: pointer;"
                    title="Excluir Item"
                    data-task-id="${task.itens_registro}-${task.trabalho_registro}"
                ></i>
            </div>
        `,
                r: () => `
                    <div class="checkbox-container">
                        <input 
                            type="checkbox" 
                            class="status-checkbox" 
                            ${task.p2_b ? "checked" : ""} 
                            onchange="event.target.checked ? (task.p2_b = 1) : (task.p2_b = 0)">
                    </div>
                `,
            },

            Subservico: {
                a: () => task["tarefa"] || "-",
                b: () => task["subservico_obs"] || "-",
                c: () => task["subservico_ude"] || "-",
                d: () => task["vmo_subservico"] || "-",
                e: () => task["vma_subservico"] || "-",
                f: () => task["vlo_subservico"] || "-",
                g: () => task["vt_subservico"] || "-",
                h: () => `
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            ">
            <i
                    class="fas fa-times-circle handle-subservico-excluir"
                    style="color: black; font-size: 12px; cursor: pointer;"
                    title="Excluir Subservico"
                    data-task-id="${task.servico_registro}-${task.subservico_registro}"
                ></i>
            </div>
        `,
            },

            Item_subservico: {
                a: () => `${task["itens"] || ""} / ${task["subitens"] || ""}` || "-",
                b: () => task.f1 !== null && task.f1 !== undefined && task.f1 !== ''
                    ? task.f1.toFixed(3).replace(".", ",")
                    : "",
                c: () => task["u01"] || "-",
                d: () => task.f2 !== null && task.f2 !== undefined && task.f2 !== ''
                    ? task.f2.toFixed(3).replace(".", ",")
                    : "",
                e: () => task["u02"] || "-",
                f: () => task.f3 !== null && task.f3 !== undefined && task.f3 !== ''
                    ? task.f3.toFixed(3).replace(".", ",")
                    : "",
                g: () => task["u03"] || "-",
                h: () => task.f4 !== null && task.f4 !== undefined && task.f4 !== ''
                    ? task.f4.toFixed(3).replace(".", ",")
                    : "",
                i: () => task["u04"] || "-",
                j: () => task.qde !== null && task.qde !== undefined && task.qde !== ''
                    ? task.qde.toFixed(3).replace(".", ",")
                    : "",
                k: () => task["itens_ude"] || "-",
                l: () => task.valor !== null && task.valor !== undefined && task.valor !== ''
                    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(task.valor)
                    : "",
                m: () => task.vt !== null && task.vt !== undefined && task.vt !== ''
                    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(task.vt)
                    : "",
                n: () => task["tipoa"] || "-",
                r: () => `
                    <div class="checkbox-container">
                        <input 
                            type="checkbox" 
                            class="status-checkbox" 
                            ${task.p2_b ? "checked" : ""} 
                            onchange="event.target.checked ? (task.p2_b = 1) : (task.p2_b = 0)">
                    </div>
                `,
            },
        };

        // Recupera o mapeamento com base no tipo de task e campo
        const mappings = fieldMappings[task.tipo];
        return mappings && mappings[field] ? mappings[field]() : "-";
    },

    getColSpan(task, coluna) {
        const colSpanMappings = {
            Servico: {
                o: 1, l: 1, m: 1, n: 1, p: 1, q: 1,
                c: 2, k: 2,
                d: 3, e: 3, f: 3, g: 3, h: 3, i: 3, j: 3,
                b: 21,
                default: 10,
            },
            Item: {
                a: 26, b: 2, c: 3, d: 2, e: 3,
                f: 2, g: 3, h: 2, i: 3, j: 2,
                k: 2, l: 3, m: 3, n: 2,
                o: 1, p: 1, q: 1, r: 1,
                default: 1,
            },
            Subservico: {
                k: 1, j: 1, i: 1, h: 1, l: 1,
                g: 4, f: 4, e: 4, d: 4, c: 4,
                b: 27,
                default: 10,
            },
            Item_subservico: {
                a: 26, b: 2, c: 3, d: 2, e: 3,
                f: 2, g: 3, h: 2, i: 3, j: 2,
                k: 2, l: 3, m: 3, n: 2,
                o: 1, p: 1, q: 1, r: 1,
                default: 1,
            },
        };

        // Verifica o mapeamento baseado no tipo de tarefa e campo
        const mapping = colSpanMappings[task.tipo];
        if (!mapping) {
            return 1; // Retorna um valor padrão caso o tipo não seja encontrado
        }

        // Retorna o valor do colSpan para o campo especificado ou o padrão
        return mapping[coluna.field] !== undefined ? mapping[coluna.field] : mapping.default;
    },

    getTdStyles(task, coluna) {
        const fieldStyles = {
            paddingLeft: {
                Servico: ["a"],
                Subservico: ["a"],
                Item: ["a"],
                Item_subservico: ["a"],
            },
            cursor: {
                Servico: ["a"],
                Subservico: ["a"],
                Item: ["a"],
                Item_subservico: ["a"],
            },
            textAlignRight: {
                Servico: ["f", "g", "h", "i", "j", "l", "m"],
                Subservico: ["d", "e", "f", "g", "h", "i", "j", "l", "m"],
                Item: ["b", "d", "f", "h", "j", "l", "m", "r"],
                Item_subservico: ["b", "d", "f", "h", "j", "l", "m", "r"],
            },
            textAlignCenter: {
                Servico: ["c", "d", "e", "n", "o"],
                Subservico: ["c", "n", "o"],
                Item: ["c", "e", "g", "i", "k", "n", "o", "p", "q"],
                Item_subservico: ["c", "e", "g", "i", "k", "n", "o", "p", "q"],
            },
            textAlignLeft: {
                Servico: ["b"],
                Subservico: ["b"],
            },
            paddingRight: {
                Servico: ["d", "e", "f", "g", "h", "i", "j", "l", "m"],
                Subservico: ["d", "e", "f", "g", "h", "i", "j", "l", "m"],
                Item: ["b", "d", "f", "h", "j", "l", "m", "r"],
                Item_subservico: ["b", "d", "f", "h", "j", "l", "m", "r"],
            },
            backgroundColor: {
                Servico: ["b", "e", "j",  "k"],
                Item: ["b", "c", "d", "e", "f", "g", "h", "i", "l", "n"],
            },
            boxShadow3D: {
                Servico: ["b", "e", "j", "k"],
                Item: ["b", "c", "d", "e", "f", "g", "h", "i", "l", "n"],
            },
        };
    
        const styles = {
            border: "1px solid rgba(0, 0, 0, 0.3)", // Mantém a borda uniforme em todos os elementos
            borderRadius: "4px",
            padding: "5px",
        };
    
        // Verifica e aplica os estilos com base no tipo e campo
        for (const [styleKey, typeFields] of Object.entries(fieldStyles)) {
            for (const [type, fields] of Object.entries(typeFields)) {
                if (task.tipo === type && fields.includes(coluna.field)) {
                    if (styleKey === "paddingLeft") styles.paddingLeft = "5px";
                    if (styleKey === "cursor") styles.cursor = "pointer";
                    if (styleKey === "textAlignRight") styles.textAlign = "right";
                    if (styleKey === "textAlignCenter") styles.textAlign = "center";
                    if (styleKey === "textAlignLeft") styles.textAlign = "left";
                    if (styleKey === "paddingRight") styles.paddingRight = "2px";
                    if (styleKey === "backgroundColor") styles.backgroundColor = "#fcfcf5";
                    
                    // Efeito 3D aprimorado
                    if (styleKey === "boxShadow3D") {
                        styles.boxShadow = "4px 4px 8px rgba(0, 0, 0, 0.3), -4px -4px 8px rgba(255, 255, 255, 0.7)";
                        styles.background = "linear-gradient(145deg, #f0f0e0, #dcdcdc)";
                        styles.transform = "scale(1.00)";
                    }
                }
            }
        }
    
        return styles;
    },
    
    

    getRowClasses(task) {
        const typeToClassMapping = {
            Servico: "descricao-servico-orcamento",
            Item: "descricao-item-orcamento",
            Subservico: "descricao-subservico-orcamento",
            Item_subservico: "descricao-item_subservico-orcamento",
        };

        const classes = [];

        // Adicionar classe para tarefas invisíveis
        if (!task.visible) {
            classes.push(`${task.tipo.toLowerCase()}-hidden`);
        }

        // Adicionar classe específica com base no tipo de tarefa
        if (typeToClassMapping[task.tipo]) {
            classes.push(typeToClassMapping[task.tipo]);
        }

        // Retornar todas as classes combinadas
        return classes.join(" ");
    },

    async carregarTasks() {
        const sucesso = await fetchTasks(this); // Passa `this` como contexto

        console.log("sucesso", sucesso);
        if (!sucesso) {
            console.error("Erro ao carregar tasks.");
        }
    },

    handleTaskClick(task) {
        TaskVisibilityMethods.handleTaskClickA.call(this, task, this.tasks);
    },
}

export const TaskVisibilityMethods = {
    handleDoubleClick() {
        this.expandirTudo();
    },

    expandirTudo() {
        this.tasks.forEach(task => {
            task.visible = true; // Define a visibilidade de todas as tarefas como `true`
        });
        this.saveTasksState(this.tasks); // Salva o estado atualizado
    },

    handleTaskClickA(task, tasks) {
        if (task.tipo === "Servico") {
            if (this.clickTimer) {
                // Limpa o temporizador e chama a função de duplo clique
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
                this.handleDoubleClick(); // Expande tudo no duplo clique
            } else {
                // Define um temporizador para o clique simples
                this.clickTimer = setTimeout(() => {
                    this.clickTimer = null; // Reseta o temporizador
                    this.toggleItemVisibility(task, tasks); // Executa o clique simples

                }, 300); // Tempo em milissegundos (ajuste conforme necessário)
            }
        }

    },

    handleButtonClickAtualizar() {
        // Chama a função `loadTasksState` passando as tarefas do contexto
        if (this.tasks) {
            this.loadTasksState(this.tasks);
        } else {
            console.error("As tarefas não estão disponíveis no contexto.");
        }
    },

    toggleItemVisibility(servicoTask, tasks) {
        if (servicoTask.tipo === "Servico") {
            tasks.forEach((task) => {
                if (task.tipo === "Item" && task.parent === servicoTask.id) {
                    task.visible = !task.visible;

                }
            });
            tasks.forEach((task) => {
                if (task.tipo === "Item_subservico" && task.parent === servicoTask.id) {
                    task.visible = !task.visible;

                }
            });
            tasks.forEach((task) => {
                if (task.tipo === "Subservico" && task.parent === servicoTask.id) {
                    task.visible = !task.visible;

                }
            });
            this.saveTasksState(tasks);
        }
    },

    saveTasksState(tasks) {
        try {
            this.tasksStateLista = tasks.map(task => ({
                id: task.id,
                visible: task.visible,
            }));
            console.log("Estado das tarefas salvo em memória:", this.tasksStateLista);
        } catch (error) {
            console.error("Erro ao salvar estado das tarefas:", error.message);
        }
    },

    loadTasksState(tasks) {
        try {
            if (this.tasksStateLista && this.tasksStateLista.length > 0) {
                // Carregar o estado salvo
                tasks.forEach(task => {
                    const savedTask = this.tasksStateLista.find(t => t.id === task.id);
                    if (savedTask) {
                        task.visible = savedTask.visible; // Aplica o estado salvo
                    } else {
                        // Inicializar tarefas que não têm estado salvo
                        task.visible = task.tipo === 'Servico';
                    }
                });
                //console.log("Estado das tarefas carregado com sucesso:", this.tasksStateLista);
            } else {
                // Nenhum estado salvo encontrado: Serviços expandidos, outros recolhidos
                tasks.forEach(task => {
                    task.visible = task.tipo === 'Servico'; // Expande serviços, recolhe outros
                });
                console.log("Nenhum estado salvo encontrado. Serviços iniciados expandidos, outros níveis recolhidos.");
            }
        } catch (error) {
            console.error("Erro ao carregar estado das tarefas:", error.message);
        }
    }

}

export function LarguraDropdown() {
    document.querySelectorAll(".select-carregar-unidade").forEach((select) => {
        const dropdown = select.querySelector(".multiselect__content-wrapper");
        if (dropdown) {
            dropdown.style.width = `${select.offsetWidth}px`;
        }
    });
}

export const Tarefa = {
    showSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.remove("hidden");
        }
    },

    hideSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.add("hidden");
        }
    },

    async initializeTasks() {
        let tarefa_id;

        // Verifica se this.task é um número ou um objeto
        if (typeof this.task === "number") {

            tarefa_id = this.task;
        } else if (this.task && this.task.tarefa_id) {
            tarefa_id = this.task.tarefa_id;
        } else {
            console.warn("tarefa_id não encontrado no contexto da tarefa.");
            return;
        }
        // Chama fetchObra diretamente
        const tasks = await fetchTasks(tarefa_id);

        // Atualiza o array reativo tasks
        this.tasks = tasks;
    },

    adjustHeaderWidth() {
        const tabelaWrapper = document.querySelector(".tabela-wrapper");
        const header = document.querySelector(".statustarefa-header");

        if (!tabelaWrapper || !header) return;

        const hasScrollbar = tabelaWrapper.scrollHeight > tabelaWrapper.clientHeight;

        // Define a largura baseada na presença da barra de rolagem
        header.style.width = hasScrollbar ? "7.9%" : "5.5%";
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustHeaderWidth); // Remove o listener
    },

    async pesquisarObs(event) {
        if (event.key === 'Enter') {
            try {
                this.showSpinner();
                // Salvar o estado atual das tarefas
                this.saveTasksState(this.tasks);

                // Atualizar o valor da pesquisa
                this.querySubitem = event.target.value;
                state.pesquisarServico.pesquisar2 = this.querySubitem;

                // Buscar novas tarefas
                const tasks = await fetchTasks();

                // Aplicar a lógica: Expandir serviços, recolher outros
                tasks.forEach(task => {
                    task.visible = task.tipo === 'Servico'; // Serviços expandidos, outros recolhidos
                });

                // Atualizar as tarefas no estado
                this.tasks = tasks;

                //console.log("Pesquisa realizada com sucesso:", tasks);
            } catch (error) {
                console.error("Erro ao realizar a pesquisa:", error.message);
            } finally {
                this.hideSpinner();
            }
        }
    },

    async pesquisarServico(event) {
        // Verifica se a tecla pressionada é 'Enter'
        if (event.key === 'Enter') {
            try {
                this.showSpinner();
                // Salva o estado atual das tarefas
                this.saveTasksState(this.tasks);

                // Atualiza o valor da pesquisa
                this.queryServico = event.target.value;
                state.pesquisarServico.pesquisar1 = this.queryServico;

                // Busca novas tarefas
                const tasks = await fetchTasks();

                // Expande serviços (visible true) e recolhe outros
                tasks.forEach(task => {
                    task.visible = task.tipo === 'Servico'; // somente os serviços são expandidos
                });

                // Atualiza as tarefas no estado
                this.tasks = tasks;
                //console.log("Pesquisa realizada com sucesso:", tasks);
            } catch (error) {
                console.error("Erro ao realizar a pesquisa:", error.message);
            } finally {
                this.hideSpinner();
            }
        }
    },

    async pesquisarFechada(isChecked) {
        const fechada = isChecked ? 1 : 0;
        //console.log("fechada:", fechada);
        try {
            // Chama fetchObra sem sobrescrever outros valores
            const tasks = await fetchTasks(state.pesquisarTarefa.tarefa_id, state.pesquisarTarefa.pesquisar1, state.pesquisarTarefa.pesquisar2, fechada);

            this.tasks = tasks;

            //console.log("Tarefas encontradas com status fechado:", this.tasks);
        } catch (error) {
            console.error("Erro ao buscar tarefas com status fechado:", error);
        }
    },

    TarefaSelecionado(task) {
        //console.warn("Tarefa selecionada:", task);

        if (task) {
            // Atualiza o estado global
            state.EditarTarefaCronograma.tarefa = task.tarefa;
            state.EditarTarefaCronograma.tarefa_id = task.tarefa_id;

            // Chama a função para atualizar os campos
            atualizarCampos(this);

            // Emite o evento e fecha o modal
            this.$emit("fecharModalTarefa", {});
            this.fecharModal();
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    fecharModal() {
        this.$emit("close"); // Emite o evento de fechamento do modal
    },

    enableAdiciona(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.tarefa) {
            this.adicionarTarefa(task,);
        }

    },

    enableEdit(task) {
        // Define o ID da tarefa que está sendo editada
        this.editingTaskId = task.tarefa_id;
    },

    enableExclui(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.tarefa) {
            this.excluirTarefa(task,);
        }
    },

    EnterTarefaEditar(task) {
        this.editingTaskId = null;
        // Ação ao pressionar Enter
        this.TarefaEditar(task);

        // Move o foco para o próximo elemento
        this.$nextTick(() => {
            const inputs = document.querySelectorAll('.editable-cell'); // Seleciona todos os campos
            const currentIndex = Array.from(inputs).findIndex(
                (input) => input.id === `input-${task.id}`
            );

            if (currentIndex >= 0 && inputs[currentIndex + 1]) {
                inputs[currentIndex + 1].focus(); // Define o foco no próximo campo
            }
        });

        this.isTyping = null;
    },

    TarefaEditar(task) {
        // Apenas chama a função se houver alteração
        if (this.editableValue !== task.tarefa) {
            this.salvarTarefa(task,);
        }
    },

    editarStatusTarefa(task) {
        if (this.editableValue !== task.statustarefa) {
            this.salvarTarefa(task);
        }
    },

    salvarTarefa(task) {
        try {
            SalvarTarefa(
                { tarefa_id: task.tarefa_id, tarefa: task.tarefa, st: task.statustarefa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar tarefa:", error);
        }
    },

    adicionarTarefa(task) {
        try {
            AdicionarTarefa(
                { tarefa_id: task.tarefa_id, tarefa: task.tarefa, st: task.statustarefa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar tarefa:", error);
        }
    },

    excluirTarefa(task) {
        try {
            ExcluirTarefa(
                { tarefa_id: task.tarefa_id, tarefa: task.tarefa, st: task.statustarefa },
                this // Passa o contexto atual do Vue
            );
        } catch (error) {
            console.error("Erro ao salvar tarefa:", error);
        }
    },
}

export const methods = {
    showSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.remove("hidden");
        }
    },

    hideSpinner() {
        const spinner = document.getElementById("global-spinner");
        if (spinner) {
            spinner.classList.add("hidden");
        }
    },

    async initializeTasks() {
        if (this.isLoading) {
            console.warn("Já há uma operação em andamento. Ignorando chamada duplicada.");
            return;
        }

        this.showSpinner();

        try {
            this.tasks = [];
            //console.log("Tasks esvaziadas antes de carregar.");

            const sucesso = await this.fetchTasks();
            if (sucesso && sucesso.length > 0) {
                this.tasks = sucesso;
                this.loadTasksState(this.tasks);
                //console.log("Tarefas carregadas no this.tasks:", this.tasks);
            } else {
                console.error("Falha ao inicializar tarefas.");
            }
        } catch (error) {
            console.error("Erro durante a inicialização das tarefas:", error.message);
        } finally {
            this.hideSpinner();
        }
    },

    async fetchTasks() {
        if (isFetching) {
            console.warn("fetchTasks já em execução, ignorando nova chamada.");
            return [];
        }

        isFetching = true;

        try {
            const response = await api.get(`/lista`, {
                params: {
                    pesquisar2: state.pesquisarServico.pesquisar2,
                    pesquisar1: state.pesquisarServico.pesquisar1,
                },
            });

            //console.log("Resposta recebida da API:", response.data);

            if (!response.data || response.data.length === 0) {
                console.warn("Nenhum dado foi retornado da API.");
                return [];
            }

            const estruturaArvore = criarEstruturaArvore(response.data);
            //console.log("Estrutura da árvore gerada:", estruturaArvore);

            const tarefasProcessadas = await mapDados(estruturaArvore);
            //console.log("Tarefas processadas:", tarefasProcessadas);

            return tarefasProcessadas;
        } catch (error) {
            console.error("Erro ao buscar os dados do orçamento:", error.message);
            return [];
        } finally {
            isFetching = false;
        }
    },

    mapAndSortTasks(tasks) {
        // Mapeia os dados das tarefas
        const mappedTasks = tasks.map((task) => this.mapTaskData(task));

        // Ordenar as tasks
        mappedTasks.sort((a, b) => {
            // Comparar servico
            if (a.servico < b.servico) return -1;
            if (a.servico > b.servico) return 1;

            // Comparar servico_obs
            if (a.servico_obs < b.servico_obs) return -1;
            if (a.servico_obs > b.servico_obs) return 1;

            // Comparar servico_rev
            return a.servico_rev - b.servico_rev;
        });

        return mappedTasks;
    },

    fecharServicoModal() {
        this.isModalServicoVisible = false; // Fecha o modal
    },

    fecharItemModal() {
        this.isModalItemVisible = false; // Fecha o modal
    },

    handleGlobalClick(event) {

        const icon_servico_editar = event.target.closest('.handle-Servico-modal-editar');
        if (icon_servico_editar) {
            const taskId = icon_servico_editar.getAttribute('data-task-id');
            this.handleServicoModalEditar(taskId);
        }

        const icon_servico_excluir = event.target.closest('.handle-Servico-modal-excluir');
        if (icon_servico_excluir) {
            const taskId = icon_servico_excluir.getAttribute('data-task-id');
            this.handleServicoExcluir(taskId);
        }

        const icon_subservico_adicionar = event.target.closest('.handle-Subservico-adicionar');
        if (icon_subservico_adicionar) {
            // Captura o ID da tarefa
            const servico_registro = icon_subservico_adicionar.getAttribute('data-task-id');
            //console.log("taskId:", servico_registro);

            // Localiza os inputs relacionados pelo `data-task-id`
            const inputValor1 = document.querySelector(`input.input-subservico_registro[data-task-id="${servico_registro}"]`);
            const inputValor2 = document.querySelector(`input.input-subservico_fator[data-task-id="${servico_registro}"]`);
            console.log("inputValor1", inputValor1);
            console.log("inputValor2", inputValor2);

            // Obtém os valores dos inputs
            const subservico_registro = inputValor1?.value || null;
            const fator = inputValor2?.value || null;

            //console.log("Valor 1:", subservico_registro);
            //console.log("Valor 2:", fator);

            // Exemplo: Chama uma função para processar os valores capturados
            this.handleSubservicoAdicionar(servico_registro, fator, subservico_registro);
        }

        const icon_item_editar = event.target.closest('.handle-Item-modal-editar');
        if (icon_item_editar) {
            const taskId = icon_item_editar.getAttribute('data-task-id');
            this.handleItemModalEditar(taskId);
        }

        const icon_item_adicionar = event.target.closest('.handle-Item-adicionar');
        if (icon_item_adicionar) {
            const taskId = icon_item_adicionar.getAttribute('data-task-id');
            this.handleItemAdicionar(taskId);
        }

        const icon_item_excluir = event.target.closest('.handle-Item-excluir');
        if (icon_item_excluir) {
            const dataTaskId = icon_item_excluir.getAttribute('data-task-id');
            if (dataTaskId) {
                const [item_registro, id] = dataTaskId.split('-'); // Usa o delimitador para dividir os valores
                this.handleItemExcluir({ item_registro, id }); // Passa ambos os valores para a função
            }
        }
        const icon_subservico_excluir = event.target.closest('.handle-subservico-excluir');
        if (icon_subservico_excluir) {
            const dataTaskId = icon_subservico_excluir.getAttribute('data-task-id');
            if (dataTaskId) {
                const [servico_registro, subservico_registro] = dataTaskId.split('-'); // Usa o delimitador para dividir os valores
                this.handleSubservicoExcluir({ servico_registro, subservico_registro }); // Passa ambos os valores para a função
            }
        }
        const icon_servico_copiar = event.target.closest('.handle-Servico-copiar');
        if (icon_servico_copiar) {
            const taskId = icon_servico_copiar.getAttribute('data-task-id');
            this.handleServicoCopiar(taskId);
        }

        const icon_adicionar_servico_orcamento = event.target.closest('.handle-Servico-adicionar-orcamento');
        if (icon_adicionar_servico_orcamento) {
            const taskId = icon_adicionar_servico_orcamento.getAttribute('data-task-id');
            this.handleServicoOrcamento(taskId);
        }

    },

    async handleServicoAdicionar() {
        if (this.isLoading) {
            console.warn("Outra operação já está em andamento. Ignorando a chamada.");
            return;
        }

        // Ativa o carregador global antes da execução
        this.showSpinner();
        try {
            // Chama a função Servico_adicionar com o contexto atual
            await Servico_adicionar.call(this);
            console.log("Serviço adicionado com sucesso.");
        } catch (error) {
            console.error("Erro ao adicionar o serviço:", error.message);
        } finally {
            // Finaliza o spinner global
            this.hideSpinner();
            console.log("Spinner desativado: operação de adicionar serviço finalizada.");
        }
    },

    handleServicoModalEditar(taskId) {
        const task = this.tasks.find((t) => t.servico_registro === Number(taskId));

        if (task) {
            this.selectedTask = task; // Define a tarefa selecionada
            state.EditarLista.servico_id = task.servico_registro;

            this.pageContext = { // Inclui o contexto da página
                tasks: this.tasks,
            };
            //console.log("this.pageContext", this.pageContext);    
            this.isModalServicoVisible = true; // Exibe o modal
        } else {
            console.warn("Tarefa não encontrada para o ID:", taskId);
        }

    },

    async handleServicoExcluir(taskId) {
        this.showSpinner(); // Ativa o spinner imediatamente
    
        try {
            const task = this.tasks.find((t) => t.servico_registro === Number(taskId));
    
            if (task) {
                // Define a tarefa selecionada
                state.EditarLista.servico_id = task.servico_registro;
    
                // Aguarda a execução da função antes de ocultar o spinner
                await Servico_excluir.call(this);
                console.log("Serviço excluído com sucesso.");
            } else {
                console.warn("Tarefa não encontrada para o ID:", taskId);
            }
    
        } catch (error) {
            console.error("Erro ao excluir serviço:", error);
        } finally {
            setTimeout(() => this.hideSpinner(), 300); // Pequeno delay para evitar fechamento prematuro
        }
    },
    

    handleServicoOrcamento(taskId) {
        const task = this.tasks.find((t) => t.servico_registro === Number(taskId));

        if (task) {
            // Adiciona os métodos de carregador ao contexto, se ainda não existirem
            this.context.showSpinner = this.context.showSpinner || (() => {
                const spinner = document.getElementById("global-spinner");
                if (spinner) spinner.classList.remove("hidden");
                //console.log("Carregador ativado: iniciando edição do serviço.");
            });

            this.context.hideSpinner = this.context.hideSpinner || (() => {
                const spinner = document.getElementById("global-spinner");
                if (spinner) spinner.classList.add("hidden");
                //console.log("Carregador desativado: operação concluída.");
            });

            // Ativa o carregador global antes da operação
            this.context.showSpinner();

            // Chama a função intermediária
            handleServicoOrcamentoAdicionar(task, this.context)
                .then(() => {
                    console.log("Serviço editado com sucesso.");
                })
                .catch((error) => {
                    console.error("Erro ao editar o serviço:", error.message);
                })
                .finally(() => {
                    this.$emit("fecharModalLista", {}); // Emite o evento para fechar o modal
                    this.fecharModal(); // Fecha o modal
                    this.context.hideSpinner(); // Garante que o carregador será desativado
                });
        } else {
            console.warn("Nenhuma tarefa foi selecionada.");
        }
    },

    async handleItemModalEditar(taskId) {
        this.showSpinner(); // Ativa o spinner imediatamente

        try {
            //console.log("Editando item:", taskId);

            // Garante que tasks estejam carregadas antes de procurar a tarefa
            if (!this.tasks || this.tasks.length === 0) {
                console.warn("A lista de tarefas está vazia.");
                return;
            }

            // Localiza a tarefa selecionada
            const task = this.tasks.find((t) => t.itens_registro === Number(taskId));

            if (task) {
                this.selectedTask = task; // Define a tarefa selecionada
                state.EditarLista.id = task.trabalho_registro;
                //console.log("task.trabalho_registro:", task.trabalho_registro);

                this.pageContext = { // Inclui o contexto da página
                    tasks: this.tasks,
                };

                this.isModalItemVisible = true; // Exibe o modal

                //console.log("Tarefa encontrada e modal exibido:", task);

                // Garante que o Vue atualize a interface antes de esconder o spinner
                await this.$nextTick();
            } else {
                console.warn("Tarefa não encontrada para o ID:", taskId);
            }

        } catch (error) {
            console.error("Erro ao editar item:", error);
        } finally {
            setTimeout(() => this.hideSpinner(), 300); // Adiciona um pequeno delay para evitar fechamento prematuro
        }
    },

    async handleSubservicoAdicionar(servico_registro, subservico_fator, subservico_registro) {
        this.showSpinner(); // Ativa o spinner imediatamente
    
        try {
            // Encontra a tarefa correspondente
            const task = this.tasks.find((t) => t.servico_registro === Number(servico_registro));
    
            if (task) {
                // Define os valores nos estados ou chama funções conforme necessário
                state.EditarLista.servico_id = task.servico_registro;
                state.EditarLista.servico_unidade_id = task.servico_ude_id;
                state.EditarLista.subservico_fator = subservico_fator; // Supondo que você precise salvar o fator
                state.EditarLista.subservico_id = subservico_registro; // Supondo que você precise salvar o subserviço
    
                // Aguarda a execução da função antes de ocultar o spinner
                await Subservico_adicionar.call(this);
    
            } else {
                console.warn("Tarefa não encontrada para o ID:", servico_registro);
            }
    
        } catch (error) {
            console.error("Erro ao adicionar subserviço:", error);
        } finally {
            setTimeout(() => this.hideSpinner(), 300); // Adiciona um pequeno delay para evitar fechamento prematuro
        }
    },
    
    async onEnterServicoObs(servico_registro) {
        const div = document.querySelector(`.input-servico_obs[data-task-id="${servico_registro}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const servico_obs = div.textContent.trim();
                console.log(`Servico Registro: ${servico_registro}, Observação: ${servico_obs}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarServicoObs({ servico_registro, servico_obs }, { tasks: this.tasks });

            } catch (error) {
                console.error("Erro ao salvar serviço:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${servico_registro}" não encontrado.`);
        }
    },

    onEnterServicoRev(servico_registro) {
        const div = document.querySelector(`.input-servico_rev[data-task-id="${servico_registro}"]`);
        if (div) {
            const servico_rev = div.textContent.trim();
            //console.log(`Servico Registro: ${servico_registro}, Observação: ${servico_rev}`);

            // Enviar para o backend ou processar os dados
            SalvarServicoRev.call(this, { servico_registro, servico_rev });
        } else {
            console.warn(`Elemento com data-task-id="${servico_registro}" não encontrado.`);
        }
    },

    async onEnterItem_f1(id) {
        const div = document.querySelector(`.input-item_f1[data-task-id="${id}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const f1 = div.textContent.trim();
                //console.log(`ID: ${id}, F1: ${f1}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarItemf1.call(this, { id, f1 });

            } catch (error) {
                console.error("Erro ao salvar item_f1:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
        }
    },

    async onEnterItem_f2(id) {
        // console.log("id", id);
        const div = document.querySelector(`.input-item_f2[data-task-id="${id}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const f2 = div.textContent.trim();
                console.log(`ID: ${id}, F2: ${f2}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarItemf2.call(this, { id, f2 });

            } catch (error) {
                console.error("Erro ao salvar item_f2:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
        }
    },

    async onEnterItem_f3(id) {
        //console.log("id", id);
        const div = document.querySelector(`.input-item_f3[data-task-id="${id}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const f3 = div.textContent.trim();
                console.log(`ID: ${id}, F3: ${f3}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarItemf3.call(this, { id, f3 });

            } catch (error) {
                console.error("Erro ao salvar item_f3:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
        }
    },

    async onEnterItem_f4(id) {
        //console.log("id", id);
        const div = document.querySelector(`.input-item_f4[data-task-id="${id}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const f4 = div.textContent.trim();
                console.log(`ID: ${id}, F4: ${f4}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarItemf4.call(this, { id, f4 });

            } catch (error) {
                console.error("Erro ao salvar item_f4:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
        }
    },

    async onEnterItem_valor(id) {
        //console.log("id", id);
        const div = document.querySelector(`.input-item_valor[data-task-id="${id}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const valor = div.textContent.trim();
                console.log(`ID: ${id}, Valor: ${valor}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarItemvalor.call(this, { id, valor });

            } catch (error) {
                console.error("Erro ao salvar item_valor:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
        }
    },

    async onEnterItem_tipo(id) {
        console.log("id", id);
        const div = document.querySelector(`.input-item_tipo[data-task-id="${id}"]`);

        if (div) {
            this.showSpinner(); // Ativa o spinner

            try {
                const tipo = div.textContent.trim();
                console.log(`ID: ${id}, Tipo: ${tipo}`);

                // Aguarda a resposta da função antes de ocultar o spinner
                await SalvarItemtipo.call(this, { id, tipo });

            } catch (error) {
                console.error("Erro ao salvar item_tipo:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

        } else {
            console.warn(`Elemento com data-task-id="${id}" não encontrado.`);
        }
    },

    async handleItemAdicionar(id) {
        //console.log("id", id);

        this.showSpinner(); // Ativa o spinner

        try {
            // Aguarda a resposta da função antes de ocultar o spinner
            await AdicionarItem.call(this, { id });

        } catch (error) {
            console.error("Erro ao adicionar item:", error);
        } finally {
            this.hideSpinner(); // Garante que o spinner será desativado após a operação
        }
    },

    async handleItemExcluir(task) {
        //console.log("Excluindo item:", task);

        this.showSpinner(); // Ativa o spinner

        try {
            // Aguarda a exclusão do item antes de ocultar o spinner
            await ExcluirItem.call(this, { task });

        } catch (error) {
            console.error("Erro ao excluir item:", error);
        } finally {
            this.hideSpinner(); // Garante que o spinner será desativado após a operação
        }
    },

    async handleSubservicoExcluir(task) {
        //console.log("Excluindo subserviço:", task);

        this.showSpinner(); // Ativa o spinner

        try {
            // Aguarda a exclusão do subserviço antes de ocultar o spinner
            await ExcluirSubservico.call(this, { task });

        } catch (error) {
            console.error("Erro ao excluir subserviço:", error);
        } finally {
            this.hideSpinner(); // Garante que o spinner será desativado após a operação
        }
    },

    async handleServicoCopiar(id) {
        //console.log("Copiando serviço:", id);

        this.showSpinner(); // Ativa o spinner

        try {
            // Aguarda a cópia do serviço antes de ocultar o spinner
            await ServicoCopiar.call(this, { id });

        } catch (error) {
            console.error("Erro ao copiar serviço:", error);
        } finally {
            this.hideSpinner(); // Garante que o spinner será desativado após a operação
        }
    }

}

export const u01 = {
    addClickOutsideListenerU01() {
        if (!this.handleClickOutsideU01Bound) {
            this.handleClickOutsideU01Bound = this.handleClickOutsideU01.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideU01Bound);
        //console.log("Listener de clique fora adicionado.");
    },

    removeClickOutsideListenerU01() {
        if (this.handleClickOutsideU01Bound) {
            document.removeEventListener("click", this.handleClickOutsideU01Bound);
            //console.log("Listener de clique fora removido.");
        }
    },

    closeDropdownU01() {
        //console.log("Fechando dropdown para ID:", this.editingTaskIdU01);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdU01 = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenU01 = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerU01();

        //console.log("Dropdown fechado.");
    },

    handleClickOutsideU01(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdU01) {
            //console.log("Nenhum dropdown está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-u01="${this.editingTaskIdU01}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown não encontrado, fechando.");
            this.closeDropdownU01();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown. Fechando dropdown:", this.editingTaskIdU01);
            this.closeDropdownU01();
        }
    },

    enableU01Edit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdU01 = task.trabalho_registro; // Define o ID do dropdown sendo editado
            this.editableValueU01 = task.u01 || ""; // Preenche o valor inicial do campo
            this.filterU01Options(); // Filtra as opções
            this.addClickOutsideListenerU01(); // Adiciona o listener para clique fora

            //console.log("Dropdown aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchU01Options() {
        await fetchU01Options(this);
    },

    updateFilteredOptionsU01() {
        if (!this.editableValueU01) {
            // Se editableValueU01 for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsU01 = this.u01Options.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueU01 = this.editableValueU01.toLowerCase().trim();


        this.filteredOptionsU01 = this.u01Options.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueU01);
        });
    },

    handleU01ItemClick(option) {
        this.selectU01Option(option);
        this.editingTaskIdU01 = null;
    },

    filterU01Options() {
        setTimeout(() => {
            // Remove Proxies para trabalhar com dados brutos
            const rawOptionsU01 = this.u01Options.map(option => toRaw(option));

            if (!this.editableValueU01 || this.editableValueU01.trim() === "") {
                // Mostra todas as opções
                this.filteredOptionsU01 = rawOptionsU01.map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
            } else {
                // Filtra com base no texto inserido
                this.filteredOptionsU01 = rawOptionsU01
                    .filter(option => {
                        // Certifique-se de que option.label existe e é uma string
                        if (typeof option.label !== "string") {
                            //console.warn("Option label is not a string:", option);
                            return false; // Exclui itens inválidos
                        }

                        return option.label.toLowerCase().includes(this.editableValueU01.toLowerCase());
                    })
                    .map(option => ({
                        label: option.label || "", // Garante que label seja uma string
                        value: option.value || null,
                    }));
            }
            //console.log("Opções filtradas:", this.filteredOptionsU01);
        }, 500);
    },

    async selectU01Option(option) {
        this.editableValueU01 = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.trabalho_registro === this.editingTaskIdU01);

        if (task) {
            this.showSpinner(); // Ativa o spinner

            try {
                task.u01 = option.label; // Nome da unidade
                task.u01_id = option.value; // ID da unidade

                const context = {
                    id: task.trabalho_registro, // ID da tarefa
                    u01: task.u01, // Nome da unidade
                    unidade_id: task.u01_id, // ID da unidade
                };

                //console.log("Contexto enviado para edição:", context);

                // Aguarda a resposta da função antes de ocultar o spinner
                await EditarU01.call(this, context);
                //console.log("Tarefa atualizada com sucesso.");

            } catch (error) {
                console.error("Erro ao atualizar a tarefa:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

            this.editingTaskIdU01 = null; // Desativa a edição
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdU01);
        }
    }

}

export const u02 = {
    addClickOutsideListenerU02() {
        if (!this.handleClickOutsideU02Bound) {
            this.handleClickOutsideU02Bound = this.handleClickOutsideU02.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideU02Bound);
        //console.log("Listener de clique fora adicionado.");
    },

    removeClickOutsideListenerU02() {
        if (this.handleClickOutsideU02Bound) {
            document.removeEventListener("click", this.handleClickOutsideU02Bound);
            //console.log("Listener de clique fora removido.");
        }
    },

    closeDropdownU02() {
        //console.log("Fechando dropdown para ID:", this.editingTaskIdU02);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdU02 = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenU02 = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerU02();

        //console.log("Dropdown fechado.");
    },

    handleClickOutsideU02(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdU02) {
            //console.log("Nenhum dropdown está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-u02="${this.editingTaskIdU02}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown não encontrado, fechando.");
            this.closeDropdownU02();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown. Fechando dropdown:", this.editingTaskIdU02);
            this.closeDropdownU02();
        }
    },

    enableU02Edit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdU02 = task.trabalho_registro; // Define o ID do dropdown sendo editado
            this.editableValueU02 = task.u02 || ""; // Preenche o valor inicial do campo
            this.filterU02Options(); // Filtra as opções
            this.addClickOutsideListenerU02(); // Adiciona o listener para clique fora

            //console.log("Dropdown aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchU02Options() {
        await fetchU02Options(this);
    },

    updateFilteredOptionsU02() {
        if (!this.editableValueU02) {
            // Se editableValueU02 for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsU02 = this.u02Options.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueU02 = this.editableValueU02.toLowerCase().trim();


        this.filteredOptionsU02 = this.u02Options.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueU02);
        });
    },

    handleU02ItemClick(option) {
        this.selectU02Option(option);
        this.editingTaskIdU02 = null;
    },

    filterU02Options() {
        setTimeout(() => {
            // Remove Proxies para trabalhar com dados brutos
            const rawOptionsU02 = this.u02Options.map(option => toRaw(option));

            if (!this.editableValueU02 || this.editableValueU02.trim() === "") {
                // Mostra todas as opções
                this.filteredOptionsU02 = rawOptionsU02.map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
            } else {
                // Filtra com base no texto inserido
                this.filteredOptionsU02 = rawOptionsU02
                    .filter(option => {
                        // Certifique-se de que option.label existe e é uma string
                        if (typeof option.label !== "string") {
                            //console.warn("Option label is not a string:", option);
                            return false; // Exclui itens inválidos
                        }

                        return option.label.toLowerCase().includes(this.editableValueU02.toLowerCase());
                    })
                    .map(option => ({
                        label: option.label || "", // Garante que label seja uma string
                        value: option.value || null,
                    }));
            }
            //console.log("Opções filtradas:", this.filteredOptionsU02);
        }, 500);
    },

    async selectU02Option(option) {
        this.editableValueU02 = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.trabalho_registro === this.editingTaskIdU02);

        if (task) {
            this.showSpinner(); // Ativa o spinner

            try {
                task.u02 = option.label; // Nome da unidade
                task.u02_id = option.value; // ID da unidade

                const context = {
                    id: task.trabalho_registro, // ID da tarefa
                    u02: task.u02, // Nome da unidade
                    unidade_id: task.u02_id, // ID da unidade
                };

                //console.log("Contexto enviado para edição:", context);

                // Aguarda a resposta da função antes de ocultar o spinner
                await EditarU02.call(this, context);
                //console.log("Tarefa atualizada com sucesso.");

            } catch (error) {
                console.error("Erro ao atualizar a tarefa:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

            this.editingTaskIdU02 = null; // Desativa a edição
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdU02);
        }
    }

}

export const u03 = {
    addClickOutsideListenerU03() {
        if (!this.handleClickOutsideU03Bound) {
            this.handleClickOutsideU03Bound = this.handleClickOutsideU03.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideU03Bound);
        //console.log("Listener de clique fora adicionado.");
    },

    removeClickOutsideListenerU03() {
        if (this.handleClickOutsideU03Bound) {
            document.removeEventListener("click", this.handleClickOutsideU03Bound);
            //console.log("Listener de clique fora removido.");
        }
    },

    closeDropdownU03() {
        //console.log("Fechando dropdown para ID:", this.editingTaskIdU03);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdU03 = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenU03 = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerU03();

        //console.log("Dropdown fechado.");
    },

    handleClickOutsideU03(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdU03) {
            //console.log("Nenhum dropdown está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-u03="${this.editingTaskIdU03}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown não encontrado, fechando.");
            this.closeDropdownU03();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown. Fechando dropdown:", this.editingTaskIdU03);
            this.closeDropdownU03();
        }
    },

    enableU03Edit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdU03 = task.trabalho_registro; // Define o ID do dropdown sendo editado
            this.editableValueU03 = task.u03 || ""; // Preenche o valor inicial do campo
            this.filterU03Options(); // Filtra as opções
            this.addClickOutsideListenerU03(); // Adiciona o listener para clique fora

            //console.log("Dropdown aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchU03Options() {
        await fetchU03Options(this);
    },

    updateFilteredOptionsU03() {
        if (!this.editableValueU03) {
            // Se editableValueU03 for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsU03 = this.u03Options.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueU03 = this.editableValueU03.toLowerCase().trim();


        this.filteredOptionsU03 = this.u03Options.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueU03);
        });
    },

    handleU03ItemClick(option) {
        this.selectU03Option(option);
        this.editingTaskIdU03 = null;
    },

    filterU03Options() {
        setTimeout(() => {
            // Remove Proxies para trabalhar com dados brutos
            const rawOptionsU03 = this.u03Options.map(option => toRaw(option));

            if (!this.editableValueU03 || this.editableValueU03.trim() === "") {
                // Mostra todas as opções
                this.filteredOptionsU03 = rawOptionsU03.map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
            } else {
                // Filtra com base no texto inserido
                this.filteredOptionsU03 = rawOptionsU03
                    .filter(option => {
                        // Certifique-se de que option.label existe e é uma string
                        if (typeof option.label !== "string") {
                            //console.warn("Option label is not a string:", option);
                            return false; // Exclui itens inválidos
                        }

                        return option.label.toLowerCase().includes(this.editableValueU03.toLowerCase());
                    })
                    .map(option => ({
                        label: option.label || "", // Garante que label seja uma string
                        value: option.value || null,
                    }));
            }
            //console.log("Opções filtradas:", this.filteredOptionsU03);
        }, 500);
    },

    async selectU03Option(option) {
        this.editableValueU03 = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.trabalho_registro === this.editingTaskIdU03);

        if (task) {
            this.showSpinner(); // Ativa o spinner

            try {
                task.u03 = option.label; // Nome da unidade
                task.u03_id = option.value; // ID da unidade

                const context = {
                    id: task.trabalho_registro, // ID da tarefa
                    u03: task.u03, // Nome da unidade
                    unidade_id: task.u03_id, // ID da unidade
                };

                //console.log("Contexto enviado para edição:", context);

                // Aguarda a resposta da função antes de ocultar o spinner
                await EditarU03.call(this, context);
                //console.log("Tarefa atualizada com sucesso.");

            } catch (error) {
                console.error("Erro ao atualizar a tarefa:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

            this.editingTaskIdU03 = null; // Desativa a edição
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdU03);
        }
    }

}

export const u04 = {
    addClickOutsideListenerU04() {
        if (!this.handleClickOutsideU04Bound) {
            this.handleClickOutsideU04Bound = this.handleClickOutsideU04.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideU04Bound);
        //console.log("Listener de clique fora adicionado.");
    },

    removeClickOutsideListenerU04() {
        if (this.handleClickOutsideU04Bound) {
            document.removeEventListener("click", this.handleClickOutsideU04Bound);
            //console.log("Listener de clique fora removido.");
        }
    },

    closeDropdownU04() {
        //console.log("Fechando dropdown para ID:", this.editingTaskIdU04);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdU04 = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenU04 = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerU04();

        //console.log("Dropdown fechado.");
    },

    handleClickOutsideU04(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdU04) {
            //console.log("Nenhum dropdown está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-u04="${this.editingTaskIdU04}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown não encontrado, fechando.");
            this.closeDropdownU04();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown. Fechando dropdown:", this.editingTaskIdU04);
            this.closeDropdownU04();
        }
    },

    enableU04Edit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdU04 = task.trabalho_registro; // Define o ID do dropdown sendo editado
            this.editableValueU04 = task.u04 || ""; // Preenche o valor inicial do campo
            this.filterU04Options(); // Filtra as opções
            this.addClickOutsideListenerU04(); // Adiciona o listener para clique fora

            //console.log("Dropdown aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchU04Options() {
        await fetchU04Options(this);
    },

    updateFilteredOptionsU04() {
        if (!this.editableValueU04) {
            // Se editableValueU04 for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsU04 = this.u04Options.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueU04 = this.editableValueU04.toLowerCase().trim();


        this.filteredOptionsU04 = this.u04Options.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueU04);
        });
    },

    handleU04ItemClick(option) {
        this.selectU04Option(option);
        this.editingTaskIdU04 = null;
    },

    filterU04Options() {
        setTimeout(() => {
            // Remove Proxies para trabalhar com dados brutos
            const rawOptionsU04 = this.u04Options.map(option => toRaw(option));

            if (!this.editableValueU04 || this.editableValueU04.trim() === "") {
                // Mostra todas as opções
                this.filteredOptionsU04 = rawOptionsU04.map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
            } else {
                // Filtra com base no texto inserido
                this.filteredOptionsU04 = rawOptionsU04
                    .filter(option => {
                        // Certifique-se de que option.label existe e é uma string
                        if (typeof option.label !== "string") {
                            //console.warn("Option label is not a string:", option);
                            return false; // Exclui itens inválidos
                        }

                        return option.label.toLowerCase().includes(this.editableValueU04.toLowerCase());
                    })
                    .map(option => ({
                        label: option.label || "", // Garante que label seja uma string
                        value: option.value || null,
                    }));
            }
            //console.log("Opções filtradas:", this.filteredOptionsU04);
        }, 500);
    },

    async selectU04Option(option) {
        this.editableValueU04 = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.trabalho_registro === this.editingTaskIdU04);

        if (task) {
            this.showSpinner(); // Ativa o spinner

            try {
                task.u04 = option.label; // Nome da unidade
                task.u04_id = option.value; // ID da unidade

                const context = {
                    id: task.trabalho_registro, // ID da tarefa
                    u04: task.u04, // Nome da unidade
                    unidade_id: task.u04_id, // ID da unidade
                };

                //console.log("Contexto enviado para edição:", context);

                // Aguarda a resposta da função antes de ocultar o spinner
                await EditarU04.call(this, context);
                //console.log("Tarefa atualizada com sucesso.");

            } catch (error) {
                console.error("Erro ao atualizar a tarefa:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

            this.editingTaskIdU04 = null; // Desativa a edição
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdU04);
        }
    }

}

export const u05 = {
    addClickOutsideListenerU05() {
        if (!this.handleClickOutsideU05Bound) {
            this.handleClickOutsideU05Bound = this.handleClickOutsideU05.bind(this);
        }

        // Adiciona o evento de clique apenas uma vez
        document.addEventListener("click", this.handleClickOutsideU05Bound);
        //console.log("Listener de clique fora adicionado.");
    },

    removeClickOutsideListenerU05() {
        if (this.handleClickOutsideU05Bound) {
            document.removeEventListener("click", this.handleClickOutsideU05Bound);
            //console.log("Listener de clique fora removido.");
        }
    },

    closeDropdownU05() {
        //console.log("Fechando dropdown para ID:", this.editingTaskIdU05);

        // Redefine o ID do dropdown aberto
        this.editingTaskIdU05 = null;

        // Atualiza o estado de visibilidade do dropdown
        this.isDropdownOpenU05 = false;

        // Remove o listener de clique fora
        this.removeClickOutsideListenerU05();

        //console.log("Dropdown fechado.");
    },

    handleClickOutsideU05(event) {
        // Verifica se há um dropdown aberto
        if (!this.editingTaskIdU05) {
            //console.log("Nenhum dropdown está aberto. Ignorando clique.");
            return;
        }

        // Identifica o wrapper do dropdown aberto
        const dropdownWrapper = document.querySelector(`[data-dropdown-id-u05="${this.editingTaskIdU05}"]`);

        if (!dropdownWrapper) {
            //console.warn("Dropdown não encontrado, fechando.");
            this.closeDropdownU05();
            return;
        }

        // Verifica se o clique foi fora do dropdown atual
        if (!dropdownWrapper.contains(event.target)) {
            //console.log("Clique fora do dropdown. Fechando dropdown:", this.editingTaskIdU05);
            this.closeDropdownU05();
        }
    },

    enableU05Edit(task) {
        if (this.doubleClickTimeout) {
            clearTimeout(this.doubleClickTimeout); // Cancela clique único em caso de duplo clique
            this.doubleClickTimeout = null;
            //console.log("Duplo clique detectado. Ignorando clique único.");
            return;
        }

        this.doubleClickTimeout = setTimeout(() => {
            this.doubleClickTimeout = null; // Reseta o temporizador após execução

            this.editingTaskIdU05 = task.trabalho_registro; // Define o ID do dropdown sendo editado
            this.editableValueU05 = task.u05 || ""; // Preenche o valor inicial do campo
            this.filterU05Options(); // Filtra as opções
            this.addClickOutsideListenerU05(); // Adiciona o listener para clique fora

            //console.log("Dropdown aberto após atraso para a tarefa:", task.id);
        }, 300); // Tempo configurado para diferenciar clique único e duplo
    },

    async fetchU05Options() {
        await fetchU05Options(this);
    },

    updateFilteredOptionsU05() {
        if (!this.editableValueU05) {
            // Se editableValueU05 for vazio ou nulo, mostre todas as opções válidas
            this.filteredOptionsU05 = this.u05Options.filter(
                option => option.label && option.label.trim() !== ''
            );
            return;
        }

        const searchValueU05 = this.editableValueU05.toLowerCase().trim();


        this.filteredOptionsU05 = this.u05Options.filter((option) => {
            if (!option.label || typeof option.label !== "string" || option.label.trim() === "") {
                //console.warn("Opção inválida encontrada:", option);
                return false; // Ignora itens sem label válido
            }
            return option.label.toLowerCase().trim().includes(searchValueU05);
        });
    },

    handleU05ItemClick(option) {
        this.selectU05Option(option);
        this.editingTaskIdU05 = null;
    },

    filterU05Options() {
        setTimeout(() => {
            // Remove Proxies para trabalhar com dados brutos
            const rawOptionsU05 = this.u05Options.map(option => toRaw(option));

            if (!this.editableValueU05 || this.editableValueU05.trim() === "") {
                // Mostra todas as opções
                this.filteredOptionsU05 = rawOptionsU05.map(option => ({
                    label: option.label || "", // Garante que label seja uma string
                    value: option.value || null,
                }));
            } else {
                // Filtra com base no texto inserido
                this.filteredOptionsU05 = rawOptionsU05
                    .filter(option => {
                        // Certifique-se de que option.label existe e é uma string
                        if (typeof option.label !== "string") {
                            //console.warn("Option label is not a string:", option);
                            return false; // Exclui itens inválidos
                        }

                        return option.label.toLowerCase().includes(this.editableValueU05.toLowerCase());
                    })
                    .map(option => ({
                        label: option.label || "", // Garante que label seja uma string
                        value: option.value || null,
                    }));
            }
            //console.log("Opções filtradas:", this.filteredOptionsU05);
        }, 500);
    },

    async selectU05Option(option) {
        this.editableValueU05 = option.label; // Atualiza o valor editável com o nome da unidade
        const task = this.tasks.find((t) => t.trabalho_registro === this.editingTaskIdU05);

        if (task) {
            this.showSpinner(); // Ativa o spinner

            try {
                task.u05 = option.label; // Nome da unidade
                task.u05_id = option.value;

                const context = {
                    id: task.trabalho_registro, // ID da tarefa
                    u05: task.u05, // Nome da unidade
                    unidade_id: task.u05_id,
                    servico_id: task.servico_registro, // ID do serviço
                };

                //console.log("Contexto enviado para edição:", context);

                // Aguarda a resposta da função antes de ocultar o spinner
                await EditarU05.call(this, context);
                //console.log("Tarefa atualizada com sucesso.");

            } catch (error) {
                console.error("Erro ao atualizar a tarefa:", error);
            } finally {
                this.hideSpinner(); // Garante que o spinner será desativado após a operação
            }

            this.editingTaskIdU05 = null; // Desativa a edição
        } else {
            console.error("Tarefa não encontrada para o ID:", this.editingTaskIdU05);
        }
    }

}

export async function fetchTasks() {
    if (isFetching) {
        //console.warn("fetchTasks já em execução, ignorando nova chamada.");
        return [];
    }

    isFetching = true;

    try {
        // Realiza a requisição para o servidor
        const response = await api.get(`/lista`, {
            params: {
                pesquisar2: state.pesquisarServico.pesquisar2,
                pesquisar1: state.pesquisarServico.pesquisar1,
            },
        });

        //console.log("Resposta recebida da API:", response.data);

        // Cria a estrutura da árvore a partir dos dados retornados
        const estruturaArvore = criarEstruturaArvore(response.data);

        // Mapeia os dados processados
        const tarefasProcessadas = await mapDados(estruturaArvore);

        //console.log("Mapeamento de dados concluído. Dados processados:", tarefasProcessadas);

        return tarefasProcessadas; // Retorna as tarefas processadas
    } catch (error) {
        console.error("Erro ao buscar os dados do orçamento:", error.message);
        return [];
    } finally {
        // Sempre redefinir isFetching para false ao final da execução
        isFetching = false;
    }
}

function criarEstruturaArvore(dados) {
    const estrutura = new Map(); // Usando Map para melhor desempenho

    dados.forEach((item) => {
        const {
            SERVICO_REGISTRO, SERVICO, SERVICO_OBS, SERVICO_UDE_REGISTRO, SERVICO_UDE, SERVICO_REV, NS_ST, SERVICO1_REGISTRO,
            SUBSERVICO_REGISTRO, SUBSERVICO, SUBSERVICO_OBS, SUBSERVICO_UDE, SUBSERVICO_REV,
            ITENS_R, D_D, SD_D, ITENS_UDE, QDE,
            VTMO, VTMA, VTLO, VT, VALOR, F1, F2, F3, F4, U01, U02, U03, U04, U01_R, U02_R, U03_R, U04_R, TIPOA,
            T_R
        } = item;

        // Se não existir SERVICO_REGISTRO, não processa o item.
        if (!SERVICO_REGISTRO) return;

        // Se o serviço ainda não foi criado, adiciona-o.
        if (!estrutura.has(SERVICO_REGISTRO)) {
            estrutura.set(SERVICO_REGISTRO, {
                tipo: "Servico",
                registro: SERVICO_REGISTRO,
                trabalho_registro: T_R,
                id: `${SERVICO_REGISTRO}`,
                servico_registro: SERVICO_REGISTRO,
                subservico_registro: SUBSERVICO_REGISTRO,
                servico_unidade_registro: SERVICO_UDE_REGISTRO,
                servico_ude: SERVICO_UDE,
                servico_rev: SERVICO_REV,
                tarefa: SERVICO,
                servico_descricao: SERVICO,
                servico_observacao: SERVICO_OBS,
                ns_registro: SERVICO1_REGISTRO,
                ns: SERVICO,
                ns_st: NS_ST,

                filhos: [],
            });
        }

        // Obtém o nó do serviço
        const servico = estrutura.get(SERVICO_REGISTRO);

        // Se existir SUBSERVICO_REGISTRO, o item deverá ser adicionado nele
        if (SUBSERVICO_REGISTRO) {
            // Procura o nó do subserviço entre os filhos do serviço
            let subservico = servico.filhos.find((f) => f.registro === SUBSERVICO_REGISTRO);

            // Se não existir, cria o nó de subserviço
            if (!subservico) {
                subservico = {
                    tipo: "Subservico",
                    registro: SUBSERVICO_REGISTRO,
                    id: `${SERVICO_REGISTRO}-${SUBSERVICO_REGISTRO}`,
                    parent: `${SERVICO_REGISTRO}`,
                    servico_registro: SERVICO_REGISTRO,
                    subservico_registro: SUBSERVICO_REGISTRO,
                    tarefa: SUBSERVICO,
                    subservico_descricao: SUBSERVICO,
                    subservico_observacao: SUBSERVICO_OBS,
                    subservico_ude: SUBSERVICO_UDE,
                    subservico_rev: SUBSERVICO_REV || 0,
                    filhos: [],
                };
                servico.filhos.push(subservico);
            }

            // Adiciona o item como filho do subserviço, se existir ITENS_R
            if (ITENS_R) {
                let itemSub = subservico.filhos.find((f) => f.registro === ITENS_R);
                if (!itemSub) {
                    itemSub = {
                        tipo: "Item_subservico",
                        registro: ITENS_R,
                        id: `${SERVICO_REGISTRO}-${SUBSERVICO_REGISTRO}-${ITENS_R}`,
                        parent: `${SERVICO_REGISTRO}`,
                        servico_registro: SERVICO_REGISTRO,
                        subservico_registro: SUBSERVICO_REGISTRO,
                        itens_registro: ITENS_R,
                        itens: D_D,
                        itens_ude: ITENS_UDE,
                        subitens: SD_D,
                        vtmo: VTMO,
                        vtma: VTMA,
                        vtlo: VTLO,
                        vt: VT,
                        valor: VALOR,
                        qde: QDE,
                        f1: F1,
                        u01: U01,
                        u01_id: U01_R,
                        f2: F2,
                        u02: U02,
                        u02_id: U02_R,
                        f3: F3,
                        u03: U03,
                        u03_id: U03_R,
                        f4: F4,
                        u04: U04,
                        u04_id: U04_R,
                        tipoa: TIPOA,

                    };
                    subservico.filhos.push(itemSub);
                }
            }
        } else {
            // Se não existir SUBSERVICO_REGISTRO, adiciona o item diretamente ao serviço
            if (ITENS_R) {
                let itemServico = servico.filhos.find((f) => f.registro === ITENS_R);
                if (!itemServico) {
                    itemServico = {
                        tipo: "Item",
                        trabalho_registro: T_R,
                        registro: ITENS_R,
                        id: `${SERVICO_REGISTRO}-${ITENS_R}`,
                        parent: `${SERVICO_REGISTRO}`,
                        servico_registro: SERVICO_REGISTRO,
                        subservico_registro: SUBSERVICO_REGISTRO,
                        itens_registro: ITENS_R,
                        itens: D_D,
                        itens_ude: ITENS_UDE,
                        subitens: SD_D,
                        vtmo: VTMO,
                        vtma: VTMA,
                        vtlo: VTLO,
                        vt: VT,
                        valor: VALOR,
                        qde: QDE,
                        f1: F1,
                        u01: U01,
                        u01_id: U01_R,
                        f2: F2,
                        u02: U02,
                        u02_id: U02_R,
                        f3: F3,
                        u03: U03,
                        u03_id: U03_R,
                        f4: F4,
                        u04: U04,
                        u04_id: U04_R,
                        tipoa: TIPOA,
                    };
                    servico.filhos.push(itemServico);
                }
            }
        }
    });
    return Array.from(estrutura.values());

}

export async function mapDados(tree) {

    //saveTasksState = JSON.parse(localStorage.getItem("tasksStateOrcamento")) || {};

    try {
        // Processa os nós da árvore recursivamente
        const tasks = [];

        const processNode = (node) => {
            const processedNode = {
                id: node.id || null,
                trabalho_registro: node.trabalho_registro || null,
                tarefa: node.tarefa || '',
                tipo: node.tipo || '',
                parent: node.parent || '',
                qde: node.qde || '',
                vmo: node.vtmo || '',
                vma: node.vtma || '',
                vlo: node.vtlo || '',
                vt: node.vt || '',
                valor: node.valor || '',
                servico_registro: node.servico_registro || '',
                servico: node.servico_descricao || '',
                servico_obs: node.servico_observacao || '',
                u05_id: node.servico_unidade_registro || '',
                u05: node.servico_ude || '',
                servico_rev: node.servico_rev || '',
                subservico_registro: node.subservico_registro || '',
                subservico: node.subservico_descricao || '',
                subservico_obs: node.subservico_observacao || '',
                subservico_ude: node.subservico_ude || '',
                subservico_rev: node.subservico_rev || 0,
                ns_registro: node.ns_registro || '',
                ns: node.ns || '',
                ns_st: node.ns_st || '',
                itens_registro: node.itens_registro || '',
                itens: node.itens || '',
                itens_ude: node.itens_ude || '',
                subitens: node.subitens || '',
                f1: node.f1 || '',
                u01: node.u01 || '',
                u01_id: node.u01_r || '',
                f2: node.f2 || '',
                u02: node.u02 || '',
                u02_id: node.u02_r || '',
                f3: node.f3 || '',
                u03: node.u03 || '',
                u03_id: node.u03_r || '',
                f4: node.f4 || '',
                u04: node.u04 || '',
                u04_id: node.u04_r || '',
                tipoa: node.tipoa || '',
                visible: node.visible !== undefined ? node.visible : true,
            };

            tasks.push(processedNode);

            // Processa os filhos do nó atual
            if (node.filhos && node.filhos.length > 0) {
                node.filhos.forEach((child) => processNode(child, processedNode));
            }
        };

        tree.forEach((rootNode) => processNode(rootNode));

        // Calcula as tarefas (aguarda a conclusão de calcularTasks)
        await calcularTasks(tasks);

        return tasks;
    } catch (error) {
        console.error("Erro ao processar tarefas:", error.message);
        throw error; // Lança o erro para ser tratado no nível superior
    }
}

export async function calcularTasks(tasks) {

    tasks.forEach(task => {
        const servicos_id_alvo = task.servico_registro;
        const subservicos_id_alvo = task.subservico_registro;

        /////////////////////calculos servicos
        let vmo_servico;
        const vmo_servico_a = tasks.reduce((acumulador, task) => {
            // Verifica se o projeto corresponde e o tipo é 'Item'
            if (task.servico_registro === servicos_id_alvo) {

                // Calcula o valor acumulado para essa servico
                return acumulador + (task.vmo || 0);
            }
            return acumulador;
        }, 0);
        if (vmo_servico_a != null) {
            vmo_servico = vmo_servico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vmo_servico = "R$0,00";
        }
        task.vmo_servico = vmo_servico;

        let vma_servico;
        const vma_servico_a = tasks.reduce((acumulador, task) => {
            // Verifica se o projeto corresponde e o tipo é 'Item'
            if (task.servico_registro === servicos_id_alvo) {

                // Calcula o valor acumulado para essa servico
                return acumulador + ((task.vma || 0));
            }
            return acumulador;
        }, 0);
        if (vma_servico_a != null) {
            vma_servico = vma_servico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vma_servico = "R$0,00";
        }
        task.vma_servico = vma_servico;

        let vlo_servico;
        const vlo_servico_a = tasks.reduce((acumulador, task) => {
            if (task.servico_registro === servicos_id_alvo) {

                // Calcula o valor acumulado para essa servico
                return acumulador + ((task.vlo || 0));
            }
            return acumulador;
        }, 0);
        if (vlo_servico_a != null) {
            vlo_servico = vlo_servico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vlo_servico = "R$0,00";
        }
        task.vlo_servico = vlo_servico;

        let vt_servico;
        const vt_servico_a = tasks.reduce((acumulador, task) => {
            if (task.servico_registro === servicos_id_alvo) {

                // Calcula o valor acumulado para essa servico
                return acumulador + ((task.vt || 0));
            }
            return acumulador;
        }, 0);
        if (vt_servico_a != null) {
            vt_servico = vt_servico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vt_servico = "R$0,00";
        }
        task.vt_servico = vt_servico;

        /////////////////////calculos subservicos
        let vmo_subservico;
        const vmo_subservico_a = tasks.reduce((acumulador, task) => {
            // Verifica se o projeto corresponde e o tipo é 'Item'
            if (task.servico_registro === servicos_id_alvo && task.subservico_registro === subservicos_id_alvo) {

                // Calcula o valor acumulado para essa subservico
                return acumulador + (task.vmo || 0);
            }
            return acumulador;
        }, 0);
        if (vmo_subservico_a != null) {
            vmo_subservico = vmo_subservico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vmo_subservico = "R$0,00";
        }
        task.vmo_subservico = vmo_subservico;

        let vma_subservico;
        const vma_subservico_a = tasks.reduce((acumulador, task) => {
            // Verifica se o projeto corresponde e o tipo é 'Item'
            if (task.servico_registro === servicos_id_alvo && task.subservico_registro === subservicos_id_alvo) {

                // Calcula o valor acumulado para essa subservico
                return acumulador + ((task.vma || 0));
            }
            return acumulador;
        }, 0);
        if (vma_subservico_a != null) {
            vma_subservico = vma_subservico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vma_subservico = "R$0,00";
        }
        task.vma_subservico = vma_subservico;

        let vlo_subservico;
        const vlo_subservico_a = tasks.reduce((acumulador, task) => {
            if (task.servico_registro === servicos_id_alvo && task.subservico_registro === subservicos_id_alvo) {

                // Calcula o valor acumulado para essa subservico
                return acumulador + ((task.vlo || 0));
            }
            return acumulador;
        }, 0);
        if (vlo_subservico_a != null) {
            vlo_subservico = vlo_subservico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vlo_subservico = "R$0,00";
        }
        task.vlo_subservico = vlo_subservico;

        let vt_subservico;
        const vt_subservico_a = tasks.reduce((acumulador, task) => {
            if (task.servico_registro === servicos_id_alvo && task.subservico_registro === subservicos_id_alvo) {

                // Calcula o valor acumulado para essa subservico
                return acumulador + ((task.vt || 0));
            }
            return acumulador;
        }, 0);
        if (vt_subservico_a != null) {
            vt_subservico = vt_subservico_a.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            vt_subservico = "R$0,00";
        }
        task.vt_subservico = vt_subservico;

    });

    return tasks; // Retorna as tarefas calculadas, se necessário
}

export async function Servico_adicionar() {
    try {
        // Salva o estado atual antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Faz o POST para adicionar o serviço
        const response = await api.post("/lista/adicionar_servico");

        // Zera o `tasks` para forçar a reatividade
        this.tasks.splice(0, this.tasks.length); // Limpa o array reativo

        // Atualiza as tarefas com os dados do servidor
        const updatedTasks = await fetchTasks();
        //console.log("Tarefas atualizadas:", updatedTasks);

        // Restaura o estado salvo
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible; // Restaura o estado de visibilidade
            }
        });

        // Atualiza o `tasks` com as tarefas processadas
        this.tasks.splice(0, this.tasks.length, ...updatedTasks);
        //console.log("Serviço adicionado com sucesso!");
        return response.data; // Retorna os dados da resposta
    } catch (error) {
        console.error("Erro ao adicionar serviço:", error);
        throw error;
    }
}

export function handleEditarNomeServico(task, context) {
    if (!task) {
        console.warn("Nenhuma tarefa foi selecionada.");
        return;
    }

    try {
        //console.log("Contexto recebido na edição:", context);
        //console.log("Tarefa recebida:", task);

        EditarNomeServico(task, context) // Passa o task e o contexto para a função de edição
            .then((response) => {
                console.log("Tarefa editada com sucesso:", response);
            })
            .catch((error) => {
                console.error("Erro ao editar tarefa:", error);
            });
    } catch (error) {
        console.error("Erro na função handleEditarNomeServico:", error);
    }
}

export async function EditarNomeServico(task, context) {

    try {
        if (!context.tasks || !Array.isArray(context.tasks)) {
            console.error("Tasks não está definido ou não é um array.");
            return;
        }

        if (task.isProcessing) return;
        task.isProcessing = true;

        // Salva o estado atual antes de atualizar
        const savedState = context.tasks.map((t) => ({
            id: t.id,
            visible: t.visible,
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz o POST para editar o serviço
        const response = await api.post('/lista/editar_servico', {
            ns_id: task.ns_registro || '',
            servico_id: state.EditarLista.servico_id || '',
        });

        // Atualiza as tarefas
        const updatedTasks = await fetchTasks();

        // Atualiza o estado das tarefas preservando a reatividade
        context.tasks.splice(0, context.tasks.length, ...updatedTasks.map((t) => {
            const savedTask = savedState.find((st) => st.id === t.id);
            return savedTask ? { ...t, visible: savedTask.visible } : t;
        }));

        // Aguarda o DOM ser renderizado e restaura a posição de rolagem
        setTimeout(() => {
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition);
            });
        }, 0);

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao editar serviço:", error);
        throw error;
    } finally {
        state.EditarLista.servico_id = '';
        task.isProcessing = false;
    }
}

export async function Servico_excluir() {
    try {
        // Salva o estado atual antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz o POST para excluir o serviço
        const response = await api.post('/lista/excluir_servico', {
            servico_id: state.EditarLista.servico_id || '',
        });

        // Modifica o array diretamente, sem recriar completamente
        const updatedTasks = await fetchTasks();

        updatedTasks.forEach((task, index) => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                updatedTasks[index].visible = savedTask.visible;
            }
        });

        // Substitui diretamente os elementos em vez de recriar o array
        this.tasks.splice(0, this.tasks.length, ...updatedTasks);
        state.EditarLista.servico_id = '';

        // Aguarda o DOM ser renderizado e restaura a posição de rolagem
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, scrollPosition);
            }, 0);
        });

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao excluir serviço:", error);
        throw error;
    }
}

export async function SalvarServicoObs({ servico_registro, servico_obs }, context) {
    try {
        if (!context || !Array.isArray(context.tasks)) {
            console.error("Contexto ou tasks não está definido ou tasks não é um array.");
            return;
        }

        const task = context.tasks.find((t) => t.servico_registro === servico_registro);

        if (!task) {
            console.error("Task não encontrada para o registro:", servico_registro);
            return;
        }

        if (task.isProcessing) return;
        task.isProcessing = true;

        // Salva o estado atual antes de atualizar
        const savedState = context.tasks.map((t) => ({
            id: t.id,
            visible: t.visible,
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz o POST para editar o serviço
        const response = await api.post('/lista/editar_servicoobs', {
            servico_registro,
            servico_obs,
        });

        // Atualiza as tarefas
        const updatedTasks = await fetchTasks();

        // Atualiza o estado das tarefas de forma reativa
        context.tasks.length = 0; // Limpa o array preservando a referência

        updatedTasks.forEach((t) => {
            const savedTask = savedState.find((st) => st.id === t.id);
            context.tasks.push(savedTask ? { ...t, visible: savedTask.visible } : t);
        });

        // Aguarda o DOM ser renderizado e restaura a posição de rolagem
        setTimeout(() => {
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition);
            });
        }, 0);

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
        throw error;
    } finally {
        if (task) {
            task.isProcessing = false;
        }
    }
}

export async function SalvarServicoRev({ servico_registro, servico_rev }) {
    //console.log("servico_registro:", servico_registro, "servico_rev:", servico_rev);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_servicorev', {
            servico_registro,
            servico_rev,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function SalvarItemf1({ id, f1 }) {
    //console.log("id:", id, "f1:", f1);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_item-f1', {
            id,
            f1,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function SalvarItemf2({ id, f2 }) {
    console.log("id:", id, "f2:", f2);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_item-f2', {
            id,
            f2,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function SalvarItemf3({ id, f3 }) {
    console.log("id:", id, "f3:", f3);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_item-f3', {
            id,
            f3,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function SalvarItemf4({ id, f4 }) {
    console.log("id:", id, "f4:", f4);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_item-f4', {
            id,
            f4,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function SalvarItemtipo({ id, tipo }) {
    //console.log("id:", id, "tipo:", tipo);

    try {
        // Adiciona verificação se a tarefa já está sendo processada
        if (this.task?.isProcessing) return;
        this.task.isProcessing = true;

        // Verifica se `tipo` é válido e define `tipoa` com base nele
        let tipoa;
        if (tipo === 'ma') {
            tipoa = 2;
        } else if (tipo === 'mo') {
            tipoa = 1;
        } else if (tipo === 'lo') {
            tipoa = 3;
        } else {
            exibirMensagem("O tipo fornecido está errado. Por favor, verifique. Mo = mão de obra, MA = material e LO = locação de equipamentos"); // Mensagem de erro
            this.task.isProcessing = false; // Libera o flag
            return; // Sai da função
        }



        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_item-tipo', {
            id,
            tipo: tipoa, // Passa `tipoa` para o backend
        });

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        this.task.isProcessing = false; // Libera o flag após o processamento
        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
        this.task.isProcessing = false; // Libera o flag em caso de erro
    }
}

export async function SalvarItemvalor({ id, valor }) {
    console.log("id:", id, "valor:", valor);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/editar_item-valor', {
            id,
            valor,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function AdicionarItem({ id }) {
    //console.log("id:", id);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/adicionar_item', {
            id,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function ExcluirItem({ task }) {
    console.log("task:", task);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/excluir_item', {
            item_id: task.item_id,
            id: task.id,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function ExcluirSubservico({ task }) {
    //console.log("task:", task);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/excluir_subservico', {
            servico_id: task.servico_registro,
            subservico_id: task.subservico_registro,
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function ServicoCopiar({ id }) {
    //console.log("task:", task);

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/copiar_servico', {
            id
        });

        //console.log('Serviço Obs atualizado com sucesso:', response.data);

        // Atualize `tasks` e restaure o estado
        const updatedTasks = await fetchTasks();
        updatedTasks.forEach(task => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                task.visible = savedTask.visible;
            }
        });

        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Restaura a posição de rolagem
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao salvar Serviço Obs:", error);
    }
}

export async function Subservico_adicionar() {
    // Validações iniciais antes de executar qualquer chamada assíncrona
    if (!state.EditarLista.subservico_fator) {
        exibirMensagem("O fator não foi definido. Preencha antes de continuar.");
        return;
    }

    if (!state.EditarLista.subservico_id) {
        exibirMensagem("O subserviço não foi definido. Preencha antes de continuar.");
        return;
    }

    try {
        // Salva o estado atual das tarefas antes de atualizar
        const savedState = this.tasks.map(task => ({
            id: task.id,
            visible: task.visible, // Salva o estado de visibilidade
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz a requisição ao backend para adicionar o subserviço
        const response = await api.post('/lista/adicionar_subservico', {
            servico_id: state.EditarLista.servico_id || '',
            servico_unidade_id: state.EditarLista.servico_unidade_id || '',
            subservico_fator: state.EditarLista.subservico_fator || '',
            subservico_id: state.EditarLista.subservico_id || '',
        });

        //console.log("Resposta do backend:", response.data);

        // Atualiza as tarefas com os dados do servidor
        const updatedTasks = await fetchTasks();

        // Restaura o estado de visibilidade das tarefas
        updatedTasks.forEach((task, index) => {
            const savedTask = savedState.find(t => t.id === task.id);
            if (savedTask) {
                updatedTasks[index].visible = savedTask.visible;
            }
        });

        // Atualiza o array reativo das tarefas
        this.tasks.splice(0, this.tasks.length, ...updatedTasks);

        // Reseta os valores no estado
        state.EditarLista.servico_id = null;
        state.EditarLista.servico_unidade_id = null;
        state.EditarLista.subservico_fator = { value: null, isProcessing: false };
        state.EditarLista.subservico_id = { value: null, isProcessing: false };

        // Restaura a posição de rolagem após a atualização
        this.$nextTick(() => {
            window.scrollTo(0, scrollPosition);
        });

        // Exibe uma mensagem de sucesso
        //exibirMensagem("Subserviço adicionado com sucesso!");
        document.querySelectorAll('.input-subservico_registro').forEach(input => input.value = '');
        document.querySelectorAll('.input-subservico_fator').forEach(input => input.value = '');

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao adicionar subserviço:", error);
        exibirMensagem("Erro ao adicionar subserviço. Tente novamente.");
    }
}

export async function SalvarTarefa(task, context) {
    console.log("task recebida para salvar:", task);

    if (task.isProcessing) return;
    task.isProcessing = true;

    if (!task.tarefa) {
        exibirMensagem("Esta tarefa está vazia."); // Mensagem personalizada
        task.isProcessing = false;
        return;
    }

    try {
        const response = await axios.post("http://localhost:10000/api/tarefa/editar", task);
        console.log("Tarefa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchTasks no contexto recebido
        context.tasks = await fetchTasks(state.pesquisarTarefa.tarefa_id, state.pesquisarTarefa.pesquisar1, state.pesquisarTarefa.pesquisar2, state.pesquisarTarefa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar tarefa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function AdicionarTarefa(task, context) {

    try {
        const response = await axios.post("http://localhost:10000/api/tarefa/adicionar", task);
        //console.log("Tarefa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchTasks no contexto recebido
        context.tasks = await fetchTasks(state.pesquisarTarefa.tarefa_id, state.pesquisarTarefa.pesquisar1, state.pesquisarTarefa.pesquisar2, state.pesquisarTarefa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar tarefa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function ExcluirTarefa(task, context) {
    //console.log("task", task);
    try {
        const response = await axios.post("http://localhost:10000/api/tarefa/excluir", task);
        //console.log("Tarefa salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchTasks no contexto recebido
        context.tasks = await fetchTasks(state.pesquisarTarefa.tarefa_id, state.pesquisarTarefa.pesquisar1, state.pesquisarTarefa.pesquisar2, state.pesquisarTarefa.pesquisar3);

        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar tarefa:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function handleEditarItem(task, context, showSpinner, hideSpinner) {
    if (!task) {
        console.warn("Nenhuma tarefa foi selecionada.");
        return;
    }

    try {
        console.log("Contexto recebido na edição:", context);
        console.log("Tarefa recebida:", task);

        if (showSpinner) showSpinner(); // Ativa o spinner se a função foi passada

        // Aguarda a resposta da função antes de ocultar o spinner
        const response = await EditarItem(task, context);
        console.log("Tarefa editada com sucesso:", response);

    } catch (error) {
        console.error("Erro ao editar tarefa:", error);
    } finally {
        if (hideSpinner) hideSpinner(); // Garante que o spinner será desativado
    }
}

export async function EditarItem(task, context) {
    //console.log("state.EditarLista.id", state.EditarLista.id);
    try {
        if (!context || !context.tasks || !Array.isArray(context.tasks)) {
            //console.error("Contexto ou tasks não está definido ou não é um array.");
            return;
        }

        if (!state || !state.EditarLista || !state.EditarLista.id) {
            //console.error("State ou EditarLista não está definido.");
            return;
        }

        if (!task) {
            //console.error("Task não está definido.");
            return;
        }

        if (task.isProcessing) return;
        task.isProcessing = true;

        // Salva o estado atual antes de atualizar
        const savedState = context.tasks.map((t) => ({
            id: t.id,
            visible: t.visible,
        }));

        // Salva a posição de rolagem atual
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Faz o POST para editar o serviço
        const response = await api.post('/lista/editar-item', {
            item_id: task.id || '',
            id: state.EditarLista.id || '',
        });

        // Atualiza as tarefas
        const updatedTasks = await fetchTasks();

        // Atualiza o estado das tarefas de forma reativa
        context.tasks.length = 0; // Limpa o array preservando a referência

        updatedTasks.forEach((t) => {
            const savedTask = savedState.find((st) => st.id === t.id);
            context.tasks.push(savedTask ? { ...t, visible: savedTask.visible } : t);
        });

        // Aguarda o DOM ser renderizado e restaura a posição de rolagem
        setTimeout(() => {
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition);
            });
        }, 0);

        // Retorna os dados da resposta
        return response.data;
    } catch (error) {
        console.error("Erro ao editar serviço:", error);
        throw error;
    } finally {
        if (state && state.EditarLista) {
            state.EditarLista.item_id = '';
        }
        if (task) {
            task.isProcessing = false;
        }
    }
}

function exibirMensagem(mensagem) {
    const messageContainer = document.createElement('div');
    messageContainer.innerText = mensagem;
    messageContainer.style.position = 'fixed';
    messageContainer.style.top = '120px';
    messageContainer.style.left = '50%';
    messageContainer.style.transform = 'translateX(-50%)';
    messageContainer.style.backgroundColor = '#ff0'; // Cor de fundo amarela
    messageContainer.style.color = 'red'; // Cor do texto branca
    messageContainer.style.padding = '10px';
    messageContainer.style.borderRadius = '5px';
    messageContainer.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontWeight = 'bold'; // Deixa o texto em negrito
    messageContainer.style.textAlign = 'center'; // Centraliza o texto
    document.body.appendChild(messageContainer);

    // Remove a mensagem após 3 segundos
    setTimeout(() => {
        document.body.removeChild(messageContainer);
    }, 3000);
}

export async function fetchU01Options(context) {
    //console.log("registro");
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.u01Options = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar

        }));
        //console.log("u01Options carregadas:", context.u01Options);
    } catch (error) {
        console.error("Erro ao carregar as opções de Descricao:", error);
    }
}

export async function fetchU02Options(context) {
    //console.log("registro");
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.u02Options = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar

        }));
        //console.log("u02Options carregadas:", context.u02Options);
    } catch (error) {
        console.error("Erro ao carregar as opções de Descricao:", error);
    }
}

export async function fetchU03Options(context) {
    //console.log("registro");
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.u03Options = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar

        }));
        //console.log("u03Options carregadas:", context.u03Options);
    } catch (error) {
        console.error("Erro ao carregar as opções de Descricao:", error);
    }
}

export async function fetchU04Options(context) {
    //console.log("registro");
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.u04Options = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar

        }));
        //console.log("u04Options carregadas:", context.u04Options);
    } catch (error) {
        console.error("Erro ao carregar as opções de Descricao:", error);
    }
}

export async function fetchU05Options(context) {
    //console.log("registro");
    try {
        const registro = 1; // Usa o ID da tarefa ou valor fixo como registro
        //console.log("Carregando dados para o registro:", registro);

        // Faz a requisição para o servidor
        const response = await api.get(`/unidade`, {
            params: { registro },
        });

        // Atualiza as opções de UDE no contexto do componente
        context.u05Options = response.data.map(option => ({
            label: option.unidade, // Nome da unidade para exibição
            value: option.registro // ID da unidade para salvar

        }));
        //console.log("u04Options carregadas:", context.u04Options);
    } catch (error) {
        console.error("Erro ao carregar as opções de Descricao:", error);
    }
}

export async function EditarU01(context) {
    console.log("context", context);
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/lista/editar-unidade-01", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarU02(context) {
    //console.log("context", context);
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/lista/editar-unidade-02", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarU03(context) {
    console.log("context", context);
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/lista/editar-unidade-03", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarU04(context) {
    //console.log("context", context);
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/lista/editar-unidade-04", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}

export async function EditarU05(context) {
    //console.log("context", context);
    //console.log("task recebida para salvar:", context);

    try {
        const response = await api.post("/lista/editar-unidade-05", context);
        //console.log("Setor salvo com sucesso:", response.data);

        // Atualiza a tabela chamando fetchSetor no contexto recebido


        return response.data; // Retorna os dados salvos
    } catch (error) {
        console.error("Erro ao salvar setor:", error);
        throw error;
    } finally {
        task.isProcessing = false;
    }
}













